/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useOutsideClick } from "hooks";
import styled from "styled-components";
import { AiFillCaretDown, AiOutlineClose } from "react-icons/ai";
import { useMainContext } from "contexts";
import "./style.scss";

export const FilterableDropDown = ({
  id = "",
  value,
  onChange,
  label,
  className,
  backgroundColor = "#eee",
  placeholder = "Search or type here",
  width,
  idField = "id",
  inputId,
  displayValue = "",
  onSelectId = () => {},
  onSelectValue = () => {},
  onClick = () => {},
  onFocus = () => {},
  data = [],
}) => {
  const menuRef = useRef(null);
  const [search, setSearch] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [innerValue, setInnerValue] = useState("");
  const { postPageEvents, activeField } = useMainContext();

  useOutsideClick(menuRef, clickOutsideMenu);
  function clickOutsideMenu() {
    setShowResults(false);
  }
  const handleChange = (params) => {
    if (params !== value) {
      setSearch("");
      onChange(params[idField], params.title);
      onSelectId(params[idField]);
      setInnerValue(params.title);
      onSelectValue(params.title, params);
      postPageEvents("click", id || label);
    }
    setShowResults(false);
  };

  const [focused, setFocused] = useState(false);

  const handleBlur = (event) => {
    setFocused(false);
    onFocus(false);
  };

  const handleClear = () => {
    onChange("");
    setSearch("");
    onSelectId("");
    setInnerValue("");
    onSelectValue("");
  };

  useEffect(() => {    
    if (activeField !== inputId && innerValue) {
      setShowResults(false);
    }
    return () => {};
  }, [activeField, inputId]);

  useEffect(() => {
    if (displayValue) {
      setInnerValue(displayValue);
    }
    return () => {};
  }, [displayValue]);

  useEffect(() => {
    if (!value) {
      setInnerValue("");
    }
    return () => {};
  }, [value]);

  useEffect(() => {
    if (search) {
      setShowResults(true);
    }
    return () => {};
  }, [search]);

  return (
    <div className={className} onClick={onClick}>
      {label && <span className="select-label my-auto">{label}</span>}
      <div className="filterable-drop-down" style={{ width }}>
        <div className="menu-button" style={{ width }}>
          {innerValue ? (
            <>
              <InnerValue className="mr-auto" value={innerValue} id={inputId} readOnly />
              <button
                className="menu-button__drop-down"
                onClick={() => handleClear()}
              >
                <AiOutlineClose color="white" alt="clear" />
              </button>
            </>
          ) : (
            <div className="d-flex w-100">
              <StyledInput
                type="text"
                id={inputId || Math.random(100)}
                aria-labelledby={"auto complete"}
                autocomplete="chrome-off"
                onFocus={() => {
                  setFocused(true);
                  onFocus(true);
                  postPageEvents("focus", id || label);
                }}
                onBlur={handleBlur}
                aria-describedby={"auto complete"}
                value={search}
                placeholder={placeholder}
                onClick={() => setShowResults(true)}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />

              <AiFillCaretDown
                color="white"
                className="menu-button__drop-down"
                alt="drop-down"
              />
            </div>
          )}

          <Underline focused={focused} />
        </div>
        {showResults && (
          <div ref={menuRef} className="menu-dropdown">
            {data.filter((item) =>
              item.title.toLowerCase().includes(search.toLowerCase())
            ) &&
            data.filter((item) =>
              item.title.toLowerCase().includes(search.toLowerCase())
            ).length > 0 ? (
              data
                .filter((item) =>
                  item.title.toLowerCase().includes(search.toLowerCase())
                )
                .map((item, index) => {
                  return (
                    <div
                      className={classNames(
                        "menu-item",
                        item.title === value ? "active" : ""
                      )}
                      key={`key${index}`}
                      onClick={() => handleChange(item)}
                      style={{ width }}
                    >
                      {item.title}
                    </div>
                  );
                })
            ) : (
              <div className="my-3 d-flex justify-content-center">
                No results
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const StyledInput = styled.input`
  background: transparent;
  color: white;
  border: none;
  height: 32px;
  line-height: 32px;
  width: calc(100% - 24px);
  font-size: 14px;
  outline: none;
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
`;

const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;

const InnerValue = styled.input`
  background: transparent;
  border: none;
  outline: none;
  color: white;
  width: 300px;
  &:focus {
    border: none;
    outline: none;
  }
`;
