/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { useInput } from "hooks";
import AutocompleteFreeText from "components/AutocompleteFreeText";
import { PHARMACY_SERVICE_PREDICTIVE_TEXT } from "config";

export const IncentivePharmacyServiceTextBuilder = ({
  className,
  value = "",
  onChange = () => {},
}) => {
  const val = useInput("");

  const handleUpdateValue = (v) => {
    onChange(`${v}`);
  };

  useEffect(() => {
    if (value) {
      val.setValue(value);
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <AutocompleteFreeText
          label='Free Text'
          value={value}
          data={PHARMACY_SERVICE_PREDICTIVE_TEXT}
          onChange={e => handleUpdateValue(e)}
        />
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
