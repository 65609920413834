/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Modal, Button } from "components";

export const CircularCloseModal = ({
  open,
  onClose,
  onConfirm = () => {},
  circulars
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = async (e) => {
    setIsLoading(true);
    e.stopPropagation();
    onConfirm();
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} size="medium" title={"Confirm Close Circular"}>
      <div>
        {circulars.length > 1 ? (
          <div>Are you sure you want to close these circulars?</div>
        ): (
          <div>Are you sure you want to close this circular?</div>
        )}
        
      </div>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          buttonTheme="error"
          width="fit-content"
          className="ml-4"
          type="button"
          isLoading={isLoading}
          onClick={(e) => handleConfirm(e)}
        >
          Confirm 
        </Button>
      </div>
    </Modal>
  );
};
