import { API, PEP_API } from "utils";
import { COUNT_PER_PAGE } from "config";


export const getCircularsApi = async ({
  page = 0,
  per_page = COUNT_PER_PAGE,
  sort_by = "created_at",
  sort_dir = "ASC",
  retailer_id,
  created_time_from,
  created_time_to,
  circ_time_from,
  circ_time_to,
  stage = undefined,
  active_retailers = true,
  tier = undefined,
  ad_blocks_count
}) => {
  try {
    const response = await API().get("api/circulars", {
      params: {
        page,
        per_page,
        sort_by,
        sort_dir,
        retailer_id,
        created_time_from,
        created_time_to,
        circ_time_from,
        circ_time_to,
        stage: stage ? stage.join(",") : undefined,
        active_retailers,
        tier: tier ? tier.join(",") : undefined,
        ad_blocks_count
      },
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getCircularById = async (id) => {
  try {
    const response = await API().get(`api/circulars/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getCircularByExtID = async (id) => {
  try {
    const response = await API().get(`api/circulars/ext_id/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
}

export const getCircularByObjID = async (id) => {
  try {
    const response = await API().get(`api/circulars/id/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
}

export const deleteCircularById = async (id) => {
  try {
    const response = await API().delete(`api/circulars/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getCircularPages = async (id) => {
  try {
    const response = await API().get(`api/circulars/${id}/pages`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const addCircularPages = async (id, data) => {
  try {
    const response = await API().post(`api/circulars/${id}/add-page`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const approveCircular = async (id) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).post(`api/circulars/${id}/approve`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const retailersRollup = async ({
  circ_week_dt,
  dt_type,
  tier
}) => {
  try {
    const response = await API().get(`api/circulars/rollup`, {
      params: {
        circ_week_dt,
        dt_type,
        tier: tier ? tier.join(",") : undefined,
      }
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const retailersRollupCirculars = async ({
  circ_week_dt,
  retailer_id,
  dt_type
}) => {
  try {
    const response = await API().get(`api/circulars/rollup/circulars`, {      
      params: {
        circ_week_dt,
        retailer_id,
        dt_type
    }});
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const addAdblocksToQueueByCircular = async (id, high_priority=false) => {
  let endpoint = ''
  try {
    if (high_priority){
      endpoint = `api/circulars/${id}/queue-stage?high_priority=true`;
    } else {
      endpoint = `api/circulars/${id}/queue-stage`;
    }
    console.log(high_priority, endpoint)
    const response = await PEP_API().post(endpoint);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const addAdblocksToQueueByCirculars = async (circulars, high_priority=false) => {
  let circularsPaths = ''
  if (high_priority) {
    circularsPaths = circulars.circulars.map((item, index) => {
      return `api/circulars/${item.uuid}/queue-stage?high_priority=true`
    })
  } else {
    circularsPaths = circulars.circulars.map((item, index) => {
      return `api/circulars/${item.uuid}/queue-stage`
    })
  }

  try {

    const response = await Promise.all(circularsPaths.map((endpoint) => PEP_API().post(endpoint)));
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const closeCirculars = async (id) => {
  try {
    const response = await API().post(`api/circulars/${id}/close`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const batchCloseCirculars = async (circulars) => {

  let circularsPaths = circulars.circulars.map((item, index) => {
    return `api/circulars/${item.uuid}/close`
  })

  try {

    const response = await Promise.all(circularsPaths.map((endpoint) => API().post(endpoint)));
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const resetCirculars = async (id) => {
  try {
    const response = await API().post(`api/circulars/${id}/reset`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const relayCicular = async (id, staging = false) => {
  try {
    const response = await API().post(`api/circulars/${id}/relay-circular?staging=${staging}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};


export const setHPCircular = async (circulars) => {
  let circularsPaths = circulars.circulars.map((item, index) => {
      return `api/circulars/${item.uuid}/update-circular-priority`
    })
  try {
    const response = await Promise.all(circularsPaths.map((endpoint) => PEP_API().post(endpoint)));
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
