import styled from "styled-components";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";

export const TableHeaderColumn = ({
  name,
  label,
  onSort = () => {},
  sortCol,
  sortType,
}) => {
  const handleSort = () => {
    onSort(name, sortType === 1 ? 2 : 1);
  };

  return (
    <RowItem>
      <div className="d-flex">
        <span onClick={handleSort} className="my-auto cursor-pointer">
          {label}
        </span>
        {name && (
          <SortIcon
            type={sortCol === name ? sortType : 0}
            onClick={handleSort}
          />
        )}
      </div>
    </RowItem>
  );
};

export const SortIcon = ({ type, onClick = () => {} }) => {
  return (
    <div
      className="d-flex ml-1 flex-column justify-content-center"
      onClick={onClick}
    >
      <Icon disabled={type !== 1}>
        <BsFillCaretUpFill size={10} />
      </Icon>
      <Icon disabled={type !== 2}>
        <BsFillCaretDownFill size={10} />
      </Icon>
    </div>
  );
};

const Icon = styled.div`
  cursor: pointer;
  width: 12px;
  height: 12px;
  height: fit-content;
  transition: all 300ms ease;
  display: flex;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  svg {
    margin: auto;
  }
`;

const RowItem = styled.div`
  margin: auto 0px auto 0;
`;
