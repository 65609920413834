import * as Yup from "yup";


export const offerValidationSchema = Yup.object({
    reg_price: Yup.number().default(null).nullable().typeError('Invalid Entry for Regular Price Start'),
    reg_price2_d: Yup.number().default(null).nullable().typeError('Invalid Entry for Regular Price End'),
    limit: Yup.number().default(null).nullable().typeError('Invalid Entry for Limit'),
    min_qty: Yup.mixed().nullable().test('is-valid', 'Invalid Entry For Minimum Quantity', function (value) {
        if (value === null) return true;
        if (typeof value === 'number') return true;
        if (typeof value === 'string') {
            if (/^\$\d+(\.\d{1,2})?$/.test(value)) return true; // Checks for currency format
            if (!isNaN(value)) return true; // Checks if the string is a valid number
        }
        return false;
    }).default(null)
})

export const adblockOfferValidationSchema = Yup.object({
    coupon_amnt: Yup.number().default(null).nullable(),
    rebate_amnt: Yup.number().default(null).nullable()
})

export const priceEaValidationSchema = Yup.object({})
