import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { SideTop, ScrollView, DropDown2, Accordion, CheckBox } from "components";
import { times } from "lodash";
import { stringify } from "query-string";
import { navigate } from "@reach/router";
import { useRetailersRollupContext } from "contexts";
import { FaSearch } from "react-icons/fa";

import {
  TableHeader,
  TableRow
} from "./components";
import { Radio, RadioGroup,FormControlLabel, withStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { getTiers } from "api";

export const RetailersRollupPage = () => {
  const [sortCol, setSortCol] = useState("");
  const [sortType, setSortType] = useState(0);
  const [circularDateList, setCircularDateList] = useState([]);
  const [initalLoad, setInitalLoad] = useState(false);
  const [tiers, setTiers] = useState([]);

  const dateTypeRadio = [
    {
      id: "circ",
      title: "Circular Week Date"
    },
    {
      id: "created",
      title: "Circular Created Date"
    }
  ]
  
  const {
    getRetailersRollup,
    retailers = [],
    tier = [],
    circ_week_dts = [],
    circ_week_dt,
    dt_type,
    isLoading,
    totalCount = 0,
    setFilter
  } = useRetailersRollupContext();

  const init = async () => {
    setFilter({dt_type: 'circ'})
    getRetailersRollup(dt_type);
    const res = await getTiers();
    if(res.success) {
       const tiersMapped = res.results.tiers.map((item) => ({
          ...item,
          title: item.ext_id,
          id: item.ext_id
        }));
        setTiers(tiersMapped);
    }else{
        //Raise Error
    }
  };

  useEffect(() => {
    //setFilter({ retailerId: search.value, ...searchParams });
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if(circ_week_dts) {
      setCircularDateList(circ_week_dts.map((week) => ({
        title: week,
        id: week
      })));

      if(!circ_week_dt || circ_week_dt === "") {
        // var today = new Date();
        // var dd = String(today.getDate()).padStart(2, '0');
        // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        // var yyyy = today.getFullYear();
        
        // today = yyyy + '-' + mm + '/' + dd;
        // let closestDate = nearestDate(circ_week_dts, );
        // console.log('closest date');
  
        //This needs to pull the correct date which should be the closest earlier day within the dropdown
        setFilter({ circ_week_dt: circ_week_dts[0]});

      }

    }



    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circ_week_dts]);

  const selectRetailerCricularWeek = async (week, item) => {
    setFilter({ circ_week_dt: week});
  };  

  const selectDateTypeRadio = async (event) => {
    setFilter({ dt_type: event.target.value })
  }

  const handleSort = (sortCol, sortType) => {
    setSortCol(sortCol);
    setSortType(sortType);
    setFilter({ sortBy: sortCol, sortDir: sortType === 1 ? "ASC" : "DESC" });
    getRetailersRollup(dt_type);
  };

  const handleSearch = async (params) => {

    if(!initalLoad) {
      setInitalLoad(true);
    }
      if (dt_type === 'circ'){
      navigate(
        `/retailers-rollup/?${stringify({
          circ_week_dt,
          dt_type
        })}`
      );
      } else {
          navigate(
            `/retailers-rollup/?${stringify({
              created_at: circ_week_dt,
              dt_type
            })}`
          );
        }
    getRetailersRollup(dt_type);
  };

  const handleTierFilter = (params) => {
    if(tiers.some(item => item.ext_id === params)) {
      if (tier && tier.includes(params)) {
        setFilter({ tier: tier.filter((item) => item !== params) });
      } else {
        setFilter({ tier: [...tier, params] });
      }
    }
  }

  return (
    <MainLayout>
      <MainContent>
        <SideSection>
          <SideTop
            currentCount={retailers.length}
            totalCount={totalCount}
            placeholder="retailers"
            isLoading={isLoading}
          />
          <Search className={`d-flex search-container`}>
            <DropDown2
                data={circularDateList}
                value={circ_week_dt}
                onChange={selectRetailerCricularWeek}
            ></DropDown2>
            <FaSearch className={"search-button"} onClick={handleSearch} />
          </Search>
            <DateTypeSection>
              <RadioGroup
                name="date-type-group"
                aria-labelledby="date-type-group-label"
                defaultValue='circ'
                onChange={selectDateTypeRadio}>
                  {dateTypeRadio.map(radio => 
                    <FormControlLabel 
                      key={radio.id} 
                      control={<WhiteRadio />} 
                      label={radio.title} 
                      value={radio.id} />
                  )}
              </RadioGroup>
            </DateTypeSection>
          <AccordionSection>
            <Accordion
              title="Tiers"
              desc=""
              className="mb-3"
              open={tier && tier?.length > 0}
            >
              {tiers.map(({ ext_id }) => (
                <div key={ext_id} className="m-3">
                  <CheckBox
                    value={(tier || []).includes(ext_id)}
                    onChange={() => handleTierFilter(ext_id)}
                  >
                    {ext_id}
                  </CheckBox>
                </div>
              ))}
            </Accordion>
          </AccordionSection>
        </SideSection>  
        <TableSection>
          <TableHeader
            sortCol={sortCol}
            sortType={sortType}
            onSort={handleSort}
          />
          <TableContent>
            {isLoading ? (
              times(7, String).map((index) => (
                <TableRow key={`${index}key`} isLoading />
              ))
            ) : !initalLoad ? (
              <div className="pt-4 mt-4 mx-auto text-center color-white">
                Select a Circular Date and then Click the Search Icon
              </div>
            ) : retailers && retailers.length > 0 && initalLoad ? (
              retailers.sort((a, b) => (a.num_circulars > b.num_circulars) ? -1 : 1).map((item, index) => (
                <TableRow key={`${index}key`} data={item} />
              ))
            ) : (
              <div className="pt-4 mt-4 mx-auto text-center color-white">
                No retailers active.
              </div>
            )}
          </TableContent>
        </TableSection>
      </MainContent>
    </MainLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.backgrounds};
`;

const SideSection = styled.div`
  width: 300px;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
`;

const Search = styled.div`
  display:flex;
  border-bottom:4px solid ${(props) => props.theme.palette.canvas};
  padding: 0 10px;
  width:100%;
  .d-block {
    flex:1;

    > div:first-of-type {
      height:48px;
    }

  }

  .search-button {
    font-size:20px;
    cursor:pointer;
    margin:12px 20px 12px 20px
  }
`;

const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TableContent = styled(ScrollView)`
  position: relative;
  transition: all 300ms ease;
  border: ${(props) =>
    props.isDragAccept || props.isDragActive
      ? `1px dashed ${props.theme.palette.primary}`
      : "1px solid transparent"};
`;

const DateTypeSection = styled.div`
  display: flex;
  padding: 0 10px;
  color: #ffffff;
  margin: 12px 12px 12px 6px;
`;

const WhiteRadio = withStyles({
  root: {
    color: grey[100],
    '&:hover': {
      backgroundColor: grey[900],
      color: grey[100],
    },
    "&$checked": {
      color: grey[100],
      '&:hover': {
        backgroundColor: grey[900],
      }
    },
    '& .MuiSvgIcon-root': {
      height: '1rem',
      width: '1rem',
    }
  },
  checked: []
})((props) => <Radio color="default" {...props} />);


const AccordionSection = styled.div`
  margin: 12px 12px 12px 12px;
  padding-right: 10px;
  min-width: 240px;
  ::-webkit-scrollbar {
    width: 10px;
    background: ${(props) => props.theme.palette.background};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.background};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }

  .title {
    color:white;
    font-size:12px;
    border-bottom: 1px solid ${(props) => props.theme.palette.itemsHover};
    text-transform:uppercase;
  }
`;
