/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import { MdMessage } from "react-icons/md";
import { FaTags, FaBarcode, FaList } from "react-icons/fa";
import { useLocation } from "@reach/router";
import { useModal } from "hooks";
import { ViewSectionModal } from "modals";
import { useMainContext } from "contexts";
import { getImage } from "api";
import _ from "lodash";
import { setCachedImage, getCachedImage, productStringEval } from "utils";

export const AdblockImage = ({
  type = "",
  // adBlockImg,
  filename = "",
  imageHeight,
  imageWidth,
  blockRef,
  isFetching = false,
  queueCount = 1,
  currentAdBlockData = {},
  circularId,
  pageId,
  id = "Adblock Imnage",
  isEndQueue,
  sizeData = []
}) => {
  const [showOCR, setShowOCR] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [isInnerFetching, setIsInnerFetching] = useState(false);
  const { postPageEvents } = useMainContext();
  const [adBlockImg, setAdBlockImg] = useState("");
  const [error, setError] = useState("");
  const location = useLocation();
  const viewSectionModal = useModal();

  const fetchImage = async () => {
    setIsInnerFetching(true);
    try {
      // Check if the image is cached
      const cachedImage = await getCachedImage(filename);
      if (cachedImage) {
        setAdBlockImg(cachedImage);
        setIsInnerFetching(false);
        return;
      }
      // Fetch the image from the API
      const img = await getImage(filename);
      console.log('IMG', img)
      if (img.success) {
        // Cache the fetched image
        await setCachedImage(filename, img.payload);
        setAdBlockImg(img.payload);
      }
    } catch (error) {
      setError(error);
    }
    setIsInnerFetching(false);
  };

  useEffect(() => {
    if (filename) {
      setError('');
      setShowProducts(false);
      fetchImage();
    } else if (!filename && !isFetching) {
      setError('This page needs to be redownloaded!')
    } else if (filename === "") {
      setError('This Block is still getting processed!')
    }
    return () => {};
  }, [filename]);


  return (
    <Container className="d-flex flex-column flex-1">
      <Header>
        <div>Adblock</div>


        {currentAdBlockData.group !== null && currentAdBlockData.group !== undefined
         ? (<Status info={true}>GROUP</Status>)
        : null}

        {currentAdBlockData.section !== null && currentAdBlockData.group !== undefined
         ? (<Status style={{cursor: "pointer"}} info={true} onClick={viewSectionModal.openModal}>SECTION</Status>)
        : null}


          
        {currentAdBlockData.dupe_products && currentAdBlockData.dupe_offers && currentAdBlockData?.dupe_adblock_uuid !== null ?
          <>
            <Status>Full Duplicate</Status>
          </>
          : currentAdBlockData.dupe_of ? <>
            <Status rejected>Orphaned Adblock</Status>
          </> : ''}

        {currentAdBlockData.product_dupe_of && currentAdBlockData?.similar_adblock_uuid !== null
          ? <>
            <Status>Product Duplicate</Status>
          </>
          : ''}
        {currentAdBlockData.offer_dupe_of && currentAdBlockData?.similar_adblock_uuid !== null
          ? <>
            <Status>Offer Duplicate</Status>
          </>
          : ''}
        {currentAdBlockData.product_import_of && currentAdBlockData?.similar_adblock_uuid !== null ?
          <>
            <Status>Product Imported</Status>
          </>
          : ''}{
          currentAdBlockData.offer_import_of && currentAdBlockData?.similar_adblock_uuid !== null ?
            <>
              <Status>Offer Imported</Status>
            </>
            : ''
        }

        {currentAdBlockData.dupe_adblock_uuid ? <div><Link target="_blank" href={location.origin + location.pathname.substring(0, location.pathname.indexOf("/", 1) + 1) + currentAdBlockData?.dupe_circular_uuid + "/" + currentAdBlockData?.dupe_page_uuid + "/" + currentAdBlockData?.dupe_adblock_uuid}>View Parent</Link></div> : '' }
        {(currentAdBlockData.product_dupe_of ||
          currentAdBlockData.offer_dupe_of ||
          currentAdBlockData.product_import_of ||
          currentAdBlockData.offer_import_of) &&
          currentAdBlockData.similar_adblock_uuid ?
          <div><Link target="_blank" href={location.origin + location.pathname.substring(0, location.pathname.indexOf("/", 1) + 1) + currentAdBlockData?.similar_circular_uuid + "/" + currentAdBlockData?.similar_page_uuid + "/" + currentAdBlockData?.similar_adblock_uuid}>View Parent</Link></div>
          : ''}


        <div className="ml-auto d-flex my-auto">

        {(type === "offers" || type === "offer") && 
            <IconButton
              title="Show Products"
              className="my-auto"
              onClick={() => {
                setShowProducts((state) => !state);
              }}
            >
              <FaList color="white" />
            </IconButton>
          }

          {type === "product" || type === "products" || type === "offers"? (
            <IconButton
              title="Open Offer"
              className=" my-auto"
              onClick={() => {
                postPageEvents("bounce", "product -> offer");
                window.open(`/offer/${circularId}/${pageId}/${currentAdBlockData.uuid}`);
                // navigate(
                //   `/offer/${circularId}/${pageId}/${currentAdBlockData.uuid}`
                // );
              }}
            >
              <FaTags color="white" />
            </IconButton>
          ) : null }
          
          { type === "offer" || type === "offers" || type === "products" ? (
            <>
            <IconButton
              title="Open Product"
              className="my-auto"
              onClick={() => {
                postPageEvents("bounce", "offer -> product");
                window.open(`/product/${circularId}/${pageId}/${currentAdBlockData.uuid}`);
                // navigate(
                //   `/product/${circularId}/${pageId}/${currentAdBlockData.uuid}`
                // );
              }}
            >
              <FaBarcode color="white" />
            </IconButton>
            </>
          ) : null}

          <IconButton
            title="Show OCR"
            className="my-auto"
            onClick={() => {
              setShowOCR((state) => !state);
              postPageEvents("click", id);
            }}
          >
            <MdMessage color="white" />
          </IconButton>
        </div>
      </Header>
      {showOCR && (
        <OcrText>
          <div className="ocr-heading">OCR TEXT:</div>
          <div className="ocr-text">{currentAdBlockData?.ocr_text}</div>
        </OcrText>
      )}
      {showProducts && (
        <Products>
          <ProductsTitle>Products Selected In Order</ProductsTitle> 
          {!_.isEmpty(currentAdBlockData) ? 
            currentAdBlockData.products.length > 0 && !(isFetching || isInnerFetching) ?
            currentAdBlockData.products.map((product, index) => (
              <Product key={`quick_product${index}`}>
                {productStringEval(product, sizeData, index)}
              </Product>
            ))
            : ''
            : <div className="loading">Loading..</div>
          }

        </Products>
      )}
      <Content className="d-flex flex-row flex-1">
        <div className="w-100 h-100" ref={blockRef}>
          {isFetching || isInnerFetching ? (
            <Skeleton height={imageHeight} width={imageWidth} />
          ) : isEndQueue ? (
            <div className="text-center mt-4 pt-4 endQueue">End of Queue</div>
          ) : currentAdBlockData.length === 0 ? (
            <div className="text-center mt-4 pt-4">No Adblock available</div>
          ) : adBlockImg ? (
            <AdBlockImg
              src={`data:image/jpeg;base64,${adBlockImg}`}
              alt="adblock"
            />
          ) : error && !(isFetching || isInnerFetching) ? (
            <div className="text-center mt-4 pt-4 color-white">{error}</div>
          ) : null}
        </div>
      </Content>
      <ViewSectionModal 
        {...viewSectionModal}
        sectionData={currentAdBlockData.section}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: calc(100% - 12px);
  .endQueue {
    color: white;
  }
`;

const AdBlockImg = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  user-select: none;
`;

const IconButton = styled.span`
  cursor: pointer;
  margin: 0 4px;
  &:hover {
    svg {
      transition: all 0.3s ease;
      fill: ${(props) => props.theme.palette.primary} !important;
    }
  }
`;

const OcrText = styled.div`
  position: absolute;
  width: calc(100% - 16px);
  top: 37px;
  left: 7px;
  padding: 16px;
  color: white;
  font-size: 14px;
  background-color: ${(props) => props.theme.palette.items};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  .ocr-heading {
    color: gray;
  }
  z-index: 0;
  user-select: text;
`;

const Products = styled.div`
  position: absolute;
  width: calc(100% - 16px);
  top: 37px;
  left: 7px;
  padding: 16px;
  color: white;
  font-size: 14px;
  background-color: ${(props) => props.theme.palette.items};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  .ocr-heading {
    color: gray;
  }
  z-index: 0;
  user-select: text;
`;

const Status = styled.div`
  margin: 0 0 0 10px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 12px;
  height: 22px;
  padding: 5px 8px;
  background: ${(props) =>
    props.approved
      ? props.theme.palette.success
      : props.rejected
      ? props.theme.palette.error
      : props.info
      ? props.theme.palette.info
      : props.theme.palette.warning};
  color: ${(props) =>
    props.approved || props.rejected
      ? props.theme.palette.white
      : props.theme.palette.background};
`;

const ProductsTitle = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.palette.itemsHover};
    padding: 0 0 4px 0;
`;

const Product = styled.div`
    padding: 5px;
`;

const Link = styled.a`
  font-size: 14px;
  color: white;
  text-decoration: underline;
  transition: all 300ms ease;
  cursor: pointer;
  margin-left:10px;
  &:hover {
    opacity: 0.7;
    color: #006280;
    cursor: pointer;
  }
`;