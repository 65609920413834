/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorageState } from "rooks";
import JobReducer from "./RetailersRollupReducer";
import {
  SET_LOADING,
  INIT_RETAILERSROLLUP,
  GET_RETAILERSROLLUP,
  SET_ERROR,
  SET_REFETCHING,
  SET_RETAILERSROLLUP_SEARCH,
  SET_RETAILERSROLLUP_FILTER,
} from "../types";
import { retailersRollup } from "api";

const RetailersRollupContext = createContext();
export const useRetailersRollupContext = () => useContext(RetailersRollupContext);
const STORAGE_KEY = "addison-retailer-circular-state";

export const RetailersRollupProvider = (props) => {


  const initialState = {
    query: "",
    status: [],
    retailers: [],
    circ_week_dts: [],
    circ_week_dt: undefined,
    dt_type: undefined,
    totalCount: 0,
    resultCount: 0,
    totalPages: 0,
    isLoading: false,
    isRefetching: false,
    sortBy: "created_at",
    sortDir: "ASC",
    tier: []
  };

  const [value, set] = useLocalstorageState(STORAGE_KEY, initialState);

  const [state, dispatch] = useReducer(JobReducer, value);

  useEffect(() => {
    set(state);
    return () => {};
  }, [state]);

  const getRetailersRollup = async () => {
    setRetailersRollupLoading();
    const res = await retailersRollup({
      circ_week_dt: state.circ_week_dt || undefined,
      dt_type: state.dt_type || undefined,
      tier: state.tier
    });
    
    dispatch({
      type: res.success ? GET_RETAILERSROLLUP : SET_ERROR,
      payload: res.payload,
    });
  };

  const initCirculars = async () => {
    dispatch({
      type: INIT_RETAILERSROLLUP,
    });
  };

  // Set Loading
  const setRetailersRollupLoading = (params = true) =>
    dispatch({ type: SET_LOADING, payload: params });

  // Set Refetching
  const setCircularsRefetching = (params = true) =>
    dispatch({ type: SET_REFETCHING, payload: params });

  const setQuery = (query) => {
    dispatch({ type: SET_RETAILERSROLLUP_SEARCH, payload: query });
  };

  const setFilter = (params) => {
    dispatch({ type: SET_RETAILERSROLLUP_FILTER, payload: params });
  };

  const { children } = props;

  return (
    <RetailersRollupContext.Provider
      value={{
        ...state,
        initCirculars,
        setRetailersRollupLoading,
        setCircularsRefetching,
        getRetailersRollup,
        setQuery,
        setFilter,
      }}
    >
      {children}
    </RetailersRollupContext.Provider>
  );
};
