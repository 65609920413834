/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { Link } from "@reach/router";
import { FaCropAlt, FaTag, FaBarcode } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import { useAuthContext, useMainContext } from "contexts";
import { getQueueCountApi, getLiveCounterAPI } from "api";
import { TableContent, TableHeader } from "./components";
import ScaleLoader from "react-spinners/ScaleLoader";
import { IoClose, IoDownloadOutline } from "react-icons/io5";
import { Button } from "components";
import { prepareCSVString } from "utils";

export const HomePage = () => {
  const { userInfo } = useAuthContext();
  const { setCurrentUserId } = useMainContext();
  const [showLiveCounter, setShowLiveCounter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [counterData, setCounterData] = useState([]);
  const circularMenuInfo = {
    label: "Circulars",
    icon: <AiFillSetting size={120} />,
    route: "/circular",
    disabled: false,
  };
  const [menuData, setMenuData] = useState([
    {
      label: "Adblock Cropping",
      icon: <FaCropAlt size={120} />,
      route: "/adblocks/",
      disabled: false,
      count: "",
    },
    {
      label: "Product Processing",
      icon: <FaBarcode size={120} />,
      route: "/products/",
      disabled: false,
      count: "",
    },
    {
      label: "Offer Processing",
      icon: <FaTag size={120} />,
      route: "/offers/",
      disabled: false,
      count: "",
    },
  ]);

  const showCounter = async () => {
    setIsLoading(true);
    const res = await getLiveCounterAPI();
    if (res.success) {
      setCounterData(res.payload)
      setShowLiveCounter(true);
    }
    setIsLoading(false);
  }

  const exportLiveCounter = async () => {
    // keyed header for csv
    // const headers = ['full_name','cropping_completed','ttb_product_completed','ttb_offer_completed','ltr_product_completed','ltr_offer_completed']
    // custom header for csv
    const headerMappings = {
      'User': 'full_name', 'Cropping': 'cropping_completed',
      'CM': 'cm_block', 'IM Products': 'im_product', 'IM Offers': 'im_offer', 
      'EM Products': 'em_product', 'EM Offers': 'em_offer',
      'TTB Products': 'ttb_product_completed', 'TTB Offers': 'ttb_offer_completed',
      'LTR Products': 'ltr_product_completed', 'LTR Offers': 'ltr_offer_completed'
    };
    const url = window.URL.createObjectURL(
      new Blob([prepareCSVString(counterData, { headerMappings })], { type: "text/plain" })
    );
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `LiveCounterReport${1}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const init = async () => {
    setCurrentUserId(userInfo?.user?.id);

    const res = await getQueueCountApi();
    if (res.success) {
      setMenuData([
        { ...menuData[0], count: res.results.adblock_processing },
        { ...menuData[1], count: res.results.product_processing },
        { ...menuData[2], count: res.results.offer_processing },
      ]);
    }
  };

  useEffect(() => {
    init();
    return () => { };
  }, []);

  return (
    <MainLayout>
      <MainContent>
        <MainMessage>
          {!showLiveCounter &&
            <>
              <WelcomeText className="w-100 ml-3">Welcome to Addison, please choose a Workflow!</WelcomeText>
             
              {userInfo?.user?.is_superuser &&
                <LiveCounter>
                  <LiveCounterButton onClick={() => showCounter()} isLoading={isLoading}>
                    {isLoading ? <ScaleLoader color="white"
                      height={18}
                      width={2}
                      radius={2}
                      margin={2} /> : 'Preview Live Counter'}
                  </LiveCounterButton>
                </LiveCounter>
            }
            </>
          }
          {(showLiveCounter && !isLoading) && <>
            <div className="d-flex" style={{ marginLeft: '78%' }}>
              <Button style={{marginTop: '6%', padding: '8% 0 14%'}} size="small" width="120px"  onClick={() => exportLiveCounter()}>
                Export
                <IoDownloadOutline className="ml-2" />
                </Button>
              <LiveCounterButton onClick={() => setShowLiveCounter(false)}>
                <IoClose />
              </LiveCounterButton>
            </div>
            <TableSection>
              <TableHeader />
              <TableContent
                data={counterData}
                isLoading={isLoading}
              >
              </TableContent>
            </TableSection>
          </>
          }
        </MainMessage>
        <MainBox>
          {/* <div className="w-100 ml-3">Welcome, please choose a Workflow</div> */}
          {menuData.map((item) => (
            <MenuItem key={item.route} {...item} />
          ))}
          {(userInfo?.user?.is_superuser || userInfo?.user?.is_moderator || userInfo?.user?.is_active) && <MenuItem {...circularMenuInfo} />}
        </MainBox>
      </MainContent>
    </MainLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  flex: 2;
  color: white;
`;
const MainBox = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  float: right;
  justify-content: center;
  align-items: center;
  right: 1px;
`;
const MenuItem = ({ label, route, icon, disabled, count = "" }) => {
  return (
    <MenuItemContainer className={disabled ? "disabled" : ""} to={route}>
      <div className="icon">{icon}</div>
      <div className="label">{label}</div>
      {typeof count == 'number' && <div className="count">{count}</div>}
    </MenuItemContainer>
  );
};

const MenuItemContainer = styled(Link)`
  background: ${(props) => props.theme.palette.backgrounds};
  color: white;
  cursor: pointer;
  transition: all 300ms ease;
  display: flex;
  padding: 10px;
  margin-bottom: 2px;
  border-radius: 5px;
  float: right;
  width: 100%;
  height: 80px;
  &.disabled {
    color: ${(props) => props.theme.palette.secondary};
    pointer-events: none;

    .label {
      color: ${(props) => props.theme.palette.secondary};
      font-size: 24px;
    }

    &:hover {
      opacity: 1;
      color: ${(props) => props.theme.palette.secondary};
      border: none;
      text-decoration: none;
    }
  }
  .icon {
    display: flex;
    margin: 0;
    padding: 0;
    max-width: 60px;
    min-height: 20px;
  }
  .icon svg {
    margin: auto;
    max-height: 30px;
  }
  .count {
    background: #404040;
    width: max-content;
    border-radius: 25%;
    padding: 5px;
    height: 20px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    max-width: 40px;
  }
  .label {
    font-weight: bold;
    font-size: 18px;
    color: white;
    width: 180px;
    text-align: left;
    display: flex;
    align-items: center;
  }
  &:hover {
    opacity: 0.8;
    color: ${(props) => props.theme.palette.primaryHover};
    border: 1px solid ${(props) => props.theme.palette.primaryHover};
    text-decoration: none;
  }
`;
const WelcomeText = styled.div`
  font-size: 48px;
`

const LiveCounter = styled.div`
  font-size: 48px;
`
const LiveCounterButton = styled.div`
  margin: 10px;
  border: 1px solid #909090;
  font-size: 20px;
  background: ${(props) => props.theme.palette.backgrounds};
  color: white;
  cursor: pointer;
  transition: all 300ms ease;
  display: flex;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  float: right;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 9px 0px;
  &:hover {
    opacity: 0.8;
    color: ${(props) => props.theme.palette.primaryHover};
    border: 1px solid ${(props) => props.theme.palette.primaryHover};
    text-decoration: none;
  }
`

const MainMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height: inherit;
  text-align: center;
`

const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 90%;
`;

