import React, { useRef, useState } from "react";
import styled from "styled-components";
import { ColorPick, Link } from "components";
import { useLocation } from "@reach/router";
import { MdError } from "react-icons/md";
import { useOutsideClick } from "hooks";
const OUTLINE_COLORS = ["blue", "red", "yellow"];

export const PageView = ({
  className,
  children,
  activeColor,
  onSelectColor,
  currentPageData,
  parentPageData = null,
  parentPageCircularData = null
}) => {

  const location = useLocation();
  const pageErrorMessageRef = useRef();
  const [showPageErrorMessage, setShowPageErrorMessage] = useState();

  useOutsideClick(pageErrorMessageRef, () => {
    setShowPageErrorMessage(false);
  })
  
  return (
    <Container className={className}>
      <Header>
        PAGE -
        { currentPageData.ext_id } -
        { currentPageData.page_num !== null ? currentPageData.page_num : ""}
        {currentPageData.has_errors
          && <div style={{display: "inherit"}}
          ref={pageErrorMessageRef}>
          <MdError
            className="ml-2 my-auto cursor-pointer"
            size='1.25em'
            onClick={() => setShowPageErrorMessage(!showPageErrorMessage)}
          />
          {
            showPageErrorMessage &&
            <ErrorMessage>
              <ErrorMessageText>
                {currentPageData?.notes}
              </ErrorMessageText>
            </ErrorMessage>
          }
        </div>
        }
        { currentPageData.crop_approved
        ? (<Status approved>Crop Approved</Status>)
        : null}

        { currentPageData.rejected
        ? (<Status rejected>REJECTED</Status>)
        : currentPageData.completed
        ? (<Status approved>COMPLETED</Status>)
        : null}

        { currentPageData.is_dupe
        ? (
        <>
          <Status>VARIANT</Status>
          
          {parentPageData.crop_approved && !currentPageData.crop_approved ?
            <Status info>Queued for Processing</Status>
          : null}
          
          <Link className={'link'} target="_blank" href={location.origin + "/adblock/" + parentPageCircularData?.uuid + "/" + parentPageData?.uuid}>View Parent</Link>

        </>
        )
        : null}


        {currentPageData.is_doubletruck 
        && (<Status doubletruck>Double Truck</Status>)}

        <div className="d-flex ml-auto">
          {OUTLINE_COLORS.map((color) => {
            return (
              <ColorPick
                bgColor={color}
                key={color}
                active={color === activeColor}
                onClick={() => onSelectColor(color)}
              />
            );
          })}
        </div>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};
const Container = styled.div`
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Header = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  display: flex;

  .link {
    margin:0 10px;
  }

`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0px 0px 0px;
  flex: 1;
  max-height: calc(100% - 12px);
`;

const Status = styled.div`
  margin: 0 0 0 10px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 12px;
  height: 22px;
  padding: 5px 8px;
  background: ${(props) =>
    props.approved
      ? props.theme.palette.success
      : props.rejected 
      ? props.theme.palette.error 
      : props.doubletruck
      ? props.theme.palette.primaryHover
      : props.info
      ? props.theme.palette.info
      : props.theme.palette.warning};
  color: ${(props) =>
    props.rejected
      ? props.theme.palette.white
      : props.theme.palette.background};
`;

const ErrorMessage = styled.div`
  z-index: 2;
  padding: 8px 0;
  position: absolute;
  color: white;
  margin-top: 22px;
  margin-left: 10px;
  background: #191919;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  /* line-height: 32px; */
  overflow: auto;
  border: 1px solid #2B2B2B;
  
`

const ErrorMessageText = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`

