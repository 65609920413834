/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal, Button } from "components";
import styled from "styled-components";

export const UnitRestrictionWarning = ({
  open,
  onClose,
  unitRestrictionMessage = [],
  onApprove = () => { }
}) => {
  const handleCancel = () => {
    onClose();
  }

  const handleApprove = () => {
    onApprove();
    onClose();
  }

  return (
    <Modal open={open} onClose={onClose} size="medium" title={"Approve AdBlock"}>
      <MessageStyle>
        <div>
          <div>Are you sure you want to Approve this Block?</div>
          <WarningMessage><WarningText>WARNING:</WarningText> The sizes might be above the limits for some products.</WarningMessage>
          <WarningMessage>
              {Object.keys(unitRestrictionMessage).map((unitDim, index) =>
                Object.keys(unitRestrictionMessage[unitDim]).length > 0 &&
                <div key={index}>
                  <WarningText>
                    Product {index + 1} - {unitDim}
                  </WarningText>
                  {unitRestrictionMessage[unitDim].dimension === 2 ?
                    <div className="d-flex">
                      {(!Number.isNaN(unitRestrictionMessage[unitDim].max) && !Number.isNaN(unitRestrictionMessage[unitDim].min))
                        ? `size_1 (${unitRestrictionMessage[unitDim].size_1}) & size2 (${unitRestrictionMessage[unitDim].size_2}) should be between ${unitRestrictionMessage[unitDim].max} & ${unitRestrictionMessage[unitDim].min}`
                        : ''}
                    </div>
                    : unitRestrictionMessage[unitDim].dimension === 5 ?
                      <>
                        <div className="d-flex">
                            {(!Number.isNaN(unitRestrictionMessage[unitDim].max) && !Number.isNaN(unitRestrictionMessage[unitDim].min))
                              ? `size_1 ${unitRestrictionMessage[unitDim].size_1 ? `(${unitRestrictionMessage[unitDim].size_1})` : ''
                              } & size_2 ${unitRestrictionMessage[unitDim].size_2 ? `(${unitRestrictionMessage[unitDim].size_2})` : ''
                              } should be between ${unitRestrictionMessage[unitDim].max} & ${unitRestrictionMessage[unitDim].min}`
                              : ''}
                            {(!Number.isNaN(unitRestrictionMessage[unitDim].max2) && !Number.isNaN(unitRestrictionMessage[unitDim].min2))
                              ? ` size_3 ${unitRestrictionMessage[unitDim].size_3 ? `(${unitRestrictionMessage[unitDim].size_3})` : ''
                              } & size_4 ${unitRestrictionMessage[unitDim].size_4 ? `(${unitRestrictionMessage[unitDim].size_4})` : ''
                              } should be between ${unitRestrictionMessage[unitDim].max2} & ${unitRestrictionMessage[unitDim].min2}`
                              : ''}
                        </div>
                      </>
                      : unitRestrictionMessage[unitDim].dimension === 6 ?
                        <>
                          <div className="d-flex">
                            {(!Number.isNaN(unitRestrictionMessage[unitDim].max) && !Number.isNaN(unitRestrictionMessage[unitDim].min))
                              ? `size_1 (${unitRestrictionMessage[unitDim].size_1}) should be between ${unitRestrictionMessage[unitDim].max} & ${unitRestrictionMessage[unitDim].min}`
                              : ''}
                            {(!Number.isNaN(unitRestrictionMessage[unitDim].max2) && !Number.isNaN(unitRestrictionMessage[unitDim].min2))
                              ? `size_2 (${unitRestrictionMessage[unitDim].size_2}) should be between ${unitRestrictionMessage[unitDim].max2} & ${unitRestrictionMessage[unitDim].min2}`
                              : ''}
                          </div>
                        </>
                        : <div className="d-flex">
                          {(!Number.isNaN(unitRestrictionMessage[unitDim].max) && !Number.isNaN(unitRestrictionMessage[unitDim].min))
                            ? `size_1 (${unitRestrictionMessage[unitDim].size_1}) should be between ${unitRestrictionMessage[unitDim].max} & ${unitRestrictionMessage[unitDim].min}`
                            : ''}
                        </div>
                  }
                </div>
              )}
            <div className="d-flex mt-2">Are you sure you want to continue?</div>
          </WarningMessage>
        </div>
      </MessageStyle>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-4"
          type="button"
          onClick={() => handleApprove()}
        >
          Approve Anyway!
        </Button>
      </div>
    </Modal>
  );
};

const MessageStyle = styled.div`
    font-size:14px;
`;
const WarningMessage = styled.div`
    font-size:14px;
    padding:10px;
`;

const WarningText = styled.span`
  color:${(props) => props.theme.palette.warning};
`;
