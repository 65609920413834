import axios from "axios";
import axiosRetry from "axios-retry";
// import https from "https";
import { API_URL, PEP_API_URL } from "config";
import { navigate } from "@reach/router";
import { toast } from 'react-toastify';

export const API = (opts = {}, optsHeader = {}, retryOptions = { timeout: 90, retries: 1, retryDelay: 1000 }) => {
  const DEFAULT_TIMEOUT = retryOptions.timeout !== undefined ? retryOptions.timeout : 90; // Request timeout
  const DEFAULT_RETRIES = retryOptions.retries !== undefined ? retryOptions.retries : 1;
  const DEFAULT_RETRY_DELAY = retryOptions.retryDelay !== undefined ? retryOptions.retryDelay : 1000; // Retry delay

  /*
  |--------------------------------------------------
  | Custom axios api
  |--------------------------------------------------
  */
  const Authorization = localStorage.getItem("user");
  const defaultOptions = {
    ...opts,
    timeout: 1000 * DEFAULT_TIMEOUT,
    headers: {
      Authorization,
      // Accept: "*/*",
      // "Accept-Enconding": "gzip, deflate, br",
      // Connection: "keep-alive",
      // "Cache-Control": "no-cache",
      // "Postman-Token": "42e6c291-9a09-c29f-f28f-11872e2490a5",
      // "Content-Type": "multipart/form-data",
      ...optsHeader,
    },
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    trailingSlash: true,
  };

  const axiosApi = axios.create({
    baseURL: API_URL,
    // httpsAgent is not needed as client use system default, until external certificates provided
    // httpsAgent: new https.Agent({
    //   rejectUnauthorized: false,
    // }),
    ...defaultOptions,
    // adapter: cache.adapter,
  });
  axios.trailingSlash = true;


  // Auto retry failed/timedout requests
  axiosRetry(axiosApi, {
    retries: DEFAULT_RETRIES,
    retryDelay: (retryCount) => {
      return DEFAULT_RETRY_DELAY || axiosRetry.exponentialDelay(retryCount); // Delay between retries
    },
    shouldResetTimeout: true, // Reset timeout for each retry
    retryCondition: (error) => {
      console.log('retryCondirion', DEFAULT_RETRIES, retryOptions)
      // Retry on network errors or timeout errors
      return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.toJSON().message === 'Network Error' || ![403, 404].includes(error.response?.status);
    },
  });


  // Request interceptor to sanitize data before sending
  // axiosApi.interceptors.request.use((config) => {
  //   if (config.data) {
  //     const { sanitizedData, hasNaN } = sanitizeData(config.data);
  //     if (hasNaN) {
  //       alert("Your input contains invalid values (NaN). Please correct them before submitting.");
  //       return Promise.reject(new Error("Blocked request due to NaN values"));
  //   }
  //     config.data = sanitizedData
  //   }

  //   return config;
  // }, (error) => Promise.reject(error));

  axiosApi.interceptors.response.use(null, (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      localStorage.removeItem("user");
      navigate(`/login?redirect=${window.location.href}`);
    }
    else {
      if (error.response?.data?.errors[0]?.msg) {
        toast.error(error.response?.data?.errors[0]?.msg.toString(), {
          position: "top-left", autoClose: 5000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, progress: undefined, theme: "light"
        });
      }
      else if (error.response?.data?.errors[0]) {
        toast.error(error.response?.data?.errors[0].toString(), {
          position: "top-left", autoClose: 5000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, progress: undefined, theme: "light"
        });
      }
      else {
        toast.error(error.toString(), {
          position: "top-left", autoClose: 5000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, progress: undefined, theme: "light"
        });
      }
    }
    return Promise.reject(error);
  });
  return axiosApi;
};

export const PEP_API = (opts = {}, optsHeader = {}, retryOptions = { timeout: 90, retries: 1, retryDelay: 1000 }) => {
  const DEFAULT_TIMEOUT = retryOptions.timeout !== undefined ? retryOptions.timeout : 90; // Request timeout
  const DEFAULT_RETRIES = retryOptions.retries !== undefined ? retryOptions.retries : 1;
  const DEFAULT_RETRY_DELAY = retryOptions.retryDelay !== undefined ? retryOptions.retryDelay : 1000; // Retry delay

  /*
  |--------------------------------------------------
  | Custom axios api
  |--------------------------------------------------
  */
  const Authorization = localStorage.getItem("user");
  const defaultOptions = {
    ...opts,
    timeout: 1000 * DEFAULT_TIMEOUT,
    headers: {
      Authorization,
      // Accept: "*/*",
      // "Accept-Enconding": "gzip, deflate, br",
      // Connection: "keep-alive",
      // "Cache-Control": "no-cache",
      // "Postman-Token": "42e6c291-9a09-c29f-f28f-11872e2490a5",
      // "Content-Type": "multipart/form-data",
      ...optsHeader,
    },
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    trailingSlash: true,
  };

  const axiosApi = axios.create({
    baseURL: API_URL === 'https://api.addisonpromo.com/' ? PEP_API_URL : API_URL,
    // httpsAgent is not needed as client use system default, until external certificates provided
    // httpsAgent: new https.Agent({
    //   rejectUnauthorized: false,
    // }),
    ...defaultOptions,
    // adapter: cache.adapter,
  });
  axios.trailingSlash = true;


  // Auto retry failed/timedout requests
  axiosRetry(axiosApi, {
    retries: DEFAULT_RETRIES,
    retryDelay: (retryCount) => {
      return DEFAULT_RETRY_DELAY || axiosRetry.exponentialDelay(retryCount); // Delay between retries
    },
    shouldResetTimeout: true, // Reset timeout for each retry
    retryCondition: (error) => {
      console.log('retryCondirion', DEFAULT_RETRIES, retryOptions)
      // Retry on network errors or timeout errors
      return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.toJSON().message === 'Network Error' || ![403, 404].includes(error.response?.status);
    },
  });


  // // Request interceptor to sanitize data before sending
  // axiosApi.interceptors.request.use((config) => {
  //   if (config.data) {
  //     config.data = sanitizeData(config.data);
  //   }

  //   return config;
  // }, (error) => Promise.reject(error));

  axiosApi.interceptors.response.use(null, (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      localStorage.removeItem("user");
      navigate(`/login?redirect=${window.location.href}`);
    }
    else {
      if (error.response?.data?.errors[0]?.msg) {
        toast.error(error.response?.data?.errors[0]?.msg.toString(), {
          position: "top-left", autoClose: 5000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, progress: undefined, theme: "light"
        });
      }
      else if (error.response?.data?.errors[0]) {
        toast.error(error.response?.data?.errors[0].toString(), {
          position: "top-left", autoClose: 5000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, progress: undefined, theme: "light"
        });
      }
      else {
        toast.error(error.toString(), {
          position: "top-left", autoClose: 5000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, progress: undefined, theme: "light"
        });
      }
    }
    return Promise.reject(error);
  });
  return axiosApi;
};

// Recursive function to clean NaN values in the data object, this should replace exising values if any
// const sanitizeData = (data) => {
//   let hasNaN = false;

//   const sanitize = (item) => {
//       if (Array.isArray(item)) {
//           return item.map(sanitize);
//       } else if (typeof item === 'object' && item !== null && !(item instanceof FormData)) {
//           return Object.keys(item).reduce((acc, key) => {
//               acc[key] = sanitize(item[key]);
//               return acc;
//           }, {});
//       } else if (item instanceof FormData) {
//         // Handle FormData specifically
//           const sanitizedFormData = new FormData();
//           for (const [key, value] of item.entries()) {
//               let sanitizedValue = value;
//               if (typeof value === 'number' && isNaN(value)) {
//                   sanitizedValue = 0;
//                   hasNaN = true;
//               } else if (typeof value === 'string' && /NaN/i.test(value)) {
//                   sanitizedValue = value.replace(/NaN/gi, '0');
//                   hasNaN = true;
//               }
//               sanitizedFormData.append(key, sanitizedValue);
//           }
//           return sanitizedFormData;
//       } else if (typeof item === 'number' && isNaN(item)) {
//           hasNaN = true;
//           return 0; // Replace numeric NaN with 0
//       } else if (typeof item === 'string' && /NaN/i.test(item)) {
//           hasNaN = true;
//           // Replace all instances of NaN in the string, case-insensitive
//           return item.replace(/NaN/gi, '0');
//       }
//       return item;
//   };

//   const sanitizedData = sanitize(data);
//   return { sanitizedData, hasNaN };
// };