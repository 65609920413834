/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2 } from "components";
import { useInput } from "hooks";

export const OfferUpTextBuilder = ({
  className,
  value,
  onChange = () => {},
}) => {
  const variation = useInput("& Up");
  const type = useInput("$");
  const val = useInput("5");

  const variationData = [
    { id: "& Up", title: "& Up" },
    { id: "& below", title: "& below" },
    { id: "Starting @", title: "Starting @" },
    { id: "less than", title: "less than" },
    { id: "up to", title: "up to" },
    { id: "starting under", title: "starting under" },
    { id: "save up to", title: "save up to" },
  ];

  const typeData = [
    { id: "$", title: "$" },
    { id: "%", title: "%" },
  ];

  const handleUpdateValue = (variation, type, val) => {
    if (variation && type && val) {
      const typeval = type === '$' ? `${type}${val}` : `${val}${type}`;
      switch (variation) {
        case "& Up":
          onChange(`${typeval} & Up`);
          break;
        case "& below":
          onChange(`${typeval} & Below`);
          break;
        case "Starting @":
          onChange(`Starting @ ${typeval}`);
          break;
        case "less than":
          onChange(`Less Than ${typeval}`);
          break;
        case "up to":
          onChange(`up to ${typeval}`);
          break;
        case "starting under":
          onChange(`Starting under ${typeval}`);
          break;
        case "save up to":
          onChange(`Save Up To ${typeval}`);
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (value) {
      const extractValue = (regex, variationString) => {
        const match = value.match(regex);
        if (match) {
          // eslint-disable-next-line 
          const [_, typeChar, valuePart] = match;
          type.setValue(typeChar);
          val.setValue(valuePart);
          variation.setValue(variationString);
        }
      };
  
      if (value.match(/& up/i)) {
        extractValue(/^(\$)(\d+)\s&\sup$/i, "& Up");
      } else if (value.match(/& below/i)) {
        extractValue(/^(\$)(\d+)\s&\sbelow$/i, "& below");
      } else if (value.match(/^starting @/i)) {
        extractValue(/^starting\s@\s(\$)(\d+)$/i, "Starting @");
      } else if (value.match(/^less than/i)) {
        extractValue(/^less\sthan\s(\$)(\d+)$/i, "less than");
      } else if (value.match(/^up to/i)) {
        extractValue(/^up\sto\s(\$)(\d+)$/i, "up to");
      } else if (value.match(/^starting under/i)) {
        extractValue(/^starting\sunder\s(\$)(\d+)$/i, "starting under");
      } else if (value.match(/^save up to/i)) {
        extractValue(/^save\sup\sto\s(\$)(\d+)$/i, "save up to");
      }
    }
  }, [value]);
  
  return (
    <div className={`${className} mt-1 p-2`}>
      <Title>Offer Text Builder</Title>
      <Container className="mt-2">
        <div style={{ width: 120, marginRight: 20 }}>
          <DropDown2
            label="Variation"
            value={variation.value}
            width={120}
            onChange={(e) => {
              variation.setValue(e);
              handleUpdateValue(e, type.value, val.value);
            }}
            data={variationData}
          />
        </div>
        <div style={{ width: 60, marginRight: 20 }}>
          <DropDown2
            label="Type"
            value={type.value}
            width={60}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(variation.value, e, val.value);
            }}
            data={typeData}
          />
        </div>
        <div style={{ width: 120, marginRight: 20 }}>
          <Input2
            label="Value"
            type="float"
            value={val.value}
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(variation.value, type.value, e);
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
