/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorageState } from "rooks";
import JobReducer from "./UsersReducer";
import {
  SET_LOADING,
  INIT_USERS,
  GET_USERS,
  SET_ERROR,
  SET_REFETCHING,
  GET_MORE_USERS,
  SET_USERS_SEARCH,
  SET_USERS_FILTER,
} from "../types";
import { getAllUsers } from "api";
import { COUNT_PER_PAGE } from "config";

const UsersContext = createContext({"test":"test"});
export const useUsersContext = () => useContext(UsersContext);
const STORAGE_KEY = "addison-users-state";

export const UsersProvider = (props) => {

  const initialState = {
    query: "",
    users: [],
    totalCount: 0,
    resultCount: 0,
    totalPages: 0,
    currentPage: 1,
    isLoading: false,
    isRefetching: false,
    sortBy: "username",
    sortDir: "ASC",
  };

  const [value, set] = useLocalstorageState(STORAGE_KEY, initialState);

  const [state, dispatch] = useReducer(JobReducer, value);

  useEffect(() => {
    set(state);
    return () => {};
  }, [state]);

  const getUsers = async (countPerPage) => {
    setUsersLoading();
    const res = await getAllUsers({
      page: 0,
      per_page: countPerPage || COUNT_PER_PAGE,
      sort_by: state.sortBy || undefined,
      sort_dir: state.sortDir || undefined,
    });
    dispatch({
      type: res.success ? GET_USERS : SET_ERROR,
      payload: res.payload,
    });
  };

  const loadMore = async ({ retailer_id = undefined }) => {
    setUsersRefetching();
    const res = await getAllUsers({
      page: state.currentPage + 1,
      sort_by: state.sortBy || undefined,
      sort_dir: state.sortDir || undefined,
      stage: state.stage,
    });
    dispatch({
      type: res.success ? GET_MORE_USERS : SET_ERROR,
      payload: res.payload,
    });
  };

  const initUsers = async () => {
    dispatch({
      type: INIT_USERS,
    });
  };

  // Set Loading
  const setUsersLoading = (params = true) =>
    dispatch({ type: SET_LOADING, payload: params });

  // Set Refetching
  const setUsersRefetching = (params = true) =>
    dispatch({ type: SET_REFETCHING, payload: params });

  const setQuery = (query) => {
    dispatch({ type: SET_USERS_SEARCH, payload: query });
  };

  const setFilter = (params) => {
    dispatch({ type: SET_USERS_FILTER, payload: params });
  };

  const { children } = props;

  return (
    <UsersContext.Provider
      value={{
        ...state,
        initUsers,
        setUsersLoading,
        setUsersRefetching,
        getUsers,
        loadMore,
        setQuery,
        setFilter,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
