import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { SideTop, ScrollView } from "components";
import { useAuthContext, useUsersContext } from "contexts";
import { CreateModifyUserModal } from "modals";
import { getTiers } from "api";
import { TeamManagementDropDown } from "components/TeamManagementDropDown";
import { Table, Button, Badge, Flex, Typography, Input, Space } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { format } from "date-fns";
import { useModal } from "hooks";
import { prepareCSVString } from "utils";

export const UsersPage = () => {
  const [tiers, setTiers] = useState("");
  const [selectedRow, setSelectedRow] = useState();
  const { userInfo } = useAuthContext();

  const upsertUserModal = useModal();

  const handleUpsertUser = (data) => {
    if (data) setSelectedRow(data);
    upsertUserModal.openModal();
  };

  const columns = [
    {
      title: 'ID', dataIndex: 'id', key: 'id', width: 100,
      sorter: (a, b) => a.id - b.id,
      render: (_, { id, is_active }) => (
        <Badge color={is_active ? "#80ff80" : "orange"} count={id} overflowCount={99999} size="default" />
      )
    },
    { title: 'Email', dataIndex: 'email', key: 'email', sorter: (a, b) => a.email - b.email },
    {
      title: 'Username', dataIndex: 'username', key: 'username',
      sorter: (a, b) => a.username - b.username
    },
    {
      title: 'Full Name', dataIndex: 'full_name', key: 'full_name',
      render: (full_name) => (full_name ? full_name : "-"),
      sorter: (a, b) => a.full_name - b.full_name,
      filterIcon: (filtered) => (<SearchOutlined style={{ color: filtered ? '#1677ff' : undefined, }} />),
      onFilter: (value, { full_name }) => full_name?.toString().toLowerCase().includes(value?.toLowerCase()),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8, }} onKeyDown={(e) => e.stopPropagation()}>
          <Input placeholder={`Search ${'Full Name'}`} value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => { confirm() }}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space size="large">
            <Button type="primary" onClick={() => { confirm() }} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
              Search
            </Button>
            <Button onClick={() => { clearFilters(); confirm() }} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>)
    },
    {
      title: 'Role(S/M/A)', dataIndex: 'role', key: 'role',
      render: (_, record) => (
        <p>{[record?.is_superuser ? "Superuser" : "", record?.is_moderator ? "Moderator" : "", record?.is_active ? "Active" : ""]
          .filter(Boolean).join(" / ")}</p>
      )
    },
    {
      title: 'Tiers', dataIndex: 'tier_perms', key: 'tier_perms', width: 300, textWrap: 'word-break',
      render: (tiers) => (
        tiers && tiers.length ? tiers.map((item) => `${item}`).join(', ') : "-"
      ),
      sorter: (a, b) => a.tier_perms?.length - b.tier_perms?.length,
      filters: tiers && tiers.map(({ ext_id }) => ({ text: ext_id, value: ext_id })),
      onFilter: (value, { tier_perms }) => {
        return tier_perms && tier_perms.includes(value);
      }
    },
    {
      title: 'Created Date', dataIndex: 'created_at', key: 'created_at',
      render: (date) => (date ? format(new Date(date), "MM/dd/yyyy hh:mm a") : "-"),
      sorter: (a, b) => a.created_at - b.created_at
    },
    {
      title: 'Actions', dataIndex: 'name', key: 'name', align: 'center',
      render: (_, record) => (
        <Button onClick={() => handleUpsertUser(record)}>Edit</Button>
      )
    },
  ];
  const {
    getUsers,
    users = [],
    isLoading,
    totalCount = 0
  } = useUsersContext();

  const init = async () => {
    getUsers(500);
    const res = await getTiers();
    if (res.success) {
      const tiersMapped = res.results.tiers.map((item) => ({
        ...item,
        title: item.ext_id,
        id: item.ext_id
      }));

      setTiers(tiersMapped);

    } else {
      //Raise Error
    }
  };

  useEffect(() => {
    init();
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportUsers = () => {
    const headerMappings = {
      'ID': 'id', 'Full Name': 'full_name',
      'Email': 'email', 'Username': 'username',
      'Role(S/M/A)': 'role',
      'Tiers': 'tier_perms', 'Created Date': 'created_at'
    };
    const exportUsers = users.map((user) => {
      return {
        ...user,
        role: [user?.is_superuser ? "Superuser" : "", user?.is_moderator ? "Moderator" : "", user?.is_active ? "Active" : ""]
          .filter(Boolean).join(" / "),
        created_at: (user.created_at ? format(new Date(user.created_at), "MM/dd/yyyy hh:mm a") : "-")
      };
    });
    const url = window.URL.createObjectURL(
      new Blob([prepareCSVString(exportUsers, { headerMappings })], { type: "text/plain" })
    );
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `Users-${new Date().toLocaleDateString().replaceAll('/', '-')}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <MainLayout>
      <Flex style={{ background: '#131313' }}>
        <SideSection>
          <SideTop
            currentCount={users.length}
            totalCount={totalCount}
            placeholder="users"
            isLoading={isLoading}
          />
          {userInfo?.user?.is_superuser &&
            <TeamManagementSection>
              <TeamManagementDropDown isLoading={isLoading} />
            </TeamManagementSection>
          }
        </SideSection>

        <TableSection>
          <Flex vertical>
            <Table bordered loading={isLoading} size="medium" tableLayout="auto" style={{ 'marginRight': '0.2%' }}
              dataSource={users} columns={columns} scroll={{ y: 620 }}
              pagination={{ defaultPageSize: 50 }}
              title={() => (
                <Flex className="" align="end" justify="space-between">
                  <div>
                    <Typography.Title level={4}> Users</Typography.Title>
                  </div><div>
                    {userInfo?.user.is_superuser &&
                      <Button type="primary" onClick={handleUpsertUser}>Create New User</Button>
                    }
                    {(userInfo?.user.is_moderator || userInfo?.user.is_superuser) &&
                      <Button className="ml-2" type="primary" onClick={exportUsers}>Export Users</Button>
                    }</div>
                </Flex>
              )}
            ></Table>

          </Flex>
          <CreateModifyUserModal data={selectedRow} tiers={tiers} {...upsertUserModal} />
        </TableSection>
      </Flex>
    </MainLayout>
  );
};

const SideSection = styled.div`
  width: 300px;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
`;
const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;


const TeamManagementSection = styled(ScrollView)`
  display: flex;
  flex-direction: column;
  border-bottom: 4px solid ${(props) => props.theme.palette.canvas};
  color: ${(props) => props.theme.palette.white};
  font-size: ${(props) => props.theme.font.size.xm};
  padding: 12px 24px 6px 24px;
  .count {
    font-size: ${(props) => props.theme.font.size.xm};
    font-weight: ${(props) => props.theme.font.weight.l};
    color: ${(props) => props.theme.palette.white};
  }
  .placeholder {
    font-size: ${(props) => props.theme.font.size.m};
    color: ${(props) => props.theme.palette.secondary};
    height: 32px;
  }
`
