import { API } from "utils";


export const postCreateJiraIssue = async (data) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).post(`api/jiraissue/create-issue`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
