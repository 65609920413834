import {
  SET_LOADING,
  SET_ERROR,
  GET_RETAILERS,
  INIT_RETAILERS,
  GET_MORE_RETAILERS,
  SET_REFETCHING,
  SET_RETAILERS_SEARCH,
  SET_RETAILERS_FILTER,
  GET_SINGLE_RETAILER
} from "../types";

export default function JobReducer(state, action) {
  const { payload } = action;

  switch (action.type) {
    case INIT_RETAILERS:
      return {
        currentPage: 0,
        retailers: [],
        isLoading: false,
        isRefetching: false,
        totalCount: 0,
        resultCount: 0,
        totalPages: 0,
        sortBy: "name",
        sortDir: "ASC",
        is_active: false,
        queue_enabled: false
      };
    case GET_RETAILERS:
      return {
        ...state,
        retailers: payload.retailers,
        isLoading: false,
        isRefetching: false,
        totalCount: payload.total,
        resultCount: payload.retailers.length,
        totalPages: payload.pages,
        currentPage: 0,
      };
    case GET_MORE_RETAILERS:
      return {
        ...state,
        retailers: [...state.retailers, ...payload.retailers],
        isRefetching: false,
        totalCount: payload.total,
        resultCount: payload.retailers.length,
        totalPages: payload.pages,
        currentPage: state.currentPage + 50,
      };
    case GET_SINGLE_RETAILER:
      return {
        ...state,
        retailers: [payload],
        isRefetching: false,
        totalCount: 1,
        resultCount: 1,
        totalPages: 1,
        currentPage: 0
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload || true,
      };
    case SET_REFETCHING:
      return {
        ...state,
        isRefetching: payload || true,
      };
    case SET_RETAILERS_SEARCH:
      return {
        ...state,
        query: payload,
      };
    case SET_RETAILERS_FILTER:
      return {
        ...state,
        ...payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
