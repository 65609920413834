import styled from "styled-components";

const FillSelect = styled.div`
  width: 16px;
  height: 16px;
  background-color: gray !important;
  border: 2px solid
    ${(props) => (props.active ? "#A9A9A9" : props.theme.palette.backgrounds)} !important;
  cursor: pointer;
  margin: auto 3px;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
`;

export { FillSelect };