import React, { useState, useEffect }  from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { times } from "lodash";
import {
  retailersRollupCirculars,
  addAdblocksToQueueByCirculars,
  batchCloseCirculars,
  closeCirculars,
  addAdblocksToQueueByCircular
} from "api";
import { SubTableRow } from "./SubTableRow";
import { useRetailersRollupContext, useAuthContext } from "contexts";
import { BsThreeDots } from "react-icons/bs";
import { CircularCloseModal } from "modals";
import { useModal } from "hooks";

export const TableRow = ({ isLoading, data }) => {

  const {
    circ_week_dt, dt_type
  } = useRetailersRollupContext();
  const { userInfo } = useAuthContext();

  const [status, setStatus] = useState(false);
  const [isCircularLoading, setIsCircularLoading] = useState(false);
  const [isSelectedCirculars, setSelectedCirculars] = useState([]);
  const [isRequeuing, setIsRequeuing] = useState(false);
  const [isClosing, setisClosing] = useState(false);
  const [activeCircular, setActiveCircular] = useState(false);
  const [circulars, setCirculars] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const confirmClose = useModal();

  const init = async () => {

    if(data?.num_pages_rejected > 0 || data?.num_adblocks_rejected > 0 ) {
      setStatus("error");
    }else if(data?.num_circulars_completed === data?.num_circulars && data?.num_circulars > 0) {
      setStatus("complete");
    }else if(data?.num_circulars > 0) {
      setStatus("inprocess");
    }else{
      setStatus("");
    }

    setActiveCircular(false);
  
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const toggleActiveRetailerCirculars = async () => {
    setIsCircularLoading(true);
    if(activeCircular === true) {
      setSelectedCirculars([]);
      setActiveCircular(false);
      setIsCircularLoading(false);
    }else{
      const results = await retailersRollupCirculars({
        retailer_id: data.id,
        circ_week_dt: circ_week_dt,
        dt_type: dt_type
      });
      if(results.success) {
        setSelectedCirculars([]);
        setCirculars(results.payload.circulars);
        setIsCircularLoading(false);
      }else{
        // Error message
      }
      setActiveCircular(true);
      setIsCircularLoading(false);
    }
    
  };

  // const queueCirculars = async () => {

  //   if(isSelectedCirculars.length === 0) {
  //     //Put error message here
  //     return;
  //   }

  //   setIsRequeuing(true);
  //   const results = await addAdblocksToQueueByCirculars({
  //     circulars: isSelectedCirculars
  //   });
  //   if(results.success) {
  //     setSelectedCirculars([]);
  //     setTimeout(resetToggledCirculars, 3000);
  //   }else{
  //     // Error message
  //     setIsRequeuing(false);
  //   }
  
  // };

  const handleRequeueCircular = async (id, hp=false) => {
    setIsRequeuing(true);
    const results = await addAdblocksToQueueByCircular(id, hp);
    if(results.success) {
      setTimeout(() => {setIsRequeuing(false)}, 3000);
    }else{
      // Error message
      setIsRequeuing(false);
    }
  };

  const resetToggledCirculars = async () => {
    setIsCircularLoading(true);

    const results = await retailersRollupCirculars({
      retailer_id: data.id,
      circ_week_dt: circ_week_dt,
      dt_type: dt_type
    });
    if(results.success) {
      setCirculars(results.payload.circulars);
      setIsRequeuing(false);
      setisClosing(false);
      setIsCircularLoading(false);
    }else{
      // Error message
    }

    setActiveCircular(true);
    setIsCircularLoading(false);

  };

  const handleCloseCirculars = async () => {

    if(isSelectedCirculars.length === 0) {
      //Put error message here
      return;
    }

    setisClosing(true);

    let new_circulars = isSelectedCirculars.filter(circular => circular.stage !== 'COMPLETE');

    const results = await batchCloseCirculars({
      circulars: new_circulars
    });
    if(results.success) {
      setTimeout(resetToggledCirculars, 3000);
    }else{
      // Error message
      setisClosing(false);
    }

  };

  const selectAllCirculars = async () => {

    if(isSelectedCirculars.length === circulars.length) {
      setSelectedCirculars([]);
    }else{

      setSelectedCirculars(circulars.map((circular) => {
        return circular
      }));
  
    }

  };

  const selectCircular = async (circular) => {

    //console.log('circular', circular_uuid);
    let tempArray = isSelectedCirculars;
    const foundCircularIndex = isSelectedCirculars.indexOf(circular);

    //See if circular is in array
    if(foundCircularIndex !== -1) {

      //console.log('remove circular', foundCircularIndex);
      //Remove clicked circular from list
      tempArray.splice(foundCircularIndex, 1);
      setSelectedCirculars((tempArray) => [
        ...tempArray
      ]);

    }else{

      //console.log('add circular', foundCircularIndex);
      //add clicked circular to list
      setSelectedCirculars((tempArray) => [
        ...tempArray,
        circular,
      ]);

    }

  };

  const handleConfirmModal = async () => {
    confirmClose.openModal();
  };

  const handleCloseCircular = async (id) => {
    setisClosing(true);
    const res = await closeCirculars(id);
    if (res.success) {
      resetToggledCirculars();
      setisClosing(false);
    } else {
      // Error Message
      setisClosing(false);
    }
  };

  return isLoading ? (
      <SkeletonTheme color="#202020" highlightColor="#444">
        <Container isLoading>
          <div className="inner">
            <RowItem>
              <Skeleton height={32} />
            </RowItem>
            <RowItem>
              <Skeleton height={32} />
            </RowItem>
            <RowItem>
              <Skeleton height={32} />
            </RowItem>
            <RowItem>
              <Skeleton height={32} />
            </RowItem>
            <RowItem>
              <Skeleton height={32} />
            </RowItem>
            <RowItem>
              <Skeleton height={32} />
            </RowItem>
          </div>
        </Container>
      </SkeletonTheme>
    ) : (
      <>
        <Container status={status}>
          <div className="inner">
            <RowItem>{data?.name}</RowItem>
            <RowItem>{data?.parent_name}</RowItem>
            <RowItem>{data?.num_circulars_completed} / {data?.num_circulars} </RowItem>
            <RowItem>{data?.num_pages_completed} / {data?.num_pages} </RowItem>
            <RowItem>
              Pages: <span>({data?.num_pages_rejected})</span> - Adblocks: <span>({data?.num_adblocks_rejected})</span>
            </RowItem>
            <RowItem>{data?.tier_ext_id}</RowItem>
            <RowItem
                onMouseEnter={() => setShowOptions(true)}
                onMouseLeave={() => setShowOptions(false)}
            >
              {isCircularLoading ? (
                  <span className={'loading'}>Loading</span>
              ) : (
                <>
                {!isCircularLoading && isRequeuing ?  (
                  <span className={'loading'}>Requeuing</span>
                ) :  (
                  <>

                    {!isCircularLoading && activeCircular && data?.num_circulars > 0 ?
                      <Selected>Selected {isSelectedCirculars.length}</Selected>
                    : <Selected>&nbsp;</Selected>}
                    
                    <IconButton>
                      <BsThreeDots color="white" />
                    </IconButton>
                  </>
                )}
                </>
              )}

              {showOptions && (
                <ToggleText>
                  <TextLink onClick={toggleActiveRetailerCirculars}>Toggle Circulars</TextLink>
                  {!isCircularLoading && activeCircular && data?.num_circulars > 0  ? (
                      <>
                        <TextLink onClick={selectAllCirculars}>{isSelectedCirculars.length === circulars.length ? 'Deselect' : 'Select' } All Circulars</TextLink>
                        {isSelectedCirculars.length !== 0  ? 
                          <>
                            {/* <TextLink onClick={queueCirculars}>Requeue Circulars</TextLink> */}
                            {userInfo.user?.is_superuser ? 
                              <TextLink onClick={handleConfirmModal}>Close Circulars</TextLink>
                            : null}
                          </>
                        : null}
                      </>
                  ) : null }
                </ToggleText>
                
              )}
           </RowItem>
          </div>
        </Container>

        {activeCircular ? (      
          <CircularsList>
          {isCircularLoading ? (
            times(7, String).map((index) => (
              <SubTableRow key={`${index}key`} isCircularLoading />
            ))
          ) : isClosing ? (
            <Message>Closing Circulars, this can take a few minutes depending upon the amount. There might be a delay on the circulars states.</Message>
          ) : isRequeuing ? (
            <Message>Requeuing Circulars, this can take a few minutes depending upon the amount. There might be a delay on the circulars states.</Message>
          ) : circulars && circulars.length > 0 ? (
            circulars.sort((a, b) => (a.num_pages > b.num_pages) ? -1 : 1).map((item, index) => (
              <SubTableRow 
              key={`${index}key`} 
              data={item} 
              retailer={data?.name}
              tier={data?.tier_ext_id}
              selectAll={isSelectedCirculars.length === circulars.length ? 'all' : isSelectedCirculars.length === 0 ? 'none' : null}
              onClick={(e) => selectCircular(e)}
              onButtonClick={(e) => handleCloseCircular(e)}
              onQueueCircular ={(e, hp) => handleRequeueCircular(e, hp)}
              />
            ))
          ) : (
            <div className="pt-4 mt-4 mx-auto text-center color-white">
              No circulars for this retailer.
            </div>
          )}
          </CircularsList>
        ) : null}

        <CircularCloseModal
          {...confirmClose}
          circulars={circulars}
          onConfirm={handleCloseCirculars}
        />
      </>
    );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.items};
  display: block;
  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  border-left: 12px solid
  ${(props) =>
    props.status === "complete"
      ? props.theme.palette.success
      : props.status === "warning"
      ? props.theme.palette.warning
      : props.status === "inprocess"
      ? props.theme.palette.primaryOutline
      : props.status === "error"
      ? props.theme.palette.error
      : props.status === "none"
      ? props.theme.palette.white
      : props.theme.palette.secondary};
  margin: 3px 6px 3px 12px;
  border-radius: 2px;
  .inner {
    display: flex;
    width: 100%;
    padding: 0 0px 0 32px;
    height: 88px;
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      flex: 1;
    }
    & > div:nth-child(2) {
      width: 300px;
    }
    & > div:nth-child(3) {
      width: 100px;
    }
    & > div:nth-child(4) {
      width: 100px;
    }
    & > div:nth-child(5) {
      width: 200px;
    }
    & > div:nth-child(6) {
      width: 60px;
    }
    & > div:nth-child(7) {
      width: 130px;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 16px auto 0;
  position:relative;
  span {
    color:${(props) => props.theme.palette.error};
  }
  .loading {
    color:${(props) => props.theme.palette.white};
  }
`;

const CircularsList = styled.div`
  padding: 5px 0px 5px 10px;
`;

const IconButton = styled.span`
  cursor: pointer;
  margin: 0 4px;
  position:absolute;
  right:0;
  top:0;
  &:hover {
    svg {
      transition: all 0.3s ease;
      fill: ${(props) => props.theme.palette.primary} !important;
    }
  }
`;

const ToggleText = styled.div`
  position: absolute;
  width: auto;
  white-space: nowrap;
  text-align: right;
  right:5px;
  color: white;
  font-size: 14px;
  background-color: ${(props) => props.theme.palette.items};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  .ocr-heading {
    color: gray;
  }
  z-index: 0;
  user-select: text;
  z-index:200;
`;

const TextLink = styled.div`
  cursor:pointer;
  margin:5px 0;
  padding:20px 10px;
  &:hover {
    background-color: ${(props) => props.theme.palette.itemsHover};
  }
`;

const Selected = styled.span`
  color:white !important;
`;

const Message = styled.div`
  color:white !important;
  padding:20px;
  text-align:center;
`;