import { API } from "utils";
import { COUNT_PER_PAGE } from "config";

export const getStagesApi = async () => {
  try {
    const response = await API().get("api/data/stages");
    return { success: true, payload: response.data?.stages };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getPageStagesApi = async () => {
  try {
    const response = await API().get("api/data/stages/page");
    return { success: true, payload: response.data?.stages };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getAdblockStagesApi = async () => {
  try {
    const response = await API().get("api/data/stages/adblock");
    return { success: true, payload: response.data?.stages };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getRetailersApi = async () => {
  try {
    const response = await API().get("api/data/retailers");
    return { success: true, payload: response.data?.retailers };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getOfferTypesApi = async () => {
  try {
    const response = await API().get("api/data/offer-types");
    return { success: true, payload: response.data?.offer_types };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getProductCategoriesApi = async () => {
  try {
    const response = await API().get("api/data/product-categories");
    return { success: true, payload: response.data?.product_categories };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getProductSizesApi = async () => {
  try {
    const response = await API().get("api/data/product-sizes");
    return { success: true, payload: response.data?.product_sizes };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getProductDimensionApi = async () => {
  try {
    const response = await API().get("api/data/product-dimensions");
    return { success: true, payload: response.data?.dimensions };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getProductBrandAutocompleteApi = async (name) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get("api/data/brands/autocomplete", {
      params: { name },
    });
    return {
      success: true,
      results: response.data?.brands.map((item) => ({
        ...item,
        name: item.brand_name,
      })),
    };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
export const getRetailerAutocompleteApi = async (name) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get("api/data/retailers/autocomplete", {
      params: {
        name,
      },
    });
    return { success: true, results: response.data?.retailers };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getRetailersList = async ({
  page = 0,
  per_page = COUNT_PER_PAGE,
  sort_by = "name",
  sort_dir = "ASC",
  is_active = false,
  queue_enabled = false
}) => {
  try {

    let data = { params: {
        page,
        per_page,
        sort_by,
        sort_dir,
      },
    }

    if(is_active) {
      data.params.is_active = is_active;
    }

    if(queue_enabled) {
      data.params.queue_enabled = queue_enabled;
    }

    const response = await API().get("api/data/retailers", data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getRetailerByIdApi = async (id) => {
  try {
    const response = await API().get(`api/data/retailer/${id}`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const postRetailerByIdActivate = async (id) => {
  try {
    const response = await API().post(`api/data/retailer/${id}/activate`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const postRetailerByIdDeactivate = async (id) => {
  try {
    const response = await API().post(`api/data/retailer/${id}/deactivate`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const postRetailerByIdActiveQueue = async (id) => {
  try {
    const response = await API().post(`api/data/retailer/${id}/activate-queue`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const postRetailerByIdDeactivateQueue = async (id) => {
  try {
    const response = await API().post(`api/data/retailer/${id}/deactivate-queue`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const postRetailerByIdActiveRequeue = async (id) => {
  try {
    const response = await API().post(`api/data/retailer/${id}/activate-requeue`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getOfferValuesApi = async (data) => {
  try {
    const response = await API().post(`api/data/get-offer-values`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getBrandByIdApi = async (id) => {
  try {
    const response = await API().get(`api/data/brand/${id}`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getCloningApi = async (data) => {
  try {
    const response = await API().get(`api/data/get-clonning?circ_type=${data.circ_type}`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const updateCloningApi = async (data) => {
  try {
    const response = await API().post(`api/data/update-clonning`, data);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getQueueCountApi = async (id) => {
  try {
    const response = await API().get(`api/data/queue-count`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getIngestionQueueCountApi = async (id) => {
  try {
    const response = await API().get(`api/data/ingestion-queue-count`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const createBrandApi = async (data) => {
  try {
    const response = await API().post(`api/data/brands/add`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getMergedBrandApi = async (name) => {
  try {
    const response = await API().get(`api/data/ext/brand/merge?name=${name}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
}

export const getBrandFullInfo = async (name) => {
  try {
    const response = await API().get("api/data/brand-full-info", {
      params: { name }
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
}

export const createModelNumberApi = async (data) => {
  try {
    const response = await API().post(`api/data/model-numbers/add`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const createOriginApi = async (data) => {
  try {
    const response = await API().post(`api/data/origins/add`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const createVarietyApi = async (data) => {
  try {
    const response = await API().post(`api/data/variants/add`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const eventAutocompleteApi = async (name) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(
      `api/data/events/autocomplete?name=${name}`
    );
    return { success: true, results: response.data.events };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getPageCroppingStats = async (fromDate, toDate, domain_url, tier_id, user_id, retailer_id) => {

  let string = `api/stats/stats/page-cropping?from_date=${fromDate}&to_date=${toDate}&domain_url=${domain_url}`;

  if(tier_id) {
    string = string + `&tier_id=${tier_id}`;
  }
  if(user_id) {
    string = string + `&user_id=${user_id}`;
  }
  if(retailer_id) {
    string = string + `&retailer_id=${retailer_id}`;
  }

  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(
      string
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getAblockTimingStats = async ({fromDate, toDate, domain_url,  tier_id, user_id, retailer_id}) => {

  let string = `api/stats/stats/adblock-timings?from_date=${fromDate}&to_date=${toDate}&domain_url=${domain_url}`;

  if(tier_id) {
    string = string + `&tier_id=${tier_id}`;
  }
  if(user_id) {
    string = string + `&user_id=${user_id}`;
  }
  if(retailer_id) {
    string = string + `&retailer_id=${retailer_id}`;
  }


  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(
      string
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getRetailerVolumeReport = async ({fromDate, toDate}) => {
  let string = '';
  if (fromDate && toDate){
    string = `api/stats/stats/retailer-volume?from_date=${fromDate}&to_date=${toDate}`;
  }
  else {
    string = `api/stats/stats/retailer-volume`;
  }

  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(
      string
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getRetailerVolumeTierWiseReport = async ({fromDate, toDate}) => {
  let string = '';
  if (fromDate && toDate){
    string = `api/stats/stats/retailer-volume-tier-wise?from_date=${fromDate}&to_date=${toDate}`;
  }
  else {
    string = `api/stats/stats/retailer-volume-tier-wise`;
  }

  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(
      string
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getRetailerTimingStats = async (fromDate, toDate) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(
      `api/stats/stats/retailers?from_date=${fromDate}&to_date=${toDate}`
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getWeeklySummaryStats = async (fromDate, toDate) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(
      `api/stats/stats/weekly-summary?from_date=${fromDate}&to_date=${toDate}`
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const postRetailerLoyaltyText = async ({id, loyalty_text = ''}) => {
  try {
    const response = await API().post(
      `api/data/retailer/${id}/set-loyalty-text?loyalty_text=${loyalty_text}`
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
}

export const postRetailerTier = async ({id, tier_ext_id = ''}) => {
  try {
    const response = await API().post(
      `api/data/retailer/${id}/set-tier?tier_ext_id=${tier_ext_id}`
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
}

export const getTiers = async () => {
  try {
    const response = await API().get(`api/user/tiers`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
}

export const getManufacturersAPI = async (manufacturer) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(`api/data/manufacturer/autocomplete?name=${manufacturer}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getUserRollupStats = async (fromDate, toDate) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(
      `api/stats/stats/users-rollup?from_date=${fromDate}&to_date=${toDate}`
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getIncompleteCircularStats = async (fromDate, toDate, circular_type) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(
      `api/stats/stats/incomplete-circulars?from_date=${fromDate}&to_date=${toDate}&circular_type=${circular_type}`
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getAdBlockProductivityStats = async (fromDate, toDate, circularType, action) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(
      `api/stats/stats/adblock-productivity?from_date=${fromDate}&to_date=${toDate}&circular_type=${circularType}&action=${action}`,
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const postUpdateRetailer = async ({ id, data }) => {
  console.log(id)
  try {
    const response = await API().post(
      `api/data/retailer/${id}/update`,
      data
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
}

export const usersAutoCompleteAPI = async (name) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(
      `api/data/users/autocomplete?name=${name}`
    );
    return { success: true, results: response.data.users };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getCurrentUserAPI = async () => {
  try {
    const response = await API().get(
      `api/user/me`
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
}

export const getLastWeekSumamryApi = async () => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get("api/stats/stats/last-week-weekly-summary");
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getMinMaxProductValue = async (sub_cat_i, prod_size) => {
  try {
    const response = await API().get(`api/data/product-size-limit?sub_cat_i=${sub_cat_i}&product_size_id=${prod_size}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
}

export const getCounterStatAPI = async (fromDate, toDate) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(`api/stats/stats/counter-stats?from_date=${fromDate}&to_date=${toDate}`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
}

export const startAcitivityAPI = async (id, view='product') => {
  try {
    const response = await API().get(`api/stats/${id}/start-activity?view=${view}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
}

export const completeActivityAPI = async (id) => {
  try {
    const response = await API().post(`api/stats/${id}/complete-activity`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
}

export const rejectImageMatchAPI = async (id, view='product') => {
  try {
    const response = await API().post(`api/stats/${id}/reject-image-match?view=${view}`);
    return { success: true, payload: response.data };
  } catch (error) {
    throw error;
  }
};

export const getAdBlockActivityStats = async (fromDate, toDate) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(
      `api/stats/adblock-activity-stats?from_date=${fromDate}&to_date=${toDate}`
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
}

export const userHeartbeatAPI = async (data) => {
  try {
    const response = await API().post(`api/stats/user-heartbeat`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
}

export const getUserHeartbeatAPI = async (fromDate, toDate) => {
  try {
    const response = await API().get(`api/stats/user-heartbeat?from_date=${fromDate}&to_date=${toDate}`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
}
