import React from "react";
import styled from "styled-components";
import { TableHeaderColumn } from "components";

export const TableHeader = ({ sortCol, sortType, onSort = () => { } }) => {
  return (
    <Container>
      <TableHeaderColumn label="Tier Name"></TableHeaderColumn>
      <TableHeaderColumn label="HP Products"></TableHeaderColumn>
      <TableHeaderColumn label="MP Products"></TableHeaderColumn>
      <TableHeaderColumn label="LP Products"></TableHeaderColumn>
      <TableHeaderColumn label="HP Offers"></TableHeaderColumn>
      <TableHeaderColumn label="MP Offers"></TableHeaderColumn>
      <TableHeaderColumn label="LP Offers"></TableHeaderColumn>
      <TableHeaderColumn label="HP Crops"></TableHeaderColumn>
      <TableHeaderColumn label="MP Crops"></TableHeaderColumn>
      <TableHeaderColumn label="LP Crops"></TableHeaderColumn>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 0 0px 0 32px;
  color: white;
  font-size: ${(props) => props.theme.palette.m};
  margin: 28px 62px 15px 12px;
  & > div:nth-child(1) {
      flex: 3;
    }
    & > div:nth-child(2) {
      flex: 1;
    }
    & > div:nth-child(3) {
      flex: 1;
      text-align: center;
    }
    & > div:nth-child(4) {
      flex: 1;
    }
    & > div:nth-child(5) {
      flex: 1;
    }
    & > div:nth-child(6) {
      flex: 1;
    }
    & > div:nth-child(7) {
      flex: 1;
    }
    & > div:nth-child(8) {
      flex: 1;
    }
    & > div:nth-child(9) {
      flex: 1;
    }
    & > div:nth-child(10) {
      flex: 1;
    } 
`;
