/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal, Button } from "components";
import styled from "styled-components";

export const RejectPageWarning = ({
  open,
  onClose,
  onReject = () => { }
}) => {

  const handleCancel = () => {
    onClose();
  }

  const handleReject = () => {
    onReject();
    onClose();
  }

  return (
    <Modal open={open} onClose={onClose} size="medium" title={"Rejecting Page"}>
      <MessageStyle>
        <div>
          <div>Are you sure you want to reject this Page?</div>
          <WarningMessage><WarningText>WARNING:</WarningText> Rejecting a page will delete all adblocks and break any relationships between matched blocks. By rejecting this page, it will no longer be worked on and will be considered completed in terms of circular completion.</WarningMessage>
          <div>Here are the only reasons a page should be rejected</div>
          <ul>
            <li>This page is not for this circular</li>
            <li>This page is unreadable</li>
            <li>This page is already exists in the circular</li>
          </ul>
        </div>
      </MessageStyle>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-4"
          type="button"
          onClick={() => handleReject()}
        >
          Reject
        </Button>
      </div>
    </Modal>
  );
};

const MessageStyle = styled.div`
    font-size:14px;
`;
const WarningMessage = styled.div`
    font-size:14px;
    padding:10px;
`;

const WarningText = styled.span`
  color:${(props) => props.theme.palette.warning};
`;
