import { uniq } from "lodash";
import { DimensionData, sizeDisablingUnits } from "./data";
import _ from "lodash";

export const findDuplicates = (products = []) => {
  console.log("find duplicates", products);
  let arr = uniq(products.map((product) => JSON.stringify(product)));
  if (arr.length === products.length) return false;
  return true;
};

export const checkDupeEntry = (productsData) => {
  //check similar product with the same size twice
  let entry_items = [];
  productsData.forEach((product) => {
    let item_obj = {
      ext_brand_i : product.ext_brand_i,
      size_1 : product.size_1,
      size_2 : product.size_2,
      size_3 : product.size_3,
      size_4 : product.size_4,
      size_dimension_i : product.size_dimension_i,
      ext_size_unit_i  : product.ext_size_unit_i,
      ext_size_unit_2i  : product.ext_size_unit_2i,
      ext_size_unit_3i  : product.ext_size_unit_3i,
      variant_id : product.variant_id,
      origin_id: product.origin_id,
      model_number_id : product.model_number_id,
      liner_f: product.liner_f
    }
    entry_items.push(item_obj)
  });
   
  return !findDuplicates(entry_items);
}


export const validateProduct = (product, sizeData) => {
  //Validate product on change, check the required fields
    var tempDimensionData = DimensionData;
  let size_name = sizeData.find((size) =>
    size.id === product["ext_size_unit_i"]
  )?.title;
  let size_id = sizeData.find((size) =>
    size.id === product["ext_size_unit_i"]
  )?.id;

  let size_name2 = sizeData.find((size) =>
    size.id === product["ext_size_unit_2_i"]
  )?.title;
  let size_id2 = sizeData.find((size) =>
    size.id === product["ext_size_unit_2_i"]
  )?.id;
  const isDimensionValid = tempDimensionData.find(dimension => dimension.id === product["size_dimension_i"])
  product.isValid = true;
  if (product["size_dimension_i"] === 5 && ((!product["size_1"] || !product["size_3"]) || (!size_name || !size_name2))) {
    return;
  }
  if (product["size_dimension_i"] === 26) {
    if (product["size_1"] && product["size_3"] && size_id && size_id2) {
      if (product["size_4"]) {
        return Number(product["size_3"]) < Number(product["size_4"]);
      }
      return true;
    } else {
      return false;
    }
  }
  if (product["ext_brand_i"] === null || product["ext_brand_i"] === '') {
    product.isValid = false;
  } else {
    if (product["size_dimension_i"] !== null && product["size_dimension_i"] !== "" && isDimensionValid) {
      var dimensionSubSet = tempDimensionData.find((x) => x.id === product["size_dimension_i"]).subSets;
      //Turning off size validation, need to find a way to find size undeterminable
      dimensionSubSet.forEach((element) => {
        //skip size_2 if it is size and qty, no validation

        if((element === 'size_1' || element === 'size_2') && 'size undeterminable' === size_name) {
          return;
        }
        
        if((element === 'size_3' || element === 'size_4') && 'size undeterminable' === size_name2) {
          return;
        }
        
        if((element === 'size_1' || element === 'size_2'))
        
        //not sure about this one but if the first can do it why not second set?
        if ((element === "size_2" || element === "size_4") && product["size_dimension_i"] === 5) {
          return;
          
        } else {
          
          if (
            (product[element] === null || product[element] === "") && product.isValid
            ) {
            if(element === 'size_1' && sizeDisablingUnits.includes(size_id) && product["size_dimension_i"] < 6){
              product.isValid = true;
              return
            }
            if(element === 'size_2' && sizeDisablingUnits.includes(size_id) && product["size_dimension_i"] < 6){
              product.isValid = true;
              return
            }
            if(element === 'size_3' && sizeDisablingUnits.includes(size_id2) && product["size_dimension_i"] < 6){
              product.isValid = true;
              return
            }
            if(element === 'size_4' && sizeDisablingUnits.includes(size_id2) && product["size_dimension_i"] < 6){
              product.isValid = true;
              return
            }
            product.isValid = false;
          }
        }
      });
    } else {
      product.isValid = false;
    }
  }
  return product.isValid;
}

export const unitSizeRestrictionMessage = (productsData = []) => {
  const unitResMessage = {}
  productsData.forEach((product) => {
    const selectedSize = product.sizes?.find(size => size.id === product.ext_size_unit_i);
    const selectedSize2 = product.sizes?.find(size => size.id === product.ext_size_unit_2_i);
    const size_1 = parseFloat(product.size_1);
    const size_2 = parseFloat(product.size_2);
    const size_3 = parseFloat(product.size_3);
    const size_4 = parseFloat(product.size_4);
    const current_product = product.brand_nm
    const max = selectedSize?.max;
    const min = selectedSize?.min;
    const max2 = selectedSize2?.max;
    const min2 = selectedSize2?.min;
    if (product.size_dimension_i === 2) {
      if (size_1 > max || size_1 < min || size_2 > max || size_2 < min) {
        unitResMessage[current_product] = {dimension: product.size_dimension_i, size_1, size_2, max, min}
      }
    }
    if (product.size_dimension_i === 5) {
      if (size_1 > max || size_1 < min || size_2 > max || size_2 < min) {
        unitResMessage[current_product] = {dimension: product.size_dimension_i, size_1, size_2, max, min}
      }
      if (size_3 > max2 || size_3 < min2 || size_4 > max2 || size_4 < min2) {
        if (!unitResMessage.hasOwnProperty(current_product)) unitResMessage[current_product] = {}; 
        unitResMessage[current_product].dimension = product.size_dimension_i
        unitResMessage[current_product].size_1 = size_1
        unitResMessage[current_product].size_2 = size_2
        unitResMessage[current_product].size_3 = size_3
        unitResMessage[current_product].size_4 = size_4
        unitResMessage[current_product].max2 = max2
        unitResMessage[current_product].min2 = min2
        unitResMessage[current_product].min = min
        unitResMessage[current_product].max = max
        console.log("ur2", unitResMessage)
      }
    }
    if (product.size_dimension_i === 6) {
      if (size_1 > max || size_1 < min || size_2 > max2 || size_2 < min2) {
        unitResMessage[current_product] = {dimension: product.size_dimension_i, size_1, size_2, max, min, max2, min2}
      }
    }
    if (product.size_dimension_i !== 2 && product.size_dimension_i !== 5 && product.size_dimension_i !== 6) {
      if (size_1 > max || size_1 < min) {
        unitResMessage[current_product] = {dimension: product.size_dimension_i, size_1, max, min}
      }
    }
  })
  console.log(unitResMessage)
  return unitResMessage;
}

export const sizeValueBuilder = (
  sizeData,
  size_1,
  size_2,
  size_3,
  size_4,
  ext_size_unit_i,
  ext_size_unit_2_i,
  size_dimension_i
) => {
  if (size_dimension_i === 2) {
    return `${size_1} - ${size_2} ${sizeData.find((size) => size.id === ext_size_unit_i)?.title}`;
  }
  else if (size_dimension_i === 4) {
    return `${size_1} x ${size_2} ${sizeData.find((size) => size.id === ext_size_unit_i)?.title} `
  }
  else if (size_dimension_i === 5) {
    return `${size_1} ${size_2} ${sizeData.find((size) => size.id === ext_size_unit_i)?.title} of ${size_3}-${size_4} ${sizeData.find((size) => size.id === ext_size_unit_2_i)?.title}`
  }
  else if (size_dimension_i === 6) {
    return `${size_1} ${sizeData.find((size) => size.id === ext_size_unit_i )?.title} ${size_2} ${sizeData.find((size) => size.id === ext_size_unit_2_i)?.title}`
  }
  else if (size_dimension_i === 7) {
    return `${size_1} ${sizeData.find((size) => size.id === ext_size_unit_i)?.title} ${size_2} ${sizeData.find((size) => size.id === ext_size_unit_2_i)?.title}`
  }
  else if (size_dimension_i === 9) {
    return `${size_1}x${size_2} ${sizeData.find((size) => size.id === ext_size_unit_i)?.title} ${size_3}x${size_4} ${sizeData.find((size) => size.id === ext_size_unit_2_i)?.title}`
  }
  else {
    return `${size_1} ${sizeData.find((size) => size.id === ext_size_unit_i)?.title}`
  }
}

export const getUniqueProducts = (productsTemp) => {
  let productsUnique = [];
  productsTemp.forEach((product) => {
    if (!productsUnique.some(e => e.brand_nm === product.brand_nm) || !productsUnique.some(e => e.brand?.brand_nm === product.brand?.brand_nm)) {
      productsUnique.push(product);
    }
  })
  return productsUnique;
}

export const productStringEval = (product = {}, sizeData = [], index = 0, showIndex = true) => {
  const brand = product.brand_nm || product.brand?.brand_name
  const idxBrand = showIndex ? `${index + 1}. ${brand}` : brand
  const getVariantName = (product) => {
    console.log(product)
    if (product?.variant_nms) return product.variant_nms.join(', ');
    if (_.isObject(product?.variants)) return product.variants?.map(v => v.variety_nm)?.join(', ');
    return '';
  };

  const getOriginName = (product) => {
    if (product?.origin_nms) return product.origin_nms.join(', ');
    if (_.isObject(product?.origins)) return product.origins?.map(o => o.origin_nm)?.join(', ');
    return '';
  };

  const getSizeUnit = (sizeId) => {
    const size = sizeData.find((size) => size.id === sizeId);
    return size ? size.title : '';
  };

  const renderSizeString = () => {
    switch (product.size_dimension_i) {
      case 2: // Range
        return `${product.size_1 || ''}${product.size_2 ? `-${product.size_2 ||  ''}` : ''} ${getSizeUnit(product.ext_size_unit_i)}`;
      case 4: // Multi
        return `${product.size_1 || ''} x ${product.size_2} ${getSizeUnit(product.ext_size_unit_i)}`;
      case 5: // Size Qty
        return `${product.size_1 || ''}${product.size_2 ? ` -${product.size_2}` : ''} ${getSizeUnit(product.ext_size_unit_i)} ${product.size_3 ? `of ${product.size_3}` : ''}${product.size_4 ? `-${product.size_4}` : ''} ${getSizeUnit(product.ext_size_unit_2_i)}`;
      case 6:
        return `${product.size_1 || ''} ${getSizeUnit(product.ext_size_unit_i)}${product.size_2 || '' ? ` - ${product.size_2 || ''} ${getSizeUnit(product.ext_size_unit_2_i)}` : ''}`;
      case 7: // Double
        return `${product.size_1 || ''} ${getSizeUnit(product.ext_size_unit_i)} ${product.size_2 || ''} ${getSizeUnit(product.ext_size_unit_2_i)}`;
      case 9:
        return `${product.size_1 || ''}x${product.size_2 || ''} ${getSizeUnit(product.ext_size_unit_i)} ${product.size_3 || ''}x${product.size_4 || ''} ${getSizeUnit(product.ext_size_unit_2_i)}`;
      case 26: // TotalSize
        return `${product.size_1 || ''} ${getSizeUnit(product.ext_size_unit_i)}${product.size_3 || '' ? ` of ${product.size_3 || ''}` : ''}${product.size_4 || '' ? ` - ${product.size_4 || ''}` : ''} ${getSizeUnit(product.ext_size_unit_2_i)}`;
      default: // Single
        return `${product.size_1 || ''} ${getSizeUnit(product.ext_size_unit_i)}`;
    }
  };

  const productString = brand ? (
    <div className="flex-1">
      {idxBrand} {getVariantName(product)} {getOriginName(product)} {renderSizeString()} {product.liner_f ? " - Liner" : ''}
    </div>
  ) : (
    <div className="flex-1">{`Unbranded Product ${index + 1}`}</div>
  );

  return productString
}

export const getSizeType = (unit, sizeData) => sizeData?.find(item => item.id === unit.value)?.whole_num_f ? 'int' : 'float';