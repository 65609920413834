import React, { useRef, useState } from 'react'
import { Input2 } from 'components/Input2';
import styled from 'styled-components';
import { useOutsideClick } from "hooks";

const AutocompleteFreeText = ({
    label = 'Free Text',
    data = [],
    value = '',
    onChange = () => {}
}) => {
    const [showDropDown, setShowDropDown] = useState(false);
    // const [value, setValue] = useState('');
    const inputRef = useRef(null);
    const menuRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(-1);
    useOutsideClick(menuRef, () => {
        setShowDropDown(false);
    });

    const handleClose = () => {
        setActiveIndex(-1);
        setShowDropDown(false);
        inputRef.current.focus();
    }

    const setChange = () => {
        if (menuRef.current){
            const selected = menuRef.current.querySelector(".current");
            if (selected) {
                selected?.scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }
        }
    }

    const handleKeyPress = event => {
        if (activeIndex < data.length) {
            const newSelection = data.filter(i => i.title.toLowerCase().startsWith(findLastWord()))
            const total = newSelection.length
            if (event.key === 'ArrowUp' && activeIndex > -1){
                event.preventDefault();
                setActiveIndex(prev => (prev - 1) % total);
                setChange()
            }
            else if (event.key === 'ArrowDown' && activeIndex < data.length - 1){
                event.preventDefault();
                setActiveIndex(prev => (prev + 1) % total);
                setChange()
            }
            else if (event.key === 'Enter' && activeIndex >= 0){
                const selection = newSelection[activeIndex]?.title
                let newValue = value.split(" ");
                if (newValue.length > 0) {
                    newValue[newValue.length - 1] = selection;
                    newValue = newValue.join(' ');
                } else {
                    newValue = selection;
                }
                onChange(newValue);
                setTimeout(() => {
                    handleClose();
                }, 1)
            }
        }else {
            handleClose();
        }
    }

    const handleTextChange = (e) => {
        if (e === '' || e[e.length - 1] === ' ') {
            setActiveIndex(-1);
            setShowDropDown(true);
        }
        onChange(e);
    }

    const handleTextClick = (e) => {
        if (!value.trim()) {
            setShowDropDown(true);
        }
    }

    const findLastWord = () => {
        let text = value;
        let words = text.trim().split(' ');
        return words[words.length - 1].toLowerCase();
    }

    const handleDropDownSelection = (e) => {
        setShowDropDown(false);
        let newValue = value.split(" ");
        if (newValue.length > 0) {
            newValue[newValue.length - 1] = e.target.innerText;
            newValue = newValue.join(' ');
        } else {
            newValue = e.target.innerText;
        }
        onChange(newValue);
        inputRef.current.focus()
    }

    return (
        <Container 
            onKeyDown={handleKeyPress}
            >
            <Input2
                ref={inputRef}
                className='w-100'
                value={value}
                onChange={(e) => handleTextChange(e)}
                onClick={handleTextClick}
                label={label}
            />
            {showDropDown &&
                <MenuDropDown 
                    ref={menuRef}
                    >
                    {data.filter(i => i.title.toLowerCase().startsWith(findLastWord())).map((item, index) =>
                        <MenuItem 
                            key={index} 
                            onClick={(e) => handleDropDownSelection(e)}
                            className={`${index === activeIndex ? "current" : ""}`}
                        >
                            {item.title}
                        </MenuItem>
                    )}
                </MenuDropDown>
            }
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    display: flex;
    padding: 0;
    outline: 0;
    text-align: left;
    width: 100%;
    min-width: ${(props) => `${props.width}px`};
    &:focus .menu-button {
        pointer-events: none;
        border-color: #fff;
    }

    /* If :focus detected the dropdown menu is displayed by making it visible */
    &:focus .menu-dropdown {
        opacity: 1;
      /* visibility: visible; */
        display: block;
    }
    &:focus .menu-button__drop-down {
        transform: rotate(180deg);
    }
`

const MenuDropDown = styled.div`
    width: 100%;
    margin: 3px 0 0 0;
    padding: 8px 0;
    pointer-events: auto;
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 50px;
    transition: all 300ms ease;
    color: white;
    background: #191919;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    max-height: 220px;
    overflow: auto;
    overflow-y: auto;
`

const MenuItem = styled.div`
    position: absolute;
    z-index: 2;
    border: none;
    outline: 0;
    display: block;
    color: white;
    background: #191919;
    text-decoration: none;
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    transition: all 300ms ease;
            &:hover {
                background: #555454;
                border-radius: 0px;
                color: white;
            }
            &.current{
                background-color: #555454;
            }
    &.active::after {
        content: " ";
        position: absolute;
        width: 12px;
        height: 6px;
        border-left: 2px solid #3bc7e3;
        border-bottom: 2px solid #3bc7e3;
        transform: rotate(-45deg);
        top: 11px;
        transition: all 300ms ease;
        right: 12px;
    }
    `;


export default AutocompleteFreeText