import { API } from "utils";

export const getQueue = async () => {
    try {
        const response = await API().get("api/queues/");
        return { success: true, payload: response.data?.tiers };
    } catch (e) {
        return { success: false, payload: [], error: e };
    }
};
