import { OFFER_INIT_INFO } from "config";

export const updateOffersBasedOnDigitalCoupon = (offersData, setOfferData) => {
  let isSaveOffer = false
  let offerDataObj
  if (offersData.length) {
    offersData.forEach((offer) => {
      if (offer.offer_type.toLowerCase() === 'save') {
        if (!offer.existingOffer)
          offer.offer_text += ' w/digital coupon'
        offer.offer_type = 'Save'
        isSaveOffer = true
      }
    })
    if (isSaveOffer) {
      setOfferData(offersData)
    } else {
      offerDataObj = {
        ...OFFER_INIT_INFO,
        index: 0,
        isValid: true,
      };
      offerDataObj.offer_type = "Other"
      offerDataObj.offer_text = 'w/digital coupon'
      setOfferData([...offersData, offerDataObj])
    }
  }
  else {
    offerDataObj = {
      ...OFFER_INIT_INFO,
      index: 0,
      isValid: true,
    };
    offerDataObj.offer_type = "Other"
    offerDataObj.offer_text = 'w/digital coupon'
    setOfferData([{ ...offerDataObj }])
  }
};
