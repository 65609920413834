import React from "react";
import styled from "styled-components";
import { MdRefresh } from "react-icons/md";

export const QuickSelection = ({
  children,
  isRefresh = false,
  onRefreshSuggestion = () => {},
}) => {
  return (
    <SelectionContainer>
      <SelectionHeader>
        QUICK SELECTION
        {isRefresh && <MdRefresh size={18} onClick={onRefreshSuggestion} />}
      </SelectionHeader>

      <SelectionContent>{children}</SelectionContent>
    </SelectionContainer>
  );
};

const SelectionContainer = styled.div`
  width: 380px;
  flex: 1;
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  /* overflow: auto; */
`;
const SelectionHeader = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const SelectionContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  color: white;
  flex: 1;
  height: 100%;
  max-height: calc(100% - 12px);
  overflow: auto;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
