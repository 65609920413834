import * as Sentry from '@sentry/react'
import { API_URL } from 'config';

function configSentry() {
  if (window.location.hostname === 'localhost') {
    return null;
  }
  return Sentry.init({
    dsn: "https://3769fdb339b2e7ec7bf58bfeffd6e27d@o3622.ingest.us.sentry.io/4507180402671616",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 0.01,
    tracePropagationTargets: [
      "localhost:3011", 
      "localhost:3000", 
      /^https:\/\/api\.dev\.addisonpromo\.com\//, 
      /^https:\/\/api\.staging\.addisonpromo\.com\//, 
      /^https:\/\/api\.addisonpromo\.com\//, 
      /^https:\/\/dev\.addisonpromo\.com\//, 
      /^https:\/\/staging\.addisonpromo\.com\//, 
      /^https:\/\/addisonpromo\.com\//
    ],
    replaysSessionSampleRate: 0.001,
    replaysOnErrorSampleRate: 0.001,
    environment: API_URL.includes('staging') ? 'staging' : 'production',
  });
}

export default configSentry();