/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2 } from "components";
import { useInput } from "hooks";
export const IncentiveCreditCard2TextBuilder = ({
  className,
  value = "",
  onChange = () => {},
}) => {
  const val = useInput("10.00");

  const type = useInput("$");

  const typeData = [
    {
      id: "$",
      title: "$",
    },
    { id: "%", title: "%" },
  ];

  const handleUpdateValue = (v, t) => {
    onChange(
      `save ${t === "$" ? `${t}${v}` : `${v}${t}`}  w/store credit card`
    );
  };

  useEffect(() => {
    if (value.endsWith("w/store credit card") && value.startsWith("Save")) {
      let main = value.slice(0, -19).trim();
      main = main.slice(4).trim();
      if (main.startsWith("$")) {
        type.setValue("$");
        val.setValue(main.slice(1));
      } else {
        type.setValue("%");
        val.setValue(main.slice(0, -1));
      }
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <div style={{ width: 120 }}>
          <Input2
            label="Value"
            value={val.value}
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(e, type.value);
            }}
          />
        </div>
        <div style={{ width: 120, marginLeft: 10 }}>
          <DropDown2
            label="Time"
            data={typeData}
            width={70}
            value={type.value}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(val.value, e);
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
