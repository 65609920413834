/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { LoginLayout } from "layouts";
import { Button, FormInput, Link, Modal } from "components";
import LogoImg from "assets/img/logo.png";
import { navigate } from "@reach/router";
import { login } from "api";
import { useAuthContext, useMainContext } from "contexts";
import { parse } from "query-string";
import { useLocation } from "@reach/router";
import { useModal } from "hooks";

const LoginForm = styled.div`
  width: 428px;
  margin: 60px auto 0 auto;
`;
const Brand = styled.div`
  font-size: 100px;
  line-height: 120px;
  color: white;
`;
const Logo = styled.div`
  width: 428px;
  margin: auto auto 0 auto;
  img {
    width: 160px;
  }
`;
const BuiltBy = styled.div`
  font-size: 16px;
  color: white;
  margin: auto 12px auto auto;
`;
const Error = styled.div`
  color: #ff8484;
  font-size: 20px;
`;

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter your name"),
  password: Yup.string().required("Please enter your password"),
  // .matches(
  //   /^(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
  //   "Must Contain 8 Characters, and one number"
  // ),
});

export const LoginPage = () => {
  const { loginUser } = useAuthContext();
  const { clear } = useMainContext();
  const [error, setError] = useState("");
  const location = useLocation();
  const expireModal = useModal();
  const googleAuthModal = useModal();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const parsed = parse(location.search);
    if (parsed.token_expire) {
      expireModal.openModal();
    } else if (parsed["google-auth-error"]) {
      googleAuthModal.openModal();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginLayout>
      <Logo src={LogoImg} alt="logo">
        <Brand>ADDISON</Brand>
        <div className="d-flex">
          <BuiltBy>built by</BuiltBy>
          <img src={LogoImg} alt="deep.ad" />
        </div>
      </Logo>
      <LoginForm>
        <Formik
          initialValues={{
            password: "",
            username: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setIsLoading(true);
            setError("");
            try {
              const res = await login(values);
              if (res.success) {

                //React is too fast when loading the page, we need to slow it down
                //so that authentication works
                setTimeout(() => {
                  loginUser(res.result);
                  clear();
                  setIsLoading(false);
                }, 2000);

              } else {
                setError("Invalid username or password");
                setIsLoading(false);
              }
            } catch (error) {
              setError(error.message);
              setIsLoading(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormInput label="User Name" name="username" />
              <FormInput label="Password" type="password" name="password" />
              {error && <Error className="mt-3">{error}</Error>}
              <Button
                size="large"
                type="submit"
                className="mt-3"
                isLoading={isSubmitting || isLoading}
              >
                Login
              </Button>
            </Form>
          )}
        </Formik>

        {/* <div className="d-flex mt-4 justify-content-center">
          <SocialButton onClick={onGoogleLogin}>
            <FcGoogle size={80} />
          </SocialButton>
          <SocialButton onClick={onMSLogin}>
            <TiVendorMicrosoft size={80} color="#2a90af" />
          </SocialButton>
        </div> */}
      </LoginForm>
      <Modal size="small" {...expireModal} title="Your session has expired">
        <div className="text-center">Please login again.</div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            width="120px"
            size="normal"
            onClick={() => expireModal.onClose()}
          >
            Ok
          </Button>
        </div>
      </Modal>
      <Modal
        size="small"
        {...googleAuthModal}
        title="Google Authentication failed"
      >
        <div className="text-center">Please login again.</div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            width="120px"
            size="normal"
            onClick={() => googleAuthModal.onClose()}
          >
            Ok
          </Button>
        </div>
      </Modal>
    </LoginLayout>
  );
};

// const SocialButton = styled.div`
//   width: 80px;
//   height: 80px;
//   margin: 0 20px;
//   cursor: pointer;
//   transition: all 300ms ease;
//   &:hover {
//     opacity: 0.7;
//   }
// `;
