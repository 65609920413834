import { getProductSizesApi } from "api";
import { useQuery } from "react-query";


export const useProductSizes = () => {
  return useQuery({
    queryKey: ['sizes'],
    queryFn: async () => {
      const sizeRes = await getProductSizesApi();
      if (!sizeRes.success) return [];

      console.log()
      return sizeRes.payload.map(({ ext_id, size_c, whole_num_f }) => ({
        id: ext_id,
        title: size_c,
        whole_num_f,
      }));
    },
    onSuccess: (data) => {
      console.log('Product Sizes fetched successfully', data);
    },
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60 * 2,
  });
};