import { API } from "utils";

export const getGroupById = async (id) => {
  try {
    const response = await API().get(`api/groups/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deleteGroupById = async (id) => {
  try {
    const response = await API().delete(`api/groups/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
