import React from "react";
import styled from "styled-components";
import { TableHeaderColumn } from "components";

export const TableHeader = ({ sortCol, sortType, onSort = () => {} }) => {
  return (
    <Container>
      <TableHeaderColumn></TableHeaderColumn>
      <TableHeaderColumn label="User"></TableHeaderColumn>
      <TableHeaderColumn label="Cropping"></TableHeaderColumn>
      <TableHeaderColumn label="CM"></TableHeaderColumn>
      <TableHeaderColumn label="IM Products"></TableHeaderColumn>
      <TableHeaderColumn label="IM Offers"></TableHeaderColumn>
      <TableHeaderColumn label="EM Products"></TableHeaderColumn>
      <TableHeaderColumn label="EM Offers"></TableHeaderColumn>
      <TableHeaderColumn label="TTB Products"></TableHeaderColumn>
      <TableHeaderColumn label="TTB Offers"></TableHeaderColumn>
      <TableHeaderColumn label="LTR Products"></TableHeaderColumn>
      <TableHeaderColumn label="LTR Offers"></TableHeaderColumn>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 0 0px 0 32px;
  color: white;
  font-size: ${(props) => props.theme.palette.m};
  margin: 28px 12px 15px 12px;
  width: 95%;
  & > div:nth-child(1) {
    width: 100px;
  }
  & > div:nth-child(2) {
    flex: 1;
  }
  & > div:nth-child(3) {
    flex: 1;
  }
  & > div:nth-child(4) {
    flex: 1;
  }
  & > div:nth-child(5) {
    flex: 1;
  }
  & > div:nth-child(6) {
    flex: 1;
  }
  & > div:nth-child(7) {
    flex: 1;
  }
  & > div:nth-child(8) {
    flex: 1;
  }
  & > div:nth-child(9) {
    flex: 1;
  }
  & > div:nth-child(10) {
    flex: 1;
  }
  & > div:nth-child(11) {
    flex: 1;
  }
`;
