
import { Modal, Button, IncentiveTextBuilder, QuickSelection, IncentiveItem } from 'components'
import { IncentiveInfo } from 'fragments';
import { useMainContext } from 'contexts';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { INCENTIVE_INIT_INFO } from 'config';
import { INCENTIVE_INFO_FIELDS } from 'config';
import { validateIncentive } from 'utils';
import { FaPlus } from 'react-icons/fa';

export function CreatePageIncentive({
  open,
  onClose,
  onSubmit = () => { },
}) {
  const [incentiveData, setIncentiveData] = useState([]);
  const [currentOfferIndex, setCurrentOfferIndex] = useState(0);
  const isPageIncentive = true;

  const {
    currentInfo,
    updateInfo,
    updateInfos,
    setFieldNames,
    setActiveField,
    setCurrentInfo
  } = useMainContext();

  useEffect(() => {
    if (open) {
      if (incentiveData.length === 0) {
        handleAddIncentive();
      }
    }
    return () => {};
  }, [open]);

  useEffect(() => {
    const index = incentiveData.length === 0 ? 0 : incentiveData.length - 1;
    if (Object.keys(currentInfo).length > 0) {
      let temp = [...incentiveData];
        temp[currentOfferIndex] = {
          ...currentInfo,
          index: index,
          isValid: validateIncentive(currentInfo),
        };
        setIncentiveData(temp);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInfo]);
  
  const handleCancel = () => {
    updateInfos({
      'incentive_text': '',
      'incentive_type': '',
      'incentive_limit': '',
      'incentive_include_net_cost': '',
      'incentive_by_mail': '',
      'incentive_online_incentive': '',
      'incentive_liner_f':''
    });
    closeModal();
  };

  const closeModal = () => {
    setIncentiveData([]);
    setCurrentInfo({});
    setCurrentOfferIndex(0);
    onClose();
  };

  const handleSubmit = () => {
    let groupIncentive = incentiveData.map((incentive) => {
      const incentiveLinerFlag = incentive?.incentive_liner_f ? '(L)' : ''
      const incentiveType = incentive?.incentive_type ? incentive?.incentive_type : ''
      const incentiveText = incentive?.incentive_text ? incentive?.incentive_text : ''
      const incentiveLimit = incentive?.incentive_limit ? incentive?.incentive_limit : ''
      const incentiveNetCost = incentive?.incentive_include_net_cost ? 1 : ''
      const incentiveByMail = incentive?.incentive_by_mail ? 1 : 0
      const incentiveOnlineIncentive = incentive?.incentive_online_incentive ? 1 : 0
      const incentiveAttachTo = incentive?.incentive_attach_to ? incentive?.incentive_attach_to : ''
       return { attach_to: incentiveAttachTo, incentive_text: `${incentiveLinerFlag}(${incentiveType}) ${incentiveText} (${incentiveLimit}) (${incentiveNetCost}) (${incentiveByMail}) (${incentiveOnlineIncentive})`}
    })
    onSubmit(groupIncentive);
    closeModal();
  }

  const handleAddIncentive = () => {
    const newIndex =
      incentiveData.length > 0
        ? incentiveData[incentiveData.length - 1].index + 1
        : 0;
    const newIncentive = {
      ...INCENTIVE_INIT_INFO,
      index: newIndex,
      isValid: false,
    };
    setIncentiveData([...incentiveData, newIncentive]);
    setCurrentOfferIndex(newIndex);
    setFieldNames(INCENTIVE_INFO_FIELDS);
    setCurrentInfo(newIncentive);
  };

  const handleRemoveIncentive = (index) => {
    const idx = incentiveData.findIndex(
      (incentive) => incentive.index === index
      );
    const newIncentivesData = incentiveData
      .filter((item) => item?.index !== index)
      .map((item, index) => ({
        ...item,
        isValid: validateIncentive(item),
        index,
      }));
    // console.log(newIncentivesData)
    setIncentiveData(newIncentivesData);
    if (newIncentivesData.length === 0) {
      setCurrentInfo({});
      setCurrentOfferIndex(0);
    } else {
      const newIdx = newIncentivesData.length > idx && idx > 0 ? idx : idx - 1;
      if (newIdx > 0){
        setCurrentOfferIndex(newIncentivesData[newIdx].index);
        setCurrentInfo(newIncentivesData[newIdx]);
      } else {
        setCurrentInfo({});
        setCurrentOfferIndex(0);
      }
    }
  };

  const handleCloneIncentive = (event, index) => {
    event.stopPropagation();
    if (incentiveData.length > 0) {
      const newIndex = incentiveData[incentiveData.length - 1].index + 1;
      setIncentiveData([
        ...incentiveData,
        { ...currentInfo, index: newIndex, uuid: undefined },
      ]);
      setCurrentOfferIndex(newIndex);
    }
  };

  const handleIncentiveSelect = (index) => {
    setCurrentOfferIndex(index)
    setFieldNames(INCENTIVE_INFO_FIELDS);
    setActiveField(INCENTIVE_INFO_FIELDS[0]);
    setCurrentInfo(incentiveData[index]);
  };


  return (
    <Modal
      open={open}
      onClose={closeModal}
      size="large"
      title={"Add Page Incentive"}
    >
      <div style={{
          height: '500px'
        }}>
        <div className="d-flex justify-content-between">
          GROUP INCENTIVE
          <AddItem onClick={handleAddIncentive}>
            <FaPlus />
          </AddItem>
        </div>
        <ItemsList>
          {incentiveData.map((incentive, index) => (
            <IncentiveItem
              key={`incentive${index}`}
              className="d-flex flex-row"
              active={
                index === currentOfferIndex
              }
              onClick={() => handleIncentiveSelect(index)}
              onRemove={() => handleRemoveIncentive(index)}
              onClone={handleCloneIncentive}
              data={incentive}
              index={index}
              incentiveData={incentiveData}
              isPageIncentive={isPageIncentive}
            />
          ))}
        </ItemsList>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '500px'
        }}>
          <QuickSelection>
            <IncentiveTextBuilder
              type={currentInfo?.incentive_type}
              value={currentInfo?.incentive_text}
              onChange={(e) => {
                updateInfo("incentive_text", e);
              }}
            // className='w-50'
            />
          </QuickSelection>
          <IncentiveInfo pageOnly />
        </div>
        <div className="d-flex mt-4">
          <Button
            size="small"
            buttonTheme="dark"
            width="fit-content"
            className="ml-auto"
            type="button"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          <Button
            size="small"
            buttonTheme="primary"
            width="fit-content"
            className="ml-4"
            type="button"
            disabled={!currentInfo?.incentive_type || !currentInfo?.incentive_text || !currentInfo?.incentive_attach_to}
            onClick={() => handleSubmit()}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal >
  )
}

// const IncentiveText = styled.div`
//   border: 1px solid
//     ${(props) =>
//     props.active
//       ? props.theme.palette.primaryOutline
//       : props.theme.palette.backgrounds};
//   width: 100%;
//   cursor: pointer;
//   font-size: 16px;
//   padding: 12px 8px;
//   div {
//     background-color: ${(props) => props.theme.palette.items};
//     margin: 0 1px;
//     padding: 6px 10px;
//   }
// `;

const ItemsList = styled.div`
  /* width: 100%; */
  overflow: auto;
  max-height: 120px;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const AddItem = styled.div`
  padding: 1px 2.5px 1px 2.5px;
  &:hover {
    background-color: grey;
  }
`