import React from "react";
import { times } from "lodash";
import styled from "styled-components";
import { ScrollView } from "components";
import { TableRow } from "./TableRow";
import UploadImg from "assets/img/cloud-upload.png";

export const TableContent = ({
  isLoading,
  data,
  sortCol,
  sortType,
  onUpload,
}) => {
  return (
    <Container>
      {isLoading ? (
        times(9, String).map((index) => (
          <TableRow key={`${index}key`} isLoading />
        ))
      ) : (
        <>
          {data?.map((item, index) =>
            <div key={index}>
              {console.log(item)}
              <TableRow status="info" data={item} />
            </div>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled(ScrollView)`
  position: relative;
  transition: all 300ms ease;
  max-height: 70vh;
  overflow-y: scroll;
  border: ${(props) =>
    props.isDragAccept || props.isDragActive
      ? `1px dashed ${props.theme.palette.primary}`
      : "1px solid transparent"};
  &::after {
    display: ${(props) =>
    props.isDragAccept || props.isDragActive ? "block" : "none"};
    content: " ";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    max-height: 90%;
    background: #66c0e120 url(${UploadImg}) no-repeat center;
    background-size: 240px 160px;
    opacity: 0.2;
    overflow: auto;
  }
`;
