/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Modal, Button, TextArea, CheckBox } from "components";
import styled from "styled-components";
import { useInput } from "hooks";

export const RejectWarningModal = ({
  open,
  onClose,
  onReject = () => { }
}) => {

  const [rejectionMessage, setRejectionMessage] = useState('');
  const removeChildren = useInput(false);

  const handleCancel = () => {
    onClose();
    removeChildren.setValue(false);
  }

  const handleReject = () => {
    onReject(rejectionMessage, removeChildren.value);
    onClose();
    setRejectionMessage('');
    removeChildren.setValue(false);
  }


  return (
    <Modal open={open} onClose={onClose} size="medium" title={"Rejecting AdBlock"}>
      <MessageStyle>
        <div>
          <div>Are you sure you want to reject this AdBlock?</div>
          <div>This will queue the rejected adblock back into the cropping queue.</div>
        </div>
        <TextArea
          placeholder="Please specify the reason for the rejection of the adblock."
          onChange={e => setRejectionMessage(e.target.value)}
      />
      </MessageStyle>
      <Checkbox
        value={removeChildren.value}
        onChange={(e) => {
          removeChildren.setValue(e);
        }}
      >
        Delete Duplicate Adblocks?
      </Checkbox>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-4"
          type="button"
          onClick={() => handleReject()}
        >
          Reject
        </Button>
      </div>
    </Modal>
  );
};

const MessageStyle = styled.div`
    font-size:14px;
`;

const Checkbox = styled(props => <CheckBox {...props}/>)`
  label{
    padding: 0px 24px;
  }
`
