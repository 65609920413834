import React, { useEffect } from "react";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";

import { Transition } from "react-transition-group";
import ReactModal from "react-modal";

export const Modal = ({
  title,
  open,
  onClose,
  children,
  size = "large",
  isCloseDisabled = false,
}) => {
  const customStyles = (state) => {
    return {
      content: {
        padding: 0,
        borderRadius: 0,
        border: "none",
        height: "fit-content",
        width: "fit-content",
        top: 0,
        left: 0,
        marginLeft: `calc(50% - ${
          size === "large" ? 512 : size === "medium" ? 384 : 240
        }px)`,
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.50)",
        transform:
          state === "entering" || state === "entered"
            ? "translateY(100px)"
            : state === "exiting" || state === "exited"
            ? "translateY(0px)"
            : "translateY(0px)",
        transition: "all 300ms ease-in-out",
        opacity: state === "entering" || state === "entered" ? 1 : 0,
      },
      overlay: {
        background: "#0A0A0Aa0",
        transition: "all 300ms ease-in-out",
        zIndex: 2
      },
    };
  };

  useEffect(() => {
    ReactModal.setAppElement("body");
    return () => {};
  }, []);

  return (
    <Transition in={open} timeout={300}>
      {(state) => (
        <ReactModal
          closeTimeoutMS={300}
          isOpen={open}
          style={customStyles(state)}
          onRequestClose={onClose}
          shouldCloseOnOverlayClick={!isCloseDisabled}
          shouldCloseOnEsc={!isCloseDisabled}
          shouldReturnFocusAfterClose={!isCloseDisabled}
        >
          <Container size={size}>
            <Header>
              <span className="my-auto">{title}</span>
              {!isCloseDisabled && (
                <CloseIcon onClick={onClose}>
                  <AiOutlineClose size={20} />
                </CloseIcon>
              )}
            </Header>
            <Content>{children}</Content>
          </Container>
        </ReactModal>
      )}
    </Transition>
  );
};

const CloseIcon = styled.div`
  color: white;
  margin: auto 0 auto auto;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
  }
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  color: ${(props) => props.theme.palette.white};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: ${(props) => props.theme.font.size.xm};
  width: ${(props) =>
    props.size === "large"
      ? "1024px"
      : (props) => (props.size === "medium" ? "768px" : "480px")};
`;
const Header = styled.div`
  background: ${(props) => props.theme.palette.items};
  display: flex;
  height: 48px;
  padding: 0 24px 0 28px;
`;
const Content = styled.div`
  padding: 32px;
  overflow: auto;
`;
