import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import _, { times } from "lodash";
import { SubTableRow } from "./SubTableRow";
import { BsThreeDots } from "react-icons/bs";
import { useUsersContext } from "contexts";

export const TableRow = ({ isLoading, data }) => {
  const {
    getUsers,
    users = [],
  } = useUsersContext();

  const [showOptions, setShowOptions] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [currentUsers, setCurrentUsers] = useState([]);

  useEffect(() => {
    if (users.length > 0 && isUserLoading) {
      const userData = users.filter(user =>
        user.tier_perms?.find(item =>
          item === data.ext_id
        ));
      setCurrentUsers(userData);
      setIsUserLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  const handleToggleUsers = () => {
    setIsUserLoading(true);
    if (showUsers === true) {
      setIsUserLoading(false);
      setShowUsers(false);
    } else {
      getUsers();
      setShowUsers(true);
    }
  }

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          {_.times(11, String).map(index =>
              <RowItem key={index}>
                <Skeleton height={32} />
              </RowItem>
          )}
          </div>  
      </Container>
    </SkeletonTheme>
  ) : (
    <>
      <Container>
        <div className="inner">
          <RowItem>{data?.ext_id}</RowItem>
          {data?.priorities.map((priority, index) =>
            <RowItem key={index}>{priority.count}</RowItem>
          )}
          <RowItem
            onMouseEnter={() => setShowOptions(true)}
            onMouseLeave={() => setShowOptions(false)}
          >
            <IconButton className="my-auto">
              <BsThreeDots color="white" />
            </IconButton>
            {showOptions && (
              <ToggleText>
                <TextLink onClick={handleToggleUsers}>
                  Toggle Users
                </TextLink>
              </ToggleText>

            )}
          </RowItem>
        </div>
      </Container>
      {showUsers ? (
        <UsersList>
          {isUserLoading ? (
            times(3, String).map(index =>
              <SubTableRow
                key={`${index}key`}
                isLoading={isUserLoading} />
            )
          ) : currentUsers && currentUsers.length > 0 ? (
              currentUsers.map((item, index) =>
                <SubTableRow
                  key={`${index}key`}
                  data={item}
                />
              )
          ) : (
            <div className="pt-4 mt-2 mb-2 mx-auto text-center color-white">
              No Users for Tier
            </div>
          )}
        </UsersList>
      ) : null}
    </>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.items};
  display: block;
  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  border-left: 12px solid
  ${(props) =>
    props.status === "complete"
      ? props.theme.palette.success
      : props.status === "warning"
        ? props.theme.palette.warning
        : props.status === "inprocess"
          ? props.theme.palette.primaryOutline
          : props.status === "error"
            ? props.theme.palette.error
            : props.status === "none"
              ? props.theme.palette.white
              : props.theme.palette.secondary};
  margin: 3px 6px 3px 12px;
  border-radius: 2px;
  .inner {
    display: flex;
    width: 100%;
    padding: 0 5px 0 36px;
    height: 88px;
    &:hover {
      background: ${(props) =>
    props.isLoading
      ? props.theme.palette.items
      : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      flex: 3;
    }
    & > div:nth-child(2) {
      flex: 1;
    }
    & > div:nth-child(3) {
      flex: 1;
    }
    & > div:nth-child(4) {
      flex: 1;
    }
    & > div:nth-child(5) {
      flex: 1;
    }
    & > div:nth-child(6) {
      flex: 1;
    }
    & > div:nth-child(7) {
      flex: 1;
    }
    & > div:nth-child(8) {
      flex: 1;
    }
    & > div:nth-child(9) {
      flex: 1;
    }
    & > div:nth-child(10) {
      flex: 1;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 16px auto 0;
  position:relative;
  span {
    color:${(props) => props.theme.palette.error};
  }
  .loading {
    color:${(props) => props.theme.palette.white};
  }
`;

const UsersList = styled.div`
  padding: 5px 0px 5px 10px;
`;

const IconButton = styled.span`
  cursor: pointer;
  margin: 0 4px;
  position:absolute;
  right:0;
  top:-14px;
  &:hover {
    svg {
      transition: all 0.3s ease;
      fill: ${(props) => props.theme.palette.primary} !important;
    }
  }
`;

const ToggleText = styled.div`
  position: absolute;
  width: auto;
  white-space: nowrap;
  text-align: right;
  right:5px;
  color: white;
  font-size: 14px;
  background-color: ${(props) => props.theme.palette.items};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  .ocr-heading {
    color: gray;
  }
  z-index: 0;
  user-select: text;
  z-index:200;
`;

const TextLink = styled.div`
  cursor:pointer;
  margin:5px 0;
  padding:10px;
  &:hover {
    background-color: ${(props) => props.theme.palette.itemsHover};
  }
`;
