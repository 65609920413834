import React from "react";
import styled from "styled-components";
import { FaPlus } from "react-icons/fa";
import {
  CheckBox
} from "components";

export const ProductSelector = ({
  title,
  children,
  className,
  max,
  value,
  onAdd = () => {},
  isAddable = false,
  onChange = () => {} 
}) => {

  const onInputChange = (value) => {
   onChange(value);
  };

  return (
    <Container className={className}>
      <Header>
        <div>{title}</div>
        { max > 1 ? (
          <Split>
            <CheckBox 
            value={value}
            onChange={onInputChange}
            >Split</CheckBox>
          </Split>
        ): null }
        {isAddable && (
          <div className="d-flex ml-auto">
            <Icon onClick={onAdd}>
              <FaPlus />
            </Icon>
          </div>
        )}
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

const Container = styled.div`
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  color: white;
`;

const Header = styled.div`
  display: flex;
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: calc(100% - 12px);
`;
const Icon = styled.div`
  margin: auto 4px;
  transition: all 300ms ease;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  &:hover {
    opacity: 0.7;
  }
`;

const Split = styled.div`
  border-left:1px solid ${(props) => props.theme.palette.secondary};
  display:flex;
  padding:0 10px 0px 10px;
  margin:0 15px;
  div {
    padding:0 5px;
  }

  label {
    padding-left: 24px;
    margin: 4px 0px 2px 0;
  }
`;
