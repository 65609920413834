/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorageState } from "rooks";
import JobReducer from "./CircularReducer";
import {
  SET_LOADING,
  INIT_CIRCULARS,
  GET_CIRCULARS,
  GET_CIRCULARS_BY_ID,
  SET_ERROR,
  SET_REFETCHING,
  GET_MORE_CIRCULARS,
  SET_CIRCULARS_SEARCH,
  SET_CIRCULARS_FILTER,
} from "../types";
import { getCircularsApi, getCircularByExtID } from "api";
import { format } from "date-fns";
const CircularContext = createContext();
export const useCircularContext = () => useContext(CircularContext);
const STORAGE_KEY = "addison-circular-state";

export const CircularProvider = (props) => {

  const findFirstLastDayOfWeek = (dateRequested) => {

    const currentDay = new Date(); // get current date
    let day = currentDay.getDate() - currentDay.getDay(); // First day is the day of the month - the day of the week;

    if(dateRequested === "last") {
      day = day + 6; // last day is the first day + 6
    }
   
    const returnDay =  format( new Date(currentDay.setDate(day)), "yyyy-MM-dd");
    return returnDay;
    
  }

  const initialState = {
    query: "",
    status: [],
    circulars: [],
    totalCount: 0,
    resultCount: 0,
    totalPages: 0,
    currentPage: 1,
    isLoading: false,
    isRefetching: false,
    creationDateFrom: findFirstLastDayOfWeek(),
    creationDateTo: findFirstLastDayOfWeek("last"),
    circDateFrom: undefined,
    circDateTo: undefined,
    completeDateFrom: "",
    completeDateTo: "",
    retailerId: undefined,
    sortBy: "created_at",
    sortDir: "ASC",
    adBlocksCount : 0,
    stage: [],
    activeRetailers: true,
    tier: []
  };

  const [value, set] = useLocalstorageState(STORAGE_KEY, initialState);

  const [state, dispatch] = useReducer(JobReducer, value);

  useEffect(() => {
    set(state);
    return () => {};
  }, [state]);

  const getCirculars = async () => {
    setCircularsLoading();
    const res = await getCircularsApi({
      page: 0,
      retailer_id: state.retailerId || undefined,
      created_time_from: state.creationDateFrom || undefined,
      created_time_to: state.creationDateTo || undefined,
      circ_time_from: state.circDateFrom || undefined,
      circ_time_to: state.circDateTo || undefined,
      sort_by: state.sortBy || undefined,
      sort_dir: state.sortDir || undefined,
      stage: state.stage,
      active_retailers: state.activeRetailers,
      tier: state.tier,
      ad_blocks_count: state.adBlocksCount?state.adBlocksCount:0
    });

    let type = GET_CIRCULARS;
    let payload = res.payload;

    if(!res.success) {
      type = SET_ERROR;
      payload = res.error;
    }

    dispatch({
      type: type,
      payload: payload
    });
  };

  const getCircularByID = async (id) => {
    setCircularsLoading();
    const res = await getCircularByExtID(id);

    let type = GET_CIRCULARS_BY_ID;
    let payload = res.payload;

    if(!res.success) {
      type = SET_ERROR;
      payload = res.error;
    }

    dispatch({
      type: type,
      payload: payload
    });
  }

  const loadMore = async ({ retailer_id = undefined }) => {
    setCircularsRefetching();
    const res = await getCircularsApi({
      page: state.currentPage + 50,
      retailer_id: retailer_id || undefined,
      created_time_from: state.creationDateFrom || undefined,
      created_time_to: state.creationDateTo || undefined,
      circ_time_from: state.circDateFrom || undefined,
      circ_time_to: state.circDateTo || undefined,
      sort_by: state.sortBy || undefined,
      sort_dir: state.sortDir || undefined,
      stage: state.stage,
      active_retailers: state.activeRetailers,
      tier: state.tier,
      ad_blocks_count: state.adBlocksCount?state.adBlocksCount:0
    });
    dispatch({
      type: res.success ? GET_MORE_CIRCULARS : SET_ERROR,
      payload: res.payload,
    });
  };

  const initCirculars = async () => {
    dispatch({
      type: INIT_CIRCULARS,
    });
  };

  // Set Loading
  const setCircularsLoading = (params = true) =>
    dispatch({ type: SET_LOADING, payload: params });

  // Set Refetching
  const setCircularsRefetching = (params = true) =>
    dispatch({ type: SET_REFETCHING, payload: params });

  const setQuery = (query) => {
    dispatch({ type: SET_CIRCULARS_SEARCH, payload: query });
  };

  const setFilter = (params) => {
    dispatch({ type: SET_CIRCULARS_FILTER, payload: params });
  };

  const { children } = props;

  return (
    <CircularContext.Provider
      value={{
        ...state,
        initCirculars,
        setCircularsLoading,
        setCircularsRefetching,
        getCirculars,
        loadMore,
        setQuery,
        setFilter,
        getCircularByID
      }}
    >
      {children}
    </CircularContext.Provider>
  );
};
