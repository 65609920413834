import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { SideTop, ScrollView } from "components";
import { times } from "lodash";

import {
  TableHeader,
  TableRow
} from "./components";
import { getQueue, getTiers } from "api";
import { navigate } from "@reach/router";
import { useAuthContext } from "contexts";


export const TiersPage = () => {
  const { userInfo } = useAuthContext();
  const [sortCol, setSortCol] = useState("");
  const [sortType, setSortType] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [tiersData, setTiersData] = useState([]);

  if (userInfo && !userInfo.user.is_superuser) {
    navigate("/home");
  }

  const init = async () => {
    setIsLoading(true);
    let tiers = [];
    let response = await getTiers();
    if (response.success) {
      tiers = response.results.tiers;
    } else {
      // Error
    }
    response = await getQueue();
    let queues = [];
    if (response.success) {
      queues = response.payload;
    } else {
      // Error
    }
    let tierData = tiers.map(tier => {
      let priorities = queues.find(queue =>
        queue.name === tier.ext_id
      ).priorities;
      return { ...tier, priorities };
    })
    setTiersData(tierData);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (sortCol, sortType) => {
    setSortCol(sortCol);
    setSortType(sortType);
  };


  return (
    <MainLayout>
      <MainContent>
        <SideSection>
          <SideTop>
          </SideTop>
        </SideSection>
        <TableSection>
          <TableHeader
            sortCol={sortCol}
            sortType={sortType}
            onSort={handleSort}
          />
          <TableContent>
            {isLoading ?
              times(10, String).map((index) => (
                <TableRow key={`${index}key`} isLoading />
              ))
              : tiersData && tiersData.length > 0 ?
                tiersData.map((item, index) => (
                  <TableRow
                    key={`${index}key`}
                    data={item}
                  />)) : (
                  <div className="pt-4 mt-4 mx-auto text-center color-white">
                    No Tier active.
                  </div>
                )
            }
          </TableContent>
        </TableSection>
      </MainContent>
    </MainLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.backgrounds};
`;

const SideSection = styled.div`
  width: 300px;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
`;
const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TableContent = styled(ScrollView)`
  position: relative;
  transition: all 300ms ease;
  border: ${(props) =>
    props.isDragAccept || props.isDragActive
      ? `1px dashed ${props.theme.palette.primary}`
      : "1px solid transparent"};
`;