import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaPlus, FaMinus } from "react-icons/fa";


export const NumberInput = ({ 
  className, 
  label, 
  value, 
  max,
  onChange = () => {} 
}) => {

  const [inputValue, setInputValue] = useState(0);

  useEffect(() => {

    if(value || value !== null) {
      setInputValue(value);
    }else{
      setInputValue(0);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);


  const onMinus = () => {

    if(inputValue > 0) {
      setInputValue(inputValue - 1);
      onChange(inputValue - 1);
    }

  }

  const onAdd = () => {
    if(inputValue < max - 1) {
      setInputValue(inputValue + 1);
      onChange(inputValue + 1);
    }
   
  }

  return (
    <Container className={className}>
      {label && <Label htmlFor={label}>{label}</Label>}

      <StyledInput
        id={label}
        value={inputValue}
      />
      <div className="d-flex ml-auto">
        <Icon onClick={onMinus}>
          <FaMinus />
        </Icon>
        <Icon onClick={onAdd}>
          <FaPlus />
        </Icon>
      </div>

    </Container>
  );
};
const Container = styled.div`
  position: relative;
  display:flex;
`;

const Label = styled.label`
  font-size: 15px;
  color: ${(props) => props.theme.palette.white};
  display: flex;
`;

const StyledInput = styled.input`
  border: none; 
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  background: transparent;
  color: ${(props) => props.theme.palette.white};
  height: 20px;
  width: 30px;
  font-size: ${(props) => props.theme.font.size.m};
  outline: none;
  padding: 3px 0px 0 8px;
  transition: all 300ms ease;
  &:focus,
  outline:none;
  &:active {
    border-bottom: 1px solid ${(props) => props.theme.palette.primaryHover};
  }
`;

const Icon = styled.div`
  margin: auto 4px;
  transition: all 300ms ease;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  &:hover {
    opacity: 0.7;
  }
`;