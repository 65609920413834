/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useLocation } from "@reach/router";
import { useAuthContext } from "contexts"
import { postCreateJiraIssue } from "api";
import { Button, Input, Form, Select, Flex, Upload, Modal } from 'antd';


export const ReportJIRAIssue = ({ open, onClose }) => {
  const location = useLocation();
  const { userInfo } = useAuthContext();
  const createMutation = useMutation((data) => postCreateJiraIssue(data));

  const issueTypes = [
    { ext_id: '1', label: 'Bug', value: 'Bug' },
    { ext_id: '3', label: 'Task', value: 'Task' },
    { ext_id: '6', label: 'Epic', value: 'Epic' },
    { ext_id: '7', label: 'Story', value: 'Story' }
  ];
  const priorities = [
    { ext_id: '2', label: 'Critical', value: 'Critical' },
    { ext_id: '1', label: 'Blocker', value: 'Blocker' },
    { ext_id: '3', label: 'Major', value: 'Major' },
    { ext_id: '4', label: 'Minor', value: 'Minor' },
    { ext_id: '5', label: 'Trivial', value: 'Trivial' },
    { ext_id: '11020', label: 'High', value: 'High' }
  ];

  const initFormObj = {
    summary: '',
    description: '',
    issue_type: 'Bug',
    priority: 'Major',
    labels: 'addison-support',
    attachments: null,
    url: location.href,
    created_by: userInfo?.user?.full_name,
  }
  const [formData, setFormData] = useState(initFormObj)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = ({ fileList: newFileList }) => {
    setFormData(({
      ...formData,
      attachments: newFileList && newFileList.map(file => file.originFileObj)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const submissionData = new FormData();
    for (let key in formData) {
      if (key === 'attachments') {
        if (formData[key] && formData[key].length > 0) {
          for (let file of formData[key]) {
            submissionData.append('attachments', file);
          }
        }
      } else {
        if (key === 'created_by')
          submissionData.append(key, userInfo.user.uuid);
        else
          submissionData.append(key, formData[key]);
      }
    }
    const response = await createMutation.mutateAsync(submissionData);
    if (!response.success) {
      alert('Something went wrong!')
    } else {
      onClose();
    }
    setFormData(initFormObj);
  };

  return (
    <Modal open={open} onCancel={() => { setFormData(initFormObj); onClose(); }} onClose={() => { setFormData(initFormObj); onClose(); }} size="medium" title={"Create a Ticket"} width={600} centered
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <Button block type="primary"
            loading={createMutation.isLoading}
            disabled={!(formData.summary && formData.description)}
            htmlType="submit"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </>
      )}
    >
      <Form layout="vertical">
        <Form.Item label="Summary*" rules={[{ required: true }]}>
          <Input placeholder="Title of issue" name="summary" onChange={handleChange}></Input>
        </Form.Item>

        <Flex justify="space-between" align="center">
          <Form.Item label="Issue Type*">
            <Select
              defaultValue={formData.issue_type}
              onChange={(e) => setFormData({ ...formData, issue_type: e })}
              style={{ width: 280 }}
              options={issueTypes}
            />
          </Form.Item>
          <Form.Item label="Priority*">
            <Select
              defaultValue={formData.priority}
              onChange={(e) => setFormData({ ...formData, priority: e })}
              style={{ width: 280 }}
              options={priorities}
            />
          </Form.Item>
        </Flex>

        <Form.Item label="Description*" rules={[{ required: true }]}>
          <Input.TextArea placeholder="Detailed description of problem" name="description"
            onChange={handleChange}></Input.TextArea>
        </Form.Item>

        <Form.Item label="Labels(optional)">
          <Input placeholder="Labels" name="labels"
            value={formData.labels}
            onChange={handleChange}></Input>
        </Form.Item>

        <Form.Item label="Attachments (optional)">
          <Upload
            beforeUpload={() => { return false; }}
            onChange={handleFileChange}
            multiple
            fileList={formData.attachments}
            name="attachments"
            accept="image/*"
          >
            <Button>Click to Upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item label="URL*">
          <Input name="url" value={formData.url} disabled></Input>
        </Form.Item>

        <Form.Item label="Created By*">
          <Input name="created_by" value={formData.created_by} disabled></Input>
        </Form.Item>


      </Form>
    </Modal>
  );
};