export * from "./API";
export * from "./string";
export * from "./parse";
export * from "./cookie";
export * from "./filter";
export * from "./json";
export * from "./url";
export * from "./data";
export * from "./products";
export * from "./csv";
export * from "./dataUtils";
export * from "./datetime"
export * from "./indexedDB";
export * from "./schemas"

export const validateIncentive = (incentive) => {
  return Boolean(incentive?.incentive_type && incentive?.incentive_text);
};

export const validateOffer = (offer) => offer?.offer_type && offer?.offer_text;
