import React from "react";
import styled from "styled-components";
import { FaGift, FaCropAlt, FaRegObjectGroup } from "react-icons/fa";
import { GiResize } from "react-icons/gi";

export const AdblockToolButton = ({ type, isActive, onClick = () => {} }) => {
  return (
    <ToolButton className="mt-2" onClick={onClick}>
      <Icon isActive={isActive}>
        {type === "Adblock" && <FaCropAlt color="white" size={20} />}
        {type === "Page" && <FaGift color="white" size={20} />}
        {type === "Circular" && <FaGift color="white" size={20} />}
        {type === "Section" && <GiResize color="white" size={20} />}
        {type === "Group" && <FaRegObjectGroup color="white" size={20} />}
      </Icon>
      <span className="tool-text">{type}</span>
    </ToolButton>
  );
};

const ToolButton = styled.div`
  height: 48px;
  cursor: pointer;
  transition: all 300ms ease;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${(props) =>
    props.active ? props.theme.palette.gray : props.theme.palette.backgrounds};
  svg {
    margin: auto;
  }
  .tool-text {
    font-size: 12px;
    margin: 0 auto;
  }
  &:hover {
    opacity: 0.7;
  }
`;
const Icon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${(props) => (props.isActive ? "white" : "transparent")};
  display: flex;
  margin: auto;
  svg {
    fill: ${(props) => (props.isActive ? "#131313" : "#fff")};
    margin: auto;
  }
`;
