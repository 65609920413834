export const incentiveTypeData = [
  {
    id: "Additional Coupon",
    title: "Additional Coupon",
  },
  {
    id: "Air Miles",
    title: "Air Miles",
  },
  {
    id: "Clearance",
    title: "Clearance",
  },
  {
    id: "Coupons",
    title: "Coupons",
  },
  {
    id: "Credit Card 1",
    title: "Credit Card 1",
  },
  {
    id: "Credit Card 2",
    title: "Credit Card 2",
  },
  {
    id: "Download",
    title: "Download",
  },
  {
    id: "Earn Free Item",
    title: "Earn Free Item",
  },
  {
    id: "Enter Purchase Savings",
    title: "Enter Purchase Savings",
  },
  {
    id: "Enter Purchase",
    title: "Enter Purchase",
  },
  {
    id: "Any One Item",
    title: "Any One Item",
  },
  {
    id: "Extra Coupons",
    title: "Extra Coupons",
  },
  {
    id: "Extra Savings",
    title: "Extra Savings",
  },
  {
    id: "Free Item",
    title: "Free Item",
  },
  {
    id: "Free Shipping",
    title: "Free Shipping",
  },
  {
    id: "Gas",
    title: "Gas",
  },
  {
    id: "Next Shopping Trip",
    title: "Next Shopping Trip",
  },
  {
    id: "Pharmacy Services",
    title: "Pharmacy Services",
  },
  {
    id: "General Pharmacy Services",
    title: "General Pharmacy Services",
  },
  {
    id: "Pharmacy Health Clinic Services",
    title: "Pharmacy Health Clinic Services",
  },
  {
    id: "RewardsI",
    title: "RewardsI",
  },
  {
    id: "Rollback",
    title: "Rollback",
  },
  {
    id: "Saturdate",
    title: "Saturdate",
  },
  {
    id: "Sweepstakes",
    title: "Sweepstakes",
  },
  {
    id: "Trade-in savings",
    title: "Trade-in savings",
  },
];

export const SizeAndQtySizeData = [
  {
    "id": 2,
    "title": "ct./pk."
  },
  {
    "id": 121,
    "title": "ct./pk. cans"
  },
  {
    "id": 122,
    "title": "ct./pk. bottles"
  },
  {
    "id": 123,
    "title": "ct./pk. cans/bottles"
  },
  {
    "id": 124,
    "title": "ct./pk. aluminum bottles"
  },
  {
    "id": 130,
    "title": "ct./pk. pods"
  },
  {
    "id": 164,
    "title": "ct./pk. cans/aluminum bottles"
  }
]

export const SizeAndQtyQuantityData = [
  {
    "id": 3,
    "title": "oz."
  },
  {
    "id": 8,
    "title": "lb."
  },
  {
    "id": 31,
    "title": "ltr."
  },
  {
    "id": 32,
    "title": "ml."
  }
]

export const DimensionData =
  [
    {
      id: 1,
      title: "Single",
      subSets: ["ext_size_unit_i", "size_1"],
    },
    {
      id: 2,
      title: "Range",
      subSets: ["ext_size_unit_i", "size_1", "size_2"],
    },
    {
      id: 3,
      title: "Plus",
      subSets: [
        "ext_size_unit_i",
        "size_1",
      ],
    },
    {
      id: 4,
      title: "Area", // Multi
      subSets: ["ext_size_unit_i", "size_1", "size_2"],
    },
    {
      id: 5,
      title: "SizeAndQty",
      subSets: [
        "ext_size_unit_i",
        "size_1",
        "size_2",
        "ext_size_unit_2_i",
        "size_3",
        "size_4",
      ],
    },
    {
      id: 6,
      title: "RangeDoubleSize",
      subSets: [
        "ext_size_unit_i",
        "size_1",
        "size_2",
        "ext_size_unit_2_i",
      ],
    },
    {
      id: 7,
      title: "Double",
      subSets: ["ext_size_unit_i", "size_1", "size_2", "ext_size_unit_2_i"],
    },
    {
      id: 9,
      title: "AreaRange",
      subSets: ["ext_size_unit_i", "size_1", "size_2", "ext_size_unit_2_i", "size_3", "size_4"],
    },
    {
      id: 26,
      title: "TotalSize",
      subSets: [
        "ext_size_unit_i",
        "size_1",
        "size_2",
        "ext_size_unit_2_i",
        "size_3",
        "size_4",
      ],
    },
  ]

export const sizeDisablingUnits = [13, 14, 15, 16, 41, 42, 43,
  49, 50, 51, 52, 53, 54, 63, 64, 67, 70, 71, 72, 73,
  82, 83, 84, 87, 99, 100, 101, 105, 106, 107, 108, 109,
  110, 113, 116, 120, 131, 143, 144, 145, 147, 148, 151, 153, 154]

export const circular_types = [
    {id: 1, title: 'Electronic Circular'},
    {id: 2, title: 'Secondary Circular'},
    {id: 3, title: 'Third Circular'},
    {id: 4, title: 'Fourth Circular'},
    {id: 5, title: 'Fifth Circular'},
    {id: 6, title: 'Bonus Pages'},
    {id: 7, title: 'Bonus Pages (2)'},
    {id: 8, title: 'Bonus Pages (3)'},
    {id: 9, title: 'Bonus Pages (4)'},
    {id: 10, title: 'Coupon Book'},
    {id: 11, title: 'Coupon Book (2)'},
    {id: 12, title: 'Coupon Book (3)'},
    {id: 13, title: 'Coupon Book (4)'},
    {id: 14, title: 'Online'},
    {id: 15, title: 'Paper Circular'},
    {id: 16, title: 'Thanksgiving'},
    {id: 17, title: 'Black Friday'},
    {id: 18, title: 'Email Promotion'},
    {id: 19, title: 'Newspaper'},
    {id: 20, title: 'Web Promotions'},
    {id: 21, title: 'Mailer'},
    {id: 22, title: 'Mailer (2)'},
    {id: 23, title: 'Mailer (3)'}
]
