/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { PAGE_TYPES } from "config";
import {
  Button,
  DropDown2,
  MetaCheckBox,
  Input2,
  CheckBox,
  ScrollView,
  ProductSelector,
  AdblockLocation,
  AdblockImage,
  SearchableUnit,
  AdblockApproved,
  ManualEntry,
  EventsDropDown,
  CreateBrand,
  HistoryDropdown,
  ProductInfoContainer,
  AutoComplete,
  Link
} from "components";
import {
  getNextProduct,
  getImage,
  approveProduct,
  getProductBrandAutocompleteApi,
  skipTopDownAdblock,
  doNotEnterTopDownAdblock,
  updateAdblockSuggestions,
  getBrandByIdApi,
  rejectAdblock,
  getSimilarAdBlockProducts,
  getCurrentUserAPI,
  getAdBlockById,
  fetchImageMatch,
  completeActivityAPI,
  startAcitivityAPI,
} from "api";
import { useMainContext, useAdblockHistoryContext } from "contexts";
import { MdFindInPage, MdRefresh } from "react-icons/md";
import { EndQueueModal, RejectWarningModal, ErrorModal, UnitRestrictionWarning, ImageMatchingModal, MethodologyReminder } from "modals";
import { indexOf, isEmpty, last, omit } from "lodash";
import { format } from "date-fns";
import {
  Card,
  Choice,
  NoSuggestions,
} from "./components";
import { useInput, useModal } from "hooks";
import { useParams, useLocation } from "@reach/router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import useKeypress from "react-use-keypress";
import { FaClone, FaTrash, FaCheckCircle, FaMinusCircle, FaInfoCircle } from "react-icons/fa";
import { DimensionData, SizeAndQtyQuantityData, SizeAndQtySizeData, findDuplicates, sizeValueBuilder, unitSizeRestrictionMessage, validateProduct, setCachedImage, getCachedImage, productStringEval, getUniqueProducts, checkDupeEntry, getSizeType } from "utils";
import { BsImageAlt } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { getMethodologyReminder } from "api/ext";
import { CreateMOV } from "components/common/ProductCommon/CreateMOV";
import { SelectMOV } from "components/common/ProductCommon/SelectMOV";
import { PRODUCT_INFO_FIELDS } from 'config'
import { useProductSizes } from "queries";

export const ProductsPage = () => {
  const params = useParams();
  const blockRef = useRef();
  const location = useLocation();

  const {
    currentCircularId,
    currentPageId,
    currentAdBlockId,
    setCurrentCircularId,
    setCurrentPageId,
    setCurrentAdBlockId,
    setCurrentPage,
    setPreviousTime,
    postPageEvents,
    setSessionId,
    setLiveCounterData,
    activeField,
    setActiveField,
    setFieldNames,
    fieldNames
  } = useMainContext();

  const [copiedText, setCopiedText] = useState(false);
  const { initHistory, pushHistory } = useAdblockHistoryContext();

  //Loading States
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  

  //For the skeleton partial
  const [imageHeight, setImageHeight] = useState(false);
  const [imageWidth, setImageWidth] = useState(false);

  // Page Data
  const [pageWidth, setPageWidth] = useState(1);
  const [pageHeight, setPageHeight] = useState(1);

  //Currently Viewed Information
  const [circularName, setCircularName] = useState("");
  const [circularType, setCircularType] = useState("");
  const [pageType, setpageType] = useState("");
  const [currentAdBlockData, setCurrentAdBlockData] = useState({});
  const [pageImg, setPageImg] = useState("");
  const [adblockFilename, setAdblockFilename] = useState("");
  const [adBlockActivityID, setAdBlockActivityID] = useState("");

  //Adblock Data
  const events = useInput([]);
  const newItem = useInput(false);
  const selectedVarieties = useInput(false);
  const blkqty = useInput(false);
  const anomaly = useInput(false);
  const [adblockCoords, setAdblockCoords] = useState({
    coords_x1: 0,
    coords_x2: 0,
    coords_y1: 0,
    coords_y2: 0,
  });
  const [sectionCoords, setSectionCoords] = useState({
    coords_x1: 0,
    coords_x2: 0,
    coords_y1: 0,
    coords_y2: 0,
  });
  const [split, setSplit] = useState(false);
  const [isApproveDisabled, setIsApproveDisabled] = useState(false);
  const [unitRestrictionMessage, setUnitRestrictionMessage] = useState([]);
  const [adultBevApplicable, setAdultBevApplicable] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [isImported, setIsImported] = useState(false);


  //Product list from adblock
  const [productsData, setProductsData] = useState([]);
  const currentProductIndex = useInput(0);
  const [currentProduct, setCurrentProduct] = useState({});
  const [allProductsValid, setAllProductsValid] = useState(false);
  const [queueCount, setQueueCount] = useState("");
  const [messageID, setMessageID] = useState(false);

  // Product Information
  const brand = useInput("");
  const brand_nm = useInput("");
  const dimensions = useInput("");
  const unit = useInput("");
  const unit2 = useInput("");
  const size1 = useInput("");
  const size2 = useInput("");
  const size3 = useInput("");
  const size4 = useInput("");
  const liner = useInput(false);

  // Multiple MOV(Model Numbers, Origin, Variant)
  const variant_ids = useInput([]);
  const variant_nms = useInput([]);
  const origin_ids = useInput([]);
  const origin_nms = useInput([]);
  const model_number_ids = useInput([]);
  const model_number_nms = useInput([]);

  //Optional Product Information
  const [variantsData, setVariantsData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [modelNumberData, setModelNumberData] = useState([]);

  // Form Dropdown sets
  const { data: sizeData } = useProductSizes();
  // const [sizeData, setSizeData] = useState([]);
  const [currentSizeData, setCurrentSizeData] = useState([]);
  // const [unitWarn, setUnitWarn] = useState([]);
  // const [unit2Warn, setUnit2Warn] = useState([]);

  //Form Flow
  const [formWorkflow, setFormWorkflow] = useState({});
  const [activeProductInfo, setActiveProductInfo] = useState("ext_brand_i");
  const [activeProductText, setActiveProductText] = useState("");

  // suggestions
  const [currentSuggestions, setCurrentSuggestions] = useState([]);
  const [isManualEntry, setIsManualEntry] = useState(false);

  // Create Brand
  const [showCreateBrand, setShowCreateBrand] = useState(false);
  const [createBrandName, setCreateBrandName] = useState("");

  // Create MOV(ModelNumbers, Origins, Varieties)
  const [showCreateMOV, setShowCreateMOV] = useState(false);
  const [createMOVName, setCreateMOVName] = useState("");

  // Suggestions general
  const [brands, setBrands] = useState([]);
  // const [defaultSizeData, setDefaultSizeData] = useState([]);
  const [unitDimensions, setUnitDimensions] = useState([
    {
      id: 1,
      title: "Single",
      subSets: ["ext_size_unit_i", "size_1"],
    },
    {
      id: 2,
      title: "Range",
      subSets: ["ext_size_unit_i", "size_1", "size_2"],
    },
    {
      id: 3,
      title: "Plus",
      subSets: [
        "ext_size_unit_i",
        "size_1",
      ],
    },
    {
      id: 5,
      title: "SizeAndQty",
      subSets: [
        "ext_size_unit_i",
        "size_1",
        "size_2",
        "ext_size_unit_2_i",
        "size_3",
        "size_4",
      ],
    },
    {
      id: 6,
      title: "RangeDoubleSize",
      subSets: [
        "ext_size_unit_i",
        "size_1",
        "size_2",
        "ext_size_unit_2_i",
      ],
    },
  ]);
  const [sizes, setSizes] = useState([]);
  const [units, setUnits] = useState([]);
  const [isSizeDisabled, setIsSizeDisabled] = useState(false);
  const [isSize2Disabled, setIsSize2Disabled] = useState(false);
  const [methodologyReminderMessage, setMethodogyReminderMessage] = useState([]);

  //Modals and user infromation
  const endQueueModal = useModal();
  const rejectModal = useModal();
  const duplicatedModal = useModal();
  const unitRestrictionWarningModal = useModal();
  const imageMatchingModal = useModal();
  const methodologyReminderModal = useModal();

  const [isEndQueue, setIsEndQueue] = useState(false);
  
  // Brand Sub Category
  const [brandSubCatI, setBrandSubCatI] = useState();

  const sizeDisablingUnits = [13, 14, 15, 16, 41, 42, 43,
    49, 50, 51, 52, 53, 54, 63, 64, 67, 70, 71, 72, 73,
    82, 83, 84, 87, 99, 100, 101, 105, 106, 107, 108, 109,
    110, 113, 116, 120, 131, 143, 144, 145, 147, 148, 151, 153, 154]
  /*
  ///////////////////////////////////////
  ////// Start View Methods ////////////
  /////////////////////////////////////
  */

  // const updateProductSize = async () => {
  //   const res = await getProductSizesApi();
  //   setSizeData(
  //     res.payload.map((item) => ({ id: item.ext_id, title: item.size_c }))
  //   );
  // };

  const init = async () => {
    setCurrentPage("TD - Products");
    setPreviousTime(new Date());
    setIsLoading(false);

    setFormWorkflow(["ext_brand_i"]);

    if (blockRef.current) {
      setImageHeight(blockRef.current.offsetHeight);
      setImageWidth(blockRef.current.offsetWidth);
    }

    setFieldNames(PRODUCT_INFO_FIELDS);
    setActiveField(PRODUCT_INFO_FIELDS[0]);
  };

  useEffect(() => {
    const initApp = async () => {
      if (sizeData) {
        initHistory("product");
        await init();
        fetchAdBlock();
      }
    };

    initApp();
  }, [params.id, sizeData]);

  useEffect(() => {
    let validCheck = productsData.every(function (e) {
      return e.isValid;
    });

    let noDupeCheck = checkDupeEntry(productsData)

    if (validCheck && noDupeCheck) {
      setAllProductsValid(true);
    } else {
      setAllProductsValid(false);
    }

    let unitDimensionNotSelected = productsData.filter((product) =>  !product.size_dimension_i)
    setIsApproveDisabled(unitDimensionNotSelected.length > 0 ? true: false);
    if (productsData.length){
      const unitResMessage = unitSizeRestrictionMessage(productsData)
      setUnitRestrictionMessage(unitResMessage);
    }
    
    let isAdultBev = productsData.some((product) => product.is_adult_bev || product?.sub_cat_i==null)
    setAdultBevApplicable(isAdultBev)
    if (!isAdultBev) {
      selectedVarieties.setValue(false);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsData]);

  useEffect(() => {
    if (!isEmpty(currentProduct)) {
      brand.setValue(currentProduct.ext_brand_i);
      brand_nm.setValue(currentProduct.brand_nm);
      dimensions.setValue(currentProduct.size_dimension_i);
      size1.setValue(
        currentProduct?.size_1 !== null ? currentProduct?.size_1 : ""
      );
      size2.setValue(
        currentProduct?.size_2 !== null ? currentProduct?.size_2 : ""
      );
      size3.setValue(
        currentProduct?.size_3 !== null ? currentProduct?.size_3 : ""
      );
      size4.setValue(
        currentProduct?.size_4 !== null ? currentProduct?.size_4 : ""
      );
      unit.setValue(currentProduct.ext_size_unit_i);
      unit2.setValue(currentProduct.ext_size_unit_2_i);
      liner.setValue(currentProduct.liner_f);

      if (currentProduct.variants) {
        let ids = [], nms = []
        currentProduct.variants.forEach(vns => {
          ids.push(vns.ext_id)
          nms.push(vns.variety_nm)
        })
        variant_ids.setValue(ids);
        variant_nms.setValue(nms);
      } else {
        variant_ids.setValue(currentProduct.variant_ids || []);
        variant_nms.setValue(currentProduct.variant_nms || []);
      }

      if (currentProduct.origins) {
        let ids = [], nms = []
        currentProduct.origins.forEach(org => {
          ids.push(org.ext_id)
          nms.push(org.origin_nm)
        })
        origin_ids.setValue(ids);
        origin_nms.setValue(nms);
      } else {
        origin_ids.setValue(currentProduct.origin_ids || []);
        origin_nms.setValue(currentProduct.origin_nms || []);
      }

      if (currentProduct.model_numbers) {
        let ids = [], nms = []
        currentProduct.model_numbers.forEach(mns=>{
          ids.push(mns.ext_id)
          nms.push(mns.model_number)
        })
        model_number_ids.setValue(ids);
        model_number_nms.setValue(nms);
      } else {
        model_number_ids.setValue(currentProduct.model_number_ids || []);
        model_number_nms.setValue(currentProduct.model_number_nms || []);
      }

      setActiveProductInfo("ext_brand_i");

      if (currentProduct.variantsData) {
        setVariantsData(currentProduct.variantsData);
      } else {
        //Reset the optional fields
        setVariantsData([]);
      }

      if (currentProduct.originsData) {
        setOriginData(currentProduct.originsData);
      } else {
        setOriginData([]);
      }

      if (currentProduct.modelNumbersData) {
        setModelNumberData(currentProduct.modelNumbersData);
      } else {
        setModelNumberData([]);
      }

      if (currentProduct.sizes) {
        setCurrentSizeData(currentProduct.sizes);
      } else {
        setCurrentSizeData([]);
      }

      if(currentProduct?.brand?.sub_cat_i)
        setBrandSubCatI(currentProduct?.brand?.sub_cat_i)
    }

    return () => {};
  }, [currentProduct]);

  useEffect(() => {
    if (currentProductIndex.value < productsData.length) {
      setCurrentProduct(
        productsData.find(
          (product) => product.index === currentProductIndex.value
        )
      );
    }

    setActiveField(PRODUCT_INFO_FIELDS[0]);
    return () => {};
  }, [currentProductIndex.value]);

  useEffect(() => {
    if (!isEmpty(currentProduct) && activeProductInfo) {
      const polar_question = [
        { title: "No", value: false },
        { title: "Yes", value: true },
      ];
      if (circularType === 'Training Circular'){
        setCurrentSuggestions([]);
      } else {
      switch (activeProductInfo) {
        case "ext_brand_i":
          if (brands)
          if (brands.length > 0) {
            setCurrentSuggestions(
              brands.slice(0, 9).map((item, index) => {
                return {
                  value: item.ext_id,
                  title: item.brand_name,
                  variants: item.variants,
                  origins: item.origins,
                  model_numbers: item.model_numbers,
                  sizes: item.sizes,
                  is_adult_bev: item.is_adult_bev
                };
              })
            );
          } else {
            setCurrentSuggestions([]);
          }
          setActiveProductText("What brand is this product?");
          break;
        case "size_dimension_i":
          if (unitDimensions.length > 0) {
            setCurrentSuggestions(
              unitDimensions.slice(0, 9).map((item, index) => {
                return {
                  value: item.ext_id,
                  title: item.name,
                };
              })
            );
          } else {
            setCurrentSuggestions([]);
          }
          setActiveProductText("What is this products unit dimensions?");
          break;
          case "ext_size_unit_i":
            if (dimensions.value === 5) {
              setCurrentSuggestions(
                SizeAndQtySizeData.slice(0, 9).map((item, index) => {
                  return {
                    value: item.id,
                    title: item.title,
                  };
                })
              );
            } else {
              if (units.length > 0) {
                setCurrentSuggestions(
                  units.slice(0, 9).map((item, index) => {
                    return {
                      value: item.ext_id,
                      title: item.size_c,
                    };
                  })
                );
              } else {
                setCurrentSuggestions([]);
              }
            }
            setActiveProductText(
              "What is the type of unit for " + activeProductInfo + " ?"
            );
            break;
          case "ext_size_unit_2_i":
            if (dimensions.value === 5) {
              setCurrentSuggestions(
                SizeAndQtyQuantityData.slice(0, 9).map((item, index) => {
                  return {
                    value: item.id,
                    title: item.title,
                  };
                })
              );
            } else {
              if (units.length > 0) {
                setCurrentSuggestions(
                  units.slice(0, 9).map((item, index) => {
                    return {
                      value: item.ext_id,
                      title: item.size_c,
                    };
                  })
                );
              } else {
                setCurrentSuggestions([]);
              }
            }
            setActiveProductText(
              "What is the type of unit for " + activeProductInfo + " ?"
            );
            break;
        case "ext_size_unit_3_i":
          if (units.length > 0) {
            setCurrentSuggestions(
              units.slice(0, 9).map((item, index) => {
                return {
                  value: item.ext_id,
                  title: item.size_c,
                };
              })
            );
          } else {
            setCurrentSuggestions([]);
          }
          setActiveProductText(
            "What is the type of unit " + activeProductInfo + " ?"
          );
          break;
        case "size_1":
          if (sizeDisablingUnits.includes(unit.value)) {
            setCurrentSuggestions([])
          } else {
            setCurrentSuggestions(
              sizes.slice(0, 9).map((item, index) => {
                return {
                  value: item.size,
                  title: item.size,
                };
              })
            );
          }
          setActiveProductText("What is the " + activeProductInfo + " amount?");
          break;
        case "size_2":
          if (sizeDisablingUnits.includes(unit.value) || (sizeDisablingUnits.includes(unit2.value) && dimensions.value === 6)) {
            setCurrentSuggestions([])
          } else {
            setCurrentSuggestions(
              sizes.slice(0, 9).map((item, index) => {
                return {
                  value: item.size,
                  title: item.size,
                };
              })
            );
          }
          setActiveProductText("What is the " + activeProductInfo + " amount?");
          break;
        case "size_3":
          if (sizeDisablingUnits.includes(unit2.value)) {
            setCurrentSuggestions([])
          } else {
            setCurrentSuggestions(
              sizes.slice(0, 9).map((item, index) => {
                return {
                  value: item.size,
                  title: item.size,
                };
              })
            );
          }
          setActiveProductText("What is the " + activeProductInfo + " amount?");
          break;
        case "size_4":
          if (sizeDisablingUnits.includes(unit2.value)) {
            setCurrentSuggestions([])
          } else {
            setCurrentSuggestions(
              sizes.slice(0, 9).map((item, index) => {
                return {
                  value: item.size,
                  title: item.size,
                };
              })
            );
          }
          setActiveProductText("What is the " + activeProductInfo + " amount?");
          break;
        case "liner_f":
          setCurrentSuggestions(polar_question);
          setActiveProductText("Is this product a liner?");
          break;
        case "brand_origin":
          let originSuggestion = []
          currentAdBlockData.suggestions?.products?.forEach(product => {
            let matchedBrand = currentAdBlockData.suggestions.brands.find(brand => brand.ext_id === product.ext_brand_i)
            if (matchedBrand?.origins?.length > 0) {
              matchedBrand.origins.forEach(origin => {
                if (origin.ext_id === product.origin_id)
                  originSuggestion.push(origin)
              })
            }
          });
          if (originSuggestion.length) {
            setCurrentSuggestions(
              originSuggestion.slice(0, 9).map((item, index) => {
                return {
                  value: item.ext_id,
                  title: item.origin_nm,
                };
              })
            );
            setActiveProductText("What's the origin of this product?")
          }
          else {
            setCurrentSuggestions([])
          }
          break;
        default:
          setCurrentSuggestions([]);
          break;
      }
    }
    }
    return () => {};
  }, [activeProductInfo, currentProduct]);

  useEffect(() => {
    if (productsData.length > 0) {
      // if (currentProduct.sizes && Array.isArray(currentProduct.sizes)){
      //   if (unit.value){
      //     const unitMinMax = currentProduct.sizes.filter(item => item.id === unit.value)[0]
      //     if (unitMinMax)
      //       setUnitWarn([unitMinMax.max, unitMinMax.min])
      //     else
      //       setUnitWarn([])
      //   }
      //   if (unit2.value){
      //     const unit2MinMax = currentProduct.sizes.filter(item => item.id === unit2.value)[0]
      //     if (unit2MinMax)
      //       setUnit2Warn([unit2MinMax.max, unit2MinMax.min])
      //     else
      //       setUnit2Warn([]);
      //   }
      // }
    if (dimensions.value !== 6){
      if (sizeDisablingUnits.includes(unit.value)){
        handleChangeProductInfo('size_1', '');
        handleChangeProductInfo('size_2', '');
        size1.setValue('');
        size2.setValue('');
        setIsSizeDisabled(true);
      } else {
        setIsSizeDisabled(false);
      }
      if (sizeDisablingUnits.includes(unit2.value)){
        handleChangeProductInfo('size_3', '');
        handleChangeProductInfo('size_4', '');
        size3.setValue('');
        size4.setValue('');
        setIsSize2Disabled(true);
      } else {
        setIsSize2Disabled(false);
      }
    } else {
      if (sizeDisablingUnits.includes(unit.value)) {
        handleChangeProductInfo('size_1', '');
        size1.setValue('');
        setIsSizeDisabled(true);
      } else {
        setIsSizeDisabled(false);
      }
      if (sizeDisablingUnits.includes(unit2.value)){
        handleChangeProductInfo('size_2', '');
        size2.setValue('');
        setIsSize2Disabled(true);
      } else {
        setIsSize2Disabled(false);
      }
    }
  }
  }, [unit.value, unit2.value, dimensions.value])

  useEffect(() => {
    if (currentAdBlockData.similar_adblock_uuid && !currentAdBlockData.has_children && !currentAdBlockData.products_approved && !currentAdBlockData.is_dupe) {
      imageMatchingModal.openModal()
    }
  }, [currentAdBlockData])

  /*
  ///////////////////////////////////////
  ////// Fetch Main Data ////////////////
  /////////////////////////////////////
  */

  const fetchAdBlock = async () => {
    setIsFetching(true);
    setIsManualEntry(false);
    setIsApproveDisabled(true);
    setIsImported(false);
    setProductsData([]);
    const res = await getCurrentUserAPI();
    if (res.success) {
      setLiveCounterData(res.results);
    }
    const result = await getNextProduct();

    if (result.success) {
      setStartTime(new Date().toISOString().replace(',',''));
      //Even if we have success we might not get a payload because end of queue sends a 204 reponse
      if (result.payload !== "") {
        setIsEndQueue(false);
        setCurrentAdBlockData(result.payload.adblock);
        setCurrentAdBlockId(result.payload.adblock.uuid);
        setCurrentPageId(result.payload.page.uuid);
        setCurrentCircularId(result.payload.circular.uuid);
        setSplit(result.payload.adblock.split > 0 ? true : false);

        const page_type = PAGE_TYPES.find((item) => item.page_c === result.payload.page.page_loc);
        setpageType(page_type.page_nm);

        pushHistory({
          circularId: result.payload.circular.uuid,
          pageId: result.payload.page.uuid,
          adblockId: result.payload.adblock.uuid,
        });
        setCircularName(
          result.payload.circular.retailer.name +
            "_" +
            format(new Date(result.payload.circular.circ_week_dt.replace(/-/g, '/').replace(/T.+/, '')), "MM/dd/yyyy")
            + `_${result.payload.circular.ext_id}`
            + `_${result.payload.circular.circ_version ? result.payload.circular.circ_version : 0}`
        );
        setCircularType(result.payload.circular.circ_type);
        if (result.payload.circ_type === 'Training Circular'){
          setCurrentSuggestions([]);
        }

        setAdblockCoords({
          coords_x1: result.payload.adblock.coords_x1,
          coords_x2: result.payload.adblock.coords_x2,
          coords_y1: result.payload.adblock.coords_y1,
          coords_y2: result.payload.adblock.coords_y2,
        });
        if (result.payload.adblock.section) {
          setSectionCoords({
            coords_x1: result.payload.adblock.section.coords_x1,
            coords_x2: result.payload.adblock.section.coords_x2,
            coords_y1: result.payload.adblock.section.coords_y1,
            coords_y2: result.payload.adblock.section.coords_y2,
          });
        } else {
          setSectionCoords({ coords_x1: 0, coords_x2: 0, coords_y1: 0, coords_y2: 0 })
        }
        setPageWidth(result.payload.page.img_width);
        setPageHeight(result.payload.page.img_height);
        setQueueCount(result.payload.queue_count);
        setMessageID(result.payload.message_id);
        setSessionId(result.payload.message_id);
        // events.setValue(
        //   result.payload.adblock?.event_nm
        //     ? result.payload.adblock?.event_nm.split(",")
        //     : []
        // );

        let eventsList = [];
        if (result.payload.adblock.products_approved) {
          eventsList = result.payload.adblock?.event_nm ? result.payload.adblock?.event_nm.split(",") : [];
        } else {
          if (result.payload.adblock?.group !== null && result.payload.adblock?.group.event_type) {
            eventsList = result.payload.adblock?.group.event_text.split(',');
          }
        }
        events.setValue(eventsList);

        let filename = result.payload.page.filename;
        if(result.payload.page.is_doubletruck) {
          filename = result.payload.page.doubletruck_filename;
        }
        
        let backImg;
        const cachedImage = await getCachedImage(filename);
        if (!cachedImage) {
          backImg = await getImage(filename);
          if (backImg.success)
            await setCachedImage(filename, backImg.payload);
        }
        setPageImg(cachedImage ? cachedImage : backImg.payload);
        let productData = []
        //TODO: If we do not find any suggestions we should put the user directly into manual entry
        if (result.payload.adblock.suggestions?.products) {
          //Set brands
          if (result.payload.adblock.suggestions?.brands) {
            setBrands(result.payload.adblock.suggestions?.brands);
          } else {
            setBrands([]);
          }

          //Set dimensions
          if (result.payload.adblock.suggestions?.dimensions) {
            setUnitDimensions(result.payload.adblock.suggestions?.dimensions);
          } else {
            setUnitDimensions([]);
          }

          //Set sizes
          result.payload.adblock.suggestions?.sizes.unshift({size:1});
          setSizes(result.payload.adblock.suggestions?.sizes);

          //Set units
          if (result.payload.adblock.suggestions?.units) {
            setUnits(result.payload.adblock.suggestions?.units);
          } else {
            setUnits([]);
          }

          let _brands = null;
          if (result.payload.adblock.suggestions?.brands && result.payload.adblock.suggestions?.brands.length > 0) {
            _brands = result.payload.adblock.suggestions?.brands;
          }
          //We need to set the index so we can find out what product is selected later
          const productsTemp = result.payload.adblock.suggestions.products.map(
            (product, index) => ({
              ...product,
              size_1: sizeDisablingUnits.includes(product.ext_size_unit_i) ? '' : product.size_1,
              size_2: sizeDisablingUnits.includes(product.ext_size_unit_i) ? '' :  product.size_dimension_i > 1 ? product.size_2 : null,
              size_3: sizeDisablingUnits.includes(product.ext_size_unit_2_i) ? '' : product.size_dimension_i > 2 ? product.size_3 : null,
              size_4: sizeDisablingUnits.includes(product.ext_size_unit_2_i) ? '' : product.size_dimension_i > 2 ? product.size_4 : null,
              brand_nm: product.brand_nm || product.brand?.brand_name,
              sub_cat_i : _brands ? _brands.find(b=>b?.ext_id==product?.ext_brand_i) : product.brand?.sub_cat_i,
              isValid: validateProduct(product, sizeData),
              index,
              variant_ids:                
                product.ext_brand_i !== null
                ? result.payload.suggestions?.brands.find(
                    (brand) => brand.ext_id === product.ext_brand_i
                  ).origins.length > 0
                  ? result.payload.suggestions?.brands
                      .find((brand) => brand.ext_id === product.ext_brand_i)
                      .origins.find((origin) => origin.id === product.variant_id)?
                        product.variant_id
                      : []
                  : []
              : [],
              variantsData:
                product.ext_brand_i !== null
                  ? result.payload.adblock.suggestions?.brands.find(
                      (brand) => brand.ext_id === product.ext_brand_i
                    ).variants.length > 0
                    ? result.payload.adblock.suggestions?.brands
                        .find((brand) => brand.ext_id === product.ext_brand_i)
                        .variants.map((item) => ({
                          id: item.ext_id,
                          title: item.variety_nm,
                        }))
                    : []
                  : [],
              origin_ids: 
                product.ext_brand_i !== null
                  ? result.payload.suggestions?.brands.find(
                      (brand) => brand.ext_id === product.ext_brand_i
                    ).origins.length > 0
                    ? result.payload.suggestions?.brands
                        .find((brand) => brand.ext_id === product.ext_brand_i)
                        .origins.find((origin) => origin.id === product.origin_id) ?
                          product.origin_id
                        : []
                    : []
                : [],
              originsData:
                product.ext_brand_i !== null
                  ? result.payload.adblock.suggestions?.brands.find(
                      (brand) => brand.ext_id === product.ext_brand_i
                    ).origins.length > 0
                    ? result.payload.adblock.suggestions?.brands
                        .find((brand) => brand.ext_id === product.ext_brand_i)
                        .origins.map((item) => ({
                          id: item.ext_id,
                          title: item.origin_nm,
                        }))
                    : []
                  : [],
              model_number_ids: 
                  product.ext_brand_i !== null
                    ? result.payload.suggestions?.brands.find(
                        (brand) => brand.ext_id === product.ext_brand_i
                      ).model_numbers?.length > 0
                      ? result.payload.suggestions?.brands
                          .find((brand) => brand.ext_id === product.ext_brand_i)
                          .model_numbers.find((model_number) => model_number.id === product.model_number_id) ?
                            product.model_number_id
                          :[]
                      : []
                  : [],
              modelNumbersData:
                  product.ext_brand_i !== null
                    ? result.payload.adblock.suggestions?.brands.find(
                        (brand) => brand.ext_id === product.ext_brand_i
                      ).model_numbers?.length > 0
                      ? result.payload.adblock.suggestions?.brands
                          .find((brand) => brand.ext_id === product.ext_brand_i)
                          .model_numbers.map((item) => ({
                            id: item.ext_id,
                            title: item.model_number,
                          }))
                      : []
                    : [],        
              sizes: product.ext_brand_i !== null
                ? result.payload.suggestions?.brands.find(
                  (brand) => brand.ext_id === product.ext_brand_i
                ).sizes?.length > 0
                  ? result.payload.suggestions?.brands
                    .find((brand) => brand.ext_id === product.ext_brand_i)
                    .sizes?.map((item) => ({
                      id: item.ext_id,
                      title: item.size_c,
                      max: item.max,
                      min: item.min
                    }))
                  : []
                : [],
                is_adult_bev: product.is_adult_bev
            })
          );
          //remove dup product suggestions AD-1509
          const _productsTemp = getUniqueProducts(productsTemp)
          productData.push(..._productsTemp);
          // setProductsData(productsTemp);
          if (productsTemp.length > 0) {
            currentProductIndex.setValue(0);
            setCurrentProduct(productsTemp[0]);
          }
        } else {
          setProductsData([]);
        }

        const getExistingSuggestions = await getSimilarAdBlockProducts(result.payload.adblock.uuid);
        if (getExistingSuggestions.success) {
          const existinSuggestions = getExistingSuggestions.payload
          if (existinSuggestions && existinSuggestions?.products) {
            if (existinSuggestions?.products.length > 0) {
              //Set brands
              if (existinSuggestions?.products) {
                setBrands(existinSuggestions?.products?.brand);
                //remove unbranded product suggestion if there is an existing suggestion
                productData = productData.filter(e => !!e.brand_nm);
              } else {
                setBrands([]);
              }

              //Set dimensions
              if (existinSuggestions?.products?.dimensions) {
                setUnitDimensions(existinSuggestions?.products?.dimensions);
              } else {
                setUnitDimensions([]);
              }

              //Set sizes
              if (existinSuggestions?.products?.sizes) {
                existinSuggestions?.products?.sizes.unshift({ size: 1 });
                setSizes(existinSuggestions?.products?.sizes);
              }

              //Set units
              if (existinSuggestions?.products?.units) {
                setUnits(existinSuggestions?.products?.units);
              } else {
                setUnits([]);
              }

              let _brands = null;
              if (existinSuggestions?.products?.brand && existinSuggestions?.products?.brand.length > 0) {
                _brands = existinSuggestions?.products?.brand;
              }
              //We need to set the index so we can find out what product is selected later
              const productsTemp = existinSuggestions?.products.map(
                (product, index) => ({
                  ...product,
                  brand_nm: product.brand_nm || product.brand?.brand_name,
                  sub_cat_i : _brands ? _brands.find(b=>b?.ext_id==product?.ext_brand_i) : product.brand?.sub_cat_i,
                  isValid: validateProduct(product, sizeData),
                  index: productData.length + index,
                  variant_ids: product.variant_ids || [],
                  variantsData: product.brand?.variants
                    ? product.brand?.variants.map((item) => ({
                      id: item.ext_id,
                      title: item.variety_nm,
                    })) : [],
                  origin_ids: product.origin_ids || [],
                  originsData: product.brand?.origins
                  ? product.brand?.origins.map((item) => ({
                    id: item.ext_id,
                    title: item.origin_nm,
                  }))
                  : [],
                  model_number_ids: product.model_number_ids || [],
                  modelNumbersData: product.model_number ? {
                    id: product.model_number.ext_id,
                    title: product.model_number.model_number,
                  } : '',
                  existingProduct: true,
                  size_1: sizeDisablingUnits.includes(product.ext_size_unit_i) ? '' : product.size_1,
                  size_2: sizeDisablingUnits.includes(product.ext_size_unit_i) ? '' : product.size_dimension_i > 1 ? product.size_2 : null,
                  size_3: sizeDisablingUnits.includes(product.ext_size_unit_2_i) ? '' : product.size_dimension_i > 2 ? product.size_3 : null,
                  size_4: sizeDisablingUnits.includes(product.ext_size_unit_2_i) ? '' : product.size_dimension_i > 2 ? product.size_4 : null,
                  is_adult_bev: product.brand?.is_adult_bev
                  // sizes: product.brand?.sizes ?  product.brand.sizes.map((item) => ({
                  //   id: item.ext_id,
                  //   title: item.size_c,
                  // })) : [],
                })
              );
              productData.push(...productsTemp);
              if (productData.length > 0) {
                currentProductIndex.setValue(0);
                setCurrentProduct(productData[0]);
              }
            }
          }
        }
        setProductsData(productData);

        blkqty.setValue(result.payload.adblock.bulkqty_f);
        anomaly.setValue(result.payload.adblock.anomaly_f)
        newItem.setValue(result.payload.adblock.newitem_f);
        
        let _isAdultBev = false;
        if (result.payload.adblock.products_approved) {
          _isAdultBev = productData.some((product) => product.brand?.is_adult_bev || product?.sub_cat_i==null)
          if (_isAdultBev) {
            selectedVarieties.setValue(result.payload.adblock.select_varieties_f);
          }
        }
        else {
          _isAdultBev = productData.some((product) => product.is_adult_bev || product?.sub_cat_i==null)
          if (_isAdultBev) {
            selectedVarieties.setValue(result.payload.adblock.select_varieties_f);
          }
        }

        setAdblockFilename(result.payload.adblock.filename);
        const startActivity = await startAcitivityAPI(result.payload.adblock.uuid);
        if (startActivity.success) {
          console.log("acc", startActivity.payload.activity_uuid)
          setAdBlockActivityID(startActivity.payload.activity_uuid);
        } else {
          toast.error("Failed to start activity");
        }
      } else {
        setProductsData([]);
        setQueueCount(0);
        setSplit(false);
        setIsEndQueue(true);
      }
    } else {
      //TODO: system message
    }
    postPageEvents("ready", "");
    setIsApproveDisabled(false);
    setIsFetching(false);
  };

  const navigateNext = async () => {
    fetchAdBlock();
  };

  const handleLinkCopy = () => {
    const el = document.createElement("input");
    el.value =
      location.origin +
      "/product/" +
      currentCircularId +
      "/" +
      currentPageId +
      "/" +
      currentAdBlockId;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopiedText(true);
    setTimeout(() => {
      setCopiedText(false);
    }, 2500);
  };

  /*
  ///////////////////////////////////////
  ////// Set Adblock Flags /////////////
  /////////////////////////////////////
  */

  const handleReject = async (reject_message, remove_children) => {
    setIsSubmitting(true);
    const result = await rejectAdblock(currentAdBlockId, "products", reject_message, remove_children);

    if (result?.success) {
      navigateNext();
    } else {
      //TODO: System error message
    }
    setIsSubmitting(false);
  };

  const handleApprove = async () => {
    const int_keys = ["size_1", "size_2", "size_3", "size_4"];

    let products = productsData;
    if (!products.length > 0){
      setIsApproveDisabled(true);
      setIsSubmitting(false);
      return
    }
    let index = 0;
    products.forEach((product) => {
      Object.entries(product).forEach(([key, value]) => {

        if(key === 'ext_size_unit_i') {
          console.log('size_unit', value);

          let size_name = sizeData.find((size) =>
              size.id === value
          )?.title;


          if('size undeterminable' === size_name) {
            product['size_1'] = null;
            product['size_2'] = null;
          }

        }

        if(key === 'ext_size_unit_2_i') {
          console.log('size_unit', value);

          let size_name = sizeData.find((size) =>
              size.id === value
          )?.title;


          if('size undeterminable' === size_name) {
            product['size_3'] = null;
            product['size_4'] = null;
          }

        }

        if(key === 'variant_id' && value === "") {
          product["variant_id"] = null
        }

        if (value === "" && int_keys.indexOf(key) !== -1) {
          product[key] = null;
        }

      });
      product['size_2'] = product['size_dimension_i'] > 1 ? product['size_2'] : null;    
      product['size_3'] = product['size_dimension_i'] > 2 ? product['size_3'] : null;
      product['size_4'] = product['size_dimension_i'] > 2 ? product['size_4'] : null;
      product['ext_size_unit_2_i'] = product['size_dimension_i'] > 1 ? product['ext_size_unit_2_i'] : null;

      product['order_num'] = index;
      if (sizeDisablingUnits.includes(product['ext_size_unit_i'])) {
        product['size_1'] = null;
        product['size_2'] = null;
      }
      if (sizeDisablingUnits.includes(product['ext_size_unit_2_i'])) {
        product['size_3'] = null;
        product['size_4'] = null;
      }
      index++;

    });

    products = products.map((product) => omit(product, ["index"]))

    if (findDuplicates(products) && !split) {
      duplicatedModal.openModal();
      return;
    }
    setIsSubmitting(true);

    const adblock = {
      split: split ? 1 : 0,
      anomaly_f: anomaly.value,
      bulkqty_f: blkqty.value,
      newitem_f: newItem.value,
      select_varieties_f: selectedVarieties.value,
      event_nm: events.value.join(),
    }

    const endTime = new Date().toISOString().replace(',', '')

    if (isImported) {
      adblock.product_import_started_at = startTime
      adblock.product_import_finished_at = endTime
    } else {
      adblock.product_finished_at = endTime;
    }

    let data = {
      adblock: adblock,
      products: products,
      message_id: messageID,
    };

    console.log('approve', data);
    console.log('adultBevApplicable', adultBevApplicable);
    const result = await approveProduct(currentAdBlockId, data);
    if (result.success) {
      handleCompleteActivity();
      const res = await getCurrentUserAPI();
      if (res.success) {
        setLiveCounterData(res.results);
      }
      navigateNext();
    } else {
      //TODO: System error message
    }
    setIsSubmitting(false);
  };

  const handleCompleteActivity = async () => {
    const completeActivity = await completeActivityAPI(adBlockActivityID);
      if (completeActivity.success) {
        toast.info("Saved Activity", {
          position: "bottom-center", autoClose: 1000, hideProgressBar: false, closeOnClick: true,
          pauseOnHover: true, progress: undefined, theme: "dark"
        })
      } else {
        toast.error("Failed to save activity", {
          position: "bottom-center", autoClose: 1000, hideProgressBar: false, closeOnClick: true,
          pauseOnHover: true, progress: undefined, theme: "dark"
        })
      }
  }

  const handleSkipAdblock = async () => {
    setIsSubmitting(true);
    const result = await skipTopDownAdblock(
      currentAdBlockId,
      {
        message_id: messageID,
      },
      "products"
    );
    if (result.success) {
      navigateNext();
    } else {
      //TODO: System error message
    }
    setIsSubmitting(false);
  };

  const handleDoNotEnterAdblock = async () => {
    setIsSubmitting(true);
    const result = await doNotEnterTopDownAdblock(
      currentAdBlockId,
      {
        message_id: messageID,
      },
      "products"
    );
    if (result.success) {
      const res = await getCurrentUserAPI();
      if (res.success) {
        setLiveCounterData(res.results);
      }
      navigateNext();
    } else {
      //TODO: System error message
    }
    setIsSubmitting(false);
  };

  const handleSplit = (value) => {
    console.log('split', value);
    setSplit(value);
  }

  const handleFindImageMatch = async () => {
    setIsLoading(true);
    let result = await fetchImageMatch(currentAdBlockId);
    if (result.success) {
      setIsFetching(true);
      const result = await getAdBlockById(currentAdBlockId);
      if (result.payload) {
        setCurrentAdBlockData(result.payload);
      }
      setIsFetching(false);
      imageMatchingModal.openModal();
    } else {
      toast.info('No Match Found', {
        position: "top-right", autoClose: 8000, hideProgressBar: false, closeOnClick: true,
        pauseOnHover: true, progress: undefined, theme: "dark"
      });
    }
    setIsLoading(false);
  }

  
  const fetchMethodologies = async () => {
    setIsLoading(true);
    const brandSize = productsData.map((product) => {
      return {
        brand_id: product.ext_brand_i,
        size_nm: sizeValueBuilder(
          sizeData,
          product.size_1,
          product.size_2,
          product.size_3,
          product.size_4,
          product.ext_size_unit_i,
          product.ext_size_unit_2_i,
          product.size_dimension_i
        )
      }
    })
    if (brandSize) {
      const methodologyPromises = brandSize.map(async (item) => {
        if (!item.brand_id || !item.size_nm) return null;
        const res = await getMethodologyReminder(item.brand_id, item.size_nm);
        return res;
      });

      const results = await Promise.all(methodologyPromises);
      let methodologyMessage = results.filter(result => {
        if (result.success && result.payload?.data) {
          const message = result.payload.data.replace(/^"|"$/g, '').replace('/', '');
          if (message.includes('Validation')) return true
        }
        return false
      }).map(result => JSON.stringify(result.payload?.data)
      )
      if (methodologyMessage.length > 0) {
        setMethodogyReminderMessage(methodologyMessage);
        methodologyReminderModal.openModal();
      } else {
        handleApprove();
      }
    }
    setIsLoading(false);
  }
  
  const handleImport = (data) => {
    setIsImported(true);
    const pData = data.map((product, index) => {
      delete product.uuid;
      return {
        ...product,
        size_2: product.size_dimension_i > 1 ? product.size_2 : null,
        size_3: product.size_dimension_i > 2 ? product.size_3 : null,
        size_4: product.size_dimension_i > 2 ? product.size_4 : null,
        brand_nm: product.brand?.brand_name,
        isValid: validateProduct(product, sizeData),
        index,
        variant_ids: product.brand?.variants ? product.brand?.variant_ids : [],
        variantsData: product.brand?.variants
          ? product.brand?.variants.map((item) => ({
            id: item.ext_id,
            title: item.variety_nm,
          }))
          : [],
        origin_ids: product.brand?.origins ? product.brand?.origin_ids : [],
        originsData: product.brand?.origins
          ? product.brand?.origins.map((item) => ({
            id: item.ext_id,
            title: item.origin_nm,
          }))
          : [],
        model_number_ids: product.brand?.model_numbers ? product.brand?.model_number_ids : [],
        modelNumbersData: product.brand?.model_numbers
          ? product.brand?.model_numbers.map((item) => ({
            id: item.ext_id,
            title: item.model_number
          }))
          : [],
        sizes: product.brand?.sizes ? product.brand.sizes.map((item) => ({
          id: item.ext_id,
          title: item.size_c,
          max: item.max,
          min: item.min
        })) : [],
        is_adult_bev: product.brand?.is_adult_bev
      }
    });
    setProductsData(pData)
    setCurrentProduct({});
    currentProductIndex.setValue(0);
  } 



  /*
  ///////////////////////////////////////
  ////// Product Controls /////////////
  /////////////////////////////////////
  */

  const handleAddProduct = () => {
    postPageEvents("click", "Add Product");
    const newIndex =
      productsData.length > 0
        ? productsData[productsData.length - 1].index + 1
        : 0;
    setProductsData([
      ...productsData,
      {
        ext_brand_i: null,
        brand_nm: "",
        size_dimension_i: null,
        size_1: "",
        size_2: "",
        size_3: "",
        size_4: "",
        ext_size_unit_i: null,
        ext_size_unit_2_i: null,
        variant_ids: [],
        variant_nms: [],
        origin_ids: [],
        origin_nms: [],
        model_number_ids: [],
        model_number_nms: [],
        liner: false,
        index: newIndex,
        isValid: false,
        sizes: [],
        is_adult_bev: false,
      },
    ]);

    //Reset the optional fields
    setVariantsData([]);
    setOriginData([]);

    currentProductIndex.setValue(newIndex);
  };

  const handleRemoveProduct = (event, index) => {
    postPageEvents("click", "Remove Product");
    event.stopPropagation();
    let temp = [...productsData];

    if (!index) {
      index = temp.findIndex((product) => product.index === index);
    }

    temp.splice(index, 1);

    const newProductsData = temp.map((item, index) => ({
      ...item,
      isValid: validateProduct(item, sizeData),
      index,
    }));
    console.log(
      "new products",
      newProductsData,
      currentProductIndex.value,
      index
    );
    setProductsData(newProductsData);
    if (newProductsData.length === 0) {
      setCurrentProduct({});
      currentProductIndex.setValue(0);
    } else if (index === 0) {
      currentProductIndex.setValue(newProductsData[0].index);
      setCurrentProduct(newProductsData[0]);
    } else if (index === currentProductIndex.value) {
      currentProductIndex.setValue(newProductsData[index - 1].index);
      setCurrentProduct(newProductsData[index - 1]);
    } else if (currentProductIndex.value === newProductsData.length) {
      currentProductIndex.setValue(
        newProductsData[newProductsData.length - 1].index
      );
      setCurrentProduct(newProductsData[newProductsData.length - 1]);
    } else {
      // Do nothing if we dont need to udpate offer
    }
  };

  const handleCloneProduct = (event, index) => {
    postPageEvents("click", "Clone Product");
    if (productsData.length > 0) {
      let temp = [...productsData];

      if (!index) {
        index = temp.findIndex(
          (offer) => offer.index === currentProductIndex.value
        );
      }

      const clonedOffer = { ...productsData[index] };
      const newIndex = productsData[productsData.length - 1].index + 1;
      setProductsData([
        ...productsData,
        { ...clonedOffer, index: newIndex, uuid: undefined },
      ]);
      currentProductIndex.setValue(newIndex);
    }
  };

  const handleProductSelect = (index) => {
    currentProductIndex.setValue(index);
  };

  /*
  ///////////////////////////////////////
  ////// Adblock Refresh ///////////////
  /////////////////////////////////////
  */

  const refreshAdblockSuggestions = async () => {
    setSuggestionsLoading(true);
    const result = await updateAdblockSuggestions(currentAdBlockId);
    if (result.success) {
      if (result.payload.suggestions.products.length > 0) {
        //Set brands
        if (result.payload?.suggestions?.brands) {
          setBrands(result.payload?.suggestions?.brands);
        } else {
          setBrands([]);
        }

        let _brands = null;
        if (result.payload?.suggestions?.brands && result.payload?.suggestions?.brands.length > 0) {
          _brands = result.payload?.suggestions?.brands;
        }


        //Set dimensions
        if (result.payload?.suggestions?.dimensions) {
          setUnitDimensions(result.payload?.suggestions?.dimensions);
        } else {
          setUnitDimensions([]);
        }

        //Set sizes
        result.payload?.suggestions?.sizes.unshift({size:1});
        setSizes(result.payload?.suggestions?.sizes);

        //Set units
        if (result.payload?.suggestions?.units) {
          setUnits(result.payload?.suggestions?.units);
        } else {
          setUnits([]);
        }

        //We need to set the index so we can find out what product is selected later
        const productsTemp = result.payload.suggestions.products.map(
          (product, index) => ({
            ...product,
            brand_nm: product.brand_nm || product.brand?.brand_name,
            sub_cat_i : _brands ? _brands.find(b=>b?.ext_id==product?.ext_brand_i) : product.brand?.sub_cat_i,
            isValid: validateProduct(product, sizeData),
            index,
          })
        );

        setProductsData(productsTemp);

        if (productsTemp.length > 0) {
          currentProductIndex.setValue(0);
          setCurrentProduct(productsTemp[0]);
        }
      } else {
        setProductsData([]);
      }
    }
    setSuggestionsLoading(false);
  };

  /*
  ////////////////////////////////////////////////////
  ////// Form and Quickslection Changes ///////////////
  ///////////////////////////////////////////////////
  */

  //This is where we update any of the actual data that needs to be captured, the other information is temp
  const handleChangeProductInfo = (key, value, title) => {
    let temp = [...productsData];
    if(key !== "origin_id" || key !== "variant_id" || key !== "model_number_id")
      temp[currentProductIndex.value][key] = value;

    temp[currentProductIndex.value].isValid = validateProduct(
      temp[currentProductIndex.value],
      sizeData
    );

    //We need to update the brand name as well
    if (key === "ext_brand_i") {
      temp[currentProductIndex.value]["brand_nm"] = title;
    }

    //We need to update the variant name as well
    if (key === "variant_id") {
      temp[currentProductIndex.value]["variant_ids"] = value;
      temp[currentProductIndex.value]["variant_nms"] = title;
    }

    //We need to update the origin name as well
    if (key === "origin_id") {
      temp[currentProductIndex.value]["origin_ids"] = value;
      temp[currentProductIndex.value]["origin_nms"] = title;
    }

    //We need to update the model number as well
    if (key === "model_number_id") {
      temp[currentProductIndex.value]["model_number_ids"] = value;
      temp[currentProductIndex.value]["model_number_nms"] = title;
    }

    setProductsData(temp);
  };

  // This is specificly when a user clicks or selects the suggestion
  const handleSuggestionClick = (selectedItem) => {
    //We can set this here but we need to focus the other inputs after setting
    if (selectedItem === "Manual") {
      setIsManualEntry(true);
    }

    switch (activeProductInfo) {
      case "ext_brand_i":
        if (selectedItem === "Manual") {
          brand.setValue("");
          brand_nm.setValue("");
          setVariantsData([]);
          setOriginData([]);
          setModelNumberData([]);
          //setSizeData();
          if(document.getElementById("ext_brand_i")){
            document.getElementById("ext_brand_i").focus();
          }
        } else {
          let temp = [...productsData];

          if (selectedItem?.variants) {
            const variantsData = selectedItem?.variants.map((item) => ({
              id: item.ext_id,
              title: item.variety_nm,
            }));
            temp[currentProductIndex.value].variant_ids = [];
            temp[currentProductIndex.value].variant_nms = [];
            temp[currentProductIndex.value]["variantsData"] = variantsData;
            setVariantsData(variantsData);
          }

          if (selectedItem?.origins) {
            const originsData = selectedItem?.origins.map((item) => ({
              id: item.ext_id,
              title: item.origin_nm,
            }));
            temp[currentProductIndex.value].origin_ids = [];
            temp[currentProductIndex.value].origin_nms = [];
            temp[currentProductIndex.value]["originsData"] = originsData;
            setOriginData(originsData);
          }

          if (selectedItem?.model_numbers) {
            const modelNumbersData = selectedItem?.model_numbers.map((item) => ({
              id: item.ext_id,
              title: item.model_number,
            }));
            temp[currentProductIndex.value].model_number_ids = [];
            temp[currentProductIndex.value].model_number_nms = [];
            temp[currentProductIndex.value]["modelNumbersData"] = modelNumbersData;
            setModelNumberData(modelNumbersData);
          }
          if (selectedItem?.is_adult_bev) {
            const is_adult_bev = selectedItem?.is_adult_bev;
            temp[currentProductIndex.value].is_adult_bev = is_adult_bev;
          }
          // temp[currentProductIndex.value]["is_adult_bev"] = selectedItem?.is_adult_bev;

          if (selectedItem?.sizes) {
            const sizes = selectedItem?.sizes.map((item) => ({
              id: item.ext_id,
              title: item.size_c,
              max: item.max,
              min: item.min
            }))
            // if (sizes && sizes.length > 0){
            //   if (unit.value){
            //     const unitMinMax = sizes.filter(item => item.id === unit.value)[0]
            //     if (unitMinMax)
            //       setUnitWarn([unitMinMax.max, unitMinMax.min])
            //     else
            //       setUnitWarn([])
            //   }
            //   if (unit2.value){
            //     const unit2MinMax = sizes.filter(item => item.id === unit2.value)[0]
            //     if (unit2MinMax)
            //       setUnit2Warn([unit2MinMax.max, unit2MinMax.min])
            //     else
            //       setUnit2Warn([]);
            //   }
            // }
            temp[currentProductIndex.value]["sizes"] = sizes;
            setCurrentSizeData(sizes)
          }

          setProductsData(temp);

          brand.setValue(selectedItem.value);
          brand_nm.setValue(selectedItem.title);
        }
        break;
      case "size_dimension_i":
        if (selectedItem !== "Manual") {
          dimensions.setValue(selectedItem.value);
        }
        break;
      case "ext_size_unit_i":
        if (selectedItem !== "Manual") {
          unit.setValue(selectedItem.value);
        }
        break;
      case "ext_size_unit_2_i":
        if (selectedItem.value !== "Manual") {
          unit2.setValue(selectedItem.value);
        }
        break;
      case "size_1":
        if (selectedItem === "Manual") {
          size1.setValue(0);
        } else {
          size1.setValue(selectedItem.value);
        }
        break;
      case "size_2":
        if (selectedItem === "Manual") {
          size2.setValue(0);
        } else {
          size2.setValue(selectedItem.value);
        }
        break;
      case "size_3":
        if (selectedItem === "Manual") {
          size3.setValue(0);
        } else {
          size3.setValue(selectedItem.value);
        }
        break;
      case "size_4":
        if (selectedItem === "Manual") {
          size4.setValue(0);
        } else {
          size4.setValue(selectedItem.value);
        }
        break;
      case "liner_f":
        if (selectedItem !== "Manual") {
          liner.setValue(selectedItem.value);
        }
        break;
      case "brand_origin":
        if (selectedItem === "Manual") {
          origin.setValue("");
          origin_nm.setValue("");
        } else {
          origin.setValue(selectedItem.value);
          origin_nm.setValue(selectedItem.title);
        }
        break;
      default:
        break;
    }

    postPageEvents("click", selectedItem?.title);
    //If we are manual dont try to find the next offer info
    if (selectedItem !== "Manual") {
      handleChangeProductInfo(
        activeProductInfo,
        selectedItem.value,
        selectedItem.title
      );
      handleNextFormElement(selectedItem);
    }
  };

  // When the user selects a brand they, might have more fields depending upon the brand
  // We only do this if they are manually selecting a different brand
  const handleBrandSelect = async (name, e) => {
    //When deleting, no event comes back
    if (!e) {
      return;
    }

    var existingOne = false;
    if (currentProduct.suggestions && currentProduct.suggestions.brands) {
      existingOne = currentProduct.suggestions.brands.find(
        (brand) => brand.ext_id === e.ext_id
      );
    }

    let temp = [...productsData];

    if (existingOne) {
      const variantsData = (existingOne.variants || []).map((item) => ({
        id: item.ext_id,
        title: item.variety_nm,
      }));
      const originsData = (existingOne.origins || []).map((item) => ({
        id: item.ext_id,
        title: item.origin_nm,
      }));
      const modelNumbersData = (existingOne.model_numbers || []).map((item) => ({
        id: item.ext_id,
        title: item.model_number,
      }));
      const sizes = (existingOne.sizes || []).map((item) => ({
        id: item.ext_id,
        title: item.size_c,
        max: item.max,
        min: item.min
      }));
      // if (sizes && sizes.length > 0){
      //   if (unit.value){
      //     const unitMinMax = sizes.filter(item => item.id === unit.value)[0]
      //     if (unitMinMax)
      //       setUnitWarn([unitMinMax.max, unitMinMax.min])
      //     else
      //       setUnitWarn([])
      //   }
      //   if (unit2.value){
      //     const unit2MinMax = sizes.filter(item => item.id === unit2.value)[0]
      //     if (unit2MinMax)
      //       setUnit2Warn([unit2MinMax.max, unit2MinMax.min])
      //     else
      //       setUnit2Warn([]);
      //   }
      // }

      setVariantsData(variantsData);
      setOriginData(originsData);
      setModelNumberData(modelNumbersData);
      setCurrentSizeData(sizes);

      // setSizeData(
      //   (existingOne.sizes || []).map((item) => ({
      //     id: item.ext_id,
      //     title: item.size_c,
      //   }))
      // );

      temp[currentProductIndex.value]["variantsData"] = variantsData;
      temp[currentProductIndex.value]["originsData"] = originsData;
      temp[currentProductIndex.value]["modelNumbersData"] = modelNumbersData;
      temp[currentProductIndex.value]["is_adult_bev"] = existingOne.is_adult_bev;
      temp[currentProductIndex.value]["sizes"] = sizes;
      setProductsData(temp);
    } else {
      handleChangeProductInfo("ext_brand_i", e.ext_id, e.brand_name);
      const res = await getBrandByIdApi(e.uuid);
      if (res.success) {
        setBrandSubCatI(res.results?.sub_cat_i)
        if (res.results?.sub_cat_i) {
          temp[currentProductIndex.value]["sub_cat_i"] = res.results?.sub_cat_i;
        }
        else
        {
          temp[currentProductIndex.value]["sub_cat_i"] = null;
        }
        if (res.results?.variants) {
          if (res.results?.is_adult_bev) {
            temp[currentProductIndex.value]["is_adult_bev"] = res.results?.is_adult_bev;
          }
          const variantsData = res.results.variants.map((item) => ({
            id: item.ext_id,
            title: item.variety_nm,
          }));
          temp[currentProductIndex.value]["variantsData"] = variantsData;
          setVariantsData(variantsData);
        }

        if (res.results?.origins) {
          const originsData = res.results.origins.map((item) => ({
            id: item.ext_id,
            title: item.origin_nm,
          }));
          temp[currentProductIndex.value]["originsData"] = originsData;
          setOriginData(originsData);
        }

        if (res.results?.model_numbers) {
          const modelNumbersData = res.results.model_numbers.map((item) => ({
            id: item.ext_id,
            title: item.model_number,
          }));
          temp[currentProductIndex.value]["modelNumbersData"] = modelNumbersData;
          setModelNumberData(modelNumbersData);
        }

        if (res.results?.sizes && res.results?.sizes.length > 0) {
          const sizes = res.results.sizes.map((item) => ({
              id: item.ext_id,
              title: item.size_c,
              max: item.max,
              min: item.min
            }));
            setCurrentSizeData(sizes)
            temp[currentProductIndex.value]["sizes"] = sizes;
            // if (sizes && sizes.length > 0){
            //   if (unit.value){
            //     const unitMinMax = sizes.filter(item => item.id === unit.value)[0]
            //     if (unitMinMax)
            //       setUnitWarn([unitMinMax.max, unitMinMax.min])
            //     else
            //       setUnitWarn([])
            //   }
            //   if (unit2.value){
            //     const unit2MinMax = sizes.filter(item => item.id === unit2.value)[0]
            //     if (unit2MinMax)
            //       setUnit2Warn([unit2MinMax.max, unit2MinMax.min])
            //     else
            //       setUnit2Warn([]);
            //   }
            // }
        }
        //  else {
        //   setSizeData(defaultSizeData);
        // }

        handleNextFormElement(e, res.results);
        setProductsData(temp);
      } else {
        //TODO: System error message
      }
    }
  };

  //Changes active product on click for forms
  const onFormElementClick = (params) => {
    setIsManualEntry(true);
    setActiveProductInfo(params);

    if(PRODUCT_INFO_FIELDS.findIndex((item) => params === item) !== -1)
      setActiveField(params);
  };

  //Changes active product on click for forms
  const handleBrandClick = (event, params) => {
    //Only trigger this if the user is clicking on the input
    if (event.target.tagName.toUpperCase() === "INPUT") {
      brand.setValue("");
      brand_nm.setValue("");

      variant_ids.setValue([]);
      variant_nms.setValue([]);
      origin_ids.setValue([]);
      origin_nms.setValue([]);
      model_number_ids.setValue([]);
      model_number_nms.setValue([]);

      //Reset the optional fields
      setVariantsData([]);
      setOriginData([]);
      setModelNumberData([]);

      handleChangeProductInfo("ext_brand_i", null, "");
      handleChangeProductInfo("variant_id", null, "");
      handleChangeProductInfo("origin_id", null, "");
      handleChangeProductInfo("model_number_id", null, "");

      //We need to also unset the variants and origins 
      let temp = [...productsData];
      if(temp[currentProductIndex.value].variantsData && temp[currentProductIndex.value].variantsData.length > 0) {
        delete temp[currentProductIndex.value].variantsData;
      }
      if(temp[currentProductIndex.value].originsData && temp[currentProductIndex.value].originsData.length > 0) {
        delete temp[currentProductIndex.value].originsData;
      }
      if(temp[currentProductIndex.value].modelNumbersData && temp[currentProductIndex.value].modelNumbersData.length > 0) {
        delete temp[currentProductIndex.value].modelNumbersData;
      }
      if(temp[currentProductIndex.value].sizes && temp[currentProductIndex.value].sizes.length > 0) {
        delete temp[currentProductIndex.value].sizes;
      }

      onFormElementClick(params);

      //Small hack to focus the element after we clear the brand information
      setTimeout(() => {
        var input = document.getElementById(params);
        if (input) {
          input.focus();
        }
      });
    }
  };

  // Changes active MOV(ModelNumbers, Origins, Varities) on click for forms
  const handleMOVClick = (event, params) => {
    if (event.target.tagName.toUpperCase() === "INPUT") {
      onFormElementClick(params);

      setTimeout(() => {
        var input = document.getElementById(params);
        if (input) {
          input.focus();
        }
      });
    } else if (params === 'brand_origin') {
      onFormElementClick('brand_origin');
      setTimeout(() => {
        var input = document.getElementById('brand_origin');
        if (input) {
          input.focus();
        }
      });
    } else if (params === 'variant_id') {
      onFormElementClick('variant_id');
      setTimeout(() => {
        var input = document.getElementById('variant_id');
        if (input) {
          input.focus();
        }
      });
    } else if (params === 'brand_model_number') {
      onFormElementClick('brand_model_number');
      setTimeout(() => {
        var input = document.getElementById('brand_model_number');
        if (input) {
          input.focus();
        }
      });
    }
  };

  const handleNextFormElement = (selectedObject, additionalBrandInfo) => {
    let tempWorkFlow = [...formWorkflow];

    if (
      variantsData.length > 0 ||
      (additionalBrandInfo && additionalBrandInfo.variants.length > 0)
    ) {
      tempWorkFlow.push("variant_id");
    }

    if (
      originData.length > 0 ||
      (additionalBrandInfo && additionalBrandInfo.origins.length > 0)
    ) {
      tempWorkFlow.push("brand_origin");
    }

    if (
      modelNumberData.length > 0 ||
      (additionalBrandInfo && additionalBrandInfo.model_numbers.length > 0)
    ) {
      tempWorkFlow.push("brand_model_number");
    }

    //Since we dont know if we have variants or origins we add size_dimension_i after we
    // make a determination
    tempWorkFlow.push("size_dimension_i");

    //We need to just take the value if it is size dimension since it doesnt set right away
    let dimensionValue = dimensions.value;
    if (activeProductInfo === "size_dimension_i") {
      dimensionValue = selectedObject.value;
    }

    //Find the path we need to take
    if (dimensionValue) {
      let subSets =
        DimensionData.find((item) => item.id === dimensionValue)?.subSets || [];
      tempWorkFlow = tempWorkFlow.concat(subSets);
    }

    //Always apend the liner as the last element in the workflow
    tempWorkFlow.push("liner_f");

    //Find the current item
    const idx = tempWorkFlow.findIndex((item) => item === activeProductInfo);
    if (idx < tempWorkFlow.length - 1) {
      //Because our fields are dynamic, the fields rendering seems slower than the state render
      //This is a simple hack to make sure we always render the state, even after elements on
      //page render
      setTimeout(() => {
        if (isFetching) {
          setActiveProductInfo(tempWorkFlow[idx]);
        } else {
          setActiveProductInfo(tempWorkFlow[idx + 1]);
        }
      });
    }
  };

  // when user select any of MOV from selection
  const handleMOVSelect = async (name, e) => {
    if (!e) {
      return;
    }
    
    const MOVFieldMapping = {
      brand_model_number: {
        movDataField: "modelNumbersData",
        movIdField: "model_number_id",
        movNameField: "model_number",
        setMOVData: setModelNumberData
      },
      variant_id: {
        movDataField: "variantsData",
        movIdField: "variant_id",
        movNameField: "variety_nm",
        setMOVData: setVariantsData
      },
      brand_origin: {
        movDataField: "originData",
        movIdField: "origin_id",
        movNameField: "origin_nm",
        setMOVData: setOriginData
      },
    };

    const activeFieldMapping = MOVFieldMapping[activeField];

    if (!activeFieldMapping) return;

    const { movDataField, movIdField, movNameField, setMOVData } = activeFieldMapping;
    
    const existingOne = currentProduct[movDataField]?.find((item) => item.id === (e.id || e.ext_id));
    
    let temp = [...productsData];
    if (!existingOne) {
      const updatedData = (currentProduct[movDataField] || []).concat({ id: e.ext_id, title: e[movNameField] });
      setMOVData(updatedData);
      temp[currentProductIndex.value][movDataField] = updatedData;
      setProductsData(temp);
    } else {
      handleChangeProductInfo(movIdField, (e.id || e.ext_id), (e.title || e[movNameField]));
    }
  };

  const handleCreateBrand = (id, title, e) => {
    setCreateBrandName("");
    setShowCreateBrand(false);
    brand.setValue(params);
    brand_nm.setValue(title);
    handleBrandSelect(title, e);
  };
  
  const handleCreateMOV = (e) => {
    setCreateMOVName("");
    setShowCreateMOV(false);
    // conditional MOV
    const MOVFieldMapping = {
      brand_model_number: {
        idField: "model_number_id",
        nameField: "model_number",
        // New model-numbers need to select for now
        setMovId: console.log,
        setMovName: console.log
      },
      variant_id: {
        idField: "variety_id",
        nameField: "variety_nm",
        setMovId: console.log, //variant.setValue,
        setMovName: console.log //variant_nm.setValue
      },
      brand_origin: {
        idField: "origin_id",
        nameField: "origin_nm",
        setMovId: console.log, //origin.setValue, 
        setMovName: console.log //origin_nm.setValue,
      },
    };

    const activeFieldMapping = MOVFieldMapping[activeField];

    if (!activeFieldMapping) return;
  
    // eslint-disable-next-line
    const { idField, nameField, setMovId, setMovName } = activeFieldMapping;
    setMovId(e.ext_id);
    setMovName(e[nameField]);
    handleMOVSelect(e[nameField], e);
  };

  const calculateIndividualSize = (e) => {
    e.preventDefault();

    let updatedProduct = productsData[currentProductIndex.value];

    if (updatedProduct.size_1) {
      if (updatedProduct.size_3) {
        updatedProduct.size_3 = Number(updatedProduct.size_3 / updatedProduct.size_1).toFixed(2)
        productsData[currentProductIndex.value].size_3 = updatedProduct.size_3
        size3.setValue(updatedProduct.size_3)
      }
      if (updatedProduct.size_4) {
        updatedProduct.size_4 = Number(updatedProduct.size_4 / updatedProduct.size_1).toFixed(2)
        productsData[currentProductIndex.value].size_4 = updatedProduct.size_4
        size4.setValue(updatedProduct.size_4)
      }
    }
  };

  const handleEventsChange = (value) => {
    events.setValue(value);
  };

  const moveNextField = (value) => {
    if (document.getElementById(activeField)) {
      document.getElementById(activeField).blur();
    }

    if (activeField && fieldNames.length > 0) {
      if (activeField === last(fieldNames)) {
        setActiveField(fieldNames[0]);
      } else {
        let count = 1;
        const idx = fieldNames.findIndex((item) => item === activeField);
        var nextField = fieldNames[idx + count];

        while(!document.getElementById(nextField) && nextField !== last(fieldNames)) {
          count++;
          nextField = fieldNames[idx + count];
        }

        setActiveField(fieldNames[indexOf(fieldNames, activeField) + count]);
      }
    }
  };

  const movePreviousField = (value) => {
    if (activeField && fieldNames.length > 0) {
      if (document.getElementById(activeField)) {
        document.getElementById(activeField).blur();
      }

      if (activeField === fieldNames[0]) {
        setActiveField(last(fieldNames));
      } else {
        let count = 1;
        const idx = fieldNames.findIndex((item) => item === activeField);
        var prevField = fieldNames[idx - 1];

        while (!document.getElementById(prevField) && (idx - count) >= 0) {
          count++;
          prevField = fieldNames[idx - count];
        }

        if((idx - count) < 0) count--;
        setActiveField(fieldNames[indexOf(fieldNames, activeField) - count]);
      }
    }
  };

  /*
  ///////////////////////////////////////
  ////// Key Commands //////////////////
  /////////////////////////////////////
  */

  // Handle key press for quick selection
  useKeypress(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"], (event) => {
    if (parseInt(event.key) <= currentSuggestions.length && !isManualEntry) {
      event.preventDefault();
      var selectedItem;

      //Any time the user clicks zero they are going to manual entry
      if (parseInt(event.key) === 0) {
        selectedItem = "Manual";
      } else {
        selectedItem = currentSuggestions[parseInt(event.key) - 1];
      }

      handleSuggestionClick(selectedItem);
    }
  });

  //Disable manual entry by pressing escape
  useKeypress(["Escape"], (event) => {
    if (isManualEntry) {
      setIsManualEntry(false);
    }
  });

  //Remove Products
  useKeypress(["-"], (event) => {
    // if (event.altKey) {
    //   handleRemoveProduct();
    // }
  });

  //Add Products
  useKeypress(["+"], (event) => {
    if (event.altKey) {
      handleAddProduct();
    }
  });

  //Clone Products
  useKeypress(["="], (event) => {
    // if (event.altKey) {
    //   handleCloneProduct();
    // }
  });

//Cycle through products
  useKeypress(["["], (event) => {
    event.preventDefault();
    //If we go forward to the end, go back to start.
    if (currentProductIndex.value === productsData.length - 1) {
      currentProductIndex.setValue(0);
    } else {
      currentProductIndex.setValue(currentProductIndex.value + 1);
    }
  });

    //Cycle through products
  useKeypress(["]"], (event) => {
    event.preventDefault();
    //If we go backwards to the start, go back to top.
    if (currentProductIndex.value === 0) {
      currentProductIndex.setValue(productsData.length - 1);
    } else {
      currentProductIndex.setValue(currentProductIndex.value - 1);
    }
  });

  //Approve Products
  useKeypress(["q", "Q"], (event) => {
    if (event.altKey) {
      if (!(queueCount === 0 || isFetching || isSubmitting || isApproveDisabled || !allProductsValid)) {
        handleApprove();
      }
    }
  });

  //Approve Products
  useKeypress(["w", "W"], (event) => {
    if (event.altKey) {
      handleDoNotEnterAdblock();
    }
  });

  //Approve Products--
  useKeypress(["e", "E"], (event) => {
    if (event.altKey) {
      handleSkipAdblock();
    }
  });

  //Approve Products
  useKeypress(["r", "R"], (event) => {
    if (event.altKey) {
      handleReject();
    }
  });

  //refresh Suggestions
  useKeypress(["t", "T"], (event) => {
    if (event.altKey) {
      refreshAdblockSuggestions();
    }
  });

  useKeypress(["Tab"], (event) => {
    event.preventDefault();
    if (event.shiftKey) {
      movePreviousField();
    } else {
      moveNextField();
    }
    }
  );

  useEffect(() => {
    if(PRODUCT_INFO_FIELDS.findIndex((item) => item === activeField) !== -1) {
      if (activeField === "size_dimension_i" || activeField.indexOf("_f") > 0) {
          //Do nothing, focus is handled in DropDown2 or CheckBox component
      }
      else
      {
        //Setting the timeout prevents the keypress from populating an input
        setTimeout(() => {
          if (document.getElementById(activeField)) {
              document.getElementById(activeField).focus();
          }}, );
      }

      if(activeField !== activeProductInfo) onFormElementClick(activeField);
    }
    return () => {};
  }, [activeField]);

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout>
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <div className="my-auto d-flex">
                {/* <BreadCrumb className="ml-3 my-auto">{circularName}</BreadCrumb> */}
                <Link href={`/circular/${currentCircularId}/${currentPageId}`} 
                  className="ml-2 my-auto" >
                  {circularName}
                </Link>
                <BreadCrumb className="ml-3 my-auto">
                  {currentPageId}
                </BreadCrumb>
                <PageType className="ml-2 my-auto">
                    {pageType}
                  </PageType>
                <HistoryDropdown className="ml-4" width={320} type="product" />
                {currentAdBlockId ? (
                  <Button
                    buttonTheme="dark"
                    size="small"
                    width="120px"
                    className="ml-2 my-auto"
                    onClick={handleLinkCopy}
                  >
                    {!copiedText ? "COPY LINK" : "COPIED!"}
                  </Button>
                ) : null}
              </div>
              <PageInfo className="ml-auto my-auto ">
                {`Remaining in queue:  ${queueCount}`}{" "}
              </PageInfo>
              <>
                {(!isLoading && currentAdBlockData.similar_adblock_uuid && !currentAdBlockData.has_children) ?
                  <Button
                    // buttonTheme={"dark"}
                    width="50px"
                    size="small"
                    className="ml-2 my-auto"
                    onClick={() => imageMatchingModal.openModal()}
                    isLoading={isSubmitting}
                    disabled={isFetching}
                    eventType="flag"
                  >
                    <BsImageAlt />
                  </Button>
                  :
                  <Button
                    buttonTheme={"dark"}
                    width="50px"
                    size="small"
                    className="ml-2 my-auto"
                    onClick={() => handleFindImageMatch()}
                    isLoading={isSubmitting}
                    disabled={isFetching}
                    eventType="flag">
                    <MdFindInPage />
                  </Button>
                }
                <Button
                  buttonTheme="dark"
                  width="160px"
                  size="small"
                  className="ml-2 my-auto"
                  onClick={handleDoNotEnterAdblock}
                  isLoading={isSubmitting}
                  disabled={queueCount === 0}
                  eventType="flag"
                >
                  DO NOT ENTER
                </Button>
                {/* <Button
                  buttonTheme="dark"
                  width="100px"
                  size="small"
                  className="ml-2 my-auto"
                  onClick={handleSkipAdblock}
                  isLoading={isSubmitting}
                  disabled={queueCount === 0}
                  eventType="flag"
                >
                  SKIP
                </Button> */}

                <Button
                  buttonTheme="dark"
                  width="100px"
                  size="small"
                  className="ml-2 my-auto"
                  onClick={() => rejectModal.openModal()}
                  isLoading={isSubmitting}
                  disabled={queueCount === 0}
                  eventType="flag"
                >
                  REJECT
                </Button>
                <Button
                  buttonTheme={`${allProductsValid ? "primary" : "dark"}`}
                  width="120px"
                  size="small"
                  className="ml-2 my-auto"
                  onClick={() => {
                    if (Object.keys(unitRestrictionMessage).length > 0){
                      unitRestrictionWarningModal.openModal()
                    } else {
                      try{
                        fetchMethodologies();
                      } catch {
                        handleApprove();
                      }
                    }
                  }}
                  isLoading={isSubmitting}
                  disabled={queueCount === 0 || isFetching || isApproveDisabled || !allProductsValid}
                  eventType="flag"
                >
                  APPROVE
                </Button>
              </>
            </div>
          )}
        </Header>

        <Content>
          <div className="d-flex flex-column" style={{ width: "320px" }}>
            <AdblockLocation
              backImg={pageImg && `data:image/jpeg;base64,${pageImg}`}
              pageWidth={pageWidth}
              pageHeight={pageHeight}
              adblockCoords={adblockCoords}
              sectionCoords={sectionCoords}
            />
            <MetadataSection title="ADBLOCK DATA">
              <EventsDropDown
                pageId={currentPageId}
                {...events}
                onChange={handleEventsChange}
              />

              <div className="mt-3">
                <MetaCheckBox {...anomaly}>Anomaly</MetaCheckBox>
                <MetaCheckBox {...blkqty}>Bulk Quantity</MetaCheckBox>
                <MetaCheckBox {...newItem}>New Item</MetaCheckBox>
                {/* {adultBevApplicable && */}
                  <MetaCheckBox {...selectedVarieties} disabled={!adultBevApplicable}>
                    Selected Varieties
                  </MetaCheckBox>
                {/* } */}
              </div>

            </MetadataSection>
          </div>
          <AdblockImage
            type="products"
            filename={adblockFilename}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            blockRef={blockRef}
            isFetching={isFetching}
            queueCount={queueCount}
            currentAdBlockData={currentAdBlockData}
            isEndQueue={isEndQueue}
            circularId={currentCircularId}
            pageId={currentPageId}
          />

          <div className="d-flex flex-column">
            <ProductSelector
              title={`PRODUCTS ${
                productsData.length > 0
                  ? productsData.findIndex(
                      (product) => product.index === currentProductIndex.value
                    ) + 1
                  : 0
              }/${productsData.length}`}
              max={productsData.length}
              onChange={handleSplit}
              value={split}
              onAdd={handleAddProduct}
              isAddable={productsData.length > 0}
            >
              {queueCount > 0 ? (
                productsData.length === 0 ? (
                  <NoItems className="mt-2">
                    <div>There is no product available.</div>
                    <div>
                      {" "}
                      <span
                        className="cursor-pointer"
                        onClick={handleAddProduct}
                      >
                        <u>Create</u>
                      </span>{" "}
                      a new product or flag the adblock appropriately.
                    </div>
                  </NoItems>
                ) : (
                  <div className="d-flex">
                    <ItemsList>
                      {productsData.map((product, index) => (
                        <Items
                          key={index}
                          className="d-flex flex-row"
                          active={index === currentProductIndex.value}
                          onClick={() => handleProductSelect(index)}
                        >
                          <ProductSelectedBar
                            active={index === currentProductIndex.value}
                          ></ProductSelectedBar>
                          <ProductValidator valid={product.isValid}>
                            {product.isValid ? (
                              <FaCheckCircle />
                            ) : (
                              <FaMinusCircle />
                            )}
                          </ProductValidator>

                          {product.existingProduct ? <Type>
                            <FaInfoCircle />
                          </Type> : ''}

                          {productStringEval(product, sizeData, index, false)}

                          <ButtonIcon
                            active={productsData.length > 0}
                            onClick={(event) =>
                              handleCloneProduct(event, product?.index)
                            }
                          >
                            <FaClone />
                          </ButtonIcon>

                          <ButtonIcon
                            active={productsData.length > 0}
                            onClick={(event) =>
                              handleRemoveProduct(event, product?.index)
                            }
                          >
                            <FaTrash />
                          </ButtonIcon>
                        </Items>
                      ))}
                    </ItemsList>
                  </div>
                )
              ) : null}
            </ProductSelector>
            <div className="d-flex flex-1">
              {showCreateBrand ? (
                <CreateBrand
                  onCancel={() => {
                    setShowCreateBrand(false);
                  }}
                  onOk={handleCreateBrand}
                  originName={createBrandName}
                />
              ) :  (showCreateMOV ? (
                <CreateMOV
                  movName={createMOVName}
                  brand_i={currentProduct.ext_brand_i}
                  sub_cat_i={brandSubCatI}
                  whichMOV={activeField}
                  onCancel={() => {
                    setShowCreateMOV(false);
                  }}
                  onOk={handleCreateMOV}
                />
              ) :
               (
                <SelectionContainer>
                  <SelectionHeader>
                    QUICK SELECTION
                    {queueCount > 0 ? (
                      <MdRefresh
                        size={18}
                        onClick={refreshAdblockSuggestions}
                      />
                    ) : null}
                  </SelectionHeader>

                  {circularType === 'Training Circular' ?
                      <SelectionContent>
                        <div
                          className="mt-4 text-center"
                          style={{
                            paddingTop: '160px',
                            color: '#aaa',
                            fontSize: '14px',
                          }}
                        >
                          <h3>Training Circular</h3>
                          <div className="mt-3">
                            Suggestions has been disabled for this circular.
                          </div>
                        </div>
                      </SelectionContent> 
                : <SelectionContent>
                    {isManualEntry && (
                      <ManualEntry onExit={() => setIsManualEntry(false)} />
                    )}

                    {queueCount > 0 ? (
                      isFetching || suggestionsLoading ? (
                        <div>Loading...</div>
                      ) : productsData.length === 0 ? (
                        <div className="mt-4 pt-4 text-center">
                          No product available
                        </div>
                      ) : (
                        <>
                          <div className="text-blue-light mb-3">
                            {activeProductText}
                          </div>

                          {currentAdBlockData?.approved ? (
                            <AdblockApproved />
                          ) : currentSuggestions.length > 0 ? (
                            <>
                              {!isManualEntry && (
                                <Choice
                                  index={0}
                                  showIndex={!isManualEntry}
                                  data={{
                                    title: "Manual Entry",
                                    value: "Manual",
                                  }}
                                  active={false}
                                  onClick={handleSuggestionClick}
                                />
                              )}

                              {currentSuggestions.map((item, index) => (
                                <Choice
                                  key={`${index}key`}
                                  showIndex={!isManualEntry}
                                  index={index + (isManualEntry ? 0 : 1)}
                                  data={item}
                                  active={
                                    currentProduct &&
                                    currentProduct[activeProductInfo] ===
                                      item.value
                                  }
                                  onClick={() => handleSuggestionClick(item)}
                                />
                              ))}
                            </>
                          ) : (
                            <>
                              <NoSuggestions />
                            </>
                          )}
                        </>
                      )
                    ) : null}
                  </SelectionContent>
                    }
                </SelectionContainer>
               )
              )}

              {/* <AdBlockProductInformation
                productsData={productsData} /> */}

              <InfoSection
                title="ADBLOCK PRODUCT INFORMATION"
                className="flex-1 flex-column"
              >
                {productsData.length === 0 ? (
                  <div className="mt-4 pt-4 text-center">
                    No product available
                  </div>
                ) : (
                  <ScrollView>
                    <form autoComplete="off">
                      <input
                        style={{ display: "none" }}
                        autoComplete="false"
                      ></input>

                      <ProductInfoContainer
                        className="mt-3"
                        active={["ext_brand_i"].includes(activeProductInfo)}
                      >
                        <AutoComplete
                          label="Brand"
                          idField="ext_id"
                          inputId="ext_brand_i"
                          id="ext_brand_i"
                          disabled={!isManualEntry}
                          func={getProductBrandAutocompleteApi}
                          onClick={(event) =>
                            handleBrandClick(event, "ext_brand_i")
                          }
                          onFocus={() => onFormElementClick("ext_brand_i")}
                          onSelectValue={handleBrandSelect}
                          value={brand.value}
                          displayValue={brand_nm.value}
                          onChange={(e) => {
                            brand.setValue(e !== "Manual" ? e : null);
                            handleChangeProductInfo("ext_brand_i", e);
                          }}
                          onCreateNew={(name) => {
                            setShowCreateBrand(true);
                            setCreateBrandName(name);
                          }}
                        />
                      </ProductInfoContainer>

                      {variantsData.length !== 0 && (
                        <ProductInfoContainer
                          className="mt-3"
                          active={["variant_id"].includes(activeProductInfo)}
                        >
                          <span className="select-label my-auto">Variant (optional)</span>
                          <SelectMOV isManualEntry={isManualEntry}
                              inputId="variant_id"
                              movData={variantsData}
                              mov_ids={variant_ids}
                              mov_nms={variant_nms}
                              onChange={(variants) => {
                                if (variants) {
                                  let ids = []
                                  let nms = []
                                  variants.forEach(variant => {
                                    if (variant) {
                                      ids.push(variant.id);
                                      nms.push(variant.title);
                                    }
                                  })
                                  variant_ids.setValue(ids)
                                  variant_nms.setValue(nms)
                                  handleChangeProductInfo(
                                    "variant_id",
                                    ids,
                                    nms
                                  );
                                }
                              }}
                              onCreateNew={() => {
                                setShowCreateMOV(true);
                                setCreateMOVName("");
                              }}
                              onClick={(e) => { handleMOVClick(e, "variant_id") }}
                              onFocus={() => onFormElementClick("variant_id")}
                            />
                        </ProductInfoContainer>
                      )}   

                      {originData.length !== 0 && (
                        <ProductInfoContainer
                          className="mt-3"
                          active={["brand_origin"].includes(activeProductInfo)}
                        >
                          <span className="select-label my-auto">Origin (optional)</span>
                          <SelectMOV isManualEntry={isManualEntry}
                              inputId="brand_origin"
                              movData={originData}
                              mov_ids={origin_ids}
                              mov_nms={origin_nms}
                              onChange={(origins) => {
                                if (origins) {
                                  let ids = []
                                  let nms = []
                                  origins.forEach(variant => {
                                    if (variant) {
                                      ids.push(variant.id);
                                      nms.push(variant.title);
                                    }
                                  })
                                  origin_ids.setValue(ids)
                                  origin_nms.setValue(nms)
                                  handleChangeProductInfo(
                                    "origin_id",
                                    ids,
                                    nms
                                  );
                                }
                              }}
                              onCreateNew={() => {
                                setShowCreateMOV(true);
                                setCreateMOVName("");
                              }}
                              onClick={(e) => { handleMOVClick(e, "origin_id") }}
                              onFocus={() => onFormElementClick("brand_origin")}
                            />
                        </ProductInfoContainer>
                      )}   

                      {modelNumberData.length !== 0 && (
                        <ProductInfoContainer
                          className="mt-3"
                          active={["brand_model_number"].includes(activeProductInfo)}
                        >
                          <span className="select-label my-auto">Model Number (optional)</span>
                          <SelectMOV isManualEntry={isManualEntry}
                              inputId="brand_model_number"
                              movData={modelNumberData}
                              mov_ids={model_number_ids}
                              mov_nms={model_number_nms}
                              onChange={(mns) => {
                                if (mns) {
                                  let ids = []
                                  let nms = []
                                  mns.forEach(mn => {
                                    if (mn) {
                                      ids.push(mn.id);
                                      nms.push(mn.title);
                                      // TODO: what about Manual?
                                      // model_number.setValue(
                                      //   mn.id !== "Manual" ? mn.id : null
                                      // );
                                    }
                                  })
                                  model_number_ids.setValue(ids)
                                  model_number_nms.setValue(nms)
                                  handleChangeProductInfo(
                                    "model_number_id",
                                    ids,
                                    nms
                                  );
                                }
                              }}
                              onCreateNew={() => {
                                setShowCreateMOV(true);
                                setCreateMOVName("");
                              }}
                              onClick={(e) => { handleMOVClick(e, "brand_model_number") }}
                              onFocus={() => onFormElementClick("brand_model_number")}
                            />
                        </ProductInfoContainer>
                      )}   

                      <ProductInfoContainer
                        className="mt-3"
                        active={["size_dimension_i"].includes(
                          activeProductInfo
                        )}
                      >
                        <DropDown2
                          label="Unit Dimensions"
                          data={DimensionData}
                          disabled={!isManualEntry}
                          inputId = "size_dimension_i"
                          onClick={() => onFormElementClick("size_dimension_i")}
                          onFocus={() => onFormElementClick("size_dimension_i")}
                          onSelectValue={(object) => {
                            handleNextFormElement({ value: object?.id });
                          }}
                          value={dimensions.value}
                          onChange={(e) => {
                            if (e !== "") {
                              dimensions.setValue(e !== "Manual" ? e : null);
                              if (dimensions.value !== e) {
                                if (e === 1) {
                                  size2.setValue('');
                                  size3.setValue('');
                                  size4.setValue('');
                                } else if (e === 2) {
                                  size3.setValue('');
                                  size4.setValue('');
                                }
                              }
                              handleChangeProductInfo("size_dimension_i", e);
                            }
                          }}
                        />
                      </ProductInfoContainer>

                      {dimensions.value === 2 ? (
                        // Range
                        <>
                          <div className="d-flex">
                            <ProductInfoContainer
                              className="mt-3 flex-1"
                              active={["ext_size_unit_i"].includes(
                                activeProductInfo
                              )}
                            >
                              <SearchableUnit
                                label="ext_size_unit"
                                inputId="ext_size_unit_i"
                                data={sizeData}
                                catUnits={currentSizeData}
                                onClick={() =>
                                  onFormElementClick("ext_size_unit_i")
                                }
                                onFocus={() =>
                                  onFormElementClick("ext_size_unit_i")
                                }
                                disabled={!isManualEntry}
                                value={unit.value}
                                onSelectValue={(object) => {
                                  handleNextFormElement({ value: object.id });
                                }}
                                onChange={(e) => {
                                  if (e !== "") {
                                    unit.setValue(e !== "Manual" ? e : null);
                                    if (dimensions.value !== 6) {
                                      size1.setValue('')
                                      size2.setValue('')
                                    } else {
                                      size1.setValue('')
                                    }
                                    handleChangeProductInfo(
                                      "ext_size_unit_i",
                                      e
                                    );
                                  }
                                }}
                              />
                            </ProductInfoContainer>
                            {
                              !sizeDisablingUnits.includes(unit.value) &&
                              <ProductInfoContainer
                                className="mt-3 width-80"
                                active={["size_1"].includes(activeProductInfo)}
                              >
                                <Input2
                                  label="size_1"
                                  inputId="size_1"
                                  type={getSizeType(unit, sizeData)}
                                  onClick={() => onFormElementClick("size_1")}
                                  onFocus={() => onFormElementClick("size_1")}
                                  disabled={!isManualEntry || isSizeDisabled}
                                  value={size1.value}
                                  onChange={(e) => {
                                    size1.setValue(e !== "Manual" ? e : 0);
                                    handleChangeProductInfo("size_1", e);
                                  }}
                                />
                              </ProductInfoContainer>
                            }
                            {!sizeDisablingUnits.includes(unit.value) &&
                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_2"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_2"
                                inputId="size_2"
                                type={getSizeType(unit, sizeData)}
                                onClick={() => onFormElementClick("size_2")}
                                onFocus={() => onFormElementClick("size_2")}
                                disabled={!isManualEntry || isSizeDisabled}
                                value={size2.value}
                                onChange={(e) => {
                                  size2.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_2", e);
                                }}
                              />
                            </ProductInfoContainer>
                            }
                          </div>
                        </>
                      ) : dimensions.value === 4 ? ( //Multi
                      <>
                        <div className="d-flex">
                          <ProductInfoContainer
                            className="mt-3 flex-1"
                            active={["ext_size_unit_i"].includes(
                              activeProductInfo
                            )}
                          >
                            <SearchableUnit
                              label="ext_size_unit"
                              inputId="ext_size_unit_i"
                              data={sizeData}
                              catUnits={currentSizeData}
                              onClick={() =>
                                onFormElementClick("ext_size_unit_i")
                              }
                              onFocus={() =>
                                onFormElementClick("ext_size_unit_i")
                              }
                              disabled={!isManualEntry}
                              value={unit.value}
                              onSelectValue={(object) => {
                                handleNextFormElement({ value: object.id });
                              }}
                              onChange={(e) => {
                                if (e !== "") {
                                  unit.setValue(e !== "Manual" ? e : null);
                                  size1.setValue('')
                                  size2.setValue('')
                                  handleChangeProductInfo(
                                    "ext_size_unit_i",
                                    e
                                  );
                                }
                              }}
                            />
                          </ProductInfoContainer>
                          {!sizeDisablingUnits.includes(unit.value) &&
                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_1"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_1"
                                inputId="size_1"
                                type={getSizeType(unit, sizeData)}
                                onClick={() => onFormElementClick("size_1")}
                                onFocus={() => onFormElementClick("size_1")}
                                disabled={!isManualEntry || isSizeDisabled}
                                value={size1.value}
                                onChange={(e) => {
                                  size1.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_1", e);
                                }}
                              />
                            </ProductInfoContainer>
                          }
                          {!sizeDisablingUnits.includes(unit.value) &&
                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_2"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_2"
                                inputId="size_2"
                                type={getSizeType(unit, sizeData)}
                                onClick={() => onFormElementClick("size_2")}
                                onFocus={() => onFormElementClick("size_2")}
                                disabled={!isManualEntry || isSizeDisabled}
                                value={size2.value}
                                onChange={(e) => {
                                  size2.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_2", e);
                                }}
                              />
                            </ProductInfoContainer>
                          }
                        </div>
                      </>
              ) : dimensions.value === 5 ? (
                        // Size Qty
                        <>
                          <div className="d-flex">
                            <ProductInfoContainer
                              className="mt-3 flex-1"
                              active={["ext_size_unit_i"].includes(
                                activeProductInfo
                              )}
                            >
                              <SearchableUnit
                                label="ext_size_unit"
                                inputId="ext_size_unit_i"
                                data={sizeData}
                                catUnits={currentSizeData}
                                onClick={() =>
                                  onFormElementClick("ext_size_unit_i")
                                }
                                onFocus={() =>
                                  onFormElementClick("ext_size_unit_i")
                                }
                                disabled={!isManualEntry}
                                value={unit.value}
                                onSelectValue={(object) => {
                                  handleNextFormElement({ value: object.id });
                                }}
                                onChange={(e) => {
                                  if (e !== "") {
                                    unit.setValue(e !== "Manual" ? e : null);
                                    if (dimensions.value !== 6) {
                                      size1.setValue('')
                                      size2.setValue('')
                                    } else {
                                      size1.setValue('')
                                    }
                                    handleChangeProductInfo(
                                      "ext_size_unit_i",
                                      e
                                    );
                                  }
                                }}
                              />
                            </ProductInfoContainer>
                            {
                              !sizeDisablingUnits.includes(unit.value) &&
                              <ProductInfoContainer
                                className="mt-3 width-80"
                                active={["size_1"].includes(activeProductInfo)}
                              >
                                <Input2
                                  label="size_1"
                                  inputId="size_1"
                                  type={getSizeType(unit, sizeData)}
                                  onClick={() => onFormElementClick("size_1")}
                                  onFocus={() => onFormElementClick("size_1")}
                                  disabled={!isManualEntry || isSizeDisabled}
                                  value={size1.value}
                                  onChange={(e) => {
                                    size1.setValue(e !== "Manual" ? e : 0);
                                    handleChangeProductInfo("size_1", e);
                                  }}
                                />
                              </ProductInfoContainer>
                            }
                            {!sizeDisablingUnits.includes(unit.value) &&
                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_2"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_2"
                                inputId="size_2"
                                type={getSizeType(unit, sizeData)}
                                onClick={() => onFormElementClick("size_2")}
                                onFocus={() => onFormElementClick("size_2")}
                                disabled={!isManualEntry || isSizeDisabled}
                                value={size2.value}
                                onChange={(e) => {
                                  size2.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_2", e);
                                }}
                              />
                            </ProductInfoContainer>
                            }
                          </div>

                          <div className="d-flex">
                            <ProductInfoContainer
                              className="mt-3 flex-1"
                              active={["ext_size_unit_2_i"].includes(
                                activeProductInfo
                              )}
                            >
                              <SearchableUnit
                                label="ext_size_unit_2"
                                inputId="ext_size_unit_2_i"
                                data={sizeData}
                                catUnits={currentSizeData}
                                onClick={() =>
                                  onFormElementClick("ext_size_unit_2_i")
                                }
                                onFocus={() =>
                                  onFormElementClick("ext_size_unit_2_i")
                                }
                                disabled={!isManualEntry}
                                value={unit2.value}
                                onSelectValue={(object) => {
                                  handleNextFormElement({ value: object.id });
                                }}
                                onChange={(e) => {
                                  if (e !== "") {
                                    unit2.setValue(e !== "Manual" ? e : null);
                                    if (dimensions.value !== 6) {
                                      size3.setValue('')
                                      size4.setValue('')
                                    } else {
                                      size2.setValue('')
                                    }
                                    handleChangeProductInfo(
                                      "ext_size_unit_2_i",
                                      e
                                    );
                                  }
                                }}
                              />
                            </ProductInfoContainer>
                            {!sizeDisablingUnits.includes(unit2.value) &&
                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_3"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_3"
                                inputId="size_3"
                                type={getSizeType(unit2, sizeData)}
                                onClick={() => onFormElementClick("size_3")}
                                onFocus={() => onFormElementClick("size_3")}
                                disabled={!isManualEntry || isSize2Disabled}
                                value={size3.value}
                                onChange={(e) => {
                                  size3.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_3", e);
                                }}
                              />
                            </ProductInfoContainer>
                              }
                            {!sizeDisablingUnits.includes(unit2.value) &&
                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_4"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_4"
                                inputId="size_4"
                                type={getSizeType(unit2, sizeData)}
                                onClick={() => onFormElementClick("size_4")}
                                onFocus={() => onFormElementClick("size_4")}
                                disabled={!isManualEntry || isSize2Disabled}
                                value={size4.value}
                                onChange={(e) => {
                                  size4.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_4", e);
                                }}
                              />
                            </ProductInfoContainer>
                              }
                          </div>
                        </>
                      ) : dimensions.value === 6 ? (
                        <>
                          <div className="d-flex flex-column">
                            <div className="d-flex">
                            <ProductInfoContainer
                              className="mt-3 flex-1"
                              active={["ext_size_unit_i"].includes(
                                activeProductInfo
                                )}
                                >
                              <SearchableUnit
                                label="ext_size_unit"
                                inputId="ext_size_unit_i"
                                data={sizeData}
                                catUnits={currentSizeData}
                                onClick={() =>
                                  onFormElementClick("ext_size_unit_i")
                                }
                                onFocus={() =>
                                  onFormElementClick("ext_size_unit_i")
                                }
                                disabled={!isManualEntry}
                                value={unit.value}
                                onSelectValue={(object) => {
                                  handleNextFormElement({ value: object.id });
                                }}
                                onChange={(e) => {
                                  if (e !== "") {
                                    unit.setValue(e !== "Manual" ? e : null);
                                    if (dimensions.value !== 6) {
                                      size1.setValue('')
                                      size2.setValue('')
                                    } else {
                                      size1.setValue('')
                                    }
                                    handleChangeProductInfo(
                                      "ext_size_unit_i",
                                      e
                                      );
                                    }
                                  }}
                                  />
                              </ProductInfoContainer>
                              {!sizeDisablingUnits.includes(unit.value) &&
                                <ProductInfoContainer
                                  className="mt-3 width-80"
                                  active={["size_1"].includes(activeProductInfo)}
                                >
                                  <Input2
                                    label="size_1"
                                    inputId="unit_size_1"
                                    type={getSizeType(unit, sizeData)}
                                    onClick={() =>
                                      onFormElementClick("unit_size_1")
                                    }
                                    onFocus={() =>
                                      onFormElementClick("unit_size_1")
                                    }
                                    disabled={!isManualEntry || isSizeDisabled}
                                    value={size1.value}
                                    onChange={(e) => {
                                      size1.setValue(e !== "Manual" ? e : 0);
                                      handleChangeProductInfo("size_1", e);
                                    }}
                                  />
                                </ProductInfoContainer>
                              }
                            </div>

                            <div className="d-flex">
                            <ProductInfoContainer
                              className="mt-3 flex-1"
                              active={["ext_size_unit_2_i"].includes(
                                activeProductInfo
                              )}
                            >
                              <SearchableUnit
                                label="ext_size_unit_2"
                                inputId="ext_size_unit_2_i"
                                data={sizeData}
                                catUnits={currentSizeData}
                                onClick={() =>
                                  onFormElementClick("ext_size_unit_2_i")
                                }
                                onFocus={() =>
                                  onFormElementClick("ext_size_unit_2_i")
                                }
                                disabled={!isManualEntry}
                                value={unit2.value}
                                onSelectValue={(object) => {
                                  handleNextFormElement({ value: object.id });
                                }}
                                onChange={(e) => {
                                  if (e !== "") {
                                    unit2.setValue(e !== "Manual" ? e : null);
                                    if (dimensions.value !== 6) {
                                      size3.setValue('')
                                      size4.setValue('')
                                    } else {
                                      size2.setValue('')
                                    }
                                    handleChangeProductInfo(
                                      "ext_size_unit_2_i",
                                      e
                                    );
                                  }
                                }}
                              />
                              </ProductInfoContainer>
                              {!sizeDisablingUnits.includes(unit2.value) &&
                                <ProductInfoContainer
                                  className="mt-3 width-80"
                                  active={["size_2"].includes(activeProductInfo)}
                                >
                                  <Input2
                                    label="size_2"
                                    inputId="size_2"
                                    type={getSizeType(unit2, sizeData)}
                                    onClick={() => onFormElementClick("size_2")}
                                    onFocus={() => onFormElementClick("size_2")}
                                    disabled={!isManualEntry || isSize2Disabled}
                                    value={size2.value}
                                    onChange={(e) => {
                                      size2.setValue(e !== "Manual" ? e : 0);
                                      handleChangeProductInfo("size_2", e);
                                    }}
                                  />
                                </ProductInfoContainer>
                              }
                            </div>
                          </div>
                        </>
                      ) : dimensions.value === 7 ? ( //Double
                      <>
                        <div className="d-flex">
                          <ProductInfoContainer
                            className="mt-3 flex-1"
                            active={["ext_size_unit_i"].includes(
                              activeProductInfo
                            )}
                          >
                            <SearchableUnit
                              label="ext_size_unit"
                              inputId="ext_size_unit_i"
                              data={sizeData}
                              catUnits={currentSizeData}
                              onClick={() =>
                                onFormElementClick("ext_size_unit_i")
                              }
                              onFocus={() =>
                                onFormElementClick("ext_size_unit_i")
                              }
                              disabled={!isManualEntry}
                              value={unit.value}
                              onSelectValue={(object) => {
                                handleNextFormElement({ value: object.id });
                              }}
                              onChange={(e) => {
                                if (e !== "") {
                                  unit.setValue(e !== "Manual" ? e : null);
                                  size1.setValue('')
                                  handleChangeProductInfo(
                                    "ext_size_unit_i",
                                    e
                                  );
                                }
                              }}
                            />
                          </ProductInfoContainer>
                          {!sizeDisablingUnits.includes(unit.value) &&
                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_1"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_1"
                                inputId="size_1"
                                type={getSizeType(unit, sizeData)}
                                onClick={() => onFormElementClick("size_1")}
                                onFocus={() => onFormElementClick("size_1")}
                                disabled={!isManualEntry || isSizeDisabled}
                                value={size1.value}
                                onChange={(e) => {
                                  size1.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_1", e);
                                }}
                              />
                            </ProductInfoContainer>
                          }
                        </div>
                        <div className="d-flex">
                          <ProductInfoContainer
                            className="mt-3 flex-1"
                            active={["ext_size_unit_2_i"].includes(
                              activeProductInfo
                            )}
                          >
                            <SearchableUnit
                              label="ext_size_unit_2"
                              inputId="ext_size_unit_2_i"
                              data={sizeData}
                              catUnits={currentSizeData}
                              onClick={() =>
                                onFormElementClick("ext_size_unit_2_i")
                              }
                              onFocus={() =>
                                onFormElementClick("ext_size_unit_2_i")
                              }
                              disabled={!isManualEntry}
                              value={unit2.value}
                              onSelectValue={(object) => {
                                handleNextFormElement({ value: object.id });
                              }}
                              onChange={(e) => {
                                if (e !== "") {
                                  unit2.setValue(e !== "Manual" ? e : null);
                                  size2.setValue('')
                                  handleChangeProductInfo(
                                    "ext_size_unit_2_i",
                                    e
                                  );
                                }
                              }}
                            />
                          </ProductInfoContainer>
                          {!sizeDisablingUnits.includes(unit.value) &&
                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_2"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_2"
                                inputId="size_2"
                                type={getSizeType(unit2, sizeData)}
                                onClick={() => onFormElementClick("size_2")}
                                onFocus={() => onFormElementClick("size_2")}
                                disabled={!isManualEntry || isSizeDisabled}
                                value={size2.value}
                                onChange={(e) => {
                                  size2.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_2", e);
                                }}
                              />
                            </ProductInfoContainer>
                          }
                        </div>
                      </>
                    ) : dimensions.value === 9 ? ( // AreaRange
                    <>
                    <div className="d-flex">
                      <ProductInfoContainer
                        className="mt-3 flex-1"
                        active={["ext_size_unit_i"].includes(
                          activeProductInfo
                        )}
                      >
                        <SearchableUnit
                          label="ext_size_unit"
                          inputId="ext_size_unit_i"
                          data={sizeData}
                          catUnits={currentSizeData}
                          onClick={() =>
                            onFormElementClick("ext_size_unit_i")
                          }
                          onFocus={() =>
                            onFormElementClick("ext_size_unit_i")
                          }
                          disabled={!isManualEntry}
                          value={unit.value}
                          onSelectValue={(object) => {
                            handleNextFormElement({ value: object.id });
                          }}
                          onChange={(e) => {
                            if (e !== "") {
                              unit.setValue(e !== "Manual" ? e : null);
                              size1.setValue('')
                              size2.setValue('')
                              handleChangeProductInfo(
                                "ext_size_unit_i",
                                e
                              );
                            }
                          }}
                        />
                      </ProductInfoContainer>
                      {!sizeDisablingUnits.includes(unit.value) &&
                        <ProductInfoContainer
                        className="mt-3 width-80"
                          active={["size_1"].includes(activeProductInfo)}
                        >
                          <Input2
                            label="size_1"
                            inputId="size_1"
                            type={getSizeType(unit, sizeData)}
                            onClick={() => onFormElementClick("size_1")}
                            onFocus={() => onFormElementClick("size_1")}
                            disabled={!isManualEntry || isSizeDisabled}
                            value={size1.value}
                            onChange={(e) => {
                              size1.setValue(e !== "Manual" ? e : 0);
                              handleChangeProductInfo("size_1", e);
                            }}
                            />
                        </ProductInfoContainer>
                      }
                      {!sizeDisablingUnits.includes(unit.value) &&
                        <ProductInfoContainer
                        className="mt-3 width-80"
                          active={["size_2"].includes(activeProductInfo)}
                        >
                          <Input2
                            label="size_2"
                            inputId="size_2"
                            type={getSizeType(unit, sizeData)}
                            onClick={() => onFormElementClick("size_2")}
                            onFocus={() => onFormElementClick("size_2")}
                            disabled={!isManualEntry || isSizeDisabled}
                            value={size2.value}
                            onChange={(e) => {
                              size2.setValue(e !== "Manual" ? e : 0);
                              handleChangeProductInfo("size_2", e);
                            }}
                            />
                        </ProductInfoContainer>
                      }
                    </div>
                    <div className="d-flex">
                      <ProductInfoContainer
                        className="mt-3 flex-1"
                        active={["ext_size_unit_2_i"].includes(
                          activeProductInfo
                        )}
                      >
                        <SearchableUnit
                          label="ext_size_unit_2"
                          inputId="ext_size_unit_2_i"
                          data={sizeData}
                          catUnits={currentSizeData}
                          onClick={() =>
                            onFormElementClick("ext_size_unit_2_i")
                          }
                          onFocus={() =>
                            onFormElementClick("ext_size_unit_2_i")
                          }
                          disabled={!isManualEntry}
                          value={unit.value}
                          onSelectValue={(object) => {
                            handleNextFormElement({ value: object.id });
                          }}
                          onChange={(e) => {
                            if (e !== "") {
                              unit2.setValue(e !== "Manual" ? e : null);
                              size3.setValue('')
                              size4.setValue('')
                              handleChangeProductInfo(
                                "ext_size_unit_2_i",
                                e
                              );
                            }
                          }}
                        />
                      </ProductInfoContainer>
                      {!sizeDisablingUnits.includes(unit.value) &&
                        <ProductInfoContainer
                        className="mt-3 width-80"
                          active={["size_3"].includes(activeProductInfo)}
                        >
                          <Input2
                            label="size_3"
                            inputId="size_3"
                            type={getSizeType(unit2, sizeData)}
                            onClick={() => onFormElementClick("size_3")}
                            onFocus={() => onFormElementClick("size_3")}
                            disabled={!isManualEntry || isSizeDisabled}
                            value={size3.value}
                            onChange={(e) => {
                              size3.setValue(e !== "Manual" ? e : 0);
                              handleChangeProductInfo("size_3", e);
                            }}
                            />
                        </ProductInfoContainer>
                      }
                      {!sizeDisablingUnits.includes(unit.value) &&
                        <ProductInfoContainer
                        className="mt-3 width-80"
                          active={["size_4"].includes(activeProductInfo)}
                        >
                          <Input2
                            label="size_4"
                            inputId="size_4"
                            type={getSizeType(unit2, sizeData)}
                            onClick={() => onFormElementClick("size_4")}
                            onFocus={() => onFormElementClick("size_4")}
                            disabled={!isManualEntry || isSizeDisabled}
                            value={size4.value}
                            onChange={(e) => {
                              size4.setValue(e !== "Manual" ? e : 0);
                              handleChangeProductInfo("size_4", e);
                            }}
                            />
                        </ProductInfoContainer>
                      }
                    </div>
                  </>
                    ) : dimensions.value === 26 ? ( // TotalSize
                      <>
                        <div className="d-flex">
                          <ProductInfoContainer
                            className="mt-3 flex-1"
                            active={["ext_size_unit_i"].includes(
                              activeProductInfo
                            )}
                          >
                            <SearchableUnit
                              label="ext_size_unit"
                              inputId="ext_size_unit_i"
                              data={sizeData}
                              catUnits={currentSizeData}
                              onClick={() =>
                                onFormElementClick("ext_size_unit_i")
                              }
                              onFocus={() =>
                                onFormElementClick("ext_size_unit_i")
                              }
                              disabled={!isManualEntry}
                              value={unit.value}
                              onSelectValue={(object) => {
                                handleNextFormElement({ value: object.id });
                              }}
                              onChange={(e) => {
                                if (e !== "") {
                                  unit.setValue(e !== "Manual" ? e : null);
                                  if (dimensions.value !== 26) {
                                    size1.setValue('')
                                    size2.setValue('')
                                  } else {
                                    size1.setValue('')
                                  }
                                  handleChangeProductInfo(
                                    "ext_size_unit_i",
                                    e
                                  );
                                }
                              }}
                            />
                          </ProductInfoContainer>
                          {!sizeDisablingUnits.includes(unit.value) &&
                            <ProductInfoContainer
                            className="mt-3 width-80"
                              active={["size_1"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="Count"
                                inputId="size_1"
                                type={getSizeType(unit, sizeData)}
                                onClick={() => onFormElementClick("size_1")}
                                onFocus={() => onFormElementClick("size_1")}
                                disabled={!isManualEntry || isSizeDisabled}
                                value={size1.value}
                                onChange={(e) => {
                                  size1.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_1", e);
                                }}
                                />
                            </ProductInfoContainer>
                          }
                          </div>
                          <div className="d-flex">
                          <ProductInfoContainer
                              className="mt-3 flex-1"
                              active={["ext_size_unit_2_i"].includes(
                                activeProductInfo
                              )}
                            >
                              <SearchableUnit catUnits={currentSizeData}
                                label="ext_size_unit_2"
                                inputId="ext_size_unit_2_i"
                                data={sizeData}
                                onClick={() =>
                                  onFormElementClick("ext_size_unit_2_i")
                                }
                                onFocus={() =>
                                  onFormElementClick("ext_size_unit_2_i")
                                }
                                disabled={!isManualEntry}
                                value={unit2.value}
                                onSelectValue={(object) => {
                                  handleNextFormElement({ value: object.id });
                                }}
                                onChange={(e) => {
                                  if (e !== "") {
                                    unit2.setValue(e !== "Manual" ? e : null);
                                    if (dimensions.value !== 26) {
                                      size3.setValue('')
                                      size4.setValue('')
                                    } else {
                                      size2.setValue('')
                                    }
                                    handleChangeProductInfo(
                                      "ext_size_unit_2_i",
                                      e
                                    );
                                  }
                                }}
                              />
                              </ProductInfoContainer>
                          {!sizeDisablingUnits.includes(unit.value) &&
                            <ProductInfoContainer
                            className="mt-3 width-80"
                              active={["size_3"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="Total Size 1"
                                inputId="size_3"
                                type={getSizeType(unit2, sizeData)}
                                onClick={() => onFormElementClick("size_3")}
                                onFocus={() => onFormElementClick("size_3")}
                                disabled={!isManualEntry || isSizeDisabled}
                                value={size3.value}
                                onChange={(e) => {
                                  size3.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_3", e);
                                }}
                                />
                            </ProductInfoContainer>
                          }
                           {!sizeDisablingUnits.includes(unit.value) &&
                            <ProductInfoContainer
                            className="mt-3 width-80"
                              active={["size_4"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="Total Size 2"
                                inputId="size_4"
                                type={getSizeType(unit2, sizeData)}
                                onClick={() => onFormElementClick("size_4")}
                                onFocus={() => onFormElementClick("size_4")}
                                disabled={!isManualEntry || isSizeDisabled}
                                value={size4.value}
                                onChange={(e) => {
                                  size4.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_4", e);
                                }}
                                />
                            </ProductInfoContainer>
                          }
                        </div>
                        <div className="d-flex">
                          <Button buttonTheme="dark" size="small" width=""
                            className="ml-2 my-auto" onClick={calculateIndividualSize}>
                              Calculate Individual Size
                           </Button>
                        </div>
                      </>
                    ) : (
                        // Single
                        <>
                          <div className="d-flex">
                            <ProductInfoContainer
                              className="mt-3 flex-1"
                              active={["ext_size_unit_i"].includes(
                                activeProductInfo
                              )}
                            >
                              <SearchableUnit
                                label="ext_size_unit"
                                inputId="ext_size_unit_i"
                                data={sizeData}
                                catUnits={currentSizeData}
                                onClick={() =>
                                  onFormElementClick("ext_size_unit_i")
                                }
                                onFocus={() =>
                                  onFormElementClick("ext_size_unit_i")
                                }
                                disabled={!isManualEntry}
                                value={unit.value}
                                onSelectValue={(object) => {
                                  handleNextFormElement({ value: object.id });
                                }}
                                onChange={(e) => {
                                  if (e !== "") {
                                    unit.setValue(e !== "Manual" ? e : null);
                                    if (dimensions.value !== 6) {
                                      size1.setValue('')
                                      size2.setValue('')
                                    } else {
                                      size1.setValue('')
                                    }
                                    handleChangeProductInfo(
                                      "ext_size_unit_i",
                                      e
                                    );
                                  }
                                }}
                              />
                            </ProductInfoContainer>
                            {
                              !sizeDisablingUnits.includes(unit.value) &&
                              <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_1"].includes(activeProductInfo)}
                              >
                                <Input2
                                  label="size_1"
                                  inputId="unit_size_1"
                                  type={getSizeType(unit, sizeData)}
                                  onClick={() =>
                                    onFormElementClick("unit_size_1")
                                  }
                                  onFocus={() =>
                                    onFormElementClick("unit_size_1")
                                  }
                                  disabled={!isManualEntry || isSizeDisabled}
                                  value={size1.value}
                                  onChange={(e) => {
                                    size1.setValue(e !== "Manual" ? e : 0);
                                    handleChangeProductInfo("size_1", e);
                                  }}
                                  />
                              </ProductInfoContainer>
                            }
                          </div>
                        </>
                      )}

                      <ProductInfoContainer
                        className="mt-3"
                        active={activeProductInfo === "liner_f"}
                        id="liner_f"
                      >
                        <CheckBox
                          inputId="liner_f"
                          value={liner.value}
                          onChange={(e) => {
                            liner.setValue(e !== "Manual" ? e : false);
                            handleChangeProductInfo("liner_f", e);
                          }}
                        >
                          Is this product a liner?
                        </CheckBox>
                      </ProductInfoContainer>
                    </form>
                  </ScrollView>
                )}
              </InfoSection>
            </div>
          </div>
        </Content>

        <EndQueueModal {...endQueueModal} />
        <RejectWarningModal 
          {...rejectModal} 
          onReject={(reject_message, remove_children) => 
            handleReject(reject_message, remove_children)} 
        />
        <ErrorModal {...duplicatedModal}>Duplicated Products!</ErrorModal>
        <UnitRestrictionWarning
          {...unitRestrictionWarningModal}
          onApprove={() => handleApprove()}
          unitRestrictionMessage={unitRestrictionMessage}
        />
        <ImageMatchingModal 
          {...imageMatchingModal}
          currentAdBlockData={currentAdBlockData}  
          circularName={circularName}
          dupe_block={currentAdBlockData.similar_adblock_uuid}
          view="product"
          ttb={false}
          startTime={startTime}
          sizeData={sizeData}
          onImport={(data) => handleImport(data)}
          onError={(err) => toast.error(err)}
          onSuccess={() => {
            handleCompleteActivity();
            navigateNext();
          }}
        />
        <MethodologyReminder
          {...methodologyReminderModal}
          message={methodologyReminderMessage}
          onApprove={() => handleApprove()}
        />
        <ToastContainer />
      </MainLayout>
    </SkeletonTheme>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;

const InfoSection = styled(Card)`
  height: calc(100% - 4px);
  overflow: hidden;
  color: white;
  flex: 1;
  width: 380px;
  .offer-label {
    color: white;
    font-size: 24px;
  }
`;

const Header = styled.div`
  height: 48px;
  color: white;
  font-size: 20px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;

const MetadataSection = styled(Card)`
  min-width: 240px;
  flex: 1;
`;

const PageInfo = styled.div`
  font-size: 16px;
  color: white;
`;

const PageType = styled.div`
  padding: 5px;
  font-size: 14px;
`;

const SelectionContainer = styled.div`
  width: 380px;
  flex: 1;
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
`;

const SelectionHeader = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const ItemsList = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 200px;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const Items = styled.div`
  border: 1px solid
    ${(props) =>
      props.active
        ? props.theme.palette.primaryOutline
        : props.theme.palette.backgrounds};
  width: 100%;
  cursor: pointer;
  div {
    background-color: ${(props) => props.theme.palette.items};
    margin: 0 1px;
    padding: 6px 10px;
  }
   .flex-1
  {
    width:500px;
  }
`;

const ProductSelectedBar = styled.div`
  width: 4px;
  padding: 0 !important;
  margin: 0 !important;
  background-color: ${(props) =>
    props.active
      ? props.theme.palette.primaryOutline
      : props.theme.palette.backgrounds} !important;
`;

const ProductValidator = styled.div`
  color: ${(props) =>
    props.valid ? props.theme.palette.success : props.theme.palette.error};
`;

const SelectionContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: calc(100% - 12px);
  color: white;
`;

const ButtonIcon = styled.div`
  color: ${(props) => (props.active ? "white" : "#333")};
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const NoItems = styled.div`
  font-size: 14px;
`;

const BreadCrumb = styled.div`
  color: white;
  font-size: 14px;
`;


const Type = styled.div`
  color: white;
`;
