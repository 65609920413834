import axios from "axios";

export const getMethodologyReminder = async (brand_id, size_nm) => {
  try {
    const response = await axios.get(`https://addison-api.azurewebsites.net/api/addison/getMethodologyReminder?brand_i=${brand_id}&size_nm="${size_nm}"`, {
      timeout: 5000
    })
    return { success: true, payload: response };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
