/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal, Button } from "components";
import styled from "styled-components";

export const DeleteWarningModal = ({
  open,
  onClose,
  onDelete = () => { }
}) => {


  const handleCancel = () => {
    onClose();
  }

  const handleDelete = () => {
    onDelete();
  }


  return (
    <Modal open={open} onClose={onClose} size="medium" title={"Delete Page"}>
      <MessageStyle>
        <div>
          Deleting a Page will delete all associated
          adblocks, groups, products, offers, incentives.
          <br /> Are you use your want to delete this page?
        </div>
      </MessageStyle>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-4"
          type="button"
          onClick={() => handleDelete()}
        >
          Delete Anyway!
        </Button>
      </div>
    </Modal>
  );
};

const MessageStyle = styled.div`
    font-size:14px;
`;
