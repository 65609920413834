import React from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useModal } from "hooks";
import { TaskViewModal } from "modals";
export const TableRow = ({ isLoading, status, data }) => {
  const viewModal = useModal();
  // const handleClick = () => {
  //   viewModal.openModal();
  // };

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container status={status}>
      <div className="inner">
        <RowItem status="info">{data.full_name ? data.full_name : 0}</RowItem>
        <RowItem status="info">{data.cropping_completed ? data.cropping_completed : 0}</RowItem>
        <RowItem status="info">{data.cm_block ? data.cm_block : 0}</RowItem>
        <RowItem status="info">{data.im_product ? data.im_product : 0}</RowItem>
        <RowItem status="info">{data.im_offer ? data.im_offer : 0}</RowItem>
        <RowItem status="info">{data.em_product ? data.em_product : 0}</RowItem>
        <RowItem status="info">{data.em_offer ? data.em_offer : 0}</RowItem>
        <RowItem status="info">{data.ttb_product_completed ? data.ttb_product_completed : 0}</RowItem>
        <RowItem status="info">{data.ttb_offer_completed ? data.ttb_offer_completed : 0}</RowItem>
        <RowItem status="info">{data.ltr_product_completed ? data.ltr_product_completed : 0}</RowItem>
        <RowItem status="info">{data.ltr_offer_completed ? data.ltr_offer_completed : 0}</RowItem>
      </div>
      <TaskViewModal {...viewModal} />
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  display: block;
  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  border-left: 12px solid
    ${(props) =>
    props.status === "success"
      ? props.theme.palette.success
      : props.status === "warning"
        ? props.theme.palette.warning
        : props.status === "info"
          ? props.theme.palette.info
          : props.status === "error"
            ? props.theme.palette.error
            : props.status === "none"
              ? props.theme.palette.white
              : props.theme.palette.secondary};

  margin: 3px 6px 3px 12px;
  border-radius: 2px;
  .inner {
    display: flex;
    width: 100%;
    padding: 0 0px 0 32px;
    height: 66px;
    cursor: pointer;
    &:hover {
      background: ${(props) =>
    props.isLoading
      ? props.theme.palette.items
      : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      width: 180px;
    }
    & > div:nth-child(2) {
      flex: 1;
    }
    & > div:nth-child(3) {
      flex: 1;
    }
    & > div:nth-child(4) {
      flex: 1;
    }
    & > div:nth-child(5) {
      flex: 1;
    }
    & > div:nth-child(6) {
      flex: 1;
    }
    & > div:nth-child(7) {
      flex: 1;
    }
    & > div:nth-child(8) {
      flex: 1;
    }
    & > div:nth-child(9) {
      flex: 1;
    }
    & > div:nth-child(10) {
      flex: 1;
    }
    & > div:nth-child(11) {
      flex: 1;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 16px auto 0;
`;

// const Error = styled.div`
//   height: 40px;
//   line-height: 40px;
//   background: ${(props) => props.theme.palette.error};
//   color: white;
//   font-size: ${(props) => props.theme.font.size.m};
//   padding-left: 40px;
//   a {
//     color: white;
//     text-decoration: underline;
//   }
// `;
