/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { PAGE_TYPES } from "config";
import ClipLoader from "react-spinners/ClipLoader";
import {
  getCircularById,
  getImage,
  deleteAdBlockById,
  closeCirculars,
  addAdblocksToQueueByCircular,
  resetCirculars,
  relayCicular,
  deleteCircularById,
  getPageCrops,
  getAdBlockById,
  getPageByObjId,
  getCircularByObjID,
  resetPage
} from "api";
import { Button, Alert, MenuDropDown } from "components";
import { Progress, Card, PageDropDown } from "./components";
import { useParams, navigate } from "@reach/router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { isJSON, formatDateTime, setCachedImage, getCachedImage } from "utils";
import { useInput, useModal, useOutsideClick } from "hooks";
import { format } from "date-fns";
import { AdblockInfo } from "./components/AdblockInfo";
import { Adblock } from "./components/Adblock";
import { PageView } from "./components/PageView";
import * as fabric from 'fabric'; 
import { useAuthContext } from "contexts";
import { DeleteCircularModal } from "modals";
import { MdError } from "react-icons/md";

const OUTLINE_COLORS = ["blue", "red", "yellow"];

export const CircularDetailPage = () => {
  const params = useParams();
  const template = useInput("");

  //Loading States
  const [isLoading, setIsLoading] = useState(true);
  const [isClosing, setIsClosing] = useState(false);
  const [isRequeuing, setIsRequeuing] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [isRelaying, setIsRelaying] = useState(false);
  const [isAdBlockLoading, setIsAdBlockLoading] = useState(true);

  //Circular data
  const [pages, setPages] = useState([]);
  const [pageType, setpageType] = useState("");
  const [circularName, setcircularName] = useState("");
  const [currentCircular, setCurrentCircular] = useState("");
  const [circularStats, setCircularStats] = useState({
    num_adblocks: 0,
    num_adblocks_completed: 0,
    num_adblocks_do_not_enter: 0,
    num_adblocks_skipped: 0,
    num_pages: 0,
    num_pages_completed: 0,
    num_offers_approved: 0,
    num_offers_rejected: 0,
    num_products_approved: 0,
    num_products_rejected: 0,
  });
  const [circularId, setCircularId] = useState("");
  //const [circularApproved, setCircularApproved] = useState(false);
  const [stage, setStage] = useState("");
  const { userInfo } = useAuthContext();
  const circularErrorMessageRef = useRef(null);

  // Page Data
  const [pageData, setPageData] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [pageStats, setPageStats] = useState({});
  const [pageId, setPageId] = useState("");
  const [parentPageData, setparentPageData] = useState([]);
  const [parentPageCircularData, setparentPageCircularData] = useState([]);
  const blockRef = useRef();

  // Adblocks Data
  const [adblocksData, setAdblocksData] = useState([]);
  const [adblockData, setAdblockData] = useState([]);
  const [currentAdblockId, setCurrentAdblockId] = useState(0);
  const [currentOutlineColor, setCurrentOutlineColor] = useState(
    OUTLINE_COLORS[0]
  );

  // Canvas Data
  const [canvas, setCanvas] = useState("");

  //Modals
  const validationModal = useModal();
  const deleteCircularModal = useModal();
  var started = false;
  var x = 0;
  var y = 0;

  // Error Data
  const [isErrored, setIsErrored] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [showCircularErrorMessage, setShowCircularErrorMessage] = useState(false);

  if (userInfo && !userInfo.user.is_superuser && !userInfo.user.is_moderator && !userInfo.user.is_active) {
    navigate("/home");
  }

  const init = () => {
    const canvi = new fabric.Canvas("canvas", {
      height: blockRef.current.offsetHeight,
      width: blockRef.current.offsetWidth,
      fireRightClick: true, // <-- enable firing of right click events
      stopContextMenu: true, // <--  prevent context menu from showing
    });

    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = "white";
    fabric.Object.prototype.cornerStrokeColor = currentOutlineColor;
    fabric.Object.prototype.cornerStyle = "circle";
    fabric.Object.prototype.cornerSize = 8;
    // zoom when mouse wheel
    canvi.on("mouse:wheel", function (opt) {
      var delta = opt.e.deltaY;
      var zoom = canvi.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvi.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
      var vpt = this.viewportTransform;
      if (canvi.getHeight() > blockRef.current.offsetHeight) {
        vpt[4] = 200 - (1000 * zoom) / 2;
        vpt[5] = 200 - (1000 * zoom) / 2;
      }
    });
    // pan when alt key & mouse down
    canvi.on("mouse:down", function (opt) {
      const evt = opt.e;
      if (evt.altKey === true) {
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      }
    });

    canvi.on("mouse:move", function (opt) {
      if (this.isDragging) {
        var e = opt.e;
        var vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      } else if (this.isCreating) {
        // Resize the newly created crop while mouse draging
        if (!started) {
          return false;
        }

        let mouse = canvi.getPointer(opt.e);

        let w = mouse.x - x,
          h = mouse.y - y;

        if (!w || !h) {
          return false;
        }

        let rect = canvi.getActiveObject();
        if (rect) {
          if (opt.e.shiftKey) {
            rect.set("width", Math.min(w, h)).set("height", Math.min(w, h));
          } else {
            rect.set("width", w).set("height", h);
          }
          rect.setControlsVisibility({ mtr: false });
        }
        canvi.renderAll();
      }
    });
    canvi.on("mouse:up", function (opt) {
      this.setViewportTransform(this.viewportTransform);
      this.isDragging = false;
      this.selection = true;
      this.isCreating = false;
    });
    canvi.on("selection:created", function (opt) {
      setCurrentAdblockId(opt.selected[0].id);
    });
    canvi.on("selection:updated", function (opt) {
      setCurrentAdblockId(opt.selected[0].id);
    });
    canvi.on("selection:cleared", function (opt) {
      setCurrentAdblockId("");
    });
    setCanvas(canvi);
  };

  useEffect(() => {
    init();

    return () => {};
  }, []);

  const initCirculars = async () => {
    setIsLoading(true);

    const res = await getCircularById(circularId);
    if (res.success) {
      //setCircularApproved(res.payload.approved);
      setCurrentCircular(res.payload);
      setPageData(res.payload.pages);

      var circularStats = {
        num_adblocks: res.payload.num_adblocks ? res.payload.num_adblocks : 0,
        num_adblocks_completed: res.payload.num_adblocks_completed
          ? res.payload.num_adblocks_completed
          : 0,
        num_adblocks_do_not_enter: res.payload.num_adblocks_do_not_enter
          ? res.payload.num_adblocks_do_not_enter
          : 0,
        num_adblocks_skipped: res.payload.num_adblocks_skipped
          ? res.payload.num_adblocks_skipped
          : 0,

        num_pages: res.payload.num_pages ? res.payload.num_pages : 0,
        num_pages_completed: res.payload.num_pages_completed
          ? res.payload.num_pages_completed
          : 0,

        num_rejected_adblocks: res.payload.num_rejected_adblocks
          ? res.payload.num_rejected_adblocks
          : 0,

        num_rejected_pages: res.payload.num_rejected_pages
          ? res.payload.num_rejected_pages
          : 0,

        num_offers_approved: res.payload.num_offers_approved
          ? res.payload.num_offers_approved
          : 0,
        num_offers_rejected: res.payload.num_offers_rejected
          ? res.payload.num_offers_rejected
          : 0,

        num_products_approved: res.payload.num_products_approved
          ? res.payload.num_products_approved
          : 0,
        num_products_rejected: res.payload.num_products_rejected
          ? res.payload.num_products_rejected
          : 0,
      };
      //console.log('circularStats', circularStats);
      setCircularStats(circularStats);

      var name =
        res.payload.retailer.name +
        "_" +
        format(
          new Date(
            res.payload.circ_week_dt.replace(/-/g, "/").replace(/T.+/, "")
          ),
          "MM/dd/yyyy"
        );
      setcircularName(name);

      setStage(res.payload.stage);
      setPages(res.payload.pages);

      if (params.page === "0") {
        if (res.payload.pages[0]?.uuid) {
          setPageId(res.payload.pages[0].uuid);
        } else {
          setIsErrored(true);
          setIsLoading(false);
        }
      } else {
        setPageId(params.page);
      }
    } else {
      //System error message
      // 422 Error Handling to tackle infinite page loads
      if (res.error?.response?.status === 422){
        setIsErrored(true);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (params?.id !== "0") {
      setCircularId(params?.id);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (circularId) {
      initCirculars();
    }
    return () => {};
  }, [circularId]);

  useEffect(() => {
    if (currentAdblockId) {
      updateAdBlockData(currentAdblockId);
    }
    return () => {};
  }, [currentAdblockId]);

  const sortAdblocks = (a,b)=> (b.rejected - a.rejected || a.is_dupe - b.is_dupe);

  const loadPage = async () => {
    setIsAdBlockLoading(true);
    if (pageId !== "0" && pageId) {
      const page = pageData.find((item) => item.uuid == pageId);
      setCurrentPageData(page);
      const page_type = PAGE_TYPES.find((item) => item.page_c === page.page_loc);
      setpageType(page_type.page_nm);
      const tempPageStats = {
        num_adblocks: page?.num_adblocks ? page?.num_adblocks : 0,
        num_adblocks_completed: page?.num_adblocks_completed
          ? page?.num_adblocks_completed
          : 0,
        num_adblocks_do_not_enter: page?.num_adblocks_do_not_enter
          ? page?.num_adblocks_do_not_enter
          : 0,
        num_adblocks_skipped: page?.num_adblocks_skipped
          ? page?.num_adblocks_skipped
          : 0,

        num_pages: page?.num_pages ? page?.num_pages : 0,
        num_pages_completed: page?.num_pages_completed
          ? page?.num_pages_completed
          : 0,

        num_rejected_adblocks: page?.num_rejected_adblocks
          ? page?.num_rejected_adblocks
          : 0,

        num_offers_approved: page?.num_offers_approved
          ? page?.num_offers_approved
          : 0,
        num_offers_rejected: page?.num_offers_rejected
          ? page?.num_offers_rejected
          : 0,

        num_products_approved: page?.num_products_approved
          ? page?.num_products_approved
          : 0,
        num_products_rejected: page?.num_products_rejected
          ? page?.num_products_rejected
          : 0,
        is_dupe: page?.is_dupe
          ? page?.is_dupe
          : null
      };

      setPageStats(tempPageStats);

      if(page.dupe_of) {
        const parent_page = await getPageByObjId(page.dupe_of);
        if(parent_page.success) {
          console.log('parent_page', parent_page.payload);
          setparentPageData(parent_page.payload);

          const parent_page_circular = await getCircularByObjID(parent_page.payload.circular_id);
          if(parent_page_circular.success) {
            console.log('parent_page circular', parent_page_circular.payload);
            setparentPageCircularData(parent_page_circular.payload);
          }else{
            //Error 
          }       

        }else{
          //Error 
        }
      }

      const res = await getPageCrops(pageId);
      if (res.success) {

        res.payload.adblocks.sort(sortAdblocks)
        if (res.payload.adblocks){
          setAdblocksData(res.payload.adblocks);
        }
        if (params.adblock){
          setCurrentAdblockId(params.adblock);
        } else if (res.payload.adblocks.length > 0) {
          setCurrentAdblockId(res.payload.adblocks[0].uuid);
        }
      } else {
        setAdblocksData([]);
      }


      if (page && page?.filename) {

        let filename = page?.filename;
        setErrorMessage(null);
        if(page.is_doubletruck) {
          filename = page.doubletruck_filename;
        }
        
        let img;
        const cachedImage = await getCachedImage(filename);
        if (!cachedImage) {
          img = await getImage(filename);
          if (img.success)
            await setCachedImage(filename, img.payload)
        }
        if (img || cachedImage) {
          canvas.clear();
          canvas.zoomToPoint({ x: 0, y: 0 }, 1);
          let vpt = canvas.viewportTransform;
          vpt[4] = 0;
          vpt[5] = 0;

          var background = new Image();
          background.onload = async function () {
            var f_img = new fabric.Image(background);
            f_img.set({
              scaleX: canvas.height / background.height,
              scaleY: canvas.height / background.height,
            });
            
            // Set the background image directly
            canvas.backgroundImage = f_img;
            console.log("adblock data", res.payload.adblocks);
            drawAdblocks(
              canvas.height / background.height,
              res.payload.adblocks
            );
          };

          background.src = "data:image/png;base64," + (cachedImage ? cachedImage : img.payload);
        }
        
        if (pageId && res.payload.adblocks){
          var isAdblockParamValid = res.payload.adblocks.some(item => item.uuid === params.adblock)
          if (params.adblock && isAdblockParamValid) {
            navigate(`/circular/${params.id}/${pageId}/${params.adblock}`);
            setCurrentAdblockId(params.adblock)
          } else {
            navigate(`/circular/${params.id}/${pageId}`);
            if (res.payload.adblocks.length > 0)
              setCurrentAdblockId(res.payload.adblocks[0].uuid)
          }
        }
      } else {
        canvas.clear();
        setErrorMessage('This page needs to be redownloaded!');
        navigate(`/circular/${params.id}/${page?.uuid}`);
      }
      setIsLoading(false);
    }


  };
  useEffect(() => {
    if (pageId !== ''){
      loadPage();
    }

    return () => {};
  }, [pageId]);

  function deleteFromList(selected) {
    //Find the object in the list, create new list
    let newAdblockData = adblocksData.filter((item) => item.uuid !== selected.uuid);
    newAdblockData.sort(sortAdblocks)
    setAdblocksData(newAdblockData);
  }

  async function handleListDelete(selected) {

    const result = await deleteAdBlockById(selected.uuid);
    if (result.success) {

      deleteFromList(selected);

    } else {
      //TODO: failed to delete adblock
    }

  }


  const drawAdblocks = (canvasRatio, adBlockData) => {
    // Draw block rect data if available
    if (adBlockData.length > 0) {
      adBlockData.forEach((adblock) => {
        const rect = new fabric.Rect({
          left: adblock.coords_x1 * canvasRatio,
          top: adblock.coords_y1 * canvasRatio,
          height: (adblock.coords_y2 - adblock.coords_y1) * canvasRatio,
          width: (adblock.coords_x2 - adblock.coords_x1) * canvasRatio,
          stroke: currentOutlineColor,
          strokeWidth: 1,
          fill: "transparent",
          cornerColor: "white",
          cornerStrokeColor: currentOutlineColor,
          id: adblock.uuid,
        });
        rect.setControlsVisibility({ mtr: false });
        canvas.add(rect);
      });
      canvas.renderAll();
      setCanvas(canvas);
    }
  };

  const handleCloseCircular = async () => {
    setIsClosing(true);
    const res = await closeCirculars(circularId);
    if (res.success) {
      setIsClosing(false);
      window.location.reload();
    } else {
      setIsClosing(false);
      // Error Message
    }
   
  };

  const handleRequeueCircular = async (high_priority = false) => {
    setIsRequeuing(true);
    const result = await addAdblocksToQueueByCircular(circularId, high_priority);
    if(result.success) {
      setTimeout(() => {setIsRequeuing(false)}, 3000);
    }else{
      // Error message
      setIsRequeuing(false);
    }
  };

  const handleResetCircular = async () => {
    setIsResetting(true);
    const result = await resetCirculars(circularId);
    if (result.success) {
      setTimeout(() => { setIsResetting(false) }, 3000);
    } else {
      setIsResetting(false);
      // Error message
    }
  }

  const handleResetPage = async () => {
    setIsResetting(true);
    const result = await resetPage(currentPageData.uuid);
    if (result.success) {
      setTimeout(() => { setIsResetting(false) }, 3000);
    } else {
      setIsResetting(false);
      // Error message
    }
  }

  const handleDevRelayCircular = async () => {
    setIsRelaying(true);
    const result = await relayCicular(circularId, false);
    if(result.success) {
      setTimeout(() => {setIsRelaying(false)}, 3000);
    }else{
      // Error message
      setIsRelaying(false);
    }
  };

  const handleStagingRelayCircular = async () => {
    setIsRelaying(true);
    const result = await relayCicular(circularId, true);
    if(result.success) {
      setTimeout(() => {setIsRelaying(false)}, 3000);
    }else{
      // Error message
      setIsRelaying(false);
    }
  };

  const handleDeleteCircular = async () => {
    setIsLoading(true);
    const result = await deleteCircularById(circularId)
    if (result.success) {
      setTimeout(() => {
        setIsLoading(false)
        navigate("/circular");
      }, 3000);
    } else {
      setIsLoading(false);
    }
  }

  const onPageSelect = (uuid, data, item) => {
    canvas.clear();
    setIsLoading(true);
    setPageId(uuid);
    setAdblockData([]);
    setpageType("");
  };

  const handleBackNavigation = () => {
    navigate("/circular");
  };

  const handlePickColor = async (color) => {
    setCurrentOutlineColor(color);
    canvas.getObjects().map(function (o) {
      return o.set({
        stroke: color,
        cornerStrokeColor: color,
      });
    });
    canvas.requestRenderAll();
  };

  const handleAdblockSelect = async (uuid, index) => {
    if (index < canvas.getObjects().length) {
      setCurrentAdblockId(uuid);
      navigate(`/circular/${params.id}/${pageId}/${uuid}`);
      canvas.setActiveObject(canvas.item(index));
      canvas.requestRenderAll();
    }
  };

  const updateAdBlockData = async (id) => {
    setIsAdBlockLoading(true);
    if (id) {
      const res = await getAdBlockById(id);
      if (res.success) {
        setAdblockData(res.payload);
      }
    }
    setIsAdBlockLoading(false);
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useOutsideClick(circularErrorMessageRef, () => {
    setShowCircularErrorMessage(false);
  })

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout>
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <div className="my-auto d-flex" style={{ width: 1250 }}>

                <Circulars className="ml-1" onClick={handleBackNavigation}>
                  Circulars
                </Circulars>

                <CircularName className="ml-2 my-auto">
                  {currentCircular.circ_subtype} - {circularName} - {currentCircular.ext_id} - {currentCircular?.circ_version ? currentCircular?.circ_version : 0} - {currentCircular.retailer.tier_ext_id ? currentCircular?.circ_version : 'T0'} 
                </CircularName>
                {currentCircular?.has_errors &&
                  <div style={{display: "inherit"}}
                    ref={circularErrorMessageRef}>
                    <MdError
                      className="ml-2 my-auto cursor-pointer"
                      size='1.25em'
                      onClick={() => setShowCircularErrorMessage(!showCircularErrorMessage)}
                    />
                    {
                      showCircularErrorMessage &&
                      <ErrorMessage>
                        <ErrorMessageText>
                          {currentCircular?.notes}
                        </ErrorMessageText>
                      </ErrorMessage>
                    }
                  </div>
                }
                
                <PageDropDown
                  className="ml-3 dropdownWidth"
                  data={pages}
                  onSelectValue={onPageSelect}
                  value={pageId}
                  isVisible={userInfo?.user?.is_superuser || userInfo?.user?.is_moderator || userInfo?.user?.is_active}
                />
                  <PageType className="ml-2 my-auto">
                    {pageType}
                  </PageType>
              </div>
              
              <Button
                width="140px"
                buttonTheme="dark"
                size="small"
                className="ml-auto my-auto"
                onClick={() => openInNewTab(`/adblock/${circularId}/${pageId}`)}
              >
                AD CROPPING
              </Button>
              <Button
                width="120px"
                buttonTheme="dark"
                size="small"
                className="ml-2 my-auto"
                onClick={() => {
                  if(currentAdblockId) {
                    openInNewTab(
                      `/product/${circularId}/${pageId}/${currentAdblockId}`
                    )
                  }else{
                    openInNewTab(
                      `/product/${circularId}/${pageId}/0`
                    )
                  }
                }}
              >
                PRODUCTS
              </Button>
              <Button
                width="180px"
                buttonTheme="dark"
                size="small"
                className="ml-2 my-auto"
                onClick={() => {
                  if(currentAdblockId) {
                    openInNewTab(
                      `/offer/${circularId}/${pageId}/${currentAdblockId}`
                    )
                  }else{
                    openInNewTab(
                      `/offer/${circularId}/${pageId}/0`
                    )
                  }
                  }}
              >
                OFFERS/INCENTIVES
              </Button>

              <MenuDropDown
                title="ACTIONS"
                onRequeue={() => handleRequeueCircular(false)}
                onHPRequeue={() => handleRequeueCircular(true)}
                onReset={handleResetCircular}
                onClose={handleCloseCircular}
                onDevRelay={handleDevRelayCircular}
                onStagingRelay={handleStagingRelayCircular}
                onDeleteCircular={() => deleteCircularModal.openModal()}
                onPageReset={handleResetPage}
                disabled={isRequeuing || isClosing || isResetting || isRelaying || isLoading}
              />

            </div>
          )}
        </Header>

        <MainContent>
          <PageView
            title="PAGE VIEW"
            className="flex-1"
            activeColor={currentOutlineColor}
            onSelectColor={handlePickColor}
            currentPageData={currentPageData}
            parentPageData={parentPageData}
            parentPageCircularData={parentPageCircularData}
          >
            <CanvasContainer className="w-100" ref={blockRef}>
              <div>
                {isLoading ? (
                    <Skeleton height={canvas.height} width={canvas.width} />
                ) : isErrored ? 
                    <div className="pt-4 mt-4 mx-auto text-center color-white">
                      {stage === 'ACCEPTED' ?
                        `No pages available for this circular. Please upload pages.`
                        : `Could not retrieve the pages. Please do a cleanup.`}
                    </div> :
                    errorMessage ?
                      <div className="pt-4 mt-4 mx-auto text-center color-white">
                        {errorMessage}
                      </div>
                      : ''
              }
              </div>
              <Canvas id="canvas" />
            </CanvasContainer>
          </PageView>
          <PageAdblocks title="PAGE ADBLOCKs" className="flex-1" count={adblocksData.length} isScrollable>
            {isLoading ? (
              <div>
                <Skeleton count={8} height={20} />
              </div>
            ) : adblocksData && adblocksData.length > 0  ? (
              adblocksData.map((item, index) => 
              // console.log(item.uuid, item.products_approved)
              (
                <Adblock
                  key={`${index}key`}
                  data={item}
                  onDelete={handleListDelete}
                  onClick={() => handleAdblockSelect(item.uuid, index)}
                  isActive={item.uuid === currentAdblockId}
                />
              )
              )
            )
             : (
              <div className="pt-4 mt-4 mx-auto text-center color-white">
                No Adblocks for this page.
              </div>
            )}
          </PageAdblocks>
          <div className="d-flex flex-column flex-1">
             <CircularInformation title="CIRCULAR" currentCircular={currentCircular} isCollapsable initCollapse={true}> 
              {isLoading ? (
                <div>
                  <Skeleton count={8} height={20} />
                </div>
              ) : (
                <>
                  <Progress
                    title="Pages Progress"
                    total={circularStats.num_pages}
                    value={circularStats.num_pages_completed}
                    className="mt-2"
                  />
                  <Progress
                    title="Adblocks Progress"
                    total={circularStats.num_adblocks}
                    value={circularStats.num_adblocks_completed}
                    className="mt-2"
                  />
                  <div className="d-flex">
                    <OtherStats>
                      No Enter: {circularStats.num_adblocks_do_not_enter}
                    </OtherStats>
                    <OtherStats>
                      Skipped: {circularStats.num_adblocks_skipped}
                    </OtherStats>
                    <OtherStats>
                      Rejected Pages: {circularStats.num_rejected_pages}
                    </OtherStats>
                    <OtherStats>
                      Rejected Adblocks: {circularStats.num_rejected_adblocks}
                    </OtherStats>
                  </div>
                  <Progress
                    title="Products Progress"
                    total={circularStats.num_adblocks}
                    value={
                      circularStats.num_products_approved +
                      circularStats.num_products_rejected +
                      circularStats.num_adblocks_do_not_enter +
                      circularStats.num_adblocks_skipped
                    }
                    className="mt-2"
                  />
                  <Progress
                    title="Offers Progress"
                    total={circularStats.num_adblocks}
                    value={
                      circularStats.num_offers_approved +
                      circularStats.num_offers_rejected +
                      circularStats.num_adblocks_do_not_enter +
                      circularStats.num_adblocks_skipped
                    }
                    className="mt-2"
                  />
                </>
              )}
            </CircularInformation>
            <PageInformation title="PAGE" currentPageData={currentPageData} isCollapsable>
              {isLoading ? (
                <div style={{
                  margin: 'auto',
                }}>
                  <ClipLoader color="#606060"  />
                </div>
              ) : (
                <>

                  <Progress
                    title="Adblocks Progress"
                    total={pageStats.num_adblocks}
                    value={pageStats.num_adblocks_completed}
                    className="mt-2"
                  />
                  <div className="d-flex">
                    {(currentPageData?.username
                        && (currentPageData?.crop_approved
                        || currentPageData?.rejected
                      )) &&
                      <OtherStats>
                        {currentPageData?.crop_approved ?
                            `Approved By`
                            : currentPageData?.rejected ?
                              `Rejected By `
                              : ''} {currentPageData.username ? 
                                currentPageData.username : 'Could not determine'
                              }
                      </OtherStats>
                    }
                    <OtherStats>
                      No Enter: {pageStats.num_adblocks_do_not_enter}
                    </OtherStats>
                    <OtherStats>
                      Skipped: {pageStats.num_adblocks_skipped}
                    </OtherStats>
                    <OtherStats>
                      Rejected: {pageStats.num_rejected_adblocks}
                    </OtherStats>
                    <OtherStats className="ml-auto text-right">
                      {formatDateTime(currentPageData.crop_approved_at)}
                    </OtherStats>
                  </div>
                  <Progress
                    title="Products Progress"
                    total={pageStats.num_adblocks}
                    value={
                      pageStats.num_products_approved +
                      pageStats.num_products_rejected +
                      pageStats.num_adblocks_skipped +
                      pageStats.num_adblocks_do_not_enter
                    }
                    className="mt-2"
                  />
                  <Progress
                    title="Offers Progress"
                    total={pageStats.num_adblocks}
                    value={
                      pageStats.num_offers_approved +
                      pageStats.num_offers_rejected +
                      pageStats.num_adblocks_skipped +
                      pageStats.num_adblocks_do_not_enter
                    }
                    className="mt-2"
                  />
                </>
              )}
            </PageInformation>
            <AdblockInfo
              data={adblockData}
              isLoading={isAdBlockLoading || isLoading}
              circularId={params.id}
              pageId={pageId}
              currentAdblockId={currentAdblockId}
            />
          </div>
        </MainContent>
        <Alert size="small" title="Validation" {...validationModal}>
          <div>{isJSON(template.value) ? "Success" : "Error"}</div>
          <div className="d-flex">
            <Button
              size="small"
              className="mt-2 ml-auto"
              width="120px"
              buttonTheme="dark"
              onClick={() => validationModal.onClose()}
            >
              OK
            </Button>
          </div>
        </Alert>
        <DeleteCircularModal
          {...deleteCircularModal}
          onDelete={handleDeleteCircular}
        />
      </MainLayout>
    </SkeletonTheme>
  );
};
const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  border-top: 3px solid ${(props) => props.theme.palette.canvas};
`;

const Header = styled.div`
  height: 48px;
  color: white;
  font-size: 16px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;

  .dropdownWidth {
    width: 340px;
  }
`;

const PageAdblocks = styled(Card)`
  margin: 0 2px;
  max-width: 320px;
`;

const CircularInformation = styled(Card)`
  margin: 0 2px;
`;

const PageInformation = styled(Card)`
  margin: 2px 2px 0 2px;
`;

const Circulars = styled.div`
  padding: 5px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const CircularName = styled.div`
  padding: 5px;
`;

const PageType = styled.div`
  padding: 5px;
`;

const OtherStats = styled.div`
  color: white;
  font-size: 14px;
  padding: 4px 10px;
  margin:0 8px 0 0;
  background-color: ${(props) => props.theme.palette.items};
`;

const CanvasContainer = styled.div`
  height: 100% !important;
  background-color: ${(props) => props.theme.palette.items};
`;
const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const ErrorMessage = styled.div`
  z-index: 2;
  padding: 8px 0;
  position: absolute;
  color: white;
  left: 25vw;
  top: 10px;
  background: #191919;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  /* line-height: 32px; */
  overflow: auto;
  border: 1px solid #2B2B2B;
  
`

const ErrorMessageText = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`
