export const isArrayContentEqual = (a, b) =>
    a.length === b.length &&
    [...new Set([...a, ...b])].every(
        v => a.filter(e => e === v).length === b.filter(e => e === v).length
    );

export const normalizeValue = (value) => {
    if (value === '' || value === null || value === undefined) {
        return '';
    }
    return value;
};
