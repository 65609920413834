import { openDB } from 'idb';


let dbPromise;

if (typeof indexedDB !== 'undefined') {
  dbPromise = openDB('image-cache-db', 1, {
    upgrade(db) {
      db.createObjectStore('images');
    },
  });
} else {
  dbPromise = Promise.resolve(null);
}

export const setCachedImage = async (id, base64Image) => {
  const db = await dbPromise;
  await db.put('images', base64Image, id);
};

export const getCachedImage = async (id) => {
  const db = await dbPromise;
  return await db.get('images', id);
};