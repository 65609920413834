/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2 } from "components";
import { useInput } from "hooks";
export const IncentiveCreditCard1TextBuilder = ({
  className,
  value = "",
  onChange = () => {},
}) => {
  const type = useInput("No Interest");
  const between = useInput("for");
  const val = useInput(12);
  const time = useInput("months");

  const typeData = [
    {
      id: "No Interest",
      title: "No Interest",
    },
    { id: "No Payments", title: "No Payments" },
    { id: "No Interest or Payments", title: "No Interest or Payments" },
    { id: "0% APR", title: "0% APR" },
  ];
  const typeData2 = [
    {
      id: "for",
      title: "for",
    },
    { id: "until", title: "until" },
  ];
  const timeData = [
    {
      id: "days",
      title: "days",
    },
    {
      id: "months",
      title: "months",
    },
    {
      id: "years",
      title: "years",
    },
  ];

  const handleUpdateValue = (t, b, v, ti) => {
    onChange(`${t} ${b} ${v} ${ti}  w/store credit card`);
  };

  useEffect(() => {
    if (value.endsWith("w/store credit card")) {
      let main = value.slice(0, -19).trim();
      const parts = main.split(" ");
      time.setValue(parts[parts.length - 1]);
      val.setValue(parts[parts.length - 2]);
      between.setValue(parts[parts.length - 3]);
      type.setValue(parts.slice(0, -3).join(" "));
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <div style={{ width: 100 }}>
          <DropDown2
            label="Type"
            data={typeData}
            width={100}
            value={type.value}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(e, between.value, val.value, time.value);
            }}
          />
        </div>
        <div
          className="d-flex flex-column"
          style={{ width: 60, marginLeft: 10 }}
        >
          <DropDown2
            label=""
            className="mt-auto"
            data={typeData2}
            width={60}
            value={between.value}
            onChange={(e) => {
              between.setValue(e);
              handleUpdateValue(type.value, e, val.value, time.value);
            }}
          />
        </div>
        <div style={{ width: 60, marginLeft: 20 }}>
          <Input2
            label="Value"
            value={val.value}
            type="float"
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(type.value, between.value, e, time.value);
            }}
          />
        </div>
        <div style={{ width: 70, marginLeft: 10 }}>
          <DropDown2
            label="Time"
            data={timeData}
            width={70}
            value={time.value}
            onChange={(e) => {
              time.setValue(e);
              handleUpdateValue(type.value, between.value, val.value, e);
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
