/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2 } from "components";
import { useInput } from "hooks";
export const OfferPurchaseTextBuilder = ({
  className,
  value,
  onChange = () => {},
}) => {
  const start = useInput("10.00");
  const end = useInput("");
  const suffix = useInput("");
  const typeData = [
    { id: "", title: "" },
    { id: "or more", title: "or more" },
    { id: "or less", title: "or less" },
  ];

  const handleUpdateValue = (s, e, suf) => {
    switch (suf) {
      case "or more":
        onChange(`w/purchase/$${s} ${suf}`);
        end.setValue("");
        break;
      case "or less":
        onChange(`w/purchase/$${s} ${suf}`);
        end.setValue("");
        break;
      case "":
        if (s && e) {
          onChange(`w/purchase/$${s}-${e}`);
          suffix.setValue("");
        } else if (s) {
          onChange(`w/purchase/$${s}`);
          end.setValue("");
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      value &&
      value.startsWith("w/purchase/$") &&
      value.slice(12).length > 0
    ) {
      const main = value.slice(12);
      if (main.endsWith("or more")) {
        suffix.setValue("or more");
        start.setValue(main.slice(0, main.length - 7));
        end.setValue("");
      } else if (main.endsWith("or less")) {
        suffix.setValue("or less");
        start.setValue(main.slice(0, main.length - 7));
        end.setValue("");
      } else {
        suffix.setValue("");
        if (main.indexOf("-") > 0) {
          const splitIdx = main.indexOf("-");
          start.setValue(main.slice(0, splitIdx));
          end.setValue(main.slice(splitIdx + 1));
          suffix.setValue("");
        } else {
          start.setValue(main);
          end.setValue("");
          suffix.setValue("");
        }
      }
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 p-2`}>
      <Title>Offer Text Builder</Title>
      <Container className="mt-2">
        <div className="mt-auto mb-1" style={{ fontSize: 13 }}>
          w/purchase/$
        </div>
        <div style={{ width: 80, marginRight: 10, marginLeft: 10 }}>
          <Input2
            label="Value Start"
            type="float"
            value={start.value}
            onChange={(e) => {
              start.setValue(e);
              handleUpdateValue(e, end.value, suffix.value);
            }}
          />
        </div>
        <div style={{ width: 80, marginRight: 10 }}>
          <Input2
            label="Value End"
            type="float"
            value={end.value}
            onChange={(e) => {
              end.setValue(e);
              handleUpdateValue(start.value, e, suffix.value);
            }}
          />
        </div>

        <div className="ml-auto" style={{ width: 80 }}>
          <DropDown2
            label="Suffix"
            data={typeData}
            width={80}
            value={suffix.value}
            onChange={(e) => {
              suffix.setValue(e);
              handleUpdateValue(start.value, end.value, e);
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
