/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal, Button } from "components";
import styled from "styled-components";

export const ApprovePageNoAdblockWarning = ({
  open,
  onClose,
  onApprove = () => { }
}) => {

  const handleCancel = () => {
    onClose();
  }

  const handleApprove = () => {
    onApprove();
    onClose();
  }

  return (
    <Modal open={open} onClose={onClose} size="medium" title={"Approve Page"}>
      <MessageStyle>
        <div>
          <div>Are you sure you want to Approve this Page?</div>
          <WarningMessage><WarningText>WARNING:</WarningText> This page does not have any ad crops!</WarningMessage>
          <div>On approving this page there won't be any ad crops attached to the page. Are you sure?</div>
        </div>
      </MessageStyle>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-4"
          type="button"
          onClick={() => handleApprove()}
        >
          Approve Anyway!
        </Button>
      </div>
    </Modal>
  );
};

const MessageStyle = styled.div`
    font-size:14px;
`;
const WarningMessage = styled.div`
    font-size:14px;
    padding:10px;
`;

const WarningText = styled.span`
  color:${(props) => props.theme.palette.warning};
`;
