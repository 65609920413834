import React from "react";
import styled from "styled-components";

export const Progress = ({ className, title, total = 0, value = 0 }) => {
  return (
    <div className={className}>
      <div className="d-flex">
        <Title>{title}</Title>
        <Value className="ml-2">{`${value} out of ${total}`}</Value>
      </div>
      <ProgressBar />
    </div>
  );
};

const Title = styled.div`
  font-size: ${(props) => props.theme.font.size.s};
  color: ${(props) => props.theme.palette.secondary};
`;

const Value = styled.div`
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 20px;
  margin-top: 8px;
  background: ${(props) => props.theme.palette.secondary};
  &::before {
    position: absolute;
  }
`;
