/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2, ToggleButton } from "components";
import { useInput } from "hooks";
import AutocompleteFreeText from "components/AutocompleteFreeText";
export const IncentiveAdditionalCouponTextBuilder = ({
  className,
  value = "",
  onChange = () => {},
}) => {
  const isUpTo = useInput(false);
  const val = useInput("2");
  const type = useInput("Coupon Available Through Sunday Paper");
  const isPurchase = useInput(false);
  const purVal = useInput("");
  const purType = useInput("");
  const suffix = useInput("");

  const typeData = [
    {
      id: "Coupon Available Through Sunday Paper",
      title: "Coupon Available Through Sunday Paper",
    },
    { id: "Video Value Coupon", title: "Video Value Coupon" },
    { id: "eSavers Coupon", title: "eSavers Coupon" },
  ];
  const typeData2 = [
    {
      id: "$",
      title: "$",
    },
    { id: "Unit", title: "Unit" },
  ];

  const handleUpdateValue = (isUT, v, t, isP, pv, pt, suf) => {
    onChange(
      `${isUT ? "Up  To" : ""}${v ? ` $${v}` : ""}${t ? ` ${t}` : ""}${
        isP ? ` w/purchase/${pt === "$" ? "$" : ""}${pv}` : ""
      }${suf ? ` ${suf}` : ""}`
    );
  };

  useEffect(() => {
    let main = value;
    if (value.startsWith("Up To")) {
      isUpTo.setValue(true);
      main = main.slice(5).trim();
    } else {
      isUpTo.setValue(false);
    }
    const purIndex = main.indexOf("w/purchase/");
    if (purIndex > 0) {
      const tv = main.slice(0, purIndex);
      const typeIndex = tv.indexOf(" ");
      val.setValue(tv.slice(0, typeIndex).length > 0 ? tv.slice(1).trim() : "");
      type.setValue(tv.slice(typeIndex).trim());
      main = main.slice(11);
      const sufIndex = main.indexOf(" ");
      const ptv = main.slice(0, sufIndex);
      if (ptv.startsWith("$")) {
        purType.setValue("$");
        purVal.setValue(ptv.slice(1));
      } else {
        purType.setValue("Unit");
        purVal.setValue(ptv);
      }
      suffix.setValue(main.slice(sufIndex).trim());
    }

    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <ToggleButton
          className="mt-auto mb-2"
          label="Up To"
          value={isUpTo.value}
          onChange={(e) => {
            isUpTo.setValue(e);
            handleUpdateValue(
              e,
              val.value,
              type.value,
              isPurchase.value,
              purVal.value,
              purType.value,
              suffix.value
            );
          }}
        />
        <div style={{ width: 50, marginLeft: 10 }}>
          <Input2
            label="Value"
            value={val.value}
            type="float"
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                e,
                type.value,
                isPurchase.value,
                purVal.value,
                purType.value,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 200, marginLeft: 10 }}>
          <AutocompleteFreeText
            label="Type"
            data={typeData}
            width={200}
            value={type.value}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                val.value,
                e,
                isPurchase.value,
                purVal.value,
                purType.value,
                suffix.value
              );
            }}
          />
        </div>
      </Container>
      <Container className="mt-1">
        <ToggleButton
          className="mt-auto mb-2"
          label="w/purchase"
          value={isPurchase.value}
          onChange={(e) => {
            isPurchase.setValue(e);
            handleUpdateValue(
              isUpTo.value,
              val.value,
              type.value,
              e,
              purVal.value,
              purType.value,
              suffix.value
            );
          }}
        />

        <div style={{ width: 60, marginLeft: 10 }}>
          <Input2
            label="Value"
            value={purVal.value}
            type="float"
            disabled={!isPurchase.value}
            onChange={(e) => {
              purVal.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                val.value,
                type.value,
                isPurchase.value,
                e,
                purType.value,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 70, marginLeft: 10 }}>
          <DropDown2
            label="Type"
            data={typeData2}
            width={70}
            disabled={!isPurchase.value}
            value={purType.value}
            onChange={(e) => {
              purType.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                val.value,
                type.value,
                isPurchase.value,
                purVal.value,
                e,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 80, marginLeft: 10 }}>
          <Input2
            label="Suffix"
            value={suffix.value}
            onChange={(e) => {
              suffix.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                val.value,
                type.value,
                isPurchase.value,
                purVal.value,
                purType.value,
                e
              );
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
