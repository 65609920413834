import {
  SET_LOADING,
  SET_ERROR,
  GET_CIRCULARS,
  GET_CIRCULARS_BY_ID,
  INIT_CIRCULARS,
  GET_MORE_CIRCULARS,
  SET_REFETCHING,
  SET_CIRCULARS_SEARCH,
  SET_CIRCULARS_FILTER,
} from "../types";

export default function JobReducer(state, action) {
  const { payload } = action;

  switch (action.type) {
    case INIT_CIRCULARS:
      return {
        currentPage: 0,
        circulars: [],
        isLoading: false,
        isRefetching: false,
        totalCount: 0,
        resultCount: 0,
        totalPages: 0,
        creationDateFrom: undefined,
        creationDateTo: undefined,
        circDateFrom: undefined,
        circDateTo: undefined,
        completeDateFrom: "",
        completeDateTo: "",
        retailerId: undefined,
        sortBy: "created_at",
        sortDir: "ASC",
        adBlocksCount : 0,
        activeRetailers: true,
      };
    case GET_CIRCULARS:
      return {
        ...state,
        circulars: payload.circulars,
        isLoading: false,
        isRefetching: false,
        totalCount: payload.total,
        resultCount: payload.circulars.length,
        totalPages: payload.pages,
        currentPage: 0,
        error: []
      };
      case GET_CIRCULARS_BY_ID:
        return {
          ...state,
          circulars: [payload],
          isLoading: false,
          isRefetching: false,
          totalCount: 1,
          resultCount: 1,
          totalPages: 1,
          currentPage: 0,
          error: []
        };
      case GET_MORE_CIRCULARS:
        return {
          ...state,
          circulars: [...state.circulars, ...payload.circulars],
          isRefetching: false,
          totalCount: payload.total,
          resultCount: payload.circulars.length,
          totalPages: payload.pages,
          currentPage: state.currentPage + 50,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload || true,
      };
    case SET_REFETCHING:
      return {
        ...state,
        isRefetching: payload || true,
      };
    case SET_CIRCULARS_SEARCH:
      return {
        ...state,
        query: payload,
      };
    case SET_CIRCULARS_FILTER:
      return {
        ...state,
        ...payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
        circulars: [],
      };
    default:
      return state;
  }
}
