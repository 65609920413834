import React, { useRef, useState } from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FaHourglass, FaInfoCircle, FaTrash } from "react-icons/fa";
import { useOutsideClick } from "hooks";
import { AiFillWarning } from "react-icons/ai";

export const AdblockItem = ({
  isLoading,
  data,
  index,
  onDelete,
  active = false,
  type = "adblock",
  onClick = () => {},
  isDeleting = false
}) => {
  const [showRejectMessage, setShowRejectMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const messageRef = useRef(null);
  const cropMessageRef = useRef(null);
  const handleDelete = (data, index) => {
    onDelete(data, index);
  };

  useOutsideClick(messageRef, () => {
    setShowRejectMessage(false);
  })
  useOutsideClick(cropMessageRef, () => {
    setShowMessage(false);
  })

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          <Skeleton height={32} />
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container status={data.rejected ? "rejected" : data.approved ? "approved" : null } type={active ? "active" : type} onClick={onClick}>
      <div className="inner">
        <AdblockTitle>
          {data.rejected ? "Rejected " : data.approved ? "Approved " : "" }
          {type === "adblock"
            ? "Adblock"
            : type === "page"
            ? "Page Incentive"
            : type === "circular"
            ? "Circular Incentive"
            : type === "rejected"
            ? "Rejected Adblock"
            : ""}{" "}
          {data?.index}
        </AdblockTitle>
        <AdblockUUID>{data?.uuid}</AdblockUUID>
          {!isDeleting ?
            <FaTrash
              className="deleteIcon"
              onClick={() => handleDelete(data, index)}
            /> :
            <FaHourglass 
              className="deleteIcon"
            />
          }
          <div ref={messageRef}>
            {data.rejected && data?.reject_message ?
              <FaInfoCircle
                className="rejectMessageIcon"
                onClick={() => setShowRejectMessage(!showRejectMessage)}
              />
              : ''}
            {showRejectMessage &&
              <RejectMessage>
                <RejectMessageText>
                  {data?.reject_message}
                </RejectMessageText>
              </RejectMessage>
            }
          </div>
          <div ref={cropMessageRef}>
            {data?.crop_message ?
              <AiFillWarning
                className="messageIcon"
                onClick={() => setShowMessage(!showMessage)}
                style={{
                  right: `${data?.reject_message ? '50px' : '30px'}`,
                }}
              />
              : ''}
            {showMessage &&
              <CropMessage>
                <RejectMessageText>
                The Crop Size is less than 20x20px viz. less than threshold
                </RejectMessageText>
              </CropMessage>
            }
          </div>
        </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  cursor: pointer;
  background: ${(props) => props.theme.palette.items};
  display: block;
  color: white;
  font-size: 12px;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid
    ${(props) =>
      props.type === "active" && props.status === "approved"
        ? props.theme.palette.success
        : props.type === "active" && props.status === "rejected"
        ? props.theme.palette.error
        : props.type === "active"
        ? props.theme.palette.primary
        : props.theme.palette.gray};
  border-left: 12px solid
    ${(props) =>
        props.status === "rejected"
        ? props.theme.palette.error
        : props.status === "approved"
        ? props.theme.palette.success
        : props.type === "active"
        ? props.theme.palette.primary
        : props.theme.palette.gray};

  margin: 3px 6px 3px 6px;
  border-radius: 2px;
  .inner {
    width: 100%;
    padding: 5px;
    height: 45px;
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    .deleteIcon {
      position: absolute;
      right: 10px;
      top: 5px;

      &:hover {
        background: ${(props) =>
          props.isLoading
            ? props.theme.palette.items
            : props.theme.palette.itemsHover};
      }
    }
    .rejectMessageIcon {
      position: absolute;
      right: 30px;
      top: 5px;
      font-size: 1.1em;

      &:hover {
        background: ${(props) =>
          props.isLoading
            ? props.theme.palette.items
            : props.theme.palette.itemsHover};
      }
    }
    .messageIcon {
      position: absolute;
      top: 5px;
      font-size: 1.2em;
      color: ${(props) => props.theme.palette.warning};
      background: ${(props) => props.isLoading};

      &:hover {
        background: ${(props) =>
          props.isLoading
            ? props.theme.palette.items
            : props.theme.palette.itemsHover};
      }
    }
  }
`;

const AdblockTitle = styled.div`
  font-size: 14px;
`;

const AdblockUUID = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.palette.secondary};
`;

const RejectMessage = styled.div`
  z-index: 2;
  right: 40px;
  padding: 8px 0;
  position: absolute;
  left: 0px;
  top: 24px;
  color: white;
  background: #191919;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  overflow-y: auto;
  line-height: 32px;
  overflow: auto;
  border: 1px solid #2B2B2B;
`

const RejectMessageText = styled.div`
  margin-left: 10px;
  text-transform: none;
`
const CropMessage = styled.div`
  z-index: 2;
  right: 40px;
  position: absolute;
  left: 0px;
  top: 24px;
  color: white;
  background: #191919;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  overflow-y: auto;
  line-height: 32px;
  overflow: auto;
  border: 1px solid #2B2B2B;
`
