import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as fabric from 'fabric';

export const AdBlockLocationView = () => {
  const ref = useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [clientWidth, setClientWidth] = useState(1);
  const [clientHeight, setClientHeight] = useState(1);
  const [data, setData] = useState(null);

  useEffect(() => {
    // init canvas when the component mounts
    setClientWidth(window.innerWidth);
    setClientHeight(window.innerHeight - 50);
    const canvi = new fabric.Canvas("minmap-canvas-windowed", {
      height: window.innerHeight - 50,
      width: window.innerWidth,
    });

    // zoom when mouse wheel
    canvi.on("mouse:wheel", function (opt) {
      var delta = opt.e.deltaY;
      var zoom = canvi.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvi.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
      var vpt = this.viewportTransform;
      if (canvi.getHeight() > ref.current.offsetHeight) {
        vpt[4] = 200 - (1000 * zoom) / 2;
        vpt[5] = 200 - (1000 * zoom) / 2;
      }
    });

    // pan when alt key & mouse down
    canvi.on("mouse:down", function (opt) {
      const evt = opt.e;
      this.isDragging = true;
      this.selection = false;
      this.lastPosX = evt.clientX;
      this.lastPosY = evt.clientY;
    });

    canvi.on("mouse:move", function (opt) {
      if (this.isDragging) {
        var e = opt.e;
        var vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      }
    });

    canvi.on("mouse:up", function (opt) {
      this.setViewportTransform(this.viewportTransform);
      this.isDragging = false;
      this.selection = true;
    });

    setCanvas(canvi);

    // cleanup canvas when the component unmounts
    return () => canvi.dispose();
  }, []);

  useEffect(() => {
    // listen for messages from the main window
    const messageListener = (event) => {
      if (event.data.type === "UPDATE_DATA") {
        setData(event.data.payload);
      }
    };

    window.addEventListener("message", messageListener);

    // remove event listener when the component unmounts
    return () => window.removeEventListener("message", messageListener);
  }, []);

  useEffect(() => {
    // update the canvas when new data is received
    if (canvas && data) {
      const { backImg, pageWidth, pageHeight, adblockCoords, sectionCoords } = data;
      if (
        canvas &&
        backImg &&
        pageWidth > 1 &&
        pageHeight > 1 &&
        (adblockCoords.coords_x1 > 0 ||
          adblockCoords.coords_x2 > 0 ||
          adblockCoords.coords_y1 > 0 ||
          adblockCoords.coords_y2 > 0)
      ) {
        let offsetX = 0,
          offsetY = 0,
          adblockWidth,
          adblockHeight;
        const clientRatio = clientWidth / clientHeight;
        const adblockRatio = pageWidth / pageHeight;
        if (adblockRatio < clientRatio) {
          offsetY = 0;
          offsetX = (clientWidth - clientHeight * adblockRatio) / 2;
          adblockWidth = clientHeight * adblockRatio;
          adblockHeight = clientHeight;
        } else {
          offsetY = (clientHeight - clientWidth / adblockRatio) / 2;
          offsetX = 0;
          adblockWidth = clientWidth;
          adblockHeight = clientHeight / adblockRatio;
        }

        const imgElement = new Image();
        imgElement.src = backImg;
        imgElement.onload = function () {
          const fabricImage = new fabric.Image(imgElement);
          fabricImage.set({
            top: offsetY,
            left: offsetX,
            originX: "left",
            originY: "top",
            scaleX: canvas.height / pageHeight,
            scaleY: canvas.height / pageHeight,
          });

          canvas.backgroundImage = fabricImage;
          canvas.renderAll();

          canvas.getObjects().forEach((obj) => canvas.remove(obj));
          const rect = new fabric.Rect({
            left: offsetX + adblockWidth * (adblockCoords.coords_x1 / pageWidth),
            top: offsetY + adblockHeight * (adblockCoords.coords_y1 / pageHeight),
            width:
              adblockWidth *
              ((adblockCoords.coords_x2 - adblockCoords.coords_x1) / pageWidth),
            height:
              adblockHeight *
              ((adblockCoords.coords_y2 - adblockCoords.coords_y1) / pageHeight),

            stroke: "red",
            strokeWidth: 2,
            fill: "transparent",
            cornerColor: "white",
            selectable: false,
          });
          rect.setControlsVisibility({ mtr: false });
          canvas.add(rect);

          // Auto zoom-in to the adblockCoords
          const zoomLevel = 3;

          // Calculate the center point of the adblock
          const centerX = offsetX + adblockWidth * ((adblockCoords.coords_x1 + adblockCoords.coords_x2) / (2 * pageWidth));
          const centerY = offsetY + adblockHeight * ((adblockCoords.coords_y1 + adblockCoords.coords_y2) / (2 * pageHeight));

          // Calc viewport transform to center the zoomed area
          const zoomToPoint = new fabric.Point(centerX, centerY);
          const vpt = canvas.viewportTransform;
          const newVpt = [zoomLevel, 0, 0, zoomLevel, 
                          canvas.width / 2 - zoomToPoint.x * zoomLevel, 
                          canvas.height / 2 - zoomToPoint.y * zoomLevel];
          canvas.setViewportTransform(newVpt);

          // Render Section
          if (sectionCoords.coords_x1 > 0 ||
            sectionCoords.coords_x2 > 0 ||
            sectionCoords.coords_y1 > 0 ||
            sectionCoords.coords_y2 > 0) {
            const sectionRect = new fabric.Rect({
              left: offsetX + adblockWidth * (sectionCoords.coords_x1 / pageWidth),
              top: offsetY + adblockHeight * (sectionCoords.coords_y1 / pageHeight),
              width:
                adblockWidth *
                ((sectionCoords.coords_x2 - sectionCoords.coords_x1) / pageWidth),
              height:
                adblockHeight *
                ((sectionCoords.coords_y2 - sectionCoords.coords_y1) / pageHeight),

              stroke: "red",
              strokeWidth: 2,
              fill: "rgba(256,0,0,0.1)",
              cornerColor: "white",
              selectable: false,
            });
            sectionRect.setControlsVisibility({ mtr: false });
            canvas.add(sectionRect);
          }
          canvas.renderAll();
        }
      }
      return () => { };
    }
  }, [canvas, data]);

  return (
    <Container>
      <Content ref={ref}>
        <Canvas id="minmap-canvas-windowed" />
      </Content>
    </Container>
  );
};


const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  color: ${(props) => props.theme.palette.white};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: ${(props) => props.theme.font.size.xm};
  width: 100%;
  display: "block";
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
`;
const Content = styled.div`
  overflow: auto;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
  cursor: move !important;
`;
