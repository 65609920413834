export const prepareCSVString = (data = [], options = {}) => {
  const { headerMappings = {}, headers: customHeaders = [] } = options;

  // Determine the headers: first from customHeaders[], then headerMappings{}, finally from data keys[]
  let headers = customHeaders.length > 0 ? customHeaders :
    Object.keys(headerMappings).length > 0 ? Object.keys(headerMappings)
      : data.length > 0 ? Object.keys(data[0]) : [];

  // Prepare the CSV string with the headers
  let csvString = headers.join(',') + '\n';

  data.forEach(obj => {
    let row = headers.map(header => {
      // Determine the correct key to use from the object
      const dataKey = headerMappings[header] || header;
      const value = obj[dataKey] === undefined ? '' : obj[dataKey];
      return `"${value ? value.toString().replace(/"/g, '""') : '-'}"`;
    }).join(',');
    csvString += row + '\n';
  });

  return csvString;
};