/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { useMainContext } from "contexts";

export const AdblockLocation = ({
  backImg,
  pageWidth = 0,
  pageHeight = 0,
  adblockCoords = { coords_x1: 0, coords_x2: 0, coords_y1: 0, coords_y2: 0 },
  sectionCoords = { coords_x1: 0, coords_x2: 0, coords_y1: 0, coords_y2: 0 },
  id = "Adblock Location",
}) => {
  const { postPageEvents } = useMainContext();
  const ref = useRef(null);
  const [clientWidth, setClientWidth] = useState(1);
  const [clientHeight, setClientHeight] = useState(1);
  const [adblockRect, setAdblockRect] = useState({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  });

  useEffect(() => {
    if (ref.current) {
      setClientWidth(ref.current.clientWidth - 24);
      setClientHeight(ref.current.clientHeight - 16);
    }
    return () => { };
  }, [ref]);

  useEffect(() => {
    if (
      backImg &&
      pageWidth > 1 &&
      pageHeight > 1 &&
      (adblockCoords.coords_x1 > 0 ||
        adblockCoords.coords_x2 > 0 ||
        adblockCoords.coords_y1 > 0 ||
        adblockCoords.coords_y2 > 0)
    ) {
      let offsetX = 0,
        offsetY = 0,
        adblockWidth,
        adblockHeight;
      const clientRatio = clientWidth / clientHeight;
      const adblockRatio = pageWidth / pageHeight;
      if (adblockRatio < clientRatio) {
        offsetY = 0;
        offsetX = (clientWidth - clientHeight * adblockRatio) / 2;
        adblockWidth = clientHeight * adblockRatio;
        adblockHeight = clientHeight;
      } else {
        offsetY = (clientHeight - clientWidth / adblockRatio) / 2;
        offsetX = 0;
        adblockWidth = clientWidth;
        adblockHeight = clientHeight / adblockRatio;
      }
      setAdblockRect({
        x: offsetX + adblockWidth * (adblockCoords.coords_x1 / pageWidth),
        y: offsetY + adblockHeight * (adblockCoords.coords_y1 / pageHeight),
        w:
          adblockWidth *
          ((adblockCoords.coords_x2 - adblockCoords.coords_x1) / pageWidth),
        h:
          adblockHeight *
          ((adblockCoords.coords_y2 - adblockCoords.coords_y1) / pageHeight),
      });
    }
    else {
      //unset red rectangle
      setAdblockRect({
        x: 0,
        y: 0,
        w: 0,
        h: 0,
      })
    }
    // auto update image and coords
    if (newWindowRef.current && !newWindowRef.current.closed) {
      updateChildWindow()
    }

    return () => { };
  }, [backImg, pageWidth, pageHeight, adblockCoords, sectionCoords]);

  // new window ref
  const newWindowRef = useRef(null);

  const openAdblockWindow = () => {
    // check if window exist and isn't closed somehow?
    if (newWindowRef.current && !newWindowRef.current.closed) {
      // send msg to existing window
      updateChildWindow();
    } else {
      // open new window
      newWindowRef.current = window.open(
        "/adblock-view-location",
        "AdBlockLocationView",
        `width=${screen.availWidth},height=${screen.availHeight}`
      );

      // send data when the window is first opened
      newWindowRef.current.onload = () => {
        updateChildWindow();
      };
    }
    postPageEvents("click", id);
  };

  const updateChildWindow = () => {
    // send msg to existing window
    newWindowRef.current.postMessage(
      {
        type: "UPDATE_DATA",
        payload: { backImg, pageWidth, pageHeight, adblockCoords, sectionCoords },
      },
      window.origin // current origin
    );
  };

  return (
    <Container>
      <Header>ADBLOCK LOCATION</Header>
      <Content ref={ref}>
        {backImg && (
          <img
            src={backImg}
            onClick={openAdblockWindow}
            alt="page back"
          />
        )}
        {adblockRect.w > 0 && adblockRect.h > 0 && <RedRect
          onClick={openAdblockWindow}
          {...adblockRect}
        />}
      </Content>
    </Container>
  );
};
const Container = styled.div`
  min-width: 240px;
  max-height: 35%;
  height: calc(100% - 440px);
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.palette.items};
  }
`;

const Header = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: calc(100% - 12px);
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const RedRect = styled.div`
  position: absolute;
  margin-top: ${(props) => props.y}px;
  margin-left: ${(props) => props.x}px;
  width: ${(props) => props.w}px;
  height: ${(props) => props.h}px;
  border: 2px solid red;
`;
