
import { Modal, Button, QuickSelection, OfferTextBuilder, OfferItem } from 'components'
import { OfferInfo } from 'fragments';
import { useMainContext } from 'contexts';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { getOfferTypesApi } from 'api';
import { extractTextBetweenFirstBalancedParenthesis, validateOffer } from 'utils';
import { OFFER_INIT_INFO } from 'config';
import { OFFER_INFO_FIELDS } from 'config';
import { FaPlus } from 'react-icons/fa';

export function CreateAdblockOffer({
  open,
  offerData,
  onClose,
  onSubmit = () => { },
}) {

  const [offerTypeData, setOfferTypeData] = useState([]);
  const [currentOfferIndex, setCurrentOfferIndex] = useState(0);
  const [offersList, setOffersList] = useState([]);
  const {
    currentInfo,
    updateInfo,
    updateInfos,
    setCurrentInfo,
    setFieldNames,
    setActiveField
  } = useMainContext();

  useEffect(() => {
    init();
    return () => {
      setCurrentInfo({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const index = offersList.length === 0 ? 0 : offersList.length - 1;
    if (Object.keys(currentInfo).length > 0) {
      let temp = [...offersList];
        temp[currentOfferIndex] = {
          ...currentInfo,
          index: index,
          isValid: validateOffer(currentInfo),
        };
      setOffersList(temp);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInfo]);
  
  useEffect(() => {
    if (offerData){
      let offersData = offerData.split(", ")
      let offers = []
      if(offersData.length > 0) {
        offers = offersData.map((item, index) => addOffer(item));
      }
      // console.log("opo", offers)
      setOffersList(offers)
      if (offers.length > 0) {
        // setCurrentInfo(offers[0]);
        setCurrentOfferIndex(0);
        setFieldNames(OFFER_INFO_FIELDS);
      }
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerData])


  const addOffer = (offer) => {
    const newIndex = offersList.length > 0 ? offersList[offersList.length - 1].index + 1 : 0;
        const newOffer = {
          ...OFFER_INIT_INFO,
          index: newIndex,
          isValid: true,
        };
        let text = offer;
        let offer_text = extractTextBetweenFirstBalancedParenthesis(text);
        let offer_type = 'Group'; // Temporary for existing Offer Text
        if (offer_text) {
          let index = offer_text.indexOf(" - ")
          offer_type = offer_text.slice(0, index).trim();
          offer_text = offer_text.slice(index + 2).trim();
          let reg_price = text.match(/\(RPS:(.+?)\)/);
          reg_price = Array.isArray(reg_price) ? reg_price[1] : ''
          let reg_price2_d = text.match(/\(RPS2:(.+?)\)/);
          reg_price2_d = Array.isArray(reg_price2_d) ? reg_price2_d[1] : ''
          let limit = text.match(/\(Limit:(.+?)\)/);
          limit = Array.isArray(limit) ? limit[1] : ''
          let min_qty_dollar_f = text.match(/\(MinQty:(.+?)\)/) ? true : false;
          let min_qty = min_qty_dollar_f ? text.match(/\(MinQty:(.+?)\)/) : null;
          min_qty = Array.isArray(min_qty) ? min_qty[1] : ''
          let must_buy_f = text.match(/\(MustBuy\)/) ? true : false;
          let qty_off_total_purchase_f = text.match(/\(OTP\)/) ? true : false;
          let mix_and_match_f = text.match(/\(MixAndMatch\)/) ? true : false;
          let include_in_net_cost_f = text.match(/\(NetCost\)/) ? true : false;
          let liner_f = text.match(/\(Liner\)/) ? true : false;
          newOffer.reg_price = reg_price;
          newOffer.reg_price2_d = reg_price2_d;
          newOffer.limit = limit;
          newOffer.min_qty_dollar_f = min_qty_dollar_f
          newOffer.min_qty = min_qty
          newOffer.must_buy_f = must_buy_f;
          newOffer.qty_off_total_purchase_f = qty_off_total_purchase_f;
          newOffer.mix_and_match_f = mix_and_match_f;
          newOffer.include_in_net_cost_f = include_in_net_cost_f;
          newOffer.liner_f = liner_f;
        } else {
          offer_text = text;
        }
        newOffer.offer_text = offer_text;
        newOffer.offer_type = offer_type;
        return newOffer;
      }


  const handleAddOffer = () => {
    const newIndex = offersList.length > 0 ? offersList[offersList.length - 1].index + 1 : 0;
    const newOffer = {
      ...OFFER_INIT_INFO,
      index: newIndex,
      isValid: false,
    };
    //We need to always have net cost and total purchase checked
    newOffer.include_in_net_cost_f = true;
    newOffer.qty_off_total_purchase_f = true;
    setOffersList([...offersList, newOffer]);
    setCurrentOfferIndex(newIndex);
    setCurrentInfo(newOffer);
    setFieldNames(OFFER_INFO_FIELDS);
  };

  const handleRemoveOffer = (index) => {
    const idx = offersList.findIndex((offer) => offer.index === index);
    const newOffersData = offersList
      .filter((item) => item?.index !== index)
      .map((item, index) => ({
        ...item,
        isValid: validateOffer(item),
        index,
      }));

    setOffersList(newOffersData);
    if (newOffersData.length === 0) {
      setCurrentInfo({});
      setCurrentOfferIndex(0);
    } else {
      const newIdx = newOffersData.length > idx ? idx : idx - 1;
      setCurrentOfferIndex(newOffersData[newIdx].index);
      setCurrentInfo(newOffersData[newIdx]);
    }
  };

  const handleCloneOffer = (event, index) => {
    event.stopPropagation();
    if (offersList.length > 0) {
      const newIndex = offersList[offersList.length - 1].index + 1;
      setOffersList([
        ...offersList,
        { ...currentInfo, index: newIndex, uuid: undefined },
      ]);
      setCurrentOfferIndex(newIndex);
    }
  };

  const handleOfferSelect = (index) => {
    setCurrentOfferIndex(index);
    setFieldNames(OFFER_INFO_FIELDS);
    setActiveField(OFFER_INFO_FIELDS[0]);
    setCurrentInfo(offersList[index]);
  };
  
  
  const handleCancel = () => {
    updateInfos({
      'offer_text': '',
      'offer_type': '',
      'reg_price': '',
      'reg_price2_d': '',
      'limit': '',
      'min_qty_dollar_f': '',
      'min_qty': '',
      'must_buy_f': '',
      'qty_off_total_purchase_f': true,
      'mix_and_match_f': '',
      'include_in_net_cost_f': true,
      'liner_f': ''
    })
    setOffersList([]);
    onSubmit('');
    onClose();
  };

  const handleSubmit = () => {
    let groupOffer = offersList.map((offer) => {
      const offerType = offer?.offer_type ? offer?.offer_type : ''
      const offerText = offer?.offer_text ? offer?.offer_text : ''
      const regPrice = offer?.reg_price ? "RPS:" + offer?.reg_price : ''
      const regPrice2 = offer?.reg_price2_d ? "RPS2:" + offer?.reg_price2_d : ''
      const limit = offer?.limit ? "Limit:" + offer?.limit : ''
      const min_qty_dollar = (offer?.min_qty_dollar_f && offer?.min_qty) ? "MinQty:" + offer?.min_qty : ''
      const must_buy = offer?.must_buy_f ? "MustBuy" : ''
      const qty_off_total_purchase = offer?.qty_off_total_purchase_f ? "OTP" : ''
      const mix_and_match = offer?.mix_and_match_f ? 'MixAndMatch' : ''
      const include_in_net_cost = offer?.include_in_net_cost_f ? 'NetCost' : ''
      const liner = offer?.liner_f ? 'Liner' : ''
      return `(${offerType} - ${offerText}) ${
        regPrice ? `(${regPrice}) ` : ''} ${
          regPrice2 ? `(${regPrice2}) ` : ''}${
            limit ? `(${limit}) ` : ''}${
                  min_qty_dollar ? `(${min_qty_dollar}) ` : ''}${
                    must_buy ? `(${must_buy}) ` : ''}${
                      qty_off_total_purchase ? `(${qty_off_total_purchase}) ` : ''}${
                        mix_and_match ? `(${mix_and_match}) ` : ''}${
                          include_in_net_cost ? `(${include_in_net_cost}) ` : ''}${
                            liner ? `(${liner}) ` : ''}`}
    )
    groupOffer = groupOffer.join(", ")
    onSubmit(groupOffer);
    onClose();
  }

  const init = async () => {
    const data = await getOfferTypesApi();
    setOfferTypeData(
      data.payload.map((item) => ({
        ...item,
        id: item.name,
        title: item.name,
      }))
    );

    // updateInfos({
    //   'include_in_net_cost_f': true,
    //   'qty_off_total_purchase_f': true,
    // })
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="large"
      title={"Add Adblock Offer"}
    >
        <div className="d-flex justify-content-between">
          GROUP OFFER 
          <AddItem onClick={handleAddOffer}>
            <FaPlus />
          </AddItem>
        </div>
        <ItemsList>
          {offersList.map((offer, index) => (
            <OfferItem
              key={`offer${index}`}
              className="d-flex flex-row"
              active={index === currentOfferIndex}
              onClick={() => handleOfferSelect(index)}
              onRemove={() => handleRemoveOffer(index)}
              onClone={handleCloneOffer}
              data={offer}
              index={index}
            />
          ))}
        </ItemsList>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: '500px'
      }}>
        <QuickSelection>
          <OfferTextBuilder
            type={currentInfo?.offer_type}
            value={currentInfo?.offer_text}
            onChange={(e) => {
              updateInfo("offer_text", e);
            }}
          />
        </QuickSelection>
        <OfferInfo 
          key={'offerinfo-'+currentOfferIndex} 
          offerTypeData={offerTypeData} 
          isLinerDisabled={true}
        />
      </div>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-4"
          type="button"
          disabled={!currentInfo?.offer_type || !currentInfo?.offer_text}
          onClick={() => handleSubmit()}
        >
          Confirm
        </Button>
      </div>
    </Modal >
  )
}

const ItemsList = styled.div`
  /* width: 100%; */
  overflow: auto;
  max-height: 120px;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const AddItem = styled.div`
  padding: 1px 2.5px 1px 2.5px;
  &:hover {
    background-color: grey;
  }
`