import React from "react";
import styled from "styled-components";
import { FaTrash } from "react-icons/fa";
import { splitIncentives } from "utils";

export const Adblock = ({ className, onClick, onDelete, isActive, data }) => {
  const handleDelete = (data) => {
    onDelete(data);
  };

  return (
    <Container className={className} onClick={onClick} isActive={isActive}>
      <FaTrash
        className="deleteIcon"
        onClick={() => handleDelete(data)}
      />
      {/* {console.log(data.uuid, data.products_approved)} */}
      {/* {data.length > 0 && */}
        <Prefix
          color={
            ((data?.rejected && !data?.skipped) || data?.do_not_enter_f || (data.is_dupe && data.dupe_adblock_uuid === null)
              ? "red"
              : data?.is_dupe && (data?.products_approved === false && data.offers_approved === false)
                ? "gray"
                : (data?.products_approved === true && data?.offers_approved === true) || (data.products_approved === true && data.incentive_nm)
                  ? "green"
                  : data?.products_approved === true || data?.offers_approved === true || data.incentive_nm || data.skipped
                    ? "yellow"
                    : "blue")
          }
        />
      {/* } */}
      <Content>

        <Type>
          {            
            (data.dupe_offers && data.dupe_products)
            ? "Duplicate"
            : data.dupe_products
            ? "Duplicate Product"
            : data.dupe_offers
            ? "Duplicate Offer"
            : ''
          }
          {            
            data.is_dupe && data.dupe_adblock_uuid === null
            ? " Errored"
            : ''
          }
        </Type>
        <Name>
          {
            data.skipped
            ? "Skipped"
            : data.do_not_enter_f
            ? "No Entry"
            : data.rejected
            ? "Rejected"
            : data.products_approved === false &&
              data.offers_approved === false &&
              !data.incentive_nm
            ? "Unstarted"
            : `${data.product_cnt ? data.product_cnt : 0} Products / ${
                data.offer_cnt ? data.offer_cnt : 0
              } Offers /${" "}
          ${
            data.incentive_nm ? splitIncentives(data.incentive_nm).length : 0
          }${" "}
          Incentives${" "}`}
        </Name>
        <Label>{data?.uuid}</Label>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  border: 1px solid
    ${(props) =>
      props.isActive
        ? props.theme.palette.primaryHover
        : props.theme.palette.backgrounds};
  cursor: pointer;
  margin: 1px 0;
  position: relative;

  .deleteIcon {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor:pointer;
    color: ${(props) => props.theme.palette.white};
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }


`;

const Prefix = styled.div`
  width: 8px;
  background: ${(props) =>
    props.color === "red"
    ? props.theme.palette.error
      : props.color === "green"
      ? props.theme.palette.success
      : props.color === "yellow"
      ? props.theme.palette.warning
      : props.color === "blue"
      ? props.theme.palette.primary
      : props.theme.palette.itemsHover};
`;
const Content = styled.div`
  flex: 1;
  padding: 4px;
  background: ${(props) => props.theme.palette.gray};
`;
const Name = styled.div`
  color: white;
  font-size: 14px;
`;
const Type = styled.div`
  color: gray;
  font-size: 10px;
`;
const Label = styled.div`
  color: gray;
  font-size: 10px;
`;
