import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useMainContext } from "contexts";

const StyledButton = styled.button`
  height: ${(props) =>
    props.size === "large"
      ? "60px"
      : props.size === "normal"
      ? "40px"
      : "32px"};
  line-height: ${(props) =>
    props.size === "large"
      ? "60px"
      : props.size === "normal"
      ? "40px"
      : "32px"};
  font-size: ${(props) =>
    props.size === "large"
      ? props.theme.font.size.xl
      : props.size === "large"
      ? props.theme.font.size.xm
      : props.theme.font.size.m};
  background: ${(props) =>
    props.disabled
      ? props.theme.palette.items
      : props.buttonTheme === "primary"
      ? props.theme.palette.primary
      : props.buttonTheme === "dark"
      ? props.theme.palette.items
      : props.buttonTheme === "green"
      ? props.theme.palette.success
      : props.buttonTheme === "warn"
      ? props.theme.palette.warning
      : props.buttonTheme === "error"
      ? props.theme.palette.error
      : ""};
  border: 1px solid
    ${(props) =>
      props.disabled
        ? props.theme.palette.gray
        : props.buttonTheme === "primary"
        ? props.theme.palette.primaryOutline
        : props.buttonTheme === "dark"
        ? props.theme.palette.gray
        : props.buttonTheme === "green"
        ? props.theme.palette.successOutline
        : props.buttonTheme === "warn"
        ? props.theme.palette.warnOutline
        : props.buttonTheme === "error"
        ? props.theme.palette.errorOutline
        : ""};
  width: ${(props) => props.width || "100%"};
  color: ${(props) =>
    props.disabled
      ? props.theme.palette.itemsHover
      : props.buttonTheme === "warn"
      ? props.theme.palette.backgrounds
      : props.theme.palette.white};

  text-align: center;
  padding: 0 12px;
  line-height: 8px;
  transition: all 300ms ease;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  &:hover {
    background: ${(props) =>
      props.disabled
        ? ""
        : props.buttonTheme === "dark"
        ? props.theme.palette.itemsHover
        : props.buttonTheme === "primary"
        ? props.theme.palette.primaryHover
        : ""};
  }
`;

export const Button = ({
  id = "",
  buttonTheme = "primary",
  size = "large",
  children,
  className,
  disabled,
  isLoading = false,
  eventType = "click",
  onClick = () => {},
  ...props
}) => {
  const { postPageEvents, pushPageEvents } = useMainContext();

  return (
    <StyledButton
      className={classNames(className, "ripple-effect")}
      buttonTheme={buttonTheme}
      size={size}
      disabled={disabled || isLoading}
      onClick={(e) => {
        postPageEvents(eventType, id || String(children));
        if (eventType === "flag") {
          pushPageEvents();
        }
        onClick(e);
      }}
      {...props}
    >
      {isLoading ? (
        <ScaleLoader
          color="white"
          height={
            size === "large"
              ? 32
              : size === "small" || size === "xsmall"
              ? 18
              : 24
          }
          width={
            size === "large" ? 4 : size === "small" || size === "xsmall" ? 2 : 2
          }
          radius={2}
          margin={
            size === "large" ? 3 : size === "small" || size === "xsmall" ? 2 : 2
          }
          css={`
            display: flex;
            justify-content: center;
            margin: auto;
            border-color: red;
            height: ${size === "large"
              ? "32px"
              : size === "small"
              ? "20px"
              : size === "xsmall"
              ? "14px"
              : "24px"};
          `}
        />
      ) : (
        children
      )}
    </StyledButton>
  );
};
