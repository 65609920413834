import React, { useState, useEffect, useRef }  from "react";
import styled from "styled-components";
import { Progress } from "components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { BsThreeDots } from "react-icons/bs";
import { format } from "date-fns";
import { Link } from "@reach/router";
import { useAuthContext } from "contexts";

import { CircularCloseModal } from "modals";
import { useModal, useOutsideClick } from "hooks";
import { MdError } from "react-icons/md";

export const SubTableRow = ({ 
  isCircularLoading, 
  data,
  retailer,
  tier,
  selectAll = false,
  onClick = () => {},
  onButtonClick = () => {},
  onQueueCircular = () => {}
 }) => {
  const [status, setStatus] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [isRequeuing, setIsRequeuing] = useState(false);
  const [isSelected, setSelected] = useState(false);
  const confirmClose = useModal();
  const { userInfo } = useAuthContext();
  const [showCircularErrorMessage, setShowCircularErrorMessage] = useState(false);
  const circularErrorMessageRef = useRef(null);

  const TextLinkColor = {
    color: "white",
  };

  const init = async () => {
    
    if(data?.num_rejected_pages > 0 || data?.num_rejected_adblocks > 0 ) {
      setStatus("error");
    }else if(data?.stage === "COMPLETE") {
      setStatus("complete");
    }else{
      setStatus("");
    }
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {

    if(selectAll === 'all') {
      setSelected(true);
    }else if(selectAll === 'none') {
      setSelected(false);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  const handleCloseCircular = async () => {
    onButtonClick(data.uuid);
  };

  const handleConfirmModal = async () => {
    confirmClose.openModal();
  };

  // const handleRequeue = async (hp=false) => {
  //   setIsRequeuing(true);
  //   setShowOptions(false);
  //   await onQueueCircular(data.uuid, hp)
  //   setTimeout(() => {setIsRequeuing(false)}, 3000);
  // }

  const handleSelection = () => {

    if(isSelected) {
      setSelected(false);
    }else{
      setSelected(true);
    }

    onClick(data);
    
  }

  useOutsideClick(circularErrorMessageRef, () => {
    setShowCircularErrorMessage(false);
  })

  return isCircularLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isCircularLoading>
        <div className="inner">
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container selected={isSelected} onClick={handleSelection} status={status}>
      <div className="inner">
        <RowItem>
            <Link to={`/circular/${data?.uuid}/0`} style={TextLinkColor}>
              {retailer}_{format(new Date(data.circ_week_dt.replace(/-/g, '/').replace(/T.+/, '')), "MM/dd/yyyy")} 
            </Link>
            <Info>
              {data?.ext_id} - {data?.circ_version ? data?.circ_version : 0}
              {data?.has_errors &&
                <div style={{ display: "inline-block" }}
                  ref={circularErrorMessageRef}>
                  <MdError
                    className="ml-2 mb-1 cursor-pointer text-white"
                    onClick={() => setShowCircularErrorMessage(!showCircularErrorMessage)}
                  />
                  {
                    showCircularErrorMessage &&
                    <ErrorMessage>
                      <ErrorMessageText>
                        {data?.notes}
                      </ErrorMessageText>
                    </ErrorMessage>
                  }
                </div>
              }
            </Info>
        </RowItem>
        <RowItem>
          <Progress data={data} />
        </RowItem>
        <RowItem>{data?.stage}</RowItem>
        <RowItem>
          {data?.num_pages_completed} / {data?.num_pages}  <span className="duplicate">({data?.num_dupe_pages !== null ? data?.num_dupe_pages : 0 })</span> <span className="rejected">({data?.num_rejected_pages !== null ? data?.num_rejected_pages : 0 })</span>
        </RowItem>
        <RowItem>
          {data?.num_adblocks_completed} / {data?.num_adblocks} <span className="duplicate">({data?.num_dupe_adblocks !== null ? data?.num_dupe_adblocks : 0 })</span> <span className="rejected">({data?.num_rejected_adblocks !== null ? data?.num_rejected_adblocks : 0 })</span>
        </RowItem>
        <RowItem>
          {tier}
        </RowItem>
        <RowItem
            onMouseEnter={() => setShowOptions(true)}
            onMouseLeave={() => setShowOptions(false)}
        >
          {isRequeuing ? <span>Requeuing</span> : 
          data.stage !== 'COMPLETE' ? (
            <>
            <IconButton className="my-auto">
            <BsThreeDots color="white" />
            </IconButton>
            {showOptions && (
              <ToggleText>
                {/* <TextLink onClick={() => handleRequeue(false)}>Requeue Circular</TextLink>
                <TextLink onClick={() => handleRequeue(true)}>HP Requeue Circular</TextLink> */}
                {userInfo.user?.is_superuser ? 
                  <TextLink onClick={handleConfirmModal}>Close Circular</TextLink>
                : null} 
              </ToggleText>
              )}
              </>
              ): null }
        </RowItem>
      </div>
      <CircularCloseModal
          {...confirmClose}
          circulars={[data]}
          onConfirm={handleCloseCircular}
        />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.items};
  display: block;
  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  border-left: ${(props) => props.selected ? '32px': '12px'} solid
    ${(props) =>
      props.status === "complete"
        ? props.theme.palette.success
        : props.status === "warning"
        ? props.theme.palette.warning
        : props.status === "inprocess"
        ? props.theme.palette.primaryOutline
        : props.status === "error"
        ? props.theme.palette.error
        : props.status === "none"
        ? props.theme.palette.white
        : props.theme.palette.secondary};

  margin: 3px 6px 3px 12px;
  border-radius: 2px;

  .inner {
    display: flex;
    cursor:pointer;
    width: 100%;
    padding: 0 0px 0 32px;
    height: 88px;
    transition: all 300ms ease;
    padding: ${(props) =>
      props.selected
        ? '0 0 0 12px;'
        : '0 0 0 32px;'};

    background: ${(props) =>
      props.selected
        ? props.theme.palette.gray
        : null};

    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      flex: 1;
    }
    & > div:nth-child(2) {
      width: 600px;
    }
    & > div:nth-child(3) {
      width: 200px;
    }
    & > div:nth-child(4) {
      width: 150px;
    }
    & > div:nth-child(5) {
      width: 150px;
    }
    & > div:nth-child(6) {
      width: 60px;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 16px auto 0;
  position:relative;
  span.duplicate {
    color:${(props) => props.theme.palette.warning};
  }
  span.rejected {
    color:${(props) => props.theme.palette.error};
  }
`;

const IconButton = styled.span`
  cursor: pointer;
  margin: 0 4px;
  position:absolute;
  right:0;
  top:-14px;
  &:hover {
    svg {
      transition: all 0.3s ease;
      fill: ${(props) => props.theme.palette.primary} !important;
    }
  }
`;

const ToggleText = styled.div`
  position: absolute;
  width: auto;
  white-space: nowrap;
  text-align: right;
  right:5px;
  color: white;
  font-size: 14px;
  background-color: ${(props) => props.theme.palette.items};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  .ocr-heading {
    color: gray;
  }
  z-index: 0;
  user-select: text;
`;

const TextLink = styled.div`
  cursor:pointer;
  margin:5px 0;
  padding:10px;
  &:hover {
    background-color: ${(props) => props.theme.palette.itemsHover};
  }
`;

const Info = styled.div`
  font-size:14px;
  color: ${(props) => props.theme.palette.secondary}
`;

const ErrorMessage = styled.div`
  z-index: 2;
  padding: 8px 0;
  position: absolute;
  color: white;
  background: #191919;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: auto;
  border: 1px solid #2B2B2B;
`

const ErrorMessageText = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`
