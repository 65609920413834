import React from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const SubTableRow = ({ 
  isLoading, 
  data
 }) => {

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container>
      <div className="inner">
      <RowItem>{data?.email}</RowItem>
      <RowItem>{data?.username}</RowItem>
      <RowItem>{data?.full_name}</RowItem>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.items};
  display: block;
  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  border-left: 12px solid
    ${(props) =>
      props.status === "complete"
        ? props.theme.palette.success
        : props.status === "warning"
        ? props.theme.palette.warning
        : props.status === "inprocess"
        ? props.theme.palette.primaryOutline
        : props.status === "error"
        ? props.theme.palette.error
        : props.status === "none"
        ? props.theme.palette.white
        : props.theme.palette.secondary};

  margin: 3px 6px 3px 12px;
  border-radius: 2px;
  .inner {
    display: flex;
    width: 100%;
    padding: 0 0px 0 32px;
    height: 88px;
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      flex: 1;
    }
    & > div:nth-child(2) {
      flex: 1;
    }
    & > div:nth-child(3) {
      flex: 1;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 16px auto 0;
  position:relative;
  span.duplicate {
    color:${(props) => props.theme.palette.warning};
  }
  span.rejected {
    color:${(props) => props.theme.palette.error};
  }
`;
