/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal, Button, Input2, DropDown2 } from "components";
import { postUpdateRetailer } from "api";
import { useMutation } from "react-query";
import styled from "styled-components";
import { ConfirmationModal } from "./commons/ConfirmationModal";
import { normalizeValue } from "utils";

export const RetailersModal = ({
    open,
    onClose,
    data,
    tiers
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const updateRetailer = useMutation(postUpdateRetailer);
    const [retailerName, setRetailerName] = useState('');
    const [loyaltyText, setLoyaltyText] = useState('');
    const [tier, setTier] = useState('');
    const [emailTier, setEmailTier] = useState('');
    const [newspaperTier, setNewspaperTier] = useState('');
    const [webTier, setWebTier] = useState('');
    const [mailerTier, setMailerTier] = useState('');
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');

    const handleOpenModal = () => {
        setConfirmationMessage("Blocks and Pages that have been in a tier won't be updated.");
        setConfirmationModalOpen(true);
      };
    
      const handleCancelConfirmation = () => {
        setConfirmationModalOpen(false);
      };
    
      const handleContinue = async () => {
        setConfirmationModalOpen(false);
        await updateRetailerFunc();
      };

    useEffect(() => {
        if (data?.name){
            setRetailerName(data.name)
        }
        if (data?.loyalty_text){
            setLoyaltyText(data.loyalty_text)
        }
        if (data?.tier_ext_id){
            setTier(data.tier_ext_id)
        }
        if (data?.email_tier_ext_id){
            setEmailTier(data.email_tier_ext_id)
        }
        if (data?.newspaper_tier_ext_id){
            setNewspaperTier(data.newspaper_tier_ext_id)
        }
        if (data?.web_tier_ext_id){
            setWebTier(data.web_tier_ext_id)
        }
        if (data?.mailer_tier_ext_id){
            setMailerTier(data.mailer_tier_ext_id)
        }
        return () => {}
    }, [data])

    const handleCancel = () => {
        onClose();
    };

    const handleSubmit = async (e) => {
        if (normalizeValue(data.tier_ext_id) !== tier ||
            normalizeValue(data.email_tier_ext_id) !== emailTier ||
            normalizeValue(data.newspaper_tier_ext_id) !== newspaperTier ||
            normalizeValue(data.web_tier_ext_id) !== webTier ||
            normalizeValue(data.mailer_tier_ext_id) !== mailerTier) {
            handleOpenModal(true);
        } else {
            e.stopPropagation();
            await updateRetailerFunc();
        }
    };

    const onTierUpdate = async (e) => {
        setTier(e);
    };
    
    const updateRetailerFunc = async () => {
        setIsLoading(true);
        const retailer_data = {
            name: retailerName,
            loyalty_text: loyaltyText,
            tier_ext_id: tier,
            email_tier_ext_id: emailTier,
            newspaper_tier_ext_id: newspaperTier,
            web_tier_ext_id: webTier,
            mailer_tier_ext_id: mailerTier,
        }
        const result = await updateRetailer.mutateAsync({
            id: data.id,
            data: retailer_data
        });
        if (result.success) {
            window.location.reload();
        }
        setIsLoading(false);
        onClose();
      }

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                size="medium"
                title={"Retailers Settings"}
            >
                <ModalInner>
                    <div>
                        <FieldWrapper>
                            <Input2
                                label="Rename Retailer"
                                placeholder="Loyalty Text"
                                value={retailerName}
                                onChange={e => setRetailerName(e)}
                            />
                        </FieldWrapper>

                        <FieldWrapper>
                            <DropDown2
                                label="Select Retailer Tier"
                                data={tiers}
                                width={120}
                                value={tier}
                                onChange={(e) => { onTierUpdate(e); }}
                            />
                        </FieldWrapper>
                        <TierFieldWrapper>
                            <DropDown2
                                label="Email Promo Tier"
                                data={tiers}
                                width={120}
                                value={emailTier}
                                onChange={(e) => { setEmailTier(e); }}
                            />
                            <DropDown2
                                label="Newspaper Tier"
                                data={tiers}
                                width={120}
                                value={newspaperTier}
                                onChange={(e) => { setNewspaperTier(e); }}
                            />
                            <DropDown2
                                label="Web Promo Tier"
                                data={tiers}
                                width={120}
                                value={webTier}
                                onChange={(e) => { setWebTier(e); }}
                            />
                            <DropDown2
                                label="Mailer Tier"
                                data={tiers}
                                width={120}
                                value={mailerTier}
                                onChange={(e) => { setMailerTier(e); }}
                            />
                        </TierFieldWrapper>
                        <FieldWrapper className="mt-2">
                            <Input2
                                label="Enter the loyalty text"
                                placeholder="Loyalty Text"
                                value={loyaltyText}
                                onChange={e => setLoyaltyText(e)}
                            />

                        </FieldWrapper>

                    </div>
                    <div className="d-flex" style={{
                        marginTop: '7%'
                    }}>
                        <Button
                            size="small"
                            buttonTheme="dark"
                            // width="fit-content"
                            className="ml-auto"
                            type="button"
                            onClick={() => handleCancel()}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            buttonTheme="primary"
                            // width="fit-content"
                            className="ml-4"
                            type="button"
                            isLoading={isLoading}
                            onClick={(e) => handleSubmit(e)}
                        >
                            Submit
                        </Button>
                    </div>
                </ModalInner>

            </Modal>
            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                msgBody={confirmationMessage}
                onContinue={handleContinue}
                onCancel={handleCancelConfirmation}
            ></ConfirmationModal>
        </>
    );
};

const ModalInner = styled.div`
  height:420px;
`;

const FieldWrapper = styled.div`
  padding: 15px 0;
`;

const TierFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
