/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useOutsideClick } from "hooks";
import styled from "styled-components";
import { 
  AiFillCaretDown, 
  AiFillCaretUp,
 } from "react-icons/ai";

 import {
  FaCheckCircle,
  FaStopCircle,
  FaArrowRight,
} from "react-icons/fa";

import { useMainContext } from "contexts";
import "./style.scss";

export const PageDropDown = ({
  value,
  onChange,
  className,
  backgroundColor = "#eee",
  placeholder = "Select a page",
  width = 320,
  onSelectValue = () => {},
  data,
  isVisible,
  readOnly = false,
}) => {
  const menuRef = useRef(null);
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { postPageEvents } = useMainContext();

  const redIcon = {
    color: "#ff8484",
  };
  const greenIcon = {
    color: "#63ce6b",
  };
  // const yellowIcon = {
  //   color: "#fff0a8",
  // };

  useOutsideClick(menuRef, clickOutsideMenu);
  function clickOutsideMenu() {
    setShowResults(false);
  }
  const handleChange = (params, data, page) => {
    onSelectValue(params, data, page);
    setShowResults(false);
  };

  const [focused, setFocused] = useState(false);

  const handleBlur = (event) => {
    setFocused(false);
  };

  const getClassByPageProp = (page) => {
    if (page?.is_dupe) {
      if (page?.parent_page_approved && !(page?.crop_approved))
        return 'parent-page-approved'
      else
        return 'variant-page'
    }
    else return ''
  }

  useEffect(() => {
    if (data) {
      setIsLoading(false);
      console.log("data", data);
    }
    return () => {};
  }, [data]);

  return (
    <div className={classNames(className, isVisible ? "" : "d-none")}>
      <div className="page-dropdown" style={{ width }}>
        <div className="menu-button" style={{ width }}>
          <div
            className="d-flex w-100"
            onClick={() => {
              !readOnly && setShowResults(true);
              postPageEvents("click", "page dropdown");
            }}
          >
            {isLoading ? (
              <StyledInput type="text" value={"Loading..."} readonly />
            ) : (
              <>
                <StyledInput
                  type="text"
                  aria-labelledby={"auto complete"}
                  onFocus={() => setFocused(true)}
                  onBlur={handleBlur}
                  aria-describedby={"auto complete"}
                  value={value}
                  placeholder={placeholder}
                  onChange={(e) => {}}
                  readonly
                />

                {showResults ? (
                  <AiFillCaretUp
                    color="white"
                    className="menu-button__drop-down"
                    alt="drop-down"
                  />
                ) : (
                  <AiFillCaretDown
                    color="white"
                    className="menu-button__drop-down"
                    alt="drop-down"
                  />
                )}
              </>
            )}
          </div>

          <Underline focused={focused} />
        </div>
        {showResults && (
          <div ref={menuRef} className="menu-dropdown">
            {isLoading ? (
              <LoadMore>Loading...</LoadMore>
            ) : data && data.length > 0 ? (
              data.map((item, index) => {
                return (
                  <div
                    className={classNames(
                      "menu-item",
                      item?.uuid === value ? "active" : ""
                    )}
                    key={`key${index}`}
                    onClick={() => {
                      handleChange(item.uuid, data, item);
                      postPageEvents("click", "Page DropDown");
                    }}
                  >
                    <span className={`page-number ${getClassByPageProp(item)}`}>{`${item?.page_num}`}</span>
                    <span>{`${item?.uuid}`}</span>
                    <span className="status">
                      {item?.completed  ? (
                        <FaCheckCircle style={greenIcon} />
                      ) : item?.rejected  ? (
                        <FaArrowRight style={redIcon} />
                      ) : item?.num_rejected_adblocks > 0 ? (
                        <FaStopCircle style={redIcon} />
                      ) : null}
                    </span>
                  </div>
                );
              })
            ) : (
              <div className="menu-item text-center">No pages</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const StyledInput = styled.input`
  background: transparent;
  color: white;
  border: none;
  height: 32px;
  line-height: 32px;
  width: calc(100% - 24px);
  font-size: 14px;
  outline: none;
  cursor: pointer;
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
`;

const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;

const LoadMore = styled.div`
  color: ${(props) => props.theme.palette.primaryOutline};
  cursor: pointer;
  transition: all 300ms ease;
  font-size: 16px;
  text-align: center;
  &:hover {
    opacity: 0.7;
  }
`;
