/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal } from "components";
import { useQuery } from "react-query";
import { getImage } from "api";
import styled from "styled-components";

export const ViewSectionModal = ({
  open,
  sectionData = '',
  onClose,
}) => {
  const { data } = useQuery(
    ['sectionImage', sectionData?.filename],
    async () => getImage(sectionData.filename),
    {
      enabled: sectionData?.filename ? true : false,
      initialData: { payload: '', success: false },
    })

  return (
    <Modal open={open} onClose={onClose} size="medium" title={"View Section"}>
      <div className="mt-1">
        {data.success ? (
          <Content>
            <div>
              <SectionImg
                src={`data:image/jpeg;base64,${data.payload}`}
                alt="sectionImage"
              />
            </div>
          </Content>
        ) : (
          <Content>
            Loading...
          </Content>
        )}
      </div>
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SectionImg = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: 50vh;
  user-select: none;
`;