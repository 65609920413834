import { Modal } from "components";
import styled from "styled-components";
import {
  ReportsSection,
  Button
} from "components";
import { MdShortcut } from "react-icons/md";
import { getLastWeekSumamryApi } from "api";

export const ReportsModal = ({
  open,
  onClose,
}) => {

  const getLastWeekSummary = async  () => {
    const result = await getLastWeekSumamryApi();
    if (result.success) {
      // Update adblocks
      const url = window.URL.createObjectURL(
        new Blob([result.payload], { type: "text/plain" })
      );
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = `weekly_summary_previous_week.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      //TODO: Show error message
    }
  }

  return (
    <Modal open={open} onClose={onClose} size="large" title={"Reports"}>
      <MessageStyle>
        <div style={{
          display: "flex",
          margin: 'auto',
          alignItems: 'center'
        }}>
          <div style={{
          }}>Select the type of report and please fill in the data as specific as possible for best results.</div>
          <Link
            // href="https://addison-dev-files.s((3.us-east-2.amazonaws.com/stats/prod/weekly_stats/weekly_stats_20231119_20231126.csv"
            onClick={getLastWeekSummary}
            target="_blank"
            className="ml-3 my-auto"
          >
            <Button
              width="220px"
              size="small"
              buttonTheme="dark"
              style={{
                marginLeft: '5%',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            ><MdShortcut />Last Weeks Weekly Stat</Button>
          </Link>
        </div>
      </MessageStyle>

      <ReportsSection isLoading={false} />

    </Modal>
  );
};

const Link = styled.a`
 &:hover {
    text-decoration: none;
  }
`

const MessageStyle = styled.div`
    font-size:14px;
    display: flex;
    width: 100%;
`;

