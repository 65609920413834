/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorageState } from "rooks";
import JobReducer from "./JobReducer";
import {
  SET_LOADING,
  INIT_JOBS,
  GET_JOBS,
  SET_ERROR,
  SET_REFETCHING,
  GET_MORE_JOBS,
  SET_JOBS_SEARCH,
  SET_JOBS_FILTER,
} from "./../types";
import { getCircularsApi } from "api";
const JobContext = createContext();
export const useJobContext = () => useContext(JobContext);
const STORAGE_KEY = "addison-job-state";

export const JobProvider = (props) => {
  const initialState = {
    query: "",
    status: [],
    jobs: [],
    totalCount: 0,
    resultCount: 0,
    totalPages: 0,
    currentPage: 1,
    isLoading: false,
    isRefetching: false,
    creationDateFrom: "",
    creationDateTo: "",
    completeDateFrom: "",
    completeDateTo: "",
  };

  const [value, set] = useLocalstorageState(STORAGE_KEY, initialState);

  const [state, dispatch] = useReducer(JobReducer, value);

  useEffect(() => {
    set(state);
    return () => {};
  }, [state]);

  const getJobs = async () => {
    setJobsLoading();
    const res = await getCircularsApi({
      page: 1,
      search: state?.query,
      status: (state?.status || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
      complete_time_from: state?.completeDateFrom,
      complete_time_to: state?.completeDateTo,
    });
    dispatch({
      type: res.success ? GET_JOBS : SET_ERROR,
      payload: res.payload,
    });
  };

  const loadMore = async () => {
    setJobsRefetching();
    const res = await getCircularsApi({
      page: state.currentPage + 1,
      search: state?.query,
      status: (state?.status || []).join(","),
      creation_time_from: state?.creationDateFrom,
      creation_time_to: state?.creationDateTo,
      complete_time_from: state?.completeDateFrom,
      complete_time_to: state?.completeDateTo,
    });
    dispatch({
      type: res.success ? GET_MORE_JOBS : SET_ERROR,
      payload: res.payload,
    });
  };

  const initJobs = async () => {
    dispatch({
      type: INIT_JOBS,
    });
  };

  // Set Loading
  const setJobsLoading = (params = true) =>
    dispatch({ type: SET_LOADING, payload: params });

  // Set Refetching
  const setJobsRefetching = (params = true) =>
    dispatch({ type: SET_REFETCHING, payload: params });

  const setQuery = (query) => {
    dispatch({ type: SET_JOBS_SEARCH, payload: query });
  };

  const setFilter = (params) => {
    dispatch({ type: SET_JOBS_FILTER, payload: params });
  };

  const { children } = props;

  return (
    <JobContext.Provider
      value={{
        ...state,
        initJobs,
        setJobsLoading,
        setJobsRefetching,
        getJobs,
        loadMore,
        setQuery,
        setFilter,
      }}
    >
      {children}
    </JobContext.Provider>
  );
};
