import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import './errorReporting';
import App from "./App";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

ReactDOM.render(
  <CookiesProvider>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </CookiesProvider>,
  document.getElementById("root")
);
