import React from "react";
import {
  OfferPriceEachTextBuilder,
  OfferBOGOTextBuilder,
  OfferSaveTextBuilder,
  OfferUpTextBuilder,
  OfferXForTextBuilder,
  OfferPurchaseTextBuilder,
  OfferFreeWithTextBuilder,
  OfferPurchaseQtyTextBuilder,
  OfferPurchaseQtyValTextBuilder,
  OfferPurchaseProductTextBuilder,
  OfferAgreementTextBuilder,
  OfferRecipeTextBuilder,
  OfferOtherTextBuilder,
} from "components";

export const OfferTextBuilder = ({ type = "", value, onChange = () => {}, onStartChange = () => {}, existingOfferTextForSave = '' }) => {
  return (
    <>
      {type.toLowerCase() === "price ea" && (
        <OfferPriceEachTextBuilder
          value={type.toLowerCase() === "price ea" ? value : "1.00"}
          onChange={onChange}
        />
      )}
      {type === "BOGO" && (
        <OfferBOGOTextBuilder
          value={type === "BOGO" ? value : "B1G1F"}
          onChange={onChange}
        />
      )}

      {(type === "Save" || type === 'save') && (
        <OfferSaveTextBuilder
          value={(type === "save" || type === 'Save') ? value : "save 10%"}
          onChange={onChange}
          onStartChange={onStartChange}
          existingOfferText={existingOfferTextForSave}
        />
      )}

      {type === "& Up" && (
        <OfferUpTextBuilder
          value={type === "& Up" ? value : "$5 & Up"}
          onChange={onChange}
        />
      )}
      {type === "X For" && (
        <OfferXForTextBuilder
          value={type === "X For" ? value : "2/$2"}
          onChange={onChange}
        />
      )}
      {type === "w/purchase/$" && (
        <OfferPurchaseTextBuilder
          value={type === "w/purchase/$" ? value : "w/purchase/$10.00"}
          onChange={onChange}
        />
      )}
      {type.toLowerCase() === "w/purchase/qty" && (
        <OfferPurchaseQtyTextBuilder
          value={
            type.toLowerCase() === "w/purchase/qty" ? value : "w/purchase/2"
          }
          onChange={onChange}
        />
      )}
      {type.toLowerCase() === "w/purchase/qty/$" && (
        <OfferPurchaseQtyValTextBuilder
          value={
            type.toLowerCase() === "w/purchase/qty/$"
              ? value
              : "w/purchase/2/$10"
          }
          onChange={onChange}
        />
      )}
      {type === "w/purchase product" && (
        <OfferPurchaseProductTextBuilder
          value={type === "w/purchase product" ? value : ""}
          onChange={onChange}
        />
      )}
      {type.toLowerCase() === "w/agreement" && (
        <OfferAgreementTextBuilder
          value={type.toLowerCase() === "w/agreement" ? value : "Qualified Upgrade"}
          onChange={onChange}
        />
      )}
      {type.toLowerCase() === "free with" && (
        <OfferFreeWithTextBuilder
          value={type.toLowerCase() === "free with" ? value : ""}
          onChange={onChange}
        />
      )}
      {type.toLowerCase() === "recipe" && (
        <OfferRecipeTextBuilder
          value={type.toLowerCase() === "recipe" ? value : "per serving"}
          onChange={onChange}
        />
      )}
      {type.toLowerCase() === "other" && (
        <OfferOtherTextBuilder
          value={type.toLowerCase() === "other" ? value : "w/purchase/"}
          onChange={onChange}
        />
      )}
    </>
  );
};
