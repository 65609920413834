import React from "react";
import styled from "styled-components";
import { Accordion, CheckBox, DateRange, Input2 } from "components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useCircularContext } from "contexts";

import "rc-slider/assets/index.css";
import "./style.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const stages = [
  { label: "Accepted", name: "ACCEPTED" },
  { label: "Section Processing", name: "SECTION_PROCESSING" },
  { label: "Page Processing", name: "PAGE_PROCESSING" },
  { label: "Product Processing", name: "PRODUCT_PROCESSING" },
  { label: "Offer Processing", name: "OFFER_PROCESSING" },
  //{ label: "Reassembly", name: "REASSEMBLY" },
  { label: "Complete", name: "COMPLETE" }
];

export const FilterSection = ({ filterOptions, isLoading, onChange, tiers }) => {
  const {
    stage = [],
    tier = [],
    setFilter,
    circDateFrom,
    circDateTo,
    creationDateFrom,
    adBlocksCount,
    creationDateTo,
    activeRetailers
  } = useCircularContext();

  const handleFilter = (params) => {
    //Make sure the fiter is within the set otherwise dont select
    if(stages.some(item => item.name === params)) {
      if (stage && stage.includes(params)) {
        setFilter({ stage: stage.filter((a) => a !== params) });
      } else {
        setFilter({ stage: [...(stage || []), params] });
      }
    }
  };

  const handleTierFilter = (params) => {
    if(tiers.some(item => item.ext_id === params)) {
      if (tier && tier.includes(params)) {
        setFilter({ tier: tier.filter((item) => item !== params) });
      } else {
        setFilter({ tier: [...tier, params] });
      }
    }
  }

  const handleSelectAll = (filterType, selectedItems, allItems) => {
    if (selectedItems.length === allItems.length) {
      setFilter({ [filterType]: [] });
    } else {
      if (filterType === "tier")
        setFilter({ [filterType]: allItems.map(item => item.ext_id) });
      else if (filterType === "stage")
        setFilter({ [filterType]: allItems.map(item => item.name) });
    }
  };

  return (

    <FilterContainer>
      <AccordionSection>
        {isLoading ? (
          <SkeletonTheme color="#202020" highlightColor="#444">
            <Skeleton count={16} height={24} className="my-2" />
          </SkeletonTheme>
        ) : (
          <>
            <div className="title">Filters</div>
            <Accordion
              title="Circulation Date"
              open={circDateFrom || circDateTo}
            >
              <div className="py-3">
                <DateRange
                  open={circDateFrom || circDateTo}
                  startDate={circDateFrom}
                  endDate={circDateTo}
                  onChange={(dates) => {
                    setFilter({
                      circDateFrom: dates[0],
                      circDateTo: dates[1],
                    });
                  }}
                  onClear={(dates) => {
                    setFilter({
                      circDateFrom: undefined,
                      circDateTo: undefined,
                    });
                  }}
                />
              </div>
            </Accordion>
            <Accordion
              title="Created Date"
              open={creationDateFrom || creationDateTo}
            >
              <div className="py-3">
                <DateRange
                  open={creationDateFrom || creationDateTo}
                  startDate={creationDateFrom}
                  endDate={creationDateTo}
                  onChange={(dates) => {
                    setFilter({
                      creationDateFrom: dates[0],
                      creationDateTo: dates[1],
                    });
                  }}
                  onClear={(dates) => {
                    setFilter({
                      creationDateFrom: undefined,
                      creationDateTo: undefined,
                    });
                  }}
                />
              </div>
            </Accordion>
            <Accordion
              title="Stages"
              desc=""
              className="mb-3"
              open={stage && stage?.length > 0}
            >
              <div className="m-3">
                <CheckBox 
                  value={stage.length === stages.length}
                  onChange={() => handleSelectAll('stage', stage, stages)}
                >
                  <span className="font-weight-600">Select All</span>
                </CheckBox>
              </div>
              {stages.map(({ label, name }) => (
                <div key={name} className="m-3">
                  <CheckBox
                    value={(stage || []).includes(name)}
                    onChange={() => handleFilter(name)}
                  >
                    {label}
                  </CheckBox>
                </div>
              ))}
            </Accordion>

            {/* <Accordion
              title="Created Date"
              className="mb-3"
              open={creationDateFrom || creationDateTo}
            >
              <div className="py-3">
                <DateRange
                  open={creationDateFrom || creationDateTo}
                  startDate={creationDateFrom}
                  endDate={creationDateTo}
                  onChange={(dates) => {
                    setFilter({
                      creationDateFrom: dates[0] || undefined,
                      creationDateTo: dates[1] || undefined,
                    });
                  }}
                  onClear={() => {
                    setFilter({
                      creationDateFrom: undefined,
                      creationDateTo: undefined,
                    });
                  }}
                />
              </div>
            </Accordion> */}
            <Accordion
              title="Tiers"
              desc=""
              className="mb-3"
              open={tier && tier?.length > 0}
            >
              <div className="m-3">
                <CheckBox
                  value={tier.length === tiers.length}
                  onChange={() => handleSelectAll('tier', tier, tiers)}
                >
                  <span className="font-weight-600">Select All</span>
                </CheckBox>
              </div>
              {tiers.map(({ ext_id }) => (
                <div key={ext_id} className="m-3">
                  <CheckBox
                    value={(tier || []).includes(ext_id)}
                    onChange={() => handleTierFilter(ext_id)}
                  >
                    {ext_id}
                  </CheckBox>
                </div>
              ))}
            </Accordion>

            <Input2 placeholder="AdBlocks Count" className="mb-3" type="number" min="0" value={adBlocksCount?adBlocksCount:""}
            onKeyPress={e => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
            onChange={(e) => setFilter({
              adBlocksCount: e
            })}
            />

            <CheckBox
              value={activeRetailers}
              onChange={(e) => setFilter({
                activeRetailers: e
              })}
            >
              Active Circulars
            </CheckBox>
          </>
        )}
      </AccordionSection>
    </FilterContainer>
  );
};


const FilterContainer = styled.div`
  overflow: auto;
  border-bottom:2px solid ${(props) => props.theme.palette.background};

  input
  {
    font-size:14px;
  }
`;

const AccordionSection = styled.div`
  margin: 12px 12px 12px 24px;
  padding-right: 10px;
  min-width: 240px;
  ::-webkit-scrollbar {
    width: 10px;
    background: ${(props) => props.theme.palette.background};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.background};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }

  .title {
    color:white;
    font-size:12px;
    border-bottom: 1px solid ${(props) => props.theme.palette.itemsHover};
    text-transform:uppercase;
  }
`;
