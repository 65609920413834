/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal, Button } from "components";
import { useMainContext } from "contexts";

export const DeleteCircularModal = ({
  open,
  onClose,
  onDelete = () => {}
}) => {
  const { currentCircularId } = useMainContext();

  const handleCancel = () => {
    onClose();
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    onDelete();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="medium"
      title={"Delete Page Incentive"}
    >
      <div>
        <div>
          Deleting a Circular will delete all associated
          pages, adblocks, groups, products, offers, incentives.
          <br /> Are you use your want to delete Circular {currentCircularId}?
        </div>
      </div>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-4"
          type="button"
          onClick={(e) => handleDelete(e)}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
};
