import { useMainContext } from "contexts";
import { MetaCheckBox, DateRange, Input2 } from "components";
import useKeypress from "react-use-keypress";

export const MetaData = ({ className }) => {
  const { 
    metaData, 
    updateMetaData, 
    updateLimitedTimeDates,
    updateCouponAmount,
    updateRebateAmount,
    currentCircularStartDate,
    setIsManualEntry } = useMainContext();
  

  useKeypress(["c", "C"], (event) => {
    if (event.altKey) {
      event.preventDefault();
      
      var div = document.getElementById("i_Coupon");
      
      if (div) {
        if(div.getElementsByTagName("input")){
          var input = div.getElementsByTagName("input");

          if(input){
            input[0].click();
        
            if(document.getElementById("coupon_ammt")){
              document.getElementById("coupon_ammt").focus();
            }
          }
        }
      }
    }
  });

  return (
    <div className={className}>
      {metaData.map((item, index) => (
        <div key={`metadat_${index}`}
              id={"i_" + item.label}>
        <MetaCheckBox
          value={item.value}
          inputId={item.label}
          onChange={(e) => {
            setIsManualEntry(true);
            updateMetaData(item.key, e)
          }}
        >
          {item.label}
        </MetaCheckBox>
        
        {item.label === 'Coupon' && item.value === true ? (
          <Input2  
            className="mt-2 ml-4 pl-2"
            inputId="coupon_ammt"
            type="float"
            label="Coupon Amount"
            value={metaData.find((data) =>
                data.key === "coupon_f"
              )?.coupon_amnt != null ?
              metaData.find((data) =>
                data.key === "coupon_f"
              )?.coupon_amnt 
              : ""} 
            onClick={() =>
              setIsManualEntry(true)
            }
            onChange={(amount) => {
                updateCouponAmount({
                  amount
                });
              }}
              onClear={(amount) => {
                updateCouponAmount(
                  null
                );
              }}></Input2>
        ) : null}

        {item.label === 'Rebate' && item.value === true ? (
          <Input2  
            className="mt-2 ml-4 pl-2"
            inputId="rebate_amnt"
            label="Rebate Amount"
            type='float'
            value={metaData.find((data) =>
                data.key === "rebate_f"
              )?.rebate_amnt != null ?  
              metaData.find((data) =>
                data.key === "rebate_f"
                )?.rebate_amnt
              : ''}
            onClick={() =>
              setIsManualEntry(true)
            }
            onChange={(amount) => {
                updateRebateAmount({
                  amount
                });
              }}
              onClear={(amount) => {
                updateRebateAmount(
                  null
                );
              }}></Input2>
        ) : null}

        {item.label === 'Limited Time' && item.value === true ? (
            <DateRange
              open={true}
              startDate={metaData.find((data) =>
                data.key === "limitedtime_f"
              )?.lt_start_dt}
              endDate={metaData.find((data) =>
                data.key === "limitedtime_f"
              )?.lt_end_dt}
              onChange={(dates) => {
                updateLimitedTimeDates({
                  limitedDateFrom: dates[0],
                  limitedDateTo: dates[1],
                });
              }}
              onClear={(dates) => {
                updateLimitedTimeDates({
                  limitedDateFrom: undefined,
                  limitedDateTo: undefined,
                });
              }}
              minDate={new Date(currentCircularStartDate)}
            />
        ) : null}


        </div>
      ))}
    </div>
  );
};
