import React, { useState } from "react";
import styled from "styled-components";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

export const Card = ({
  title,
  children,
  className,
  isScrollable = false,
  isCollapsable,
  initCollapse = false,
  count,
  currentPageData,
  currentCircular
}) => {
  const [isCollapsed, setIsCollapsed] = useState(initCollapse);

  return (
    <Container className={className}>
      {title && (
        <Header
          onClick={() => isCollapsable && setIsCollapsed((state) => !state)}
        >
          <div>{title}</div>

            {currentPageData !== undefined ? (
              <>
                <OutOf>- {currentPageData.num_adblocks_completed} / {currentPageData.num_adblocks}</OutOf>
                {/* <Stage>- {currentPageData.stage}</Stage> */}
              </>
            ): null}  

            { currentPageData !== undefined ?
            currentPageData.rejected ? (
              <Status rejected>Rejected</Status>
            ) : currentPageData.completed ? (
              <Status approved>Completed</Status>
            ) : null 
            : null}  

            {currentCircular !== undefined ? (
              <>
                <OutOf>- {currentCircular.num_adblocks_completed} / {currentCircular.num_adblocks}</OutOf>
                <Stage>- {currentCircular.stage}</Stage>
              </>
            ): null}  

          { currentCircular !== undefined ?
             currentCircular?.stage === 'COMPLETE' && currentCircular?.num_pages_completed > 0 ? (
              <Status approved>Completed</Status>
            ) : null 
            : null}  

          {isCollapsable && (
            <div className="ml-auto">
              {isCollapsed ? (
                <FaCaretUp color="white" />
              ) : (
                <FaCaretDown color="white" />
              )}
            </div>
          )}
 
          { count !== undefined ? (
            <Count>({count})</Count>
          ) : null}
          
        </Header>
      )}
      {isCollapsed ? null : isScrollable ? (
        <ScrollContent>{children}</ScrollContent>
      ) : (
        <Content>{children}</Content>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 4px 3px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  display: flex;
  cursor: pointer;
  position:relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: 100%;
`;

const ScrollContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 8px;
  margin: 8px 0;
  flex: 1;
  max-height: 100%;
  margin-right: 4px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const Status = styled.div`
  margin-left: 10px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 12px;
  height: 22px;
  padding: 5px 8px;
  background: ${(props) =>
    props.approved
      ? props.theme.palette.success
      : props.rejected
      ? props.theme.palette.error
      : props.theme.palette.warning};
  color: ${(props) =>
    props.rejected
      ? props.theme.palette.white
      : props.theme.palette.background};
`;

const OutOf = styled.span`
  margin-left: 5px;
`;

const Stage = styled.span`
  margin-left: 5px;
`;

const Count = styled.span`
  position:absolute;
  right:0;
  top:0;
`;

