/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal, Button } from "components";
import styled from "styled-components";

export const ConfirmationModal = ({
    isOpen,
    title = "Confirmation!",
    message = "Are you sure you want to continue??",
    msgBody,
    onCancel,
    onContinue
}) => {

    const handleCancel = () => {
        onCancel();
    }

    const handleContinue = () => {
        onContinue();
    }

    return (
        <Modal open={isOpen} onClose={onCancel} size="medium" title={title}>
            <MessageStyle>
                <div>
                    <div>{message}</div>
                    <WarningMessage><WarningText>WARNING:</WarningText> {msgBody}</WarningMessage>
                </div>
            </MessageStyle>
            <div className="d-flex mt-4">
                <Button
                    size="small"
                    buttonTheme="dark"
                    width="fit-content"
                    className="ml-auto"
                    type="button"
                    onClick={() => handleCancel()}
                >
                    Cancel
                </Button>
                <Button
                    size="small"
                    buttonTheme="primary"
                    width="fit-content"
                    className="ml-4"
                    type="button"
                    onClick={() => handleContinue()}
                >
                    Continue Anyway!
                </Button>
            </div>
        </Modal>
    );
};

const MessageStyle = styled.div`
    font-size:14px;
`;
const WarningMessage = styled.div`
    font-size:14px;
    padding:10px;
`;

const WarningText = styled.span`
  color:${(props) => props.theme.palette.warning};
`;
