/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useOutsideClick, useInput, useModal } from "hooks";
import styled from "styled-components";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { Input2, CheckBox } from "components";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce";
import { eventAutocompleteApi } from "api";
import { ApplyEventsModal } from "modals";
import { useMainContext } from "contexts";
import "./style.scss";

export const EventsDropDown = ({
  id = "",
  pageId = "",
  value = [],
  label = "Events",
  className,
  backgroundColor = "#eee",
  textColor = '',
  placeholder = "-- Select All Events That Apply --",
  width,
  idField = "id",
  inputId,
  displayValue = "",
  onChange = () => {},
  func = () => {},
  onSelectId = () => {},
  onSelectValue = () => {},
  onClick = () => {},
  onFocus = () => {},
  onRefresh = () => {},
  applyToPage = true
}) => {
  const menuRef = useRef(null);
  const search = useInput("");
  const [showResults, setShowResults] = useState(false);
  const [debouncedValue] = useDebounce(search.value, 500);
  const [focused, setFocused] = useState(false);
  const applyModal = useModal();
  const searchInputRef = useRef(null);
  const { postPageEvents } = useMainContext();

  useOutsideClick(menuRef, clickOutsideMenu);
  function clickOutsideMenu() {
    setShowResults(false);
  }

  const { data, isLoading } = useQuery(
    [debouncedValue],
    () => eventAutocompleteApi(debouncedValue),
    {
      keepPreviousData: true,
      enabled: debouncedValue.length > 0,
    }
  );

  const handleChange = (params) => {
    let updatedValue = [];
    postPageEvents("click", `event - ${params}`);
    if (value.includes(params) > 0) {
      updatedValue = value.filter((item) => item !== params);
    } else {
      updatedValue = [...value, params];
    }
    onChange(updatedValue);
  };

  useEffect(() => {
    if (debouncedValue) {
    } else {
      setShowResults(false);
    }
    return () => {};
  }, [debouncedValue]);

  const handleBlur = (event) => {
    setFocused(false);
    onFocus(false);
  };

  const handleApplyClick = () => {
    applyModal.openModal();
    postPageEvents("click", (id || "Events DropDown") + "Apply Page");
  };

  const handleClick = () => {
    setShowResults((state) => !state);
    setTimeout(() => {
      searchInputRef.current.focus();
    }, 300);
  };

  return (
    <EventsContainer
      className={className}
      onClick={() => {
        onClick();
        postPageEvents("click", id || "Events DropDown");
      }}
    >
      {label && <span className={`select-label my-auto ${textColor}`}>{label}</span>}
      {applyToPage &&
      <ApplyButton onClick={handleApplyClick}>Apply to Page</ApplyButton>
      }
      <div className="events-dropdown" style={{ width }}>
        <div className="menu-button" style={{ width }}>
          <InnerValue
            className="mr-auto"
            onClick={() => handleClick()}
            value={
              value.length > 3
                ? `${value.length} Events Selected`
                : value.length > 0
                ? value.join(", ")
                : ""
            }
            readOnly
            onBlur={handleBlur}
            placeholder={placeholder}
          />
          <button className="menu-button__drop-down">
            {showResults ? (
              <AiFillCaretUp
                color="white"
                className="menu-button__drop-down"
                alt="drop-down"
              />
            ) : (
              <AiFillCaretDown
                color="white"
                className="menu-button__drop-down"
                alt="drop-down"
              />
            )}
          </button>

          <Underline focused={focused} />
        </div>

        <div
          className="menu-dropdown p-1"
          style={{ visibility: showResults ? "visible" : "hidden" }}
          ref={menuRef}
        >
          {value.length > 0 && (
            <>
              <SelectedContainer>
                {value.map((item, index) => (
                  <CheckBox
                    className={classNames("menu-item w-100")}
                    key={`key${index}`}
                    onChange={() => handleChange(item)}
                    value={true}
                  >
                    {item}
                  </CheckBox>
                ))}
              </SelectedContainer>
            </>
          )}
          <Input2
            className="input"
            placeholder="Type to filter events"
            {...search}
            ref={searchInputRef}
          />
          <div className="menu-dropdown-content mt-2">
            {data?.results &&
            data?.results.filter((event) => !value.includes(event.event_nm))
              .length > 0 ? (
              data.results
                .filter((event) => !value.includes(event.event_nm))
                .map((item, index) => {
                  return (
                    <CheckBox
                      className={classNames("menu-item w-100")}
                      key={`key${index}`}
                      onChange={() => handleChange(item.event_nm)}
                      value={false}
                    >
                      {item.event_nm}
                    </CheckBox>
                  );
                })
            ) : (
              <div className="my-3 d-flex justify-content-center">
                {isLoading ? "Loading..." : "No results"}
              </div>
            )}
          </div>
        </div>
      </div>
      <ApplyEventsModal
        {...applyModal}
        events={value}
        pageId={pageId}
        onRefresh={onRefresh}
      />
    </EventsContainer>
  );
};

const EventsContainer = styled.div`
  position: relative;

  .input {
    font-size: 14px;
  }
`;

const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;

const InnerValue = styled.input`
  background: transparent;
  border: none;
  outline: none;
  color: white;
  width: 300px;
  &:focus {
    border: none;
    outline: none;
  }
`;
const SelectedContainer = styled.div`
  max-height: 100px;
  margin-right: 4px;
  padding-left: 10px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const ApplyButton = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  color: white;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
`;
