import { Select, Button, Divider } from 'antd'

// Based on PRODUCT_INFO_FIELDS
const MOVFieldMapper = {
  'brand_model_number': 'Model Number',
  'brand_origin': 'Origin',
  'variant_id': 'Variety'
};

export const SelectMOV = ({
  isManualEntry,
  movData,
  mov_ids, mov_nms,
  onClick = () => { },
  onFocus, onSelectValue, onChange, onCreateNew,
  inputId
}) => {
  let mns = []

  const handleChange = (values, opt) => {
    values.forEach(val => {
      let tmp = movData.find(mn => mn.id === val);
      if (tmp)
        mns.push(tmp)
    })
    mns = [...new Set(mns.map(JSON.stringify))].map(JSON.parse);
    onChange(mns)
  };

  const handleDeselect = (value, opt) => {
    mns = mns.filter(function (mn) { return mn.id !== value });
    onChange(mns)
  };

  const handleClear = () => {
    mns = []
    onChange(mns)
  };

  return (
    <Select
      mode="multiple"
      allowClear
      style={{ width: '100%' }}
      placeholder={`${MOVFieldMapper[inputId]} (optional)`}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '0' }} />
          <Button block type="text" onClick={onCreateNew}>
            {`Add New ${MOVFieldMapper[inputId]}`}
          </Button>
        </>
      )}
      onChange={handleChange}
      onDeselect={handleDeselect}
      onFocus={() => onFocus(inputId)}
      onClear={handleClear}
      onSelect={onSelectValue}
      defaultValue={mov_ids.value}
      options={movData}
      fieldNames={{ label: 'title', value: 'id' }}
      optionFilterProp='title'
      disabled={!isManualEntry}
      key={Math.random().toString()}
      id={inputId}
      onClick={onClick}
    />
  )
}
