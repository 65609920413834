/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useOutsideClick, useInput } from "hooks";
import styled from "styled-components";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { Input2, CheckBox } from "components";
import "./style.scss";
import { addTeammembers, getTeammembers, usersAutoCompleteAPI } from "api";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce";

export const TeamManagementDropDown = ({
  label = "Team Management",
  className,
  textColor = '',
  placeholder = "Select All Operators That Apply",
  width,
}) => {
  const menuRef = useRef(null);
  const [users, setUsers] = useState([]);
  const search = useInput("");
  const [showResults, setShowResults] = useState(false);
  const searchInputRef = useRef(null);
  const [debouncedValue] = useDebounce(search.value, 500);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const init = async () => {
    const results = await getTeammembers();
    if (results.success) {
      if (results?.payload.length > 0) {
        setUsers(results?.payload)
      }
    }
  };
  useEffect(() => {
    init();
  }, [])

  useOutsideClick(menuRef, clickOutsideMenu);
  function clickOutsideMenu() {
    setShowResults(false);
  }

  const handleChange = (params) => {
    let updatedValue = [];
    if (users.includes(params) > 0) {
      updatedValue = users.filter((item) => item !== params);
    } else {
      updatedValue = [...users, params];
    }
    setUsers(updatedValue)
  };

  const handleClick = () => {
    setShowResults((state) => !state);
    setTimeout(() => {
      searchInputRef.current.focus();
    }, 300);
  };

  const handleAddTeammates = async () => {
    setIsSubmitting(true)
    const addUser = await addTeammembers(users);
    console.log(addUser)
    if (addUser.success) {
      setIsSubmitting(false);
      setUsers(addUser?.payload)
    }
  }

  useEffect(() => {
    if (debouncedValue) {
    } else {
      setShowResults(false);
    }
    return () => { };
  }, [debouncedValue]);

  const { data, isLoading } = useQuery(
    [debouncedValue],
    () => usersAutoCompleteAPI(debouncedValue),
    {
      keepPreviousData: true,
      enabled: debouncedValue.length > 0,
    }
  );

  return (
    <EventsContainer
      className={className}
    >
      {label && <span className={`select-label my-auto ${textColor}`}>{label}</span>}
      <ApplyButton
        onClick={() => {
          if (!isSubmitting)
            handleAddTeammates()
        }}>
        {isSubmitting ? 'Saving' : 'Save'}
      </ApplyButton>
      <div className="events-dropdown" style={{ width }}>
        <div className="menu-button" style={{ width }}>
          <InnerValue
            className="mr-auto"
            onClick={() => handleClick()}
            readOnly
            placeholder={placeholder}
            value={`${users.length} Operators`}
            disabled={isSubmitting}
          />
          <button className="menu-button__drop-down">
            {showResults ? (
              <AiFillCaretUp
                color="white"
                className="menu-button__drop-down"
                alt="drop-down"
              />
            ) : (
              <AiFillCaretDown
                color="white"
                className="menu-button__drop-down"
                alt="drop-down"
              />
            )}
          </button>
        </div>

        <div
          className="menu-dropdown p-1"
          style={{ visibility: showResults ? "visible" : "hidden" }}
          ref={menuRef}
        >
          {users.length > 0 && (
            <>
              <SelectedContainer>
                {users.map((item, index) => (
                  <CheckBox
                    className={classNames("menu-item w-100")}
                    key={`key${index}`}
                    onChange={() => handleChange(item)}
                    value={true}
                  >
                    {item.full_name}
                  </CheckBox>
                ))}
              </SelectedContainer>
            </>
          )}
          <Input2
            className="input"
            placeholder="Type to filter operators"
            {...search}
            ref={searchInputRef}
            disabled={isSubmitting}
          />
          <div className="menu-dropdown-content mt-2">
            {!isLoading ?
              data?.results.filter((item1) => !users.some(item2 => item2.id === item1.id)).map((user, index) => {
                return (
                  <CheckBox
                    className={classNames("menu-item w-100")}
                    key={`key${index}`}
                    onChange={() => handleChange(user)}
                    value={false}
                  >
                    {user.username}
                  </CheckBox>
                );
              }) : 'Loading'
            }
          </div>
        </div>
      </div>
    </EventsContainer>
  );
};

const EventsContainer = styled.div`
  position: relative;

  .input {
    font-size: 14px;
  }
`;

const InnerValue = styled.input`
  background: transparent;
  border: none;
  outline: none;
  color: white;
  width: 300px;
  &:focus {
    border: none;
    outline: none;
  }
`;
const SelectedContainer = styled.div`
  max-height: 100px;
  margin-right: 4px;
  padding-left: 10px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const ApplyButton = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  color: white;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
`;
