/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { Button, CheckBox, PageDropDown, CircularDropDown } from "components";
import { Card } from "./components";
import { useParams } from "@reach/router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FaCropAlt } from "react-icons/fa";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { FiRefreshCw } from "react-icons/fi";
import { GiMove } from "react-icons/gi";
import { getImage, getPageSections, addSection2Page } from "api";
// import Cropper from "react-cropper";

export const SectionPage = () => {
  const params = useParams();
  const cropperRef = useRef();
  const canvasRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [isCropping, setIsCropping] = useState(false);
  const [currentCircular, setCurrentCircular] = useState({});
  const [currentPage, setCurrentPage] = useState({})
  const [sectionImg, setSectionImg] = useState(null);
  const [sectionData, setSectionData] = useState([])
  const [canvasData, setCanvasData] = useState({
    top: 0, left: 0, width: 0, height: 0, naturalWidth: 1, naturalHeight: 1
  })
  const [cropBoxData, setCropBoxData] = useState({
    top: 0, left: 0, width: 0, height: 0
  })
  const [buttonState, setButtonState] = useState({ x: 0, y: 0, show: false });
  const init = async () => {
    setIsLoading(false);
  };

  useEffect(() => {
    init();
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleLabel = () => { };

  const handleApprove = () => { };

  const onCircularSelect = (params) => {
    setCurrentCircular(params);
  };

  const onPageSelect = async (params) => {
    fetchImage(params.filename);
    setCurrentPage(params);
    const res = await getPageSections(params.uuid);
    setSectionData(res.payload.sections)
  };

  const fetchImage = async (id) => {
    const img = await getImage(id);
    setSectionImg(img.payload);
  };

  const drawSections = () => {
    if (canvasRef.current && cropperRef.current && canvasData.width) {
      const canvasEle = canvasRef.current;
      let ctx = null;

      // get context of the canvas
      ctx = canvasEle.getContext("2d");
      canvasEle.width = canvasEle.clientWidth;
      canvasEle.height = canvasEle.clientHeight;
      ctx.clearRect(0, 0, canvasEle.width, canvasEle.height);

      ctx.beginPath();
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 1;
      sectionData.forEach((section) => {
        ctx.rect(
          section.coords_x1 / canvasData.naturalWidth * canvasData.width + canvasData.left,
          section.coords_y1 / canvasData.naturalHeight * canvasData.height + canvasData.top,
          (section.coords_x2 - section.coords_x1) / canvasData.naturalWidth * canvasData.width,
          (section.coords_y2 - section.coords_y1) / canvasData.naturalHeight * canvasData.height,
        );
      })
      ctx.stroke();
    }
  }

  useEffect(() => {
    drawSections()
    return () => {
    }
  }, [sectionData, cropperRef.current, canvasData])

  const handleCrop = () => {
    cropperRef.current.cropper.setDragMode('crop');
  }
  const handleMove = () => {
    cropperRef.current.cropper.setDragMode('move');
  }

  const handleZoomIn = () => {
    cropperRef.current.cropper.zoom(0.1);
  }

  const handleZoomOut = () => {
    cropperRef.current.cropper.zoom(-0.1);
  }

  const handleReset = () => {
    cropperRef.current.cropper.clear();
    cropperRef.current.cropper.setDragMode('move');
  }

  const handleSectionCropCancel = () => {
    setButtonState({ x: 0, y: 0, show: false });
    cropperRef.current.cropper.clear();
    cropperRef.current.cropper.setDragMode('move');

  }

  const handleSectionCrop = async () => {
    setIsCropping(true)
    await addSection2Page(currentPage?.uuid, {
      coords_x1: (cropBoxData.left - canvasData.left) / canvasData.width * canvasData.naturalWidth,
      coords_y1: (cropBoxData.top - canvasData.top) / canvasData.height * canvasData.naturalHeihgt,
      coords_x2: (cropBoxData.left - canvasData.left) / canvasData.width * canvasData.naturalWidth + cropBoxData.width / canvasData.width * canvasData.naturalWidth,
      coords_y2: (cropBoxData.top - canvasData.top) / canvasData.height * canvasData.naturalHeihgt + cropBoxData.height / canvasData.height * canvasData.naturalHeight
    })
    setIsCropping(false)
    setButtonState({ x: 0, y: 0, show: false });
    cropperRef.current.cropper.clear();
    cropperRef.current.cropper.setDragMode('move');
  }

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout>
        <div className="flex-1 d-flex">
          <div className="text-white m-auto">Coming soon...</div>
        </div>
        {/* <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <div className="my-auto d-flex">
                <CircularDropDown onSelectValue={onCircularSelect} />
                <PageDropDown
                  className="ml-3"
                  id={currentCircular?.uuid}
                  onSelectValue={onPageSelect}
                />
              </div>

              <Button
                buttonTheme="dark"
                width="100px"
                size="small"
                className="ml-auto my-auto"
                onClick={handleLabel}
              >
                LABEL
              </Button>
              <Button
                buttonTheme="dark"
                width="100px"
                size="small"
                className="ml-2 my-auto"
                onClick={handleApprove}
              >
                APPROVE
              </Button>
            </div>
          )}
        </Header>

        <Content>
          <ToolContent>
            <FaCropAlt color="white" size={20} onClick={handleCrop} />
            <AiOutlineZoomIn color="white" size={24} onClick={handleZoomIn} />
            <AiOutlineZoomOut color="white" size={24} onClick={handleZoomOut} />
            <GiMove color="white" size={24} onClick={handleMove} />
            <FiRefreshCw color="white" size={20} onClick={handleReset} />
          </ToolContent>
          <div className="d-flex flex-1">
            <AdBlockSection title="Section">
              {sectionImg && (
                <div className="w-100 h-100 position-relative">
                  <Cropper
                    src={`data:image/jpeg;base64,${sectionImg}`}
                    style={{ height: "100%" }}
                    dragMode="move"
                    guides={false}
                    background={false}
                    autoCrop={false}
                    center={true}
                    viewMode={1}
                    ref={cropperRef}
                    ready={(e) => {
                      setCanvasData(cropperRef.current.cropper.getCanvasData())
                    }}
                    cropend={(e) => {
                      setCanvasData(cropperRef.current.cropper.getCanvasData())
                      setCropBoxData(cropperRef.current.cropper.getCropBoxData())
                      setButtonState({
                        x:
                          cropperRef.current.cropper.getCropBoxData().left +
                          cropperRef.current.cropper.getCropBoxData().width / 2,
                        y:
                          cropperRef.current.cropper.getCropBoxData().top +
                          cropperRef.current.cropper.getCropBoxData().height +
                          168,
                        show: true,
                      });
                    }}
                    cropstart={(e) => {
                      setButtonState({ x: 0, y: 0, show: false });
                      const canvasEle = canvasRef.current;
                      let ctx = null;
                      ctx = canvasEle.getContext("2d");
                      ctx.clearRect(0, 0, canvasEle.clientWidth, canvasEle.clientHeight);
                      ctx.beginPath();
                      ctx.stroke();
                    }}
                    zoom={(e) => {
                      setCanvasData(cropperRef.current.cropper.getCanvasData())
                    }}
                  />
                  <CanvasPan ref={canvasRef} />
                  <ButtonContainer {...buttonState}>
                    <Button
                      size="small"
                      width="80px"
                      buttonTheme="dark"
                      onClick={handleSectionCropCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      width="80px"
                      className="ml-3"
                      onClick={handleSectionCrop}
                      isLoading={isCropping}
                    >
                      OK
                    </Button>
                  </ButtonContainer>
                </div>
              )}
            </AdBlockSection>
          </div>
          <LayerSection title="SECTION DATA">
            <CheckBox className="my-1">
              Is there page offer information?
            </CheckBox>
            <CheckBox className="my-1">
              Is there page metadata information?
            </CheckBox>
            <CheckBox className="my-1">
              Is there page product information?
            </CheckBox>
            <CheckBox className="my-1">
              Is there product limit information?
            </CheckBox>
            <CheckBox className="my-1">
              Is there page offer information?
            </CheckBox>
          </LayerSection>
        </Content> */}
      </MainLayout>
    </SkeletonTheme>
  );
};

const CanvasPan = styled.canvas`
  position: absolute;
  z-index:0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
`


const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
`;

const LayerSection = styled(Card)`
  min-width: 320px;
`;
const AdBlockSection = styled(Card)`
  flex: 1;
  position: relative;
`;

const Header = styled.div`
  height: 48px;
  color: white;
  font-size: 20px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;

const ToolContent = styled.div`
  width: 64px;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin: 4px 2px;

  svg {
    cursor: pointer;
    transition: all 300ms ease;
    margin: 12px auto;
    &:hover {
      opacity: 0.7;
    }
  }
`;

const ButtonContainer = styled.div`
  position: fixed;
  left: ${(props) => `${props.x}px`};
  top: ${(props) => `${props.y}px`};
  z-index: 99;
  display: ${(props) => (props.show ? "block" : "none")};
`;
