import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useOutsideClick } from "hooks";
import { Button } from "components";
import { IoTriangleSharp } from 'react-icons/io5'
import { useAuthContext } from 'contexts';

export const MenuDropDown = ({
    title = '',
    onRequeue = () => { },
    onHPRequeue = () => { },
    onReset = () => { },
    onClose = () => { },
    onDevRelay = () => { },
    onStagingRelay = () => { },
    onDeleteCircular = () => { },
    onPageReset = () => {},
    disabled = false,
}) => {

    const menuRef = useRef(null);
    const { userInfo } = useAuthContext();

    useOutsideClick(menuRef, () => {
        setMenuSelected(false);
    })

    const [menuSelected, setMenuSelected] = useState(false);

    useEffect(() => {
        console.log(menuSelected)
    }, [menuSelected])

    useEffect(() => {
        if (disabled) {
            setMenuSelected(!disabled);
        }
    }, [disabled])

    return (
        <div className="my-auto d-flex" ref={menuRef}>
            <Menu className="ml-2 my-auto">
                <MenuButton
                    buttonTheme={"dark"}
                    width="150px"
                    size="small"
                    onClick={() => setMenuSelected(!menuSelected)}
                    isLoading={disabled}
                >
                    <MenuText>{title}</MenuText>
                    <MenuIcon menuSelected={menuSelected}>
                        <IoTriangleSharp />
                    </MenuIcon>
                </MenuButton>
                {menuSelected &&
                    <DropDown >
                        <MenuItem
                            buttonTheme={"dark"}
                            width="150px"
                            size="small"
                            onClick={onRequeue}
                            disabled={disabled}
                        > REQUEUE
                        </MenuItem>
                        <MenuItem
                            buttonTheme={"dark"}
                            width="150px"
                            size="small"
                            onClick={onHPRequeue}
                            disabled={disabled}
                        > HP REQUEUE
                        </MenuItem>
                        {userInfo.user?.is_superuser && <MenuItem
                            buttonTheme={"dark"}
                            width="150px"
                            size="small"
                            onClick={() => onPageReset()}
                            disabled={disabled}
                        > RESET PAGE
                        </MenuItem>}
                        {userInfo.user?.is_superuser && <MenuItem
                            buttonTheme={"dark"}
                            width="150px"
                            size="small"
                            onClick={() => onReset()}
                            disabled={disabled}
                        > RESET
                        </MenuItem>}
                        {(userInfo.user?.is_superuser || userInfo.user?.is_moderator) && <MenuItem
                            buttonTheme={"dark"}
                            width="150px"
                            size="small"
                            onClick={() => onDeleteCircular()}
                            disabled={disabled}
                        > DELETE
                        </MenuItem>}
                        <MenuItem
                            buttonTheme={"dark"}
                            width="150px"
                            size="small"
                            onClick={() => onDevRelay()}
                            disabled={disabled}
                        > Copy To Dev
                        </MenuItem>
                        <MenuItem
                            buttonTheme={"dark"}
                            width="150px"
                            size="small"
                            onClick={() => onStagingRelay()}
                            disabled={disabled}
                        > Copy To Staging
                        </MenuItem>
                        <MenuItem
                            buttonTheme={"dark"}
                            width="150px"
                            size="small"
                            onClick={() => onClose()}
                            disabled={disabled}
                        > CLOSE
                        </MenuItem>
                    </DropDown>
                }
            </Menu>
        </div>
    )
}

const Menu = styled.div`
  position:relative;
  display:flex;
  align-items: center;
  padding: 0;
  outline:0;
  text-align:left;
  width: 100%;
  height: 32px;
  box-sizing: border-box;
`

const MenuButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0px;
  border: none;
  opacity: none;
`

const MenuText = styled.div`
  border: 1px solid #2B2B2B;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
`

const MenuIcon = styled.div`
  width: 25%;
  border: 1px solid #2B2B2B;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;

  svg{
    font-size: 14px;
    transition: all 300ms ease;
    ${({ menuSelected }) => menuSelected && 'transform: rotate(180deg);'}
  }
`

const MenuItem = styled(Button)`
    margin-top: 2px;
`

const DropDown = styled.div`
    margin: 3px 0 0 0;
    pointer-events: auto;
    position: absolute;
    z-index: 1;
    top: 29px;
    color: white;
    background: #191919;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    max-height: 320px;
`
