import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2, EventsDropDown, DateRange, Button } from "components";
import { FaHourglass, FaTrash } from "react-icons/fa";
import { useInput, useModal } from "hooks";
import { CreateAdblockIncentive, CreateAdblockOffer } from "modals";


export const GroupItem = ({
  isLoading,
  data,
  index,
  onDelete,
  active = false,
  type = "group",
  onClick = () => { },
  onChangeText = () => { },
  onChangeSelect = () => { },
  isDeleting = false
}) => {

  // Group Data
  const [typeGroup, setTypeGroup] = useState([
    { title: 'offer', id: 'offer' },
    { title: 'incentive', id: 'incentive' },
    { title: 'event', id: 'event' },
    { title: 'limited time', id: 'limitedtime' }
  ])
  // Flags for Group Types
  // Using flags for this instead of lists or object to minimize code complexity
  // An array could be used to reduce the number of lines and caring about the indexes
  // But as we are using one group type per group I believe we can skip it for now.
  const [offerType, setOfferType] = useState(false);
  const [incentiveType, setIncentiveType] = useState(false);
  const [eventType, setEventType] = useState(false);
  const [limitedTime, setLimitedTime] = useState(false);

  // Inputs for Group Texts
  const events = useInput([]);
  const [offerText, setOfferText] = useState("");
  const [incentiveText, setIncentiveText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showGroupDropDown, setShowGroupDropDown] = useState(true);

  // Flags
  const [hideAddGroup, setHideAddGroup] = useState(false);

  // Model
  const adblockIncentiveModal = useModal();
  const adblockOfferModal = useModal();

  useEffect(() => {
    if (data?.offer_type
      || data?.event_type
      || data?.incentive_type
      || data?.event_type
      || data?.limited_time) {
      let removeFromTypeGroup = [];
      if (data?.offer_type) {
        setOfferType(true);
        setOfferText(data?.offer_text);
        removeFromTypeGroup.push('offer')
      }
      if (data?.event_type) {
        if (typeof data?.event_text === 'string') {
          let ev = data?.event_text.split(',');
          setEventType(true);
          events.setValue(ev);
        }
        removeFromTypeGroup.push('event')
      }
      if (data?.limited_time) {
        setLimitedTime(true);
        setStartDate(data?.start || '');
        setEndDate(data?.end || '');
        removeFromTypeGroup.push('limitedtime')
      }
      if (data?.incentive_type) {
        setIncentiveType(true);
        setIncentiveText(data?.incentive_text);
        removeFromTypeGroup.push('incentive')
      }
      let group_types = typeGroup.filter(item => !removeFromTypeGroup.includes(item.id));
      setTypeGroup(group_types);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  /**
   * Group Text and Group Type is deprecated now!
   * The Below Code is for the same.
   */

  // useEffect(() => {
  //   if (data?.group_type === 'event' && data?.group_text) {
  //     if (typeof data?.group_text === 'string') {
  //       events.setValue(data?.group_text?.split(","));
  //       onChangeText('');
  //     }
  //   }
  //   handleLimitedTimeSelection();
  //   return () => { }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // useEffect(() => {

  //   console.log('groupt_type', data?.group_type);
  //   if (data) {

  //     if (data?.group_type) {
  //       setGroupType(data?.group_type);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  // useEffect(() => {
  //   handleLimitedTimeSelection();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [groupType])

  // const onChangeSelect = (type, item) => {
  //   setGroupType(type);
  // };

  const handleDelete = (data, index) => {
    onDelete(data, index);
  };

  const handleAdblockIncentiveModal = () => {
    console.log('modal', adblockIncentiveModal.open);
    adblockIncentiveModal.openModal();
  }
  
  const handleAdblockOfferModal = () => {
    adblockOfferModal.openModal();
  }

  const handleAddGroupItem = () => {
    setShowGroupDropDown(true);
  }

  // const handleLimitedTimeSelection = () => {
  //   if (data?.group_type === 'limitedtime' && data?.group_text) {
  //     if (typeof data?.group_text === 'string') {
  //       const text = data?.group_text.split('to');
  //       setStartDate(text[0]);
  //       setEndDate(text[1]);
  //       onChangeText(`${text[0]}to${text[1]}`);
  //     }
  //   } else if (data?.group_type === 'limitedtime' && startDate && endDate) {
  //     onChangeText(`${startDate}to${endDate}`);
  //   }
  // }

  const handleDropDownSelection = (e) => {
    setHideAddGroup(true);
    setShowGroupDropDown(false);
    setTypeGroup(typeGroup.filter(item => item.id !== e))
    if (e === 'offer') {
      setOfferType(true);
    }
    else if (e === 'event') {
      setEventType(true);
    }
    else if (e === 'incentive') {
      setIncentiveType(true);
    }
    else if (e === 'limitedtime') {
      setLimitedTime(true);
      setHideAddGroup(false);
      handleChangeText(offerText, events.value, incentiveText, startDate, endDate, true);
    }
  }

  const handleDeleteGroupItem = (item) => {
    let g = {}
    if (item === 'offer') {
      setOfferType(false);
      setOfferText('');
      g = { title: 'offer', id: 'offer' };
    }
    else if (item === 'event') {
      setEventType(false);
      events.setValue([]);
      g = { title: 'event', id: 'event' }
    }
    else if (item === 'incentive') {
      setIncentiveType(false);
      setIncentiveText('');
      g = { title: 'incentive', id: 'incentive' }
    }
    else if (item === 'limitedtime') {
      setLimitedTime(false);
      setStartDate('');
      setEndDate('');
      g = { title: 'limited time', id: 'limitedtime' }
    }
    setTypeGroup([...typeGroup, g]);
    setHideAddGroup(false);
  }

  const handleChangeText = (offer_text, event_text, incentive_text, start, end, limitedTimeOverride) => {
    const group_data = {
      offer_type: offerType,
      offer_text: offer_text,
      event_type: eventType,
      event_text: event_text,
      incentive_type: incentiveType,
      incentive_text: incentive_text,
      limited_time: limitedTimeOverride ?? limitedTime,
      start: start || undefined,
      end: end || undefined,
    }
    onChangeText(group_data);
  }

  const handleEventChange = (e) => {
    if (e) {
      setHideAddGroup(false);
    } else {
      setHideAddGroup(true);
    }
    events.setValue(e);
    handleChangeText(offerText, e, incentiveText, startDate, endDate)
  }

  return (
    <Container type={active ? "active" : type} onClick={onClick}>
      <div className="inner">
        <SectionTitle>Group {data?.index}</SectionTitle>
        <SectionUUID className="mt-1">{data?.uuid}</SectionUUID>

        <div className="group-container" >
          {offerType &&
            <GroupItems
              groupType='offer'
              onDelete={handleDeleteGroupItem}
            >
              <span className="select-label my-auto text-light">Offer</span>
              <TextBuilderButton
                  isSelected={adblockOfferModal.open}
                  onClick={handleAdblockOfferModal}
                >
                  Open Offer Text Builder
                </TextBuilderButton>
              <Input2
                value={offerText}
                placeholder="Enter the offer text"
                onChange={(e) => {
                  if (e) {
                    setHideAddGroup(false);
                  } else {
                    setHideAddGroup(true);
                  }
                  setOfferText(e);
                  handleChangeText(e, events.value, incentiveText, startDate, endDate);
                }}
              />
            </GroupItems>
          }
          {eventType &&
            <GroupItems
              groupType='event'
              onDelete={handleDeleteGroupItem}
            >
              <EventsDropDown
                textColor="text-light"
                pageId={data.uuid}
                {...events}
                onChange={(e) => handleEventChange(e)}
                applyToPage={false}
              />
            </GroupItems>
          }
            {incentiveType &&
              <GroupItems
              groupType='incentive'
              onDelete={handleDeleteGroupItem}
              >
                <span className="select-label my-auto text-light">Incentives</span>
                <TextBuilderButton
                  isSelected={adblockIncentiveModal.open}
                  onClick={handleAdblockIncentiveModal}
                >
                  Open Incentive Builder
                </TextBuilderButton>
                <Input2
                  value={incentiveText}
                  placeholder="Incentive Text"
                  className="mt-2"
                  onChange={(e) => {
                    if (e) {
                      setHideAddGroup(false);
                    } else {
                      setHideAddGroup(true);
                    }
                    setIncentiveText(e);
                    handleChangeText(offerText, events.value, e, startDate, endDate);
                  }}
                  />
              </GroupItems>
            }

          {limitedTime &&
            <GroupItems 
              groupType='limitedtime'
              onDelete={handleDeleteGroupItem}
            >
              <span className="select-label my-auto text-light">Limited Time</span>
              <DateRange
                open={true}
                className="mt-2 mb-2"
                width="w-50"
                startDate={startDate}
                endDate={endDate}
                onChange={(dates) => {
                  setStartDate(dates[0]);
                  setEndDate(dates[1]);
                  if (dates[0] && dates[1]){
                    setHideAddGroup(false);
                  } else {
                    setHideAddGroup(true);
                  }
                  handleChangeText(offerText, events.value, incentiveText, dates[0], dates[1]);
                }}
              />
            </GroupItems>
          }
        </div>
        <div className="">
          {(showGroupDropDown && typeGroup.length > 0) &&
            <DropDown2
              data={typeGroup}
              onChange={(e) => handleDropDownSelection(e)}
              className="group-item"
            ></DropDown2>
          }
          <AddButton
            size="small"
            onClick={handleAddGroupItem}
            disabled={hideAddGroup || !typeGroup.length > 0}
          >
            ADD
          </AddButton>
        </div>
      

        {!isDeleting ?
          <FaTrash
            className="deleteIcon"
            onClick={() => handleDelete(data, index)}
          /> :
          <FaHourglass
            className="deleteIcon"
          />
        }
      <CreateAdblockIncentive
        {...adblockIncentiveModal}
        onSubmit={e => {
          handleChangeText(offerText, events.value, e, startDate, endDate)
          setHideAddGroup(false);
        }}
      />
      <CreateAdblockOffer
        {...adblockOfferModal}
        offerData={offerText}
        onSubmit={e => {
          handleChangeText(e, events.value, incentiveText, startDate, endDate)
          setHideAddGroup(false);
        }}
      />
      </div>
    </Container>
  );
};

const GroupItems = ({
  children,
  groupType,
  onDelete = () => { }
}) => {
  return <div className="group">
    <div className="group-item">
      {children}
    </div>
    <div
      className="group-remove text-center cursor-pointer"
      onClick={() => onDelete(groupType)}
    >
      X
    </div>
  </div>
}


const Container = styled.div`
    position: relative;
    cursor: pointer;
    background: ${(props) => props.theme.palette.items};
    display: block;
    color: white;
    font-size: 12px;
    transition: all 300ms ease;
    font-size: ${(props) => props.theme.palette.m};
    border: 1px solid
    ${(props) =>
    props.type === "active"
      ? props.theme.palette.primary
      : props.type === "page"
        ? props.theme.palette.accentHover
        : props.type === "circular"
          ? props.theme.palette.accentHover
          : props.type === "rejected"
            ? props.theme.palette.error
            : props.theme.palette.gray};
    border-left: 12px solid
    ${(props) =>
    props.type === "active"
      ? props.theme.palette.primary
      : props.type === "page"
        ? props.theme.palette.accentHover
        : props.type === "circular"
          ? props.theme.palette.accentHover
          : props.type === "rejected"
            ? props.theme.palette.error
            : props.theme.palette.gray};

    margin: 3px 6px 3px 6px;
    border-radius: 2px;
    input {

    font-size:12px !important;
  }
  .inner {
    width: 100%;
    padding: 5px;
    /* height: 45px; */
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    .deleteIcon {
      position: absolute;
      right: 10px;
      top: 5px;

      &:hover {
        background: ${(props) =>
          props.isLoading
            ? props.theme.palette.items
            : props.theme.palette.itemsHover};
            }
        }

    .group-container{}

    .group{
      display: flex;
      justify-content: space-around;
    }

    .group-item {
      width: 85%;
      padding: 5px;
    }

    .group-remove {
      width: 15%;
      padding: 5px;
      margin-top: 10px;
      font-weight: bolder;
      height: fit-content;
      border-radius: 20%;
      transition: all 0.1s ease-in-out;

    &:hover {
      background: #2B2B2B;
      }
    }

  }
    `;

const SectionTitle = styled.div`
    font-size: 14px;
    `;

const SectionUUID = styled.div`
    font-size: 10px;
    color: ${(props) => props.theme.palette.secondary};
    `;

const TextBuilderButton = styled.p`
    text-decoration: underline;
    font-size: 10px;
    box-sizing: border-box;
    text-align: right;
    /* z-index: 2; */
    /* position: absolute; */
    margin: 0;
    right: 5px;
    margin-top: 8px;
    transition: opacity 300ms ease-out;
    `

const AddButton = styled(Button)`
    width: 50px;
    max-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
    left: 80%;
    background-color: #2B2B2B;
    border: 1px solid #2B2B2B;

    &:hover {
      background-color: #555454;
  }
    `
