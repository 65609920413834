import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa';
import useKeypress from 'react-use-keypress';
import styled from 'styled-components';

export const SearchCircularByID = ({
  value,
  width,
  placeholder = "Search by Circular ID",
  onSearch = () => { }
}) => {
  const [focused, setFocused] = useState(false);

  const handleSearch = () => {
    if (value.value) {
      onSearch(value.value);
    }
  };

  useKeypress(["Enter"], (event) => {
    if(focused) {
      event.preventDefault();
      if(value !== "") {
        handleSearch();
      }
  }});

  return (
    <div className={`d-flex search-container`}>
      <div className="search-retailer-by-name" style={{ width }}>
        <div className="menu-button" style={{ width }}>
          <div className="d-flex w-100">
            <StyledInput
              type="text"
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              value={value.value}
              placeholder={placeholder}
              onChange={(e) => {
                value.setValue(e.target.value);
              }}
            />
          </div>
          <Underline focused={focused} />
        </div>
      </div>
      <FaSearch className={"search-button"} onClick={handleSearch} />
    </div>
  )
}

export default SearchCircularByID

const StyledInput = styled.input`
  background: transparent;
  color: white;
  border: none;
  height: 32px;
  line-height: 32px;
  width: calc(100% - 24px);
  font-size: 14px;
  outline: none;
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
`;

const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: calc(100% - 24px);
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;
