import React from "react";
import styled from "styled-components";
// import { DropDown2 } from "components";
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
//import { useRetailersRollupContext } from "contexts";

import "rc-slider/assets/index.css";
import "./style.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

export const FilterSection = ({ filterOptions, isLoading, onChange }) => {
  // const {
  //   circ_week_dts = [],
  // } = useRetailersRollupContext();

  return (

    <FilterContainer>
      {/* <AccordionSection>
        {isLoading ? (
          <SkeletonTheme color="#202020" highlightColor="#444">
            <Skeleton count={16} height={24} className="my-2" />
          </SkeletonTheme>
        ) : (
          <>
            <div className="title">Filters</div>
            <DropDown2
            label="Circular Date"
            data={circ_week_dts}
            width={200}
            value={{}}
            onChange={(e) => {
              console.log(e);
            }}
          />
          </>
        )}
      </AccordionSection> */}
    </FilterContainer>
  );
};


const FilterContainer = styled.div`
  overflow: auto;
  border-bottom:2px solid ${(props) => props.theme.palette.background};
`;

// const AccordionSection = styled.div`
//   margin: 12px 12px 12px 24px;
//   padding-right: 10px;
//   min-width: 240px;
//   ::-webkit-scrollbar {
//     width: 10px;
//     background: ${(props) => props.theme.palette.background};
//   }

//   /* Track */
//   ::-webkit-scrollbar-track {
//     background: ${(props) => props.theme.palette.background};
//     border: 1px solid ${(props) => props.theme.palette.itemsHover};
//   }

//   /* Handle */
//   ::-webkit-scrollbar-thumb {
//     background: ${(props) => props.theme.palette.itemsHover};
//     cursor: pointer;
//   }

//   /* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
//     background: #555;
//     cursor: pointer;
//   }

//   .title {
//     color:white;
//     font-size:12px;
//     border-bottom: 1px solid ${(props) => props.theme.palette.itemsHover};
//     text-transform:uppercase;
//   }
// `;
