import {
  SET_LOADING,
  SET_ERROR,
  GET_RETAILERSROLLUP,
  INIT_RETAILERSROLLUP,
  SET_REFETCHING,
  SET_RETAILERSROLLUP_SEARCH,
  SET_RETAILERSROLLUP_FILTER,
} from "../types";

export default function JobReducer(state, action) {
  const { payload } = action;

  switch (action.type) {
    case INIT_RETAILERSROLLUP:
      return {
        currentPage: 0,
        circ_week_dts: [],
        circ_week_dt: undefined,
        retailers: [],
        isLoading: false,
        totalCount: 0,
        resultCount: 0,
        sortBy: "created_at",
        sortDir: "ASC",
      };
    case GET_RETAILERSROLLUP:
      return {
        ...state,
        retailers: payload.retailers,
        circ_week_dts: payload.circ_week_dts,
        isLoading: false,
        resultCount: payload.retailers.length
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload || true,
      };
    case SET_REFETCHING:
      return {
        ...state,
        isRefetching: payload || true,
      };
    case SET_RETAILERSROLLUP_SEARCH:
      return {
        ...state,
        query: payload,
      };
    case SET_RETAILERSROLLUP_FILTER:
      return {
        ...state,
        ...payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
