/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2 } from "components";
import { useInput } from "hooks";
export const OfferFreeWithTextBuilder = ({
  className,
  value,
  onChange = () => {},
}) => {
  const suffix = useInput("");
  const type = useInput("coupon");
  const typeData = [
    { id: "coupon", title: "coupon" },
    { id: "digital coupon", title: "digital coupon" },
    { id: "rebate", title: "rebate" },
  ];

  const handleUpdateValue = (t, s) => {
    onChange(`Free w/${t} ${s}`);
  };

  useEffect(() => {
    if (value && value.startsWith("Free w/")) {
      const main = value.slice(7);
      if (main.startsWith("coupon")) {
        type.setValue("coupon");
        suffix.setValue(main.slice(7));
      } else if (main.startsWith("digital coupon")) {
        type.setValue("digital coupon");
        suffix.setValue(main.slice(14));
      } else if (main.startsWith("rebate")) {
        type.setValue("rebate");
        suffix.setValue(main.slice(6));
      }
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 p-2`}>
      <Title>Offer Text Builder</Title>
      <Container className="mt-2">
        <div className="mt-auto mb-1">Free w/ </div>
        <div style={{ width: 120, marginLeft: 10 }}>
          <DropDown2
            label="Type"
            data={typeData}
            width={120}
            value={type.value}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(e, suffix.value);
            }}
          />
        </div>
        <div style={{ width: 120, marginLeft: 10 }}>
          <Input2
            label="Suffix"
            value={suffix.value}
            onChange={(e) => {
              suffix.setValue(e);
              handleUpdateValue(type.value, e);
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
