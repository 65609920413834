import { OfferItem } from "components/OfferItem";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

export const OfferList = ({
  offersData = [],
  handleAddProduct = () => { },
  className,
  displayOnly = false,
  width = ''
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log("from model", offersData)
    if (offersData.length) {
      setData(offersData)
    } else {
      setData([]);
    }
  }, [offersData])

  return (
    <Container className={className} width={width}>
      {data.length === 0 ? (
        <NoItems className="mt-2">
          <div>There is no offer available.</div>
          {!displayOnly &&
            <div>
              {" "}
              <span className="cursor-pointer" onClick={handleAddProduct}>
                <u>Create</u>
              </span>{" "}
              a new product or flag the adblock appropriately.
            </div>
          }
        </NoItems>
      ) : (
        <div className="d-flex">
          <ItemsList>
            {data.map((offer, index) => (
              <OfferItem
                key={`offer${index}`}
                className="d-flex flex-row"
                data={offer}
                displayOnly={displayOnly}
              />
            ))}
          </ItemsList>
        </div>
      )}
    </Container>
  );
};



const Container = styled.div`
  overflow: auto;
  max-height: ${(props) => props.width === 'small' ? '90px' : '100%'};
`;

const NoItems = styled.div`
  font-size: 14px;
`;

const ItemsList = styled.div`
  width: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
