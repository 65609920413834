import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  postRetailerByIdActivate,
  postRetailerByIdDeactivate,
  //postRetailerByIdActiveQueue,
  postRetailerByIdDeactivateQueue,
  postRetailerByIdActiveRequeue,
 } from "api";
import { useModal } from "hooks";
import { RetailersModal } from "modals";
import { IoSettingsSharp } from "react-icons/io5";


export const TableRow = ({ isLoading, data, tiers }) => {
  
  const [active, setActive] = useState(false);
  const [activeQueue, setActiveQueue] = useState(false);
  const retailersModal = useModal();

  const init = async () => {
    if(data) {
      setActive(data?.active);
      setActiveQueue(data?.queue_enabled);
    }
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const toggleActiveRetailer = async () => {
    if(data.active === true) {
      await postRetailerByIdDeactivate(data.id);
      setActive(false);
      data.active = false;
    }else{
      await postRetailerByIdActivate(data.id);
      setActive(true);
      data.active = true;
    }

  };

  const toggleQueueRetailer = async () => {
    if(data.queue_enabled === true) {
      await postRetailerByIdDeactivateQueue(data.id);
      setActiveQueue(false);
      data.queue_enabled = false;
    }else{
      await postRetailerByIdActiveRequeue(data.id);
      setActiveQueue(true);
      data.queue_enabled = true;
    }

  };

  const handleModal = async () => {
    retailersModal.openModal();
  };

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container status={data.is_active}>
      <div className="inner">
        <RowItem>{data?.name}</RowItem>
        <RowItem>{data?.parent_name}</RowItem>
        <RowItem>{data?.ext_id}</RowItem>
        <RowItem>{data?.tier_ext_id}</RowItem>
        <RowItem>{data?.email_tier_ext_id}</RowItem>
        <RowItem>{data?.newspaper_tier_ext_id}</RowItem>
        <RowItem>{data?.web_tier_ext_id}</RowItem>
        <RowItem>{data?.mailer_tier_ext_id}</RowItem>
        <RowItem>
          <Link onClick={toggleQueueRetailer}>
            {activeQueue === true ? "Deactivate" : "Activate"}
          </Link>
        </RowItem>
        <RowItem>
          <Link onClick={toggleActiveRetailer}>
            {active === true ? "Deactivate" : "Activate"}
          </Link>
        </RowItem>
          <RowItem>
            <IconButton className="my-auto" onClick={handleModal}>
              <IoSettingsSharp color="white" />
            </IconButton>
          </RowItem>
      </div>      
        <RetailersModal
          {...retailersModal}
          data={data}
          tiers={tiers}
        />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.items};
  display: block;

  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  border-left: 12px solid
    ${(props) =>
      props.status === true
        ? props.theme.palette.success
        : props.theme.palette.secondary};

  margin: 3px 6px 3px 12px;
  border-radius: 2px;
  .inner {
    display: flex;
    width: 100%;
    padding: 0 0px 0 32px;
    height: 88px;
    position:relative;
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      flex: 1;
    }
    & > div:nth-child(2) {
      width: 330px;
    }
    & > div:nth-child(3) {
      width: 100px;
    }
    & > div:nth-child(4) {
      width: 50px;
    }
    & > div:nth-child(5) {
      width: 50px;
    }
    & > div:nth-child(6) {
      width: 50px;
    }
    & > div:nth-child(7) {
      width: 50px;
    }
    & > div:nth-child(8) {
      width: 50px;
    }
    & > div:nth-child(9) {
      width: 200px;
    }
    & > div:nth-child(10) {
      width: 200px;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 16px auto 0;
  span {
    color:${(props) => props.theme.palette.error};
  }
`;

const Link = styled.div`
  text-decoration: underline;
  &:hover {
    color:${(props) => props.theme.palette.primaryHover}
  }
  cursor:pointer;
`;

const IconButton = styled.span`
  cursor: pointer;
  margin: 0 20px;
  position:absolute;
  font-size: 24px;
  right:10px;
  top: 24px;
  &:hover {
    svg {
      transition: all 0.3s ease;
      fill: ${(props) => props.theme.palette.primary} !important;
    }
  }
`;
