/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
// import { DropDown2 } from "components";
import { useInput } from "hooks";
// import { AGREEMENT_VALUES } from "config";
import AutocompleteFreeText from "components/AutocompleteFreeText";
import { AGREEMENT_OFFER_PREDICTIVE_TEXT } from "config";
export const OfferAgreementTextBuilder = ({
  className,
  value,
  onChange = () => {},
}) => {
  const val = useInput("Qualified Upgrade");

  const handleUpdateValue = (v) => {
    onChange(`${v}`);
  };

  useEffect(() => {
    if (value) {
      val.setValue(value);
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">

        <div style={{ width: 300 }}>
        <AutocompleteFreeText
          label='Agreement'
          data={AGREEMENT_OFFER_PREDICTIVE_TEXT}
          width={300}
          value={val.value}
          onChange={(e) => {
            val.setValue(e);
            handleUpdateValue(e);
          }}
        />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
