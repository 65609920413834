import React from "react";
import styled from "styled-components";
import { TableHeaderColumn } from "components";

export const TableHeader = ({ sortCol, sortType, onSort = () => {} }) => {

  return (
    <Container>
      <TableHeaderColumn></TableHeaderColumn>
      <TableHeaderColumn label="Name"></TableHeaderColumn>
      <TableHeaderColumn label="Parent Name"></TableHeaderColumn>
      <TableHeaderColumn label="Ext ID"></TableHeaderColumn>
      <TableHeaderColumn label="Tier"></TableHeaderColumn>
      <TableHeaderColumn label="Email"></TableHeaderColumn>
      <TableHeaderColumn label="Newspaper"></TableHeaderColumn>
      <TableHeaderColumn label="Web"></TableHeaderColumn>
      <TableHeaderColumn label="Mail"></TableHeaderColumn>
      <TableHeaderColumn label="Queue"></TableHeaderColumn>
      <TableHeaderColumn label="Active"></TableHeaderColumn>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 0 0px 0 32px;
  color: white;
  position:relative;
  font-size: ${(props) => props.theme.palette.m};
  margin: 28px 12px 15px 12px;
  & > div:nth-child(1) {
    flex: 0;
  }
  & > div:nth-child(2) {
    width: 350px;
  }
  & > div:nth-child(3) {
    width: 270px;
  }
  & > div:nth-child(4) {
    width: 110px;
  }
  & > div:nth-child(5) {
    width: 65px;
  }
  & > div:nth-child(6) {
    width: 50px;
  }
  & > div:nth-child(7) {
    width: 85px;
  }
  & > div:nth-child(8) {
    width: 65px;
  }
  & > div:nth-child(9) {
    width: 90px;
  }
  & > div:nth-child(10) {
    width: 220px;
  }
`;