import { API } from "utils";

export const getSectionById = async (id) => {
  try {
    const response = await API().get(`api/sections/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deleteSectionById = async (id) => {
  try {
    const response = await API().delete(`api/sections/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
