/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2 } from "components";
import { useInput } from "hooks";
export const IncentiveCouponTextBuilder = ({
  className,
  value = "",
  onChange = () => {},
}) => {
  const val = useInput("");

  const handleUpdateValue = (v) => {
    onChange(`Receive $ Coupons ${v}`);
  };

  useEffect(() => {
    if (value.startsWith("Receive $ Coupons")) {
      val.setValue(value.slice(17).trim());
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <div className="mt-auto mb-1">Receive $ Coupons</div>
        <div style={{ width: 120, marginLeft: 10 }}>
          <Input2
            label="Value"
            value={val.value}
            type="float"
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(e);
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
